import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Container } from 'components/Container'
import { Footer } from 'components/Footer'
import { Option, OptionType } from 'components/Option'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import femaleImage from 'assets/images/intro-female-3.png'
import maleImage from 'assets/images/intro-male-3.png'

import { CustomPageId } from 'page-constants'
import { Gender } from 'root-constants'

import { StyledGenderV3 as S } from './GenderV3.styles'
import { QUESTION } from './constants'

export const GenderV3: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()

  const { title, subtitle } = useDynamicOBConfig()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: CustomPageId.GENDER,
    question: title || QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: CustomPageId.GENDER,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
        eventLogger.logFirstPageCompleted({
          question: title || QUESTION,
          answers: value,
        })
      },
    }),
    [handleChange, handleContinue, setIsAnswersDisabled, title],
  )

  return (
    <Container>
      <S.Title>{title || <Trans i18nKey="onboarding.gender.title" />}</S.Title>
      <S.Subtitle>{subtitle || t('onboarding.gender.subtitle')}</S.Subtitle>
      <S.CallToAction>{t('onboarding.gender.callToAction')}</S.CallToAction>

      <S.Actions>
        <Option
          {...optionProps}
          disabled={isAnswersDisabled}
          value={Gender.MALE}
        >
          <S.Action>
            <S.MaleImage src={maleImage} alt="" />
            <S.AnswerForMale />
            <S.MaleGender>{t('onboarding.gender.male')}</S.MaleGender>
          </S.Action>
        </Option>
        <Option
          {...optionProps}
          disabled={isAnswersDisabled}
          value={Gender.FEMALE}
        >
          <S.Action>
            <S.FemaleImage src={femaleImage} alt="" />
            <S.AnswerForFemale />
            <S.FemaleGender>{t('onboarding.gender.female')}</S.FemaleGender>
          </S.Action>
        </Option>
      </S.Actions>
      <div>
        <Footer />
      </div>
    </Container>
  )
}
