import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'
import { Button } from 'storybook-ui'

import { resetErrorAction } from 'root-redux/actions/common'
import { getUserStatusAction } from 'root-redux/actions/user'
import { selectActionList, selectError } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store'

import { useLockScroll } from 'hooks/useLockScroll'

import { ANIMATION_PATH } from 'modules/purchase/components/PaymentWaitingModal/constants'
import { PaymentState } from 'modules/purchase/constants'
import { select3DSecureIframeUrl } from 'modules/purchase/redux/selects'

import { CDN_FOLDER_LINK, Color } from 'root-constants'

import { CHECK_3D_SECURE, PURCHASE } from '../../redux/actions/common'
import { StyledPaymentWaitingModal as S } from './PaymentWaitingModal.styles'

type TProps = {
  isPaymentWaitingShown: boolean
  setIsPaymentWaitingShown: (value: boolean) => void
}

export const PaymentWaitingModal: React.FC<TProps> = ({
  isPaymentWaitingShown,
  setIsPaymentWaitingShown,
}) => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()

  const rootRef = useRef<HTMLDivElement>(null)
  const loadingAnimationRef = useRef<HTMLDivElement | null>(null)

  const fetchingActionsList = useSelector(selectActionList)
  const error = useSelector(selectError)
  const threeDSecureIframeURL = useSelector(select3DSecureIframeUrl)
  const uuid = useSelector(selectUUID)

  const [paymentState, setPaymentState] = useState<PaymentState>(
    PaymentState.LOADING,
  )

  const isPurchaseInProcess = useMemo(
    () =>
      fetchingActionsList.includes(PURCHASE) ||
      fetchingActionsList.includes(CHECK_3D_SECURE),
    [fetchingActionsList],
  )

  useEffect(() => {
    if (isPurchaseInProcess) {
      setIsPaymentWaitingShown(true)
    }

    if (isPurchaseInProcess && loadingAnimationRef.current) {
      lottie.loadAnimation({
        container: loadingAnimationRef.current,
        path: `${CDN_FOLDER_LINK}${ANIMATION_PATH}`,
        loop: true,
        name: 'waitingAnimation',
      })
    }

    if (!isPurchaseInProcess && isPaymentWaitingShown && error) {
      setPaymentState(PaymentState.ERROR)
    }

    if (
      !isPurchaseInProcess &&
      isPaymentWaitingShown &&
      !error &&
      !threeDSecureIframeURL
    ) {
      setPaymentState(PaymentState.SUCCESS)
    }

    return () => lottie.destroy('waitingAnimation')
  }, [
    error,
    isPurchaseInProcess,
    isPaymentWaitingShown,
    setIsPaymentWaitingShown,
    threeDSecureIframeURL,
  ])

  useEffect(() => {
    if (loadingAnimationRef.current && paymentState === PaymentState.LOADING) {
      lottie.loadAnimation({
        container: loadingAnimationRef.current,
        path: `${CDN_FOLDER_LINK}${ANIMATION_PATH}`,
        loop: true,
        name: 'paymentStateAnimation',
      })
    }

    return () => lottie.destroy('paymentStateAnimation')
  }, [paymentState])

  useEffect(() => {
    if (isPaymentWaitingShown && rootRef.current) {
      rootRef.current.focus()
    }
  }, [isPaymentWaitingShown])

  const handleSuccessButtonClick = () => {
    setIsPaymentWaitingShown(false)
    dispatch(getUserStatusAction(uuid))
  }

  const handleResetError = useCallback(() => {
    setIsPaymentWaitingShown(false)
    setPaymentState(PaymentState.LOADING)
    dispatch(resetErrorAction())
  }, [dispatch, setIsPaymentWaitingShown])

  useLockScroll(isPaymentWaitingShown)

  return (
    <S.Wrapper isShown={isPaymentWaitingShown} ref={rootRef} tabIndex={-1}>
      <S.Content>
        <S.LottieContainer>
          {paymentState === PaymentState.LOADING && (
            <>
              <S.Animation ref={loadingAnimationRef} />
              <S.Title>{t('purchase.paymentWaiting.processing')}</S.Title>
              <S.Subtitle>
                {t('purchase.paymentWaiting.processingPayment')}
              </S.Subtitle>
            </>
          )}
          {paymentState === PaymentState.SUCCESS && (
            <>
              <S.Title>
                {t('purchase.paymentWaiting.paymentWasSuccessful')}
              </S.Title>
              <Button
                width="100%"
                backgroundColor={Color.GREEN_400}
                color={Color.WHITE}
                fontSize="17px"
                fontWeight="700"
                lineHeight="24px"
                borderRadius="30px"
                padding="16px"
                onClick={handleSuccessButtonClick}
              >
                {t('purchase.paymentWaiting.toLoginPage')}
              </Button>
            </>
          )}
          {paymentState === PaymentState.ERROR && (
            <>
              <S.Title>
                {t('purchase.paymentWaiting.errorDuringPayment')}
              </S.Title>
              <S.Subtitle>
                {error?.description ||
                  error ||
                  t('purchase.paymentWaiting.defaultError')}
              </S.Subtitle>
              <Button
                width="100%"
                backgroundColor={Color.RED_400}
                color={Color.WHITE}
                fontSize="17px"
                fontWeight="700"
                lineHeight="24px"
                borderRadius="30px"
                padding="16px"
                onClick={handleResetError}
              >
                {t('actions.tryAgain')}
              </Button>
            </>
          )}
        </S.LottieContainer>
      </S.Content>
    </S.Wrapper>
  )
}
