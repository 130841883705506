import styled from 'styled-components'

import checkIcon from 'assets/images/kit-check-icon.svg'

import { Color } from 'root-constants'

type TMarkIcon = {
  isSelected: boolean
}

export const StyledFinalReview = {
  Title: styled.h2`
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
    color: ${Color.GREY_100};
  `,
  Subtitle: styled.p`
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: ${Color.GREY_101};
    text-align: center;
    margin-bottom: 24px;
  `,
  FieldContainer: styled.div`
    margin-bottom: 16px;
  `,
  SectionTitle: styled.h3`
    font-weight: 700;
    font-size: 17px;
    line-height: 24px;
    color: ${Color.GREY_100};
    margin-bottom: 8px;
  `,
  Gender: styled.div`
    //margin-bottom: 24px;
  `,
  Label: styled.label`
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    padding-left: 28px;
    position: relative;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  `,
  MarkIcon: styled.span<TMarkIcon>`
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    opacity: 0.5;
    border: 1px solid ${Color.GREY_1000};
    position: absolute;
    top: 0;
    left: 0;

    ${({ isSelected }) =>
      isSelected &&
      `
          content: url(${checkIcon});
          border: none;
        `};
  `,
  MarkValue: styled.span`
    padding: 0 0 0 8px;
  `,
  Radio: styled.input`
    width: 1px;
    height: 1px;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: -1;

    &:disabled + span {
      border-color: ${Color.GREY_102};
    }

    &:disabled ~ span {
      color: ${Color.GREY_102};
    }

    &:checked + span {
      opacity: 1;
      border: none;
    }
    &:checked ~ span {
      opacity: 1;
      border: none;
      color: ${Color.GREY_100};
    }
  `,
  List: styled.ul``,
  Item: styled.li`
    &:not(:last-of-type) {
      margin: 0 0 24px 0;
    }
  `,
}
