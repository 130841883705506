import { AgeRangeValue, PrimaryGoalValue } from 'value-constants'

import female3V2 from 'assets/images/build-habits-perfect-solution-female-v2.png'
import female3 from 'assets/images/build-habits-perfect-solution-female.png'
import male3 from 'assets/images/build-habits-perfect-solution-male.png'
import female1V2 from 'assets/images/get-fit-perfect-solution-female-v2.png'
import female1 from 'assets/images/get-fit-perfect-solution-female.png'
import male1 from 'assets/images/get-fit-perfect-solution-male.png'
import female2V2 from 'assets/images/lose-weight-perfect-solution-female-v2.png'
import female2 from 'assets/images/lose-weight-perfect-solution-female.png'
import male2 from 'assets/images/lose-weight-perfect-solution-male.png'

import { Gender } from 'root-constants'

export const GENDER_MAP = {
  [Gender.FEMALE]: 'commonComponents.gender.women',
  [Gender.MALE]: 'commonComponents.gender.men',
}

const AGES_DEFAULT = {
  [AgeRangeValue.AR_18_29]: 'onboarding.perfectSolution.twenties',
  [AgeRangeValue.AR_30_39]: 'onboarding.perfectSolution.thirties',
  [AgeRangeValue.AR_40_49]: 'onboarding.perfectSolution.forties',
  [AgeRangeValue.AR_45_49]: 'onboarding.perfectSolution.forties',
  [AgeRangeValue.AR_50_54]: 'onboarding.perfectSolution.fifties',
  [AgeRangeValue.AR_54_59]: 'onboarding.perfectSolution.fifties',
  [AgeRangeValue.AR_50_PLUS]: 'onboarding.perfectSolution.fifties',
  [AgeRangeValue.AR_60_PLUS]: 'onboarding.perfectSolution.sixties',
}

const AGES_STRONG = {
  [AgeRangeValue.AR_18_29]: 'onboarding.perfectSolution.twentiesStrong',
  [AgeRangeValue.AR_30_39]: 'onboarding.perfectSolution.thirtiesStrong',
  [AgeRangeValue.AR_40_49]: 'onboarding.perfectSolution.fortiesStrong',
  [AgeRangeValue.AR_45_49]: 'onboarding.perfectSolution.fortiesStrong',
  [AgeRangeValue.AR_50_54]: 'onboarding.perfectSolution.fiftiesStrong',
  [AgeRangeValue.AR_54_59]: 'onboarding.perfectSolution.fiftiesStrong',
  [AgeRangeValue.AR_50_PLUS]: 'onboarding.perfectSolution.fiftiesStrong',
  [AgeRangeValue.AR_60_PLUS]: 'onboarding.perfectSolution.sixtiesStrong',
}

export const PERFECT_SOLUTION_MAP_V1 = {
  [PrimaryGoalValue.GET_FIT]: {
    imgSrc: {
      [Gender.FEMALE]: female1,
      [Gender.MALE]: male1,
    },
    firstText: 'onboarding.perfectSolution.getFit.firstText',
    secondText: 'onboarding.perfectSolution.getFit.secondText',
    ages: AGES_DEFAULT,
  },
  [PrimaryGoalValue.LOSE_WEIGHT]: {
    imgSrc: {
      [Gender.FEMALE]: female2,
      [Gender.MALE]: male2,
    },
    firstText: 'onboarding.perfectSolution.loseWeight.firstText',
    secondText: 'onboarding.perfectSolution.loseWeight.secondText',
    ages: AGES_STRONG,
  },
  [PrimaryGoalValue.BUILD_HEALTHY_HABITS]: {
    imgSrc: {
      [Gender.FEMALE]: female3,
      [Gender.MALE]: male3,
    },
    firstText: 'onboarding.perfectSolution.buildHealthyHabits.firstText',
    secondText: 'onboarding.perfectSolution.buildHealthyHabits.secondText',
    ages: AGES_DEFAULT,
  },
}

export const PERFECT_SOLUTION_MAP_V2 = {
  ...PERFECT_SOLUTION_MAP_V1,
  [PrimaryGoalValue.GET_FIT]: {
    ...PERFECT_SOLUTION_MAP_V1[PrimaryGoalValue.GET_FIT],
    imgSrc: {
      [Gender.FEMALE]: female1V2,
      [Gender.MALE]: male1,
    },
  },
  [PrimaryGoalValue.LOSE_WEIGHT]: {
    ...PERFECT_SOLUTION_MAP_V1[PrimaryGoalValue.LOSE_WEIGHT],
    imgSrc: {
      [Gender.FEMALE]: female2V2,
      [Gender.MALE]: male2,
    },
  },
  [PrimaryGoalValue.BUILD_HEALTHY_HABITS]: {
    ...PERFECT_SOLUTION_MAP_V1[PrimaryGoalValue.BUILD_HEALTHY_HABITS],
    imgSrc: {
      [Gender.FEMALE]: female3V2,
      [Gender.MALE]: male3,
    },
  },
}

const KIT_PERFECT_SOLUTION_IMAGES = {
  [Gender.FEMALE]: '/images/kit-perfect-solution-female',
  [Gender.MALE]: '/images/kit-perfect-solution-male',
}

export const KIT_PERFECT_SOLUTION_MAP = {
  [PrimaryGoalValue.GET_FIT]: {
    firstText: 'onboarding.perfectSolution.getFit.firstTextV2',
    secondText: 'onboarding.perfectSolution.getFit.secondText',
    imgSrc: KIT_PERFECT_SOLUTION_IMAGES,
    ages: AGES_STRONG,
  },
  [PrimaryGoalValue.LOSE_WEIGHT]: {
    firstText: 'onboarding.perfectSolution.loseWeight.firstTextV2',
    secondText: 'onboarding.perfectSolution.loseWeight.secondText',
    imgSrc: KIT_PERFECT_SOLUTION_IMAGES,
    ages: AGES_STRONG,
  },
  [PrimaryGoalValue.BUILD_HEALTHY_HABITS]: {
    firstText: 'onboarding.perfectSolution.buildHealthyHabits.firstText',
    secondText: 'onboarding.perfectSolution.buildHealthyHabits.secondText',
    imgSrc: KIT_PERFECT_SOLUTION_IMAGES,
    ages: AGES_STRONG,
  },
}
