import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import 'firebase/auth'

import { ErrorNotification } from 'components/ErrorNotification'
import { Modal } from 'components/Modal'
import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'
import { Spinner } from 'components/Spinner'

import { resetErrorAction } from 'root-redux/actions/common'
import { bindUserAction } from 'root-redux/actions/user'
import { selectError, selectIsFetching } from 'root-redux/selects/common'
import { selectUserOnboardingEmail } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store'

import { useAuthObserver } from 'hooks/useAuthObserver'
import { useEmailInputField } from 'hooks/useEmailInputField'
import { useGetRedirectResult } from 'hooks/useGetRedirectResult'
import { useInitFirebase } from 'hooks/useInitFirebase'
import { usePasswordInputField } from 'hooks/usePasswordInputField'

import {
  registerWithEmailFirebaseAction,
  resetEmailErrorMessageAction,
  resetPasswordErrorMessageAction,
  selectEmailErrorMessage,
  selectPasswordErrorMessage,
} from 'modules/login/redux'

import { eventLogger } from 'services/eventLogger.service'

import { LoginMethod } from 'root-constants'

import { StyledLogin as S } from './Login.styles'
import { Disclaimer } from './components/Disclaimer'

export const Login: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()

  const error = useSelector(selectError)
  const isFetching = useSelector(selectIsFetching)
  const emailErrorMessage = useSelector(selectEmailErrorMessage)
  const passwordErrorMessage = useSelector(selectPasswordErrorMessage)
  const userOnboardingEmail = useSelector(selectUserOnboardingEmail)

  const [isModalShown, setIsModalShown] = useState(false)
  const [isFirebaseDataLoading, setIsFirebaseDataLoading] = useState(false)

  const [email, setEmail] = useEmailInputField(emailErrorMessage, () =>
    dispatch(resetEmailErrorMessageAction()),
  )

  const [password, setPassword] = usePasswordInputField(
    passwordErrorMessage,
    () => dispatch(resetPasswordErrorMessageAction()),
  )

  const errorText = useMemo(() => {
    if (!email.isValid && !password.isValid) return t('login.invalidFields')
    if (!email.isValid) return email.firebaseError || email.validationText
    if (!password.isValid) {
      return password.firebaseError || password.validationText
    }

    return ''
  }, [
    t,
    email.isValid,
    email.validationText,
    email.firebaseError,
    password.isValid,
    password.validationText,
    password.firebaseError,
  ])

  const isComplete = useMemo(
    () =>
      email.isValid &&
      email.value !== '' &&
      password.isValid &&
      password.value !== '',
    [email.isValid, email.value, password.isValid, password.value],
  )

  useEffect(() => {
    setEmail((prevState) => ({ ...prevState, value: userOnboardingEmail }))
  }, [setEmail, userOnboardingEmail])

  useEffect(() => {
    error && setIsModalShown(true)
  }, [error])

  useEffect(() => {
    eventLogger.logCreateAccountShown()
  }, [])

  const handleContinueWithEmail = useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault()

      eventLogger.logLoginMethodSelected({ method: LoginMethod.EMAIL })
      dispatch(
        registerWithEmailFirebaseAction({
          email: email.value,
          password: password.value,
        }),
      )
    },
    [dispatch, email.value, password.value],
  )

  const authStateChangeHandler = useCallback(
    (token: string) => {
      dispatch(bindUserAction(token))
    },
    [dispatch],
  )

  useInitFirebase()
  useGetRedirectResult(authStateChangeHandler, setIsFirebaseDataLoading)
  useAuthObserver(authStateChangeHandler)

  return (
    <form onSubmit={handleContinueWithEmail}>
      <KitPageContainer
        paddingTop={0}
        isContinueButtonDisabled={!isComplete || isFetching}
        hasContinueButton
        continueButtonType="submit"
      >
        {(isFetching || isFirebaseDataLoading) && <Spinner />}
        <PageTitle marginBottom={24}>{t('login.title')}</PageTitle>
        <S.EmailLogin
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
        />

        <S.DisclaimerContainer>
          <ErrorNotification errorText={errorText} />
          <Disclaimer />
        </S.DisclaimerContainer>

        <Modal
          onClose={() => {
            setIsModalShown(false)
            dispatch(resetErrorAction())
          }}
          isShown={isModalShown}
          error={error}
        />
      </KitPageContainer>
    </form>
  )
}
