import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledMoneyBackGuaranteeV3 = {
  GuaranteeContainer: styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${Color.GREY_130};
    border-radius: 32px;
    padding: 20px 16px 24px 16px;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 120/120;
    height: 120px;
    width: 120px;
    margin: 0 0 16px 0;
  `,
  TextContainer: styled.div`
    margin: 0 0 16px 0;
    padding: 0 0 16px 0;
    border-bottom: 1px solid ${Color.GREY_400};
  `,
  Title: styled.h4`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${Color.GREY_100};
    text-transform: capitalize;
    margin: 0 0 8px 0;
  `,
  Text: styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${Color.GREY_101};
  `,
  CheckoutTitle: styled.h4`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${Color.GREY_100};
    text-transform: capitalize;
    margin: 0 0 12px 0;
  `,
  CheckoutMethods: styled.div`
    display: flex;
    flex-wrap: wrap;
    height: 54px;

    > :not(:last-child) {
      margin: 0 6px 6px 0;
    }

    img {
      width: 36px;
      height: 24px;
    }
  `,
}
