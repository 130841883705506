import React from 'react'
import { Trans } from 'react-i18next'

import { Button, buttonTheme } from 'storybook-ui'

import { KitPageContainer } from 'components/PageContainer'
import { Spinner } from 'components/Spinner'

import {
  Disclaimer,
  FAQList,
  MoneyBackGuarantee,
  PlanHighlights,
  SubscriptionsBlock,
} from 'modules/purchase/components'
import { CustomerReviewsV4 } from 'modules/purchase/components/CustomerReviewsV4'
import { HowItWorks } from 'modules/purchase/components/HowItWorks'
import { RefundBanner } from 'modules/purchase/components/RefundBanner'

import { CDN_FOLDER_LINK } from 'root-constants'

import { StyledCancelOffer as S } from './CancelOffer.styles'
import { IMAGE_PATH } from './constants'
import { useCancelOffer } from './useCancelOffer'

export const CancelOfferV1: React.FC = () => {
  const { hasPrices, language, handleGetPlan, discountFromSubscriptions } =
    useCancelOffer()

  if (!hasPrices) return <Spinner />

  const GetPlanButton = () => (
    <Button
      width="100%"
      margin="0 auto"
      style={{ textTransform: 'capitalize' }}
      theme={buttonTheme.NUTRIMATE_PRIMARY}
      onClick={handleGetPlan}
    >
      <Trans i18nKey="actions.getMyPlan" />
    </Button>
  )

  return (
    <KitPageContainer paddingTop={0}>
      <S.DiscountBanner
        imgSrc={`${CDN_FOLDER_LINK}${IMAGE_PATH}-${language}.png`}
      >
        <S.DiscountContent>
          <S.DiscountTitle>
            <Trans i18nKey="cancelOffer.discountTitle" />
          </S.DiscountTitle>
          <S.DiscountValue>
            <Trans
              i18nKey="cancelOffer.discountValue"
              values={{ discountValue: discountFromSubscriptions }}
            />
          </S.DiscountValue>
        </S.DiscountContent>
      </S.DiscountBanner>

      <S.PlanDescription>
        <Trans i18nKey="cancelOffer.planDescription" />
      </S.PlanDescription>

      <S.SubscriptionsContainer>
        <SubscriptionsBlock titleMargin={24} isCancelOffer />
        <MoneyBackGuarantee />
        <GetPlanButton />
      </S.SubscriptionsContainer>

      <RefundBanner />

      <HowItWorks>
        <GetPlanButton />
      </HowItWorks>

      <PlanHighlights />

      <S.CustomerReviewsWrap>
        <CustomerReviewsV4 />
      </S.CustomerReviewsWrap>

      <RefundBanner />

      <S.SubscriptionsContainerV2>
        <SubscriptionsBlock titleMargin={16} isCancelOffer />
        <GetPlanButton />
        <Disclaimer />
      </S.SubscriptionsContainerV2>

      <FAQList />
      <S.FeaturedBlockV2 />
      <Disclaimer />
    </KitPageContainer>
  )
}
