import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectDynamicDiscount } from 'root-redux/selects/common'

import { convertTextFromSnakeCase } from 'helpers/convertTextFromSnakeCase'

import { getDecoratedTimerValue } from 'modules/purchase/helpers/rootHelpers'
import { selectSubscriptionTimerValue } from 'modules/purchase/redux/selects'

import { DEFAULT_DYNAMIC_DISCOUNT_AMOUNT } from 'root-constants'

import { StyledDiscountBlockV2 as S } from './DiscountBlockV2.styles'

type TProps = {
  className?: string
}

export const DiscountBlockV2: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  const timerValue = useSelector(selectSubscriptionTimerValue)
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  const discount = useMemo(
    () =>
      convertTextFromSnakeCase(
        t('purchase.discountBlockV2.discount', {
          discount: dynamicDiscount?.amount || DEFAULT_DYNAMIC_DISCOUNT_AMOUNT,
        }),
      ),
    [dynamicDiscount?.amount, t],
  )

  const { minutes, seconds } = useMemo(() => {
    if (!timerValue) {
      return {
        minutes: '00',
        seconds: '00',
      }
    }

    return getDecoratedTimerValue(timerValue, false)
  }, [timerValue])

  return (
    <S.ContentBlock className={className} theme={dynamicDiscount?.theme}>
      <S.Discount>{discount}</S.Discount>
      <S.DiscountDivider />
      <S.ContentRow>
        <S.DiscountLabel>
          {t('purchase.discountBlockV2.discountLabel')}
        </S.DiscountLabel>
        <S.ContentColumn>
          <S.TimerTitle>
            {t('purchase.discountBlockV2.applicable')}
          </S.TimerTitle>
          <S.TimerBlock>
            <S.TimerColumn>
              <S.TimerValue>{minutes}</S.TimerValue>
              <S.TimerUnits>{t('commonComponents.timer.minutes')}</S.TimerUnits>
            </S.TimerColumn>
            <S.TimerColumn>
              <S.TimerValue>{seconds}</S.TimerValue>
              <S.TimerUnits>{t('commonComponents.timer.seconds')}</S.TimerUnits>
            </S.TimerColumn>
          </S.TimerBlock>
        </S.ContentColumn>
      </S.ContentRow>
    </S.ContentBlock>
  )
}
