import { composeWithDevTools } from '@redux-devtools/extension'
import { applyMiddleware, legacy_createStore as createStore } from 'redux'
import thunk from 'redux-thunk'

import { getCurrentEnvironment } from 'helpers/getCurrentEnvironment'

import { IAction, IAppState } from 'models/store.model'

import rootReducer from './rootReducer'
import { TAppStore } from './types'

const { isDevEnvironment } = getCurrentEnvironment()

const enhancer = composeWithDevTools(applyMiddleware(thunk))
export const store: TAppStore = createStore<IAppState, IAction<any>, any, any>(
  rootReducer,
  enhancer,
)

/*
TODO: research how to make a hot reload for the redux store, it doesn't work now.
https://redux.js.org/usage/configuring-your-store/#hot-reloading
*/
if (isDevEnvironment && import.meta.webpackHot) {
  import.meta.webpackHot.accept('./rootReducer', () =>
    store.replaceReducer(rootReducer),
  )
}
