import { useEffect, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

import { FBConsentValue } from 'value-constants'

import { selectUUID, selectUserCountryCode } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'

export const useFacebookPixelInitialization = (
  facebookPixelIds: string[],
): void => {
  const uuid = useSelector(selectUUID)
  const userCountryCode = useSelector(selectUserCountryCode)

  const { isEUUser, hasAdvertisingAndTargetingCookie, cookieConsent } =
    useCookieConsentAnswer()

  useLayoutEffect(() => {
    if (!window.fbq || !facebookPixelIds.length || !uuid || !userCountryCode) {
      return
    }

    facebookPixelIds.forEach((facebookPixelId) => {
      window.fbq('init', facebookPixelId, { external_id: uuid })
    })

    window.fbq('track', 'PageView')
  }, [
    facebookPixelIds,
    hasAdvertisingAndTargetingCookie,
    isEUUser,
    cookieConsent,
    uuid,
    userCountryCode,
  ])

  useEffect(() => {
    if (!window.fbq || !isEUUser) return

    window.fbq(
      'consent',
      hasAdvertisingAndTargetingCookie
        ? FBConsentValue.GRANT
        : FBConsentValue.REVOKE,
    )
  }, [hasAdvertisingAndTargetingCookie, isEUUser])
}
