import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { Container } from 'components/Container'
import { Footer } from 'components/Footer'
import { Option, OptionType } from 'components/Option'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'
import { useTitleFromUrl } from 'hooks/useTitleFromUrl'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import femaleImage from 'assets/images/intro-female.png'
import maleImage from 'assets/images/intro-male.png'

import { CustomPageId } from 'page-constants'
import { FEATURE_KEYS, Gender as GenderValue } from 'root-constants'

import { StyledGender as S } from './Gender.styles'
import { QUESTION } from './constants'

export const Gender: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const isVariantB = useFeatureIsOn(FEATURE_KEYS.mf_title_test)

  const { title: titleFromUrl, subText: subtitleFromUrl } = useTitleFromUrl()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: CustomPageId.GENDER,
    question: titleFromUrl || QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: CustomPageId.GENDER,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
        eventLogger.logFirstPageCompleted({
          question: titleFromUrl || QUESTION,
          answers: value,
        })
      },
    }),
    [handleChange, handleContinue, setIsAnswersDisabled, titleFromUrl],
  )

  return (
    <Container>
      <S.Title>
        {titleFromUrl || isVariantB ? (
          <Trans i18nKey="onboarding.gender.subtitle" />
        ) : (
          <Trans i18nKey="onboarding.gender.title" />
        )}
      </S.Title>
      <S.Subtitle>
        {subtitleFromUrl || t('onboarding.gender.subtitle')}
      </S.Subtitle>
      <S.CallToAction>{t('onboarding.gender.callToAction')}</S.CallToAction>

      <S.Actions>
        <Option
          {...optionProps}
          disabled={isAnswersDisabled}
          value={GenderValue.MALE}
        >
          <S.Action>
            <S.MaleImage src={maleImage} alt="" />
            <S.AnswerForMale />
            <S.MaleGender>{t('onboarding.gender.male')}</S.MaleGender>
          </S.Action>
        </Option>
        <Option
          {...optionProps}
          disabled={isAnswersDisabled}
          value={GenderValue.FEMALE}
        >
          <S.Action>
            <S.FemaleImage src={femaleImage} alt="" />
            <S.AnswerForFemale />
            <S.FemaleGender>{t('onboarding.gender.female')}</S.FemaleGender>
          </S.Action>
        </Option>
      </S.Actions>
      <Footer />
    </Container>
  )
}
