import { RefObject, createRef, useEffect, useRef, useState } from 'react'

import { useElementViewportVisibility } from 'hooks/useElementViewportVisibility'

import { isWebView } from 'helpers/isWebView'

import { VIDEO_REVIEWS } from 'modules/purchase/components/VideoReviews/constants'

import { eventLogger } from 'services/eventLogger.service'

export const useVideoReviews = () => {
  const [activeVideoID, setActiveVideoID] = useState('')
  const { current: alreadyPlayedVideos } = useRef<Set<string>>(new Set([]))
  const isAutoplayStarted = useRef(false)
  const isEventAdded = useRef(false)
  const videoBlock = useRef<HTMLDivElement | null>(null)
  const videoRefs = useRef(
    VIDEO_REVIEWS.map(() => createRef<HTMLVideoElement>()),
  )
  const isVideosInViewport = useElementViewportVisibility(videoBlock)
  const isBrowser = !isWebView()

  const onClick = async (ref: RefObject<HTMLVideoElement>) => {
    const currentRef = ref.current
    if (!currentRef) return
    const { id } = currentRef

    if (activeVideoID === id) {
      setActiveVideoID('')
    } else {
      setActiveVideoID(id)
      currentRef.currentTime = 0
      await currentRef.play()
    }

    if (!alreadyPlayedVideos.has(id)) {
      eventLogger.logVideoReviewTap({ videoKey: id })
      alreadyPlayedVideos.add(id)
    }
  }

  const startAutoPlay = () => {
    if (isAutoplayStarted.current) return

    videoRefs.current?.forEach((videoRef) => {
      const isAvailableToPlay = videoRef.current?.canPlayType('video/mp4')
      if (!isAvailableToPlay) return

      try {
        videoRef.current?.play()
      } catch (error) {
        console.error('Error playing video:', error)
      }
    })
    isAutoplayStarted.current = true
  }

  const handleBrowserVideos = () => {
    videoRefs.current?.forEach((videoRef) => {
      const isAvailableToPlay = videoRef.current?.canPlayType('video/mp4')
      if (!isAvailableToPlay) return

      if (isVideosInViewport) {
        try {
          videoRef.current?.play()
        } catch (error) {
          console.error('Error playing video:', error)
        }
      } else {
        videoRef.current?.pause()
      }
    })
  }

  const handleMobileEvents = (controller: AbortController | null) => {
    const options = {
      signal: controller?.signal,
      once: true,
      passive: true,
    }
    window.addEventListener('touchstart', startAutoPlay, options)
    window.addEventListener('touchend', startAutoPlay, options)
    isEventAdded.current = true
  }

  useEffect(() => {
    const controller = window.AbortController ? new AbortController() : null

    if (isBrowser) handleBrowserVideos()
    else if (!isEventAdded.current) handleMobileEvents(controller)

    return () => {
      controller?.abort()
      window.removeEventListener('touchstart', startAutoPlay)
      window.removeEventListener('touchend', startAutoPlay)
      isEventAdded.current = false
    }
  }, [isVideosInViewport])

  return {
    videoBlock,
    videoRefs,
    isVideosInViewport,
    activeVideoID,
    onClick,
  }
}
