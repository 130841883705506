import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CookieConsentValue } from 'value-constants'

import { setIsPersonalDataAllowedAction } from 'root-redux/actions/user'
import { selectCookieConsentCountries } from 'root-redux/selects/common'
import {
  selectCookieConsent,
  selectUserCountryCode,
} from 'root-redux/selects/user'

export const useCookieConsentAnswer = () => {
  const dispatch = useDispatch()
  const countryCode = useSelector(selectUserCountryCode)
  const cookieConsent = useSelector(selectCookieConsent)
  const cookieConsentCountries = useSelector(selectCookieConsentCountries)

  const isEUUser = useMemo(
    () => cookieConsentCountries.includes(countryCode.toUpperCase()),
    [cookieConsentCountries, countryCode],
  )

  const hasAdvertisingAndTargetingCookie = useMemo(
    () => cookieConsent?.includes(CookieConsentValue.ADVERTISING_AND_TARGETING),
    [cookieConsent],
  )

  const hasFunctionalCookie = useMemo(
    () => cookieConsent?.includes(CookieConsentValue.FUNCTIONAL_COOKIES),
    [cookieConsent],
  )

  const hasAnalyticsAndPerformanceCookie = useMemo(
    () => cookieConsent?.includes(CookieConsentValue.ANALYTICS_AND_PERFORMANCE),
    [cookieConsent],
  )

  const isPersonalDataAllowed = useMemo(
    () => (isEUUser && hasFunctionalCookie) || !isEUUser,
    [isEUUser, hasFunctionalCookie],
  )

  useEffect(() => {
    dispatch(setIsPersonalDataAllowedAction(isPersonalDataAllowed))
  }, [dispatch, isPersonalDataAllowed])

  return {
    isEUUser,
    hasAdvertisingAndTargetingCookie,
    hasFunctionalCookie,
    hasAnalyticsAndPerformanceCookie,
    cookieConsent,
    isPersonalDataAllowed,
  }
}
