import helenAvatar from 'assets/images/helen-avatar.png'
import helenImg from 'assets/images/helen-img.png'
import margaretAvatar from 'assets/images/margaret-avatar.png'
import margaretImg from 'assets/images/margaret-img.png'
import robertAvatar from 'assets/images/robert-avatar.png'
import robertImg from 'assets/images/robert-img.png'

export const T_PREFIX = 'purchase.customerReviewsV5'

export const TEXT_REVIEWS = [
  {
    name: `${T_PREFIX}.firstReview.name`,
    date: `${T_PREFIX}.firstReview.date`,
    text: `${T_PREFIX}.firstReview.text`,
    avatar: margaretAvatar,
    img: margaretImg,
    commentsCount: 55,
  },
  {
    name: `${T_PREFIX}.secondReview.name`,
    date: `${T_PREFIX}.secondReview.date`,
    text: `${T_PREFIX}.secondReview.text`,
    avatar: robertAvatar,
    img: robertImg,
    commentsCount: 26,
  },
  {
    name: `${T_PREFIX}.thirdReview.name`,
    date: `${T_PREFIX}.thirdReview.date`,
    text: `${T_PREFIX}.thirdReview.text`,
    avatar: helenAvatar,
    img: helenImg,
    commentsCount: 19,
  },
] as const
