import styled, { css } from 'styled-components'

import selectedIcon from 'assets/images/select-check-icon.svg'

import { Color } from 'root-constants'

export const customSelectStyles = {
  menu: () => ({
    border: 'none',
    marginTop: '8px',
    padding: '15px 0',
    backgroundColor: Color.WHITE,
    borderRadius: '20px',
    boxShadow: '0 8px 20px 0 #E8E8E8',
  }),
  control: (_, state) => ({
    // none of react-select's styles are passed to <Control />
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '16px',
    padding: '30px 16px 14px 16px',
    minHeight: '60px',
    backgroundColor: !state.selectProps.menuIsOpen ? Color.LIGHT : Color.WHITE,
    border: 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    overflow: 'visible',
    position: 'relative',
    alignSelf: 'flex-end',
    fontWeight: 400,
    fontSize: 17,
    lineHeight: '24px',
  }),
  multiValue: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    backgroundColor: 'transparent',
    marginRight: 5,
    fontSize: 16,
  }),

  multiValueRemove: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    marginTop: '-14px',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    height: '1.5p x',
    color: Color.BLACK,
    padding: 0,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  input: (provided) => ({
    ...provided,
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
}

export const StyledSelect = {
  Root: styled.div`
    position: relative;
  `,
  Option: styled.div<{ isSelected: boolean }>`
    display: flex;
    align-items: center;
    padding: 16px 16px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${Color.DARK_BLUE};
    position: relative;
    background-color: ${({ isSelected }) =>
      isSelected ? Color.GREY_130 : Color.WHITE};

    ${({ isSelected }) =>
      isSelected &&
      `
      &:after {
      position: absolute;
      content: url(${selectedIcon});
      width: 24px;
      height: 24px;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
      `}
  `,
  CircleMarkIcon: styled.span`
    width: 20px;
    height: 20px;
    opacity: 0.5;
    border: 1px solid #555965;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 10px);
    right: 20px;
  `,
  MultiValue: styled.span`
    font-weight: 500;
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }
  `,
  Placeholder: styled.span<{ isEmpty: boolean }>`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${Color.GREY_101};
    position: absolute;
    top: 10px;
    left: 16px;
    pointer-events: none;
    z-index: 2;
    ${({ isEmpty }) =>
      isEmpty &&
      css`
        top: 24px;
        color: ${Color.GREY_101};
        font-size: 17px;
        line-height: 24px;
      `};
  `,
}
