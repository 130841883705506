import styled, { css } from 'styled-components'
import { DynamicDiscountThemeValue } from 'value-constants'

import colon from 'assets/images/colon.svg'

import { Color, Gradient } from 'root-constants'

type TTheme = {
  theme: DynamicDiscountThemeValue
}

export const StyledDiscountBlockV2 = {
  ContentBlock: styled.div<TTheme>`
    width: 100%;
    padding: 8px 16px;
    border-radius: 16px;

    display: flex;
    flex-direction: column;
    align-items: center;

    color: ${Color.WHITE};
    font-weight: 700;

    ${({ theme }) => {
      switch (theme) {
        case DynamicDiscountThemeValue.RED:
          return css`
            background: linear-gradient(${Gradient.LINEAR_RED_CORAL});
          `
        case DynamicDiscountThemeValue.VIOLET:
          return css`
            background: linear-gradient(${Gradient.LINEAR_VIOLET_BLUE});
          `
        case DynamicDiscountThemeValue.ORANGE:
          return css`
            background: linear-gradient(${Gradient.LINEAR_RED_YELLOW});
          `
        case DynamicDiscountThemeValue.BLACK:
          return css`
            background: linear-gradient(${Gradient.LINEAR_RED_YELLOW});
          `
        default:
          return css`
            background: linear-gradient(${Gradient.LINEAR_RED_YELLOW});
          `
      }
    }}
  `,
  Discount: styled.p`
    margin: 0 0 8px 0;

    text-align: center;
    font-size: 24px;
    line-height: 24px;
  `,
  DiscountDivider: styled.div`
    width: 100%;
    height: 1px;
    margin: 0 0 12px 0;

    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      #fff 13.63%,
      #fff 89.7%,
      rgba(255, 255, 255, 0) 100%
    );
  `,
  ContentRow: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  `,
  DiscountLabel: styled.p`
    font-size: 28px;
    line-height: 40px;
    margin: 0 8px 0 0;
  `,
  ContentColumn: styled.div`
    display: flex;
    flex-direction: column;
  `,
  TimerTitle: styled.p`
    border-radius: 8px;
    margin: 0 0 2px 0;
    padding: 4px 8px;
    background-color: ${Color.BLACK_100};

    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    text-align: center;
  `,
  TimerBlock: styled.div`
    display: flex;
    justify-content: space-between;
    column-gap: 11px;
    position: relative;
    width: 136px;

    &:after {
      position: absolute;
      content: url(${colon});
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `,
  TimerColumn: styled.div`
    min-width: 62px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 9px 12px;
    background-color: ${Color.WHITE};
    border-radius: 10px;
  `,
  TimerValue: styled.span`
    font-size: 24px;
    line-height: 32px;
    color: ${Color.BLACK_100};
  `,
  TimerUnits: styled.span`
    font-size: 9px;
    line-height: 12px;
    color: ${Color.BLACK_100};
  `,
}
