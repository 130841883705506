import { useLayoutEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectUserCountryCode } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'

import { getCurrentEnvironment } from 'helpers/getCurrentEnvironment'

import { HOT_JAR_CLIENT_ID } from 'root-constants'

export const useHotJarInitialization = (): void => {
  const { isProdEnvironment } = getCurrentEnvironment()

  const HOTJAR_CLIENT_ID = isProdEnvironment
    ? HOT_JAR_CLIENT_ID.PROD
    : HOT_JAR_CLIENT_ID.DEV

  const userCountryCode = useSelector(selectUserCountryCode)

  const { isEUUser, hasAnalyticsAndPerformanceCookie } =
    useCookieConsentAnswer()

  const shouldHotJarBePaused = useMemo(
    () => isEUUser && !hasAnalyticsAndPerformanceCookie,
    [isEUUser, hasAnalyticsAndPerformanceCookie],
  )

  useLayoutEffect(() => {
    if (shouldHotJarBePaused || !userCountryCode) {
      return
    }

    const script = document.createElement('script')
    script.text = `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${HOTJAR_CLIENT_ID},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
    script.async = true
    document.head.appendChild(script)
  }, [HOTJAR_CLIENT_ID, shouldHotJarBePaused, userCountryCode])
}
