import { ProgressHTMLAttributes } from 'react'

import styled, { css } from 'styled-components'

import progressCheckImg from 'assets/images/check-icon-progressbar.svg'

import { Color } from 'root-constants'

interface IProps extends ProgressHTMLAttributes<HTMLProgressElement> {
  color?: string
  background?: string
  pointsNumber: number
}

type TStepNumberProps = {
  isActive: boolean
  isPassed?: boolean
}

const progressValueStyles = css`
  background-color: currentColor;
  transition: all 0.2s ease-out;
`

export const StyledPostPaymentProgressBar = {
  Wrapper: styled.div`
    margin: 50px auto 16px;
    display: flex;
    justify-content: center;
  `,
  StepBlock: styled.div`
    position: relative;
    display: flex;
    align-items: center;
  `,
  StepBlockProgress: styled.progress<IProps>`
    overflow: hidden;
    max-width: ${({ pointsNumber }) =>
      `${(280 - 16 * (pointsNumber + 1)) / pointsNumber}px`};
    height: 1px;
    appearance: none;
    border: none;
    display: block;
    padding: 0 6px;

    &::-moz-progress-bar {
      ${progressValueStyles}
    }

    &::-ms-fill {
      ${progressValueStyles}
    }

    &::-webkit-progress-bar {
      background: #eceaf4;
    }

    &::-webkit-progress-value {
      background: ${Color.GREEN_300};
    }
  `,
  Step: styled.div<TStepNumberProps>`
    display: flex;
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: ${Color.GREY_800};
    transition: background 0.2s linear;
    color: ${Color.GREY_101};

    ${({ isActive }) =>
      isActive &&
      `
          color: ${Color.GREY_100};
        `}

    ${({ isPassed }) =>
      isPassed &&
      `
      background: ${Color.GREEN_300};
      color: ${Color.GREEN_300};
      
      &:before {
      position: absolute;
      content: url('${progressCheckImg}');
      line-height: 1;
      left: 50%;
      transform: translate(-50%, 0);
    }
    `}
  `,
  StepText: styled.span`
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 600;
    font-size: 11px;
    line-height: 14px;
    text-align: center;
    white-space: nowrap;
  `,
  StepNumber: styled.span`
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    color: ${Color.GREY_100};
    font-weight: 600;
    font-size: 11px;
  `,
}
