import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import dayjs from 'dayjs'
import {
  EVENT_MAP,
  LOST_WEIGHT_PER_WEEK,
} from 'pages/questions/EventFeedback/constants'
import { MotivationValue } from 'value-constants'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentUserGoalWeight,
  selectCurrentUserMotivation,
  selectEventDate,
  selectLanguage,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'
import { usePredictableData } from 'hooks/usePredictableData'
import { useUsersBmi } from 'hooks/useUsersBmi'
import { useWeightMeasurementSystem } from 'hooks/useWeightMeasurementSystem'

import { convertTextFromSnakeCase } from 'helpers/convertTextFromSnakeCase'
import {
  DEFAULT_DATE_FORMAT,
  LOCALIZED_DATE_FORMAT,
  daysFromNowToGoalDate,
} from 'helpers/dateHelper'

import { Period } from 'modules/purchase/constants'

import { TPageProps } from 'models/common.model'

import { Language } from 'root-constants'

import { StyledEventFeedbackV3 as S } from './EventFeedbackV3.styles'

export const EventFeedbackV3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const goalWeight = useSelector(selectCurrentUserGoalWeight)
  const eventDate = useSelector(selectEventDate)
  const motivation = useSelector(selectCurrentUserMotivation) as MotivationValue
  const language = useSelector(selectLanguage)
  const { measuringSystemLabel, measurementSystem } =
    useWeightMeasurementSystem()
  const { predictableDate } = usePredictableData()
  const {
    currentBMI: {
      bmiValue: { value },
    },
  } = useUsersBmi()
  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.eventFeedbackV3.title', {
      lng: Language.EN,
    }),
    nextPagePath,
  })

  const targetWeight = `${goalWeight} ${measuringSystemLabel}`
  const event = t(EVENT_MAP[motivation])
  const lostWeightPerDay = Number(
    (
      LOST_WEIGHT_PER_WEEK[value][measurementSystem] / Period.SEVEN_DAYS
    ).toFixed(2),
  )
  const isEventBeforePredictableDay = !!predictableDate.diff(
    dayjs(eventDate, DEFAULT_DATE_FORMAT),
    'day',
  )
  const lostWeightForEvent = (() => {
    if (isEventBeforePredictableDay) {
      const lostWeight = Number(
        (daysFromNowToGoalDate(eventDate) * lostWeightPerDay).toFixed(1),
      )

      return `<strong>~${lostWeight} ${measuringSystemLabel}</strong>`
    }

    return t(`onboarding.eventFeedback.justInTime`)
  })()

  return (
    <KitPageContainer
      hasContinueButton
      onContinueButtonClick={() => handleContinue('')}
    >
      <PageTitle marginBottom={12}>
        {t('onboarding.eventFeedbackV3.title')}
      </PageTitle>
      <S.InfoContainer>
        <S.Prediction>
          <Trans
            i18nKey="onboarding.eventFeedbackV3.subTitle"
            values={{
              targetWeight,
              date: convertTextFromSnakeCase(
                predictableDate.locale(language).format(LOCALIZED_DATE_FORMAT),
              ),
            }}
            components={{ span: <span /> }}
          />
        </S.Prediction>
        {event && eventDate && (
          <>
            <S.LostLabel>{t(`onboarding.eventFeedbackV3.lose`)}</S.LostLabel>
            <S.LostValues>
              <Trans
                i18nKey="onboarding.eventFeedbackV3.lostValues"
                values={{ weight: lostWeightForEvent, event }}
              />
            </S.LostValues>
          </>
        )}
      </S.InfoContainer>
      <S.LoseWeightGraphV3
        predictableDate={predictableDate}
        eventName={event}
      />
    </KitPageContainer>
  )
}
