import styled, { css } from 'styled-components'

import lockIcon from 'assets/images/protected-payment-icon.svg'

import { Color } from 'root-constants'

const containerStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledCreditCardTitleV1 = {
  TitleContainer: styled.div`
    ${containerStyle};
  `,
  PaymentInfo: styled.span`
    font-weight: 700;
    font-size: 16px;
    line-height: 43px;
  `,
  Label: styled.span`
    position: relative;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${Color.GREY_500};

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -16px;
      width: 12px;
      height: 14px;
      background: url(${lockIcon}) no-repeat;
      background-size: contain;
    }
  `,
}

export const StyledCreditCardTitleV2 = {
  TitleContainer: styled.div`
    ${containerStyle};
  `,
  PaymentInfo: styled.span`
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: ${Color.GREY_100};
  `,
  Label: styled.span`
    position: relative;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: ${Color.GREY_1000};

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -18px;
      width: 16px;
      height: 16px;
      background: url(${lockIcon}) no-repeat;
      background-size: contain;
    }
  `,
}
