import { IAppConfig, IAppConfigRaw } from 'models/config.model'

export const getAppConfigFromConfigRaw = (
  config: IAppConfigRaw,
): IAppConfig => ({
  amplitudeApiKey: config.amplitude_api_key,
  firebaseConfig: config.firebase_config,
  giaApiKeys: config.gia_api_keys,
  googleAnalyticsId: config.google_analytics_id,
  cookiesConsentCountries: config.cookies_consent_countries || [],
})
