import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { BODY_PART_NAME_MAP } from 'map-constants'
import { DATA_MAP } from 'pages/questions/TargetAreasV2/constants'
import { AnswerWithCheckbox, answerWithCheckboxTheme } from 'storybook-ui'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentUserGender,
  selectLanguage,
} from 'root-redux/selects/common'

import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { convertTextFromSnakeCase } from 'helpers/convertTextFromSnakeCase'

import { TPageProps } from 'models/common.model'

import check from 'assets/images/white-check-icon.svg'

import { CustomPageId } from 'page-constants'
import {
  CDN_FOLDER_LINK,
  Color,
  Language,
  TARGET_AREA_FULL_LIST,
} from 'root-constants'

import { StyledTargetAreasV2 as S } from './TargetAreasV2.styles'

export const TargetAreasV2: React.FC<TPageProps> = ({ nextPagePath }) => {
  const [answers, setAnswers] = useState<string[]>([])

  const { t } = useTranslation()
  const { title, subtitle } = useDynamicOBConfig()

  const gender = useSelector(selectCurrentUserGender)
  const language = useSelector(selectLanguage)

  const { imgSrc, options } = useMemo(() => DATA_MAP[gender], [gender])

  const handleWholeBodyClick = (_, isChecked: boolean) =>
    setAnswers(isChecked ? TARGET_AREA_FULL_LIST : [])

  const handleOptionClick = (value: string, isChecked: boolean) => {
    if (isChecked) {
      setAnswers([...answers, value])
    } else {
      setAnswers(answers.filter((item) => item !== value))
    }
  }

  const localePrefix = useMemo(
    () => (language === Language.ES ? `-${language}` : `-${Language.EN}`),
    [language],
  )

  const handleContinue = useNextStep({
    pageId: CustomPageId.TARGET_AREAS,
    question: t('onboarding.targetAreas.title', {
      lng: Language.EN,
    }),
    nextPagePath,
  })

  const handleContinueClick = useCallback(
    () => handleContinue(answers),
    [answers, handleContinue],
  )

  return (
    <KitPageContainer
      hasContinueButton
      onContinueButtonClick={handleContinueClick}
      isContinueButtonDisabled={!answers.length}
    >
      <PageTitle marginBottom={8} textAlign="left">
        {title || t('onboarding.targetAreas.title')}
      </PageTitle>
      <S.Subtitle>
        {subtitle || t('onboarding.targetAreas.subTitle')}
      </S.Subtitle>

      <AnswerWithCheckbox
        theme={answerWithCheckboxTheme.NUTRIMATE}
        value=""
        checked={answers.length === TARGET_AREA_FULL_LIST.length}
        checkboxBorderRadius="6px"
        checkboxBorder={`1.5px solid ${Color.GREY_800}`}
        iconSrc={check}
        iconSize="auto"
        minWidth="100%"
        margin="0 0 12px 0"
        onChange={handleWholeBodyClick}
      >
        <S.AnswerTitle>
          {convertTextFromSnakeCase(t('onboarding.targetAreas.whole'))}
        </S.AnswerTitle>
      </AnswerWithCheckbox>

      <S.Row>
        <S.ImageContainer>
          <img
            src={`${CDN_FOLDER_LINK}${imgSrc}${localePrefix}.png`}
            alt="target-areas"
          />
        </S.ImageContainer>
        <S.OptionList>
          {options.map(({ value, lineSvg, lineTop, lineWidth }) => (
            <S.AnswerWithCheckbox
              key={value}
              theme={answerWithCheckboxTheme.NUTRIMATE}
              value={value}
              checked={answers.includes(value)}
              checkboxBorder={`1.5px solid ${Color.GREY_800}`}
              iconSrc={check}
              iconSize="auto"
              margin="0 0 8px 0"
              minWidth="160px"
              onChange={handleOptionClick}
            >
              <S.AnswerTitle>
                {convertTextFromSnakeCase(t(BODY_PART_NAME_MAP[value]))}
              </S.AnswerTitle>
              <S.SvgImage svg={lineSvg} top={lineTop} width={lineWidth} />
            </S.AnswerWithCheckbox>
          ))}
        </S.OptionList>
      </S.Row>
    </KitPageContainer>
  )
}
