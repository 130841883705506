import styled from 'styled-components'

import { DisclaimerV2, SubscriptionsBlock } from 'modules/purchase/components'
import { PersonalizedPlanTimer } from 'modules/purchase/components/PersonalizedPlanTimer'

import { Color } from 'root-constants'

export const StyledKitPurchaseEmailVariant1 = {
  Banner: styled.div`
    aspect-ratio: 343 / 293;
    width: 100%;
    margin: 0 0 24px 0;
  `,
  Title: styled.h1`
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: ${Color.GREY_100};
    text-align: center;
    margin: 0 0 40px 0;

    strong {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    }
  `,
  PersonalizedPlanTimer: styled(PersonalizedPlanTimer)`
    margin: 0 0 40px 0;
  `,
  SubscriptionsBlock: styled(SubscriptionsBlock)`
    margin: 0 0 16px 0;
  `,
  Disclaimer: styled(DisclaimerV2)`
    color: ${Color.GREY_101};
    margin: 0 0 40px 0;

    a {
      color: ${Color.GREY_101};
    }
  `,
}
