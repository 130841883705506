import React, { useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { usePageInfo } from 'contexts/PageInfoProvider'
import {
  ADDITIONAL_PLAN_COMPONENTS,
  PERCENTAGE,
  PLAN_COMPONENTS,
  REVIEWS_MAP,
} from 'pages/presale/PresaleV2/constants'

import { Button } from 'components/Button'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import {
  ViewportScrollPercentage,
  useViewportValue,
} from 'hooks/useViewportValue'

import { PurchasePageType } from 'modules/purchase/constants'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { Emojis, ScreenName } from 'root-constants'

import { StyledPresaleV2 as S } from './PresaleV2.styles'

export const PresaleV2: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()

  const gender = useSelector(selectCurrentUserGender)

  const reviews = useMemo(() => REVIEWS_MAP[gender], [gender])

  const { title } = useDynamicOBConfig()
  const { currentSubscriptionPageId } = usePageInfo()
  const viewportValue = useViewportValue()

  useEffect(() => {
    eventLogger.logPresalePageShown(PurchasePageType.WEB_LANDING)
  }, [])

  useEffect(() => {
    if (viewportValue > ViewportScrollPercentage.V_0) {
      eventLogger.logPresaleScroll(viewportValue, ScreenName.ONBOARDING)
    }
  }, [viewportValue])

  const handleNextClick = useCallback(() => {
    eventLogger.logPresalePageCompleted()
    goTo({ pathname: currentSubscriptionPageId, search })
  }, [currentSubscriptionPageId, search])

  return (
    <>
      <S.PageContainer>
        <S.PageTitle marginBottom={24}>
          {title || <Trans i18nKey="presale.title" />}
        </S.PageTitle>
        <S.UserInfoBlock />
        <S.PresaleGraph />
        <S.Title>
          <Trans
            i18nKey="presaleV2.planTitle"
            values={{ emoji: Emojis.FINGER_DOWN_POINTER }}
          />
        </S.Title>
      </S.PageContainer>
      <S.PersonalizedPlan swipeData={PLAN_COMPONENTS} />
      <S.PageContainer>
        <S.ListWithEmojis
          items={ADDITIONAL_PLAN_COMPONENTS}
          title="presaleV2.additionalOptions.title"
        />
        <S.PercentageProgress
          title="presaleV2.progressTitle"
          note="presaleV2.progressNote"
          percentageValue={PERCENTAGE}
        />
      </S.PageContainer>
      <S.Reviews title="presaleV2.reviewTitle" reviews={reviews} />
      <S.StickyButtonContainer>
        <Button type="button" onClick={handleNextClick}>
          {t('actions.continue')}
        </Button>
      </S.StickyButtonContainer>
    </>
  )
}
