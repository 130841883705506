import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import dayjs from 'dayjs'

import { SHORT_MONTH_FORMAT, SHORT_YEAR_FORMAT } from 'helpers/dateHelper'

import { getDecoratedTimerValue } from 'modules/purchase/helpers/rootHelpers'
import {
  selectSubscriptionPaywallDiscountPercent,
  selectSubscriptionTimerValue,
} from 'modules/purchase/redux/selects'

import { StyledDefaultDiscountBlock as S } from './DefaultDiscountBlock.styles'

type TProps = {
  className?: string
}

export const DefaultDiscountBlock: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()
  const discount = useSelector(selectSubscriptionPaywallDiscountPercent)
  const timerValue = useSelector(selectSubscriptionTimerValue)
  const currentDate = dayjs()

  const code = useMemo(
    () =>
      `${currentDate
        .format(SHORT_MONTH_FORMAT)
        .toLowerCase()}${currentDate.format(SHORT_YEAR_FORMAT)}`,
    [currentDate],
  )

  const { minutes, seconds } = useMemo(() => {
    if (!timerValue) {
      return {
        minutes: '00',
        seconds: '00',
      }
    }

    return getDecoratedTimerValue(timerValue, false)
  }, [timerValue])

  return (
    <S.ContentBlock className={className}>
      <S.ContentColumn>
        <S.ColumnTitle>{t('purchase.limitedOffer.applied')}</S.ColumnTitle>
        <S.Offer>
          <S.OldOffer>
            <Trans
              i18nKey="purchase.limitedOffer.offerPercentage"
              values={{
                currentPercentage: discount,
              }}
              components={{ span: <span /> }}
            />
          </S.OldOffer>
          <S.NewOffer>
            <S.OldPromoCode>
              <Trans
                i18nKey="purchase.limitedOffer.newcomer"
                values={{
                  code,
                }}
              />
            </S.OldPromoCode>
            <S.NewPromoCode>
              <Trans
                i18nKey="purchase.limitedOffer.lastcall"
                values={{
                  code,
                }}
              />
            </S.NewPromoCode>
          </S.NewOffer>
        </S.Offer>
      </S.ContentColumn>
      <S.ContentColumn>
        <S.ColumnTitle>{t('purchase.limitedOffer.applicable')}</S.ColumnTitle>
        <S.TimerBlock>
          <S.TimerColumn>
            <S.TimerValue>{minutes}</S.TimerValue>
            <S.TimerUnits>{t('commonComponents.timer.minutes')}</S.TimerUnits>
          </S.TimerColumn>
          <S.TimerColumn>
            <S.TimerValue>{seconds}</S.TimerValue>
            <S.TimerUnits>{t('commonComponents.timer.seconds')}</S.TimerUnits>
          </S.TimerColumn>
        </S.TimerBlock>
      </S.ContentColumn>
    </S.ContentBlock>
  )
}
