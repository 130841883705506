import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Button, buttonTheme } from 'storybook-ui'
import { CancelOfferQuestionValue } from 'value-constants'

import { OptionType } from 'components/Option'
import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { getSubscriptionListAction } from 'root-redux/actions/common'
import { selectCurrentUserGender } from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store'

import { useVatInfo } from 'hooks/useHasVatInfo'

import { selectSubscriptionPaywallDiscountPercent } from 'modules/purchase/redux/selects'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import bannerFemale from 'assets/images/cancelOffer/cancel-offer-question-female.png'
import bannerMale from 'assets/images/cancelOffer/cancel-offer-question-male.png'
import fileIcon from 'assets/images/cancelOffer/file-icon.png'

import { goTo } from 'browser-history'
import { Gender, SubscriptionListType, SubscriptionTags } from 'root-constants'

import { StyledCancelOfferQuestion as S } from './CancelOfferQuestion.styles'
import { BENEFITS } from './constants'

export const CancelOfferQuestionV1: FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const dispatch: TAppDispatch = useDispatch()
  const { t } = useTranslation()

  const answerRef = useRef<HTMLDivElement>(null)
  const actionsRef = useRef<HTMLDivElement>(null)

  const gender = useSelector(selectCurrentUserGender)
  const discountAmount = useSelector(selectSubscriptionPaywallDiscountPercent)

  const [answer, setAnswer] = useState('')
  const hasVatInfo = useVatInfo()

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        eventLogger.logCancelOfferPageAnswer(value)
        setAnswer(value)
      },
    }),
    [pageId, setAnswer],
  )

  const bannerSrc = useMemo(
    () => (gender === Gender.FEMALE ? bannerFemale : bannerMale),
    [gender],
  )

  const { hasNoAnswer, hasYesAnswer } = useMemo(
    () => ({
      hasNoAnswer: answer === CancelOfferQuestionValue.NO,
      hasYesAnswer: answer === CancelOfferQuestionValue.YES,
    }),
    [answer],
  )

  const answerTitle = useMemo(
    () =>
      hasYesAnswer
        ? t('cancelOffer.questionPage.mythsSection.nothingButMyths')
        : t('cancelOffer.questionPage.mythsSection.totallyRight'),
    [t, hasYesAnswer],
  )

  useEffect(() => {
    eventLogger.logCancelOfferPageShown()
  }, [])

  useEffect(() => {
    actionsRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [actionsRef.current])

  useEffect(() => {
    if (answer) {
      answerRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [answer, answerRef])

  const handleNextPage = useCallback(() => {
    eventLogger.logCancelOfferPageClosed()
    goTo(nextPagePath)
  }, [nextPagePath])

  useLayoutEffect(() => {
    const tags = `${SubscriptionTags.CANCEL_OFFER},${
      hasVatInfo ? SubscriptionTags.TAX : SubscriptionTags.NO_TAX
    }`

    dispatch(getSubscriptionListAction(SubscriptionListType.PURCHASE, tags))
  }, [dispatch, hasVatInfo])

  return (
    <KitPageContainer
      hasContinueButton
      isActionButtonsVisible={!!answer}
      onContinueButtonClick={handleNextPage}
    >
      <S.Banner>
        <img src={bannerSrc} alt="banner" />
      </S.Banner>
      <PageTitle marginBottom={16} textAlign="left">
        {t('cancelOffer.questionPage.listTitle')}
      </PageTitle>
      <S.Benefits>
        {BENEFITS.map((benefit) => (
          <S.Benefit key={benefit}>{t(benefit)}</S.Benefit>
        ))}
      </S.Benefits>
      <S.Question>{t('cancelOffer.questionPage.question')}</S.Question>
      <S.Actions ref={actionsRef} hasAnswer={!!answer}>
        <S.Option
          {...optionProps}
          value={CancelOfferQuestionValue.NO}
          checked={hasNoAnswer}
          marginBottom="0"
          disabled={!!answer}
        >
          <Button
            type="button"
            width="100%"
            style={{
              pointerEvents: 'none',
              textTransform: 'capitalize',
            }}
            theme={
              !hasNoAnswer
                ? buttonTheme.NUTRIMATE_DEFAULT
                : buttonTheme.NUTRIMATE_PRIMARY
            }
          >
            {t('cancelOffer.questionPage.no')}
          </Button>
        </S.Option>
        <S.Option
          {...optionProps}
          value={CancelOfferQuestionValue.YES}
          checked={hasYesAnswer}
          marginBottom="0"
          disabled={!!answer}
        >
          <Button
            type="button"
            width="100%"
            style={{
              pointerEvents: 'none',
              textTransform: 'capitalize',
            }}
            theme={
              !hasYesAnswer
                ? buttonTheme.NUTRIMATE_DEFAULT
                : buttonTheme.NUTRIMATE_PRIMARY
            }
          >
            {t('cancelOffer.questionPage.yes')}
          </Button>
        </S.Option>
      </S.Actions>

      {!!answer && (
        <S.AnswerContainer ref={answerRef}>
          <S.AnswerTitle>{answerTitle}</S.AnswerTitle>
          <S.AnswerQuestion>
            {t('cancelOffer.questionPage.mythsSection.question')}
          </S.AnswerQuestion>
          <S.AnswerCallToAction>
            {t('cancelOffer.questionPage.mythsSection.subtitle')}
          </S.AnswerCallToAction>
          <S.DiscountContainer>
            <S.DiscountIcon>
              <img src={fileIcon} alt="file-icon" />
            </S.DiscountIcon>
            <S.DiscountContent>
              <Trans
                i18nKey="cancelOffer.questionPage.mythsSection.discount"
                values={{
                  discountAmount,
                }}
                components={{ span: <span /> }}
              />
            </S.DiscountContent>
          </S.DiscountContainer>
          <S.AnswerInfo>
            {t('cancelOffer.questionPage.mythsSection.text')}
          </S.AnswerInfo>
        </S.AnswerContainer>
      )}
    </KitPageContainer>
  )
}
