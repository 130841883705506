import styled from 'styled-components'
import { Swiper } from 'swiper/react'

import leftArrow from 'assets/images/swiper-left-arrow.svg'
import rightArrow from 'assets/images/swiper-right-arrow.svg'

import { Color } from 'root-constants'

export const StyledSliderWithScaledActiveSlideV2 = {
  Carousel: styled(Swiper)`
    .swiper-wrapper {
      margin: 0 0 66px 0;
    }

    .swiper-pagination {
      bottom: 20px;

      .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        background: ${Color.GREY};
        opacity: 1;

        &.swiper-pagination-bullet-active {
          background: ${Color.GREEN_300};
        }
      }
    }

    .swiper-slide {
      width: fit-content;
      display: flex;
      flex-direction: column;
      opacity: 0.6;
      transform: scale(0.8);
    }

    .swiper-slide-active {
      opacity: 1;
      transform: scale(1);
    }

    .swiper-button-prev,
    .swiper-button-next {
      top: 88%;
      height: 32px;
      width: 32px;
      border-radius: 22px;
      margin: 0;
      z-index: 11;

      &:hover {
        cursor: pointer;
      }
    }

    .swiper-button-prev {
      left: 34px;

      &:before {
        content: url(${leftArrow});
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        line-height: 0;
      }
    }
    .swiper-button-next {
      right: 34px;

      &:before {
        content: url(${rightArrow});
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        line-height: 0;
      }
    }
  `,
}
