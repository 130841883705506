import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { KitPageContainer } from 'components/PageContainer'
import { Spinner } from 'components/Spinner'

import {
  resetErrorAction,
  setSubscriptionListAction,
  stopFetching,
} from 'root-redux/actions/common'

import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useCommonStore } from 'hooks/useCommonStore'
import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { usePaymentStore } from 'hooks/usePaymentStore'
import { useStripeButton } from 'hooks/useStripeButton'
import { useUserStore } from 'hooks/useUserStore'

import { createProductId } from 'helpers/createProductId'

import {
  PaymentMethods,
  PaymentWaitingModal,
  ThreeDSecureIframe,
} from 'modules/purchase/components'
import { logGeneralCheckoutStartedEvents } from 'modules/purchase/helpers/logGeneralCheckoutStartedEvents'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'
import { selectIsCheckoutFlowShown } from 'modules/purchase/redux/selects'

import { eventLogger } from 'services/eventLogger.service'

import { goTo, replaceHistory } from 'browser-history'
import { PageId } from 'page-constants'
import { ScreenName } from 'root-constants'

import { StyledKitCheckoutVariant1 as S } from './KitCheckoutVariant1.styles'

export const KitCheckoutVariant1: React.FC = () => {
  const { search } = useLocation()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { currentSubscriptionPageId } = useGetPageInfo()
  const isCheckoutFlowShown = useSelector(selectIsCheckoutFlowShown)

  const {
    cohort,
    screenName,
    stripeAccountId,
    stripeAccountName,
    fetchingActionsList,
    subscriptionsList,
  } = useCommonStore()

  const {
    fullPrice,
    periodName,
    periodQuantity,
    selectedSubscriptionId,
    threeDSecureIframeUrl,
    trialCurrentPrice,
  } = usePaymentStore()

  const { uuid } = useUserStore()

  useAmplitudeInitialization(cohort, ScreenName.CHECKOUT)
  useStripeButton()

  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState(false)

  const productId = useMemo(
    () =>
      createProductId({
        periodName,
        periodQuantity,
        price: trialCurrentPrice || fullPrice,
      }),
    [periodName, periodQuantity, trialCurrentPrice, fullPrice],
  )

  const isSubscriptionTrial = useMemo(
    () => trialCurrentPrice !== fullPrice,
    [trialCurrentPrice, fullPrice],
  )

  const isCheckoutReady = useMemo(
    () =>
      !fetchingActionsList.includes(CHECK_PAYMENT_REQUEST_BUTTON) &&
      isCheckoutFlowShown,
    [fetchingActionsList, isCheckoutFlowShown],
  )

  useLayoutEffect(() => {
    dispatch(stopFetching(CHECK_PAYMENT_REQUEST_BUTTON))

    if (selectedSubscriptionId) return

    replaceHistory({
      pathname: currentSubscriptionPageId,
      search,
    })
  }, [dispatch, currentSubscriptionPageId, search, selectedSubscriptionId])

  useEffect(() => {
    if (productId) {
      eventLogger.logPurchaseShown({
        productId,
        screenName,
        stripeAccountId,
        stripeAccountName,
      })
    }

    logGeneralCheckoutStartedEvents({ uuid, subscriptionsList })
  }, [
    productId,
    screenName,
    stripeAccountId,
    stripeAccountName,
    uuid,
    subscriptionsList,
  ])

  const handleCloseCheckout = useCallback(() => {
    dispatch(resetErrorAction())
    dispatch(setSubscriptionListAction([]))

    eventLogger.logPurchaseScreenClosed({
      productId,
      screenName,
    })

    screenName === ScreenName.CANCEL_OFFER
      ? goTo({ pathname: PageId.CANCEL_OFFER_1, search })
      : goTo({ pathname: PageId.CANCEL_OFFER_QUESTION_1, search })
  }, [dispatch, productId, screenName, search])

  return (
    <KitPageContainer paddingTop={0}>
      {threeDSecureIframeUrl ? (
        <ThreeDSecureIframe />
      ) : (
        <S.Wrapper>
          <S.Title>{t('kitCheckoutV1.title')}</S.Title>
          <S.CloseButton onClick={handleCloseCheckout} />
          {isSubscriptionTrial ? (
            <S.TrialCheckoutSummaryV2 />
          ) : (
            <S.CheckoutSummaryV2 />
          )}
          {isCheckoutReady && (
            <>
              <S.PaymentMethodsTitle>
                {t('kitCheckoutV1.paymentMethodsTitle')}
              </S.PaymentMethodsTitle>
              <PaymentMethods />
            </>
          )}
          <PaymentWaitingModal
            isPaymentWaitingShown={isPaymentWaitingShown}
            setIsPaymentWaitingShown={setIsPaymentWaitingShown}
          />
          {!isCheckoutReady && <Spinner />}
        </S.Wrapper>
      )}
    </KitPageContainer>
  )
}
