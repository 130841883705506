import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  INTERMEDIATE_VALUE,
  MIN_KG_DIFF,
  MIN_LB_DIFF,
  MIN_WEIGHT_KG,
  MIN_WEIGHT_LB,
} from 'pages/questions/GoalWeightV2/constants'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { PageTitle } from 'components/PageTitle'
import { SvgImage } from 'components/SvgImage'

import { setMultipleAnswerAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'
import { selectCurrentUserCurrentWeight } from 'root-redux/selects/common'

import { useWeightMeasurementSystem } from 'hooks/useWeightMeasurementSystem'

import { IInputState, TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import alertIcon from 'assets/images/sprite/alert-circle.svg'

import { goTo } from 'browser-history'
import { CustomPageId } from 'page-constants'
import { FloatNumberRegex, INITIAL_INPUT_VALUE } from 'root-constants'

import { StyledGoalWeightV2 as S } from './GoalWeightV2.styles'
import { QUESTION } from './constants'

export const GoalWeightV2: React.FC<TPageProps> = ({ nextPagePath }) => {
  const [goalWeight, setGoalWeight] = useState<IInputState>({
    ...INITIAL_INPUT_VALUE,
  })
  const [isFocused, setIsFocused] = useState(false)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currentWeightValue = useSelector(selectCurrentUserCurrentWeight)
  const { measuringSystemLabel, isMetricSelected } =
    useWeightMeasurementSystem()

  const differentWeightPercent = useMemo(
    () =>
      (
        100 -
        (Number(goalWeight.value) * 100) / Number(currentWeightValue)
      ).toFixed(2),
    [currentWeightValue, goalWeight.value],
  )

  const descriptionDifferentWeight = useMemo(() => {
    // TO DO: move it to separate component
    const isOptimal = Number(differentWeightPercent) <= INTERMEDIATE_VALUE
    return (
      <S.Disclaimer normal={isOptimal}>
        <S.Title normal={isOptimal}>
          <Trans
            i18nKey={
              isOptimal
                ? 'onboarding.goalWeight.disclaimer.titleSecond'
                : 'onboarding.goalWeight.disclaimer.titleFirst'
            }
            values={{ value: differentWeightPercent }}
          />
        </S.Title>
        <S.SubTitle>
          <Trans
            i18nKey={
              isOptimal
                ? 'onboarding.goalWeight.disclaimer.subtitleSecond'
                : 'onboarding.goalWeight.disclaimer.subtitleFirst'
            }
          />
        </S.SubTitle>
      </S.Disclaimer>
    )
  }, [differentWeightPercent])

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    // TO DO: check availability of using handleContinue here
    dispatch(
      setMultipleAnswerAction({
        answers: {
          [CustomPageId.GOAL_WEIGHT]: +goalWeight.value,
          measurement_system: measuringSystemLabel,
        },
      }),
    )

    eventLogger.logQuestion({
      question: QUESTION,
      answers: `${goalWeight.value},${measuringSystemLabel}`,
    })
    dispatch(sendUserAnswersAction())
    goTo(nextPagePath)
  }

  const handleChange = useCallback(({ target: { value, validity } }) => {
    if (!value || FloatNumberRegex.test(value)) {
      setGoalWeight({
        value,
        isValid: validity.valid,
      })
    }
  }, [])

  const handleFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleBlur = useCallback(() => {
    setIsFocused(false)
  }, [])

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <PageTitle marginBottom={24}>
          {t('onboarding.goalWeight.title2')}
        </PageTitle>
        <S.Actions>
          <S.WeightLabel>
            <S.Label>{measuringSystemLabel}</S.Label>
          </S.WeightLabel>
          <S.InputContainer>
            <S.InputWrapper>
              <S.WeightInput
                isValid={goalWeight.isValid}
                value={goalWeight.value}
                type="number"
                min={isMetricSelected ? MIN_WEIGHT_KG : MIN_WEIGHT_LB}
                max={
                  currentWeightValue &&
                  currentWeightValue -
                    (isMetricSelected ? MIN_KG_DIFF : MIN_LB_DIFF)
                }
                lang="en"
                step={isMetricSelected ? '0.1' : '1'}
                onChange={handleChange}
                allowFloatNumbers={isMetricSelected}
                onBlur={handleBlur}
                onFocus={handleFocus}
              />
              {!isFocused && !goalWeight.value && <S.Placeholder />}
              <S.Suffix>{measuringSystemLabel}</S.Suffix>
            </S.InputWrapper>
          </S.InputContainer>
          {!!goalWeight.value && !goalWeight.isValid && (
            <S.ErrorMessage>
              <SvgImage svg={alertIcon} />
              <span>{t('onboarding.goalWeight.errorMessage')}</span>
            </S.ErrorMessage>
          )}
        </S.Actions>
        {goalWeight.isValid && descriptionDifferentWeight}
      </Container>
      <S.ButtonContainer isValid={goalWeight.isValid} value={goalWeight.value}>
        <Button type="submit" disabled={!goalWeight.isValid}>
          {t('actions.continue')}
        </Button>
      </S.ButtonContainer>
    </form>
  )
}
