import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SupportActionLink } from 'components/SupportActionLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useVatInfo } from 'hooks/useHasVatInfo'
import { usePaymentStore } from 'hooks/usePaymentStore'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { selectProductId } from 'modules/purchase/redux/selects'

import { Cohort } from 'root-constants'

import { StyledDisclaimer as S } from './Disclaimer.styles'
import { SUBSCRIPTION_DESCRIPTIONS, TRIAL_DISCLAIMER } from './constants'

type TProps = {
  className?: string
  isTrialOnly?: boolean
}

export const Disclaimer: React.FC<TProps> = ({
  className,
  isTrialOnly = false,
}) => {
  const { t } = useTranslation()
  const productId = useSelector(selectProductId)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const hasVatInfo = useVatInfo()
  const { fullPrice, currency } = usePaymentStore()

  const disclaimerTransKey = useMemo(
    () =>
      isTrialOnly
        ? TRIAL_DISCLAIMER[productId]
        : (SUBSCRIPTION_DESCRIPTIONS[cohortToUse] ||
            SUBSCRIPTION_DESCRIPTIONS[Cohort.MF_INTRO])[productId],
    [isTrialOnly, productId, cohortToUse],
  )

  const price = useMemo(() => {
    return t('purchase.subscriptionV2.price', {
      price: fullPrice,
      currency: CURRENCY_SYMBOLS[currency],
      context: currency,
    })
  }, [currency, fullPrice, t])

  return (
    <S.Disclaimer className={className}>
      <Trans
        i18nKey={disclaimerTransKey}
        components={{ span: <span /> }}
        values={{
          price,
          includingVatUsd: hasVatInfo ? t('purchase.includingVat') : '',
        }}
      />
      <TermsOfUseLink /> {t('purchase.contactUs')} <SupportActionLink />
    </S.Disclaimer>
  )
}
