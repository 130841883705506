import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledUpsellGuides = {
  Container: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Title: styled.h4`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: ${Color.GREY_100};
    margin: 0 0 24px 0;
  `,
  GuideBlock: styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    column-gap: 12px;
    padding: 12px;
    border-radius: 16px;
    border: 1px solid ${Color.GREY_800};
    background-color: ${Color.WHITE};
    margin: 0 0 24px 0;

    &:not(:last-of-type) {
      margin: 0 0 16px 0;
    }
  `,
  GuideInfo: styled.div`
    display: flex;
    flex-direction: column;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 110/156;
    min-width: 110px;
    width: 110px;
  `,
  GuideTitle: styled.h3`
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    color: ${Color.GREY_100};
    margin-bottom: 4px;
  `,
  GuideDescription: styled.p`
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: ${Color.GREY_101};
  `,
}
