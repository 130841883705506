import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useTimerEffect } from 'contexts/TimerProvider'

import { getDecoratedTimerValue } from 'modules/purchase/helpers/rootHelpers'

import { DEFAULT_TIMER_CONTENT } from 'root-constants'

import { StyledPersonalizedPlanTimer as S } from './PersonalizedPlanTimer.styles'

type TProps = {
  className?: string
}

export const PersonalizedPlanTimer: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  const timerMinutesRef = useRef<HTMLSpanElement>(null)
  const timerSecondsRef = useRef<HTMLSpanElement>(null)

  useTimerEffect((time) => {
    if (timerMinutesRef.current && timerSecondsRef.current) {
      const { minutes, seconds } = getDecoratedTimerValue(time, false)
      timerMinutesRef.current.innerText = minutes
      timerSecondsRef.current.innerText = seconds
    }
  })

  return (
    <S.Container className={className}>
      <S.InfoBlock>
        <S.Info>{t('purchase.personalizedPlanTimer.info')}</S.Info>
      </S.InfoBlock>
      <S.TimerBlock>
        <S.TimerContent>
          {t('purchase.personalizedPlanTimer.offerExpires')}
          <span ref={timerMinutesRef}>{DEFAULT_TIMER_CONTENT}</span>:
          <span ref={timerSecondsRef}>{DEFAULT_TIMER_CONTENT}</span>
        </S.TimerContent>
      </S.TimerBlock>
    </S.Container>
  )
}
