import React, { SyntheticEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Container } from 'components/Container'
import { NavigationButton } from 'components/NavigationButton'
import { PageTitle } from 'components/PageTitle'
import { SvgImage } from 'components/SvgImage'

import { selectCurrentUserCurrentWeight } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'
import { useWeightMeasurementSystem } from 'hooks/useWeightMeasurementSystem'

import { IInputState, TPageProps } from 'models/common.model'

import alertIcon from 'assets/images/sprite/alert-circle.svg'

import { CustomPageId } from 'page-constants'
import { FloatNumberRegex, INITIAL_INPUT_VALUE } from 'root-constants'

import { StyledGoalWeight as S } from './GoalWeight.styles'
import {
  MIN_KG_DIFF,
  MIN_LB_DIFF,
  MIN_WEIGHT_KG,
  MIN_WEIGHT_LB,
  QUESTION,
} from './constants'

export const GoalWeight: React.FC<TPageProps> = ({ nextPagePath }) => {
  const [weight, setWeight] = useState<IInputState>({
    ...INITIAL_INPUT_VALUE,
  })
  const [isFocused, setIsFocused] = useState(false)
  const { t } = useTranslation()
  const currentWeightValue = +useSelector(selectCurrentUserCurrentWeight)
  const { measuringSystemLabel, measurementSystem, isMetricSelected } =
    useWeightMeasurementSystem()

  const handleContinue = useNextStep({
    pageId: CustomPageId.GOAL_WEIGHT,
    question: QUESTION,
    nextPagePath,
  })
  const handleClick = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleContinue(+weight.value, measurementSystem)
  }

  const handleFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleBlur = useCallback(() => {
    setIsFocused(false)
  }, [])

  const handleChange = useCallback(({ target: { value, validity } }) => {
    if (!value || FloatNumberRegex.test(value)) {
      setWeight((prevState) => ({
        ...prevState,
        value,
        isValid: validity.valid,
      }))
    }
  }, [])

  return (
    <form onSubmit={handleClick}>
      <Container>
        <PageTitle marginBottom={24}>
          {t('onboarding.goalWeight.title')}
        </PageTitle>
        <S.WeightLabel>{measuringSystemLabel}</S.WeightLabel>
        <S.Actions>
          <S.InputWrapper>
            <S.WeightInput
              type="number"
              value={weight.value as string}
              isContentCentered
              isValid={weight.isValid}
              min={isMetricSelected ? MIN_WEIGHT_KG : MIN_WEIGHT_LB}
              max={
                currentWeightValue -
                (isMetricSelected ? MIN_KG_DIFF : MIN_LB_DIFF)
              }
              lang="en"
              step="1"
              allowFloatNumbers={false}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            {!isFocused && !weight.value && <S.Placeholder />}
            <S.Suffix isErrorVisible={!!weight.value && !weight.isValid}>
              {measuringSystemLabel}
            </S.Suffix>
          </S.InputWrapper>
          {weight.value && !weight.isValid && (
            <S.ErrorMessage>
              <SvgImage svg={alertIcon} />
              <span>{t('onboarding.goalWeight.errorMessage')}</span>
            </S.ErrorMessage>
          )}
        </S.Actions>

        <NavigationButton type="submit" disabled={!weight.isValid}>
          {t('actions.continue')}
        </NavigationButton>
      </Container>
    </form>
  )
}
