import { useEffect } from 'react'

export const useInitPixels = () => {
  const isUnsubscribePath = window.location.pathname.includes('/unsubscribe')

  // Facebook Pixel
  useEffect(() => {
    if (!isUnsubscribePath && !window.fbq) {
      const script = document.createElement('script')
      script.text = `!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');`

      script.async = true
      document.head.appendChild(script)
    }
  }, [isUnsubscribePath])

  useEffect(() => {
    // TikTok Pixel
    if (!window.ttq) {
      const script = document.createElement('script')
      script.text = `!function (w, d, t) {
          if (w.TiktokAnalyticsObject) return
          w.TiktokAnalyticsObject=t;
          var ttq=w[t]=w[t]||[];
          ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],
          ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)));};};
          for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);
          ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e;},
          ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},
          ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a);};
          }(window, document, "ttq");`

      script.async = true
      document.head.appendChild(script)
    }

    // Snap Pixel
    if (!window.snaptr) {
      const script = document.createElement('script')
      script.text = `(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
        {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments);};
        a.queue=[];var s="script";r=t.createElement(s);r.async=!0;
        r.src=n;var u=t.getElementsByTagName(s)[0];
        u.parentNode.insertBefore(r,u);})(window,document,
        "https://sc-static.net/scevent.min.js");;`

      script.async = true
      document.head.appendChild(script)
    }
  }, [])
}
