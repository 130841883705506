import { Button } from 'storybook-ui'
import styled, { css } from 'styled-components'

import minusIcon from 'assets/images/minus-accordion-icon.svg'
import plusIcon from 'assets/images/plus-accordion-icon.svg'

import { Color, KIT_MAX_COLUMN_WIDTH, MediaBreakpoint } from 'root-constants'

export const StyledCookieConsent = {
  Wrapper: styled.div<{ fullHeight: boolean }>`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 8px 20px 0 #cccfde;
    background-color: ${Color.WHITE};
    z-index: 20;
    ${({ fullHeight }) =>
      fullHeight &&
      css`
        height: 100dvh;
        overflow-y: auto;
      `};
  `,
  Header: styled.header`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #dcdfec;
    padding: 10px 16px;
    position: relative;
  `,
  CloseButton: styled.button`
    all: unset;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  `,
  Container: styled.div`
    max-width: ${KIT_MAX_COLUMN_WIDTH}px;
    margin: 0 auto;
    padding: 16px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: ${KIT_MAX_COLUMN_WIDTH}px;
    }
  `,
  Divider: styled.div`
    width: 100%;
    height: 1px;
    margin: 8px auto 0 auto;
    background-color: ${Color.GREY_800};

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: ${KIT_MAX_COLUMN_WIDTH}px;
    }
  `,
  Title: styled.h2`
    font-weight: 700;
    font-size: 17px;
    line-height: 24px;
    color: ${Color.GREY_100};
    margin: 0 0 8px 0;
  `,
  Description: styled.p`
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: ${Color.GREY_101};
    margin: 0 0 16px 0;

    a {
      text-decoration: underline;
      color: ${Color.GREY_101};
      text-decoration-thickness: 1px;
    }
  `,
  Button: styled(Button)`
    text-transform: capitalize;
  `,
  ManageTitle: styled.h2`
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: ${Color.GREY_100};
    margin: 0 0 20px 0;
  `,
  Settings: styled.ul`
    border: 1px solid ${Color.GREY_800};
    border-bottom: none;
  `,
  SettingItem: styled.li`
    position: relative;

    & label {
      position: absolute;
      right: 16px;
      top: 18px;
    }
  `,
  Setting: styled.details`
    &[open] summary::after {
      content: url(${minusIcon});
    }

    &:last-child span {
      flex-basis: 164px;
    }
  `,
  SettingName: styled.summary`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 64px;
    padding: 12px 16px 12px 44px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: ${Color.GREY_100};
    border-bottom: 1px solid #dcdfec;

    &::-webkit-details-marker {
      display: none;
    }

    & span {
      flex-basis: 190px;
    }

    &::after {
      width: 20px;
      height: 20px;
      content: url(${plusIcon});
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
  `,
  SettingDetails: styled.p`
    padding: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${Color.GREY_101};
    background-color: ${Color.VERY_LIGHT_BLUE};
    border-bottom: 1px solid ${Color.GREY_800};
  `,
  ActiveLabel: styled.strong`
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: ${Color.GREEN_300};
    text-wrap: nowrap;
  `,
}
