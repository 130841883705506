import styled from 'styled-components'

import calendarIconV2 from 'assets/images/calendar-v2.svg'

import { Color } from 'root-constants'

export const StyledCustomDatePickerV2 = {
  Wrapper: styled.div`
    position: relative;

    .react-datepicker__input-container {
      position: relative;
      display: flex;
      justify-content: center;
      margin-top: 24px;
    }

    .react-datepicker {
      width: 343px;
      border-radius: 16px;
      border: 1px solid ${Color.GREY};
    }

    .react-datepicker-popper {
      padding-top: 0;
      transform: translate3d(0, 0, 0);
      z-index: 15;
    }

    .react-datepicker-popper .react-datepicker__triangle {
      margin-top: 0 !important;
      transform: none !important;
      left: 0 !important;

      ::after,
      ::before {
        border: none !important;
      }
    }

    .react-datepicker__header {
      padding: 12px 0 0 0 !important;
      background-color: transparent;
      border: none;
    }

    .react-datepicker__navigation {
      top: 12px;
    }

    .react-datepicker__navigation-icon::before {
      border-color: ${Color.GREY_101};
      border-width: 2px 2px 0 0;
    }

    .react-datepicker__month-container {
      width: 343px;
      margin: 0 auto;
      float: none;

      > :not(:last-child) {
        margin: 0 0 16px 0;
      }
    }

    .react-datepicker__current-month {
      color: ${Color.GREY_100};
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      text-transform: capitalize;
      margin: 0 0 16px 0;
    }

    .react-datepicker__week {
      display: flex;
      justify-content: space-between;

      .react-datepicker__day {
        width: 36px;
        height: 36px;
        font-weight: 600;
        font-size: 14px;
        line-height: 36px;
      }

      .react-datepicker__day--selected {
        background: #a9cd5033;
        border: 1px solid ${Color.GREEN_300};
        border-radius: 8px;
        color: ${Color.GREEN_300};
        outline: none;
      }

      .react-datepicker__day--keyboard-selected {
        background-color: transparent;
      }
    }

    .react-datepicker__month {
      padding: 0 12px 12px 12px;
      margin: 0;
    }

    .react-datepicker__day-names {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;

      .react-datepicker__day-name {
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        color: ${Color.GREY_101};
        text-transform: capitalize;
        margin: 0;
      }
    }
  `,
  WrapperDataField: styled.div`
    height: 64px;
    width: 343px;
    border-radius: 16px;
    border: 1px solid ${Color.GREY_102};
    background: ${Color.WHITE};
    padding: 12px 16px;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    display: flex;
    justify-content: space-around;
    position: relative;
  `,
  DateField: styled.div`
    user-select: none;
    text-transform: capitalize;
  `,
  CalendarIcon: styled.div`
    position: absolute;
    width: 24px;
    height: 24px;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    background: url(${calendarIconV2});
  `,
}
