import React, { FC } from 'react'
import { Trans } from 'react-i18next'

import { FlagContainer } from 'components/FlagContainer'
import { PriceValue } from 'components/PriceValue'

import { isComplexCurrencySymbol } from 'modules/purchase/helpers/currency'
import { TSubscriptionItemProps } from 'modules/purchase/types'

import { Color } from 'root-constants'

import { useSubscriptionItem } from '../useSubscriptionItem'
import { StyledKitSubscriptionItemV1 as S } from './KitSubscriptionItemV1.styles'
import { StyledKitSubscriptionItemV1ComplexCurrency as C } from './KitSubscriptionItemV1ComplexCurrency.styles'

export const KitSubscriptionItemV1: FC<TSubscriptionItemProps> = ({
  subscription,
  isSelected,
}) => {
  const { id, isDefault, currency } = subscription

  const {
    periodName,
    isSubscriptionTrial,
    noTrialCurrentPrice,
    oldPrice,
    trialCurrentPrice,
    pricePerDay,
    oldPricePerDay,
  } = useSubscriptionItem(subscription)

  return !isComplexCurrencySymbol(currency) ? (
    <S.PlanContainer key={id} isSelected={isSelected}>
      <S.PlanColumn>
        <S.PlanPeriod isSelected={isSelected}>{periodName}</S.PlanPeriod>
        <S.PlanPrice>
          {isSubscriptionTrial ? (
            <>
              <S.PreviousPrice>
                <PriceValue value={oldPrice} currency={currency} />
              </S.PreviousPrice>
              <S.CurrentPrice>
                <PriceValue value={trialCurrentPrice} currency={currency} />
              </S.CurrentPrice>
              <S.PreviousDayPrice>
                <PriceValue value={oldPricePerDay} currency={currency} />
              </S.PreviousDayPrice>
            </>
          ) : (
            <S.CurrentPrice>
              <PriceValue value={noTrialCurrentPrice} currency={currency} />
            </S.CurrentPrice>
          )}
        </S.PlanPrice>
      </S.PlanColumn>
      <S.PlanColumn>
        <FlagContainer
          backgroundColor={isSelected ? Color.GREEN_300 : Color.GREY_130}
        >
          <S.DayPriceContainer isSelected={isSelected}>
            <S.CurrentDayPrice>
              <PriceValue value={pricePerDay} currency={currency} />
            </S.CurrentDayPrice>
            <S.DayPricePeriod>
              <Trans i18nKey="purchase.subscriptionV2.perDay" />
            </S.DayPricePeriod>
          </S.DayPriceContainer>
        </FlagContainer>
      </S.PlanColumn>
      {isDefault && (
        <S.PopularBadge isSelected={isSelected}>
          <Trans i18nKey="purchase.subscriptionV2.mostPopularBadge" />
        </S.PopularBadge>
      )}
    </S.PlanContainer>
  ) : (
    <C.PlanContainer key={id} isSelected={isSelected}>
      <C.PlanColumn>
        <C.PlanPeriod isSelected={isSelected}>{periodName}</C.PlanPeriod>
        <C.PlanPrice>
          {isSubscriptionTrial ? (
            <>
              <C.PreviousPrice>
                <PriceValue value={oldPrice} currency={currency} />
              </C.PreviousPrice>
              <C.CurrentPrice>
                <PriceValue value={trialCurrentPrice} currency={currency} />
              </C.CurrentPrice>
            </>
          ) : (
            <C.CurrentPrice>
              <PriceValue value={noTrialCurrentPrice} currency={currency} />
            </C.CurrentPrice>
          )}
        </C.PlanPrice>
      </C.PlanColumn>
      <C.PlanColumn>
        <FlagContainer
          backgroundColor={isSelected ? Color.GREEN_300 : Color.GREY_130}
        >
          <C.DayPriceContainer isSelected={isSelected}>
            {isSubscriptionTrial && (
              <C.PreviousDayPrice isSelected={isSelected}>
                <PriceValue value={oldPricePerDay} currency={currency} />
              </C.PreviousDayPrice>
            )}
            <C.CurrentDayPrice>
              <PriceValue value={pricePerDay} currency={currency} />
            </C.CurrentDayPrice>
            <C.DayPricePeriod>
              <Trans i18nKey="purchase.subscriptionV2.perDay" />
            </C.DayPricePeriod>
          </C.DayPriceContainer>
        </FlagContainer>
      </C.PlanColumn>
      {isDefault && (
        <C.PopularBadge isSelected={isSelected}>
          <Trans i18nKey="purchase.subscriptionV2.mostPopularBadge" />
        </C.PopularBadge>
      )}
    </C.PlanContainer>
  )
}
