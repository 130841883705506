import { Button } from 'storybook-ui'
import styled from 'styled-components'

import { DisclaimerV2, SubscriptionsBlock } from 'modules/purchase/components'
import { PersonalizedPlanTimer } from 'modules/purchase/components/PersonalizedPlanTimer'
import { PositionedTimerV1 } from 'modules/purchase/components/PositionedTimer'

import { Color } from 'root-constants'

export const StyledKitPurchaseEmailVariant2 = {
  PositionedTimer: styled(PositionedTimerV1)<{ isVisible: boolean }>`
    visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  `,
  Banner: styled.div`
    aspect-ratio: 343 / 293;
    width: 100%;
    margin: 0 0 24px 0;
  `,
  Title: styled.h1`
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: ${Color.GREY_100};
    text-align: center;
    margin: 0 0 40px 0;

    strong {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    }
  `,
  PersonalizedPlanTimer: styled(PersonalizedPlanTimer)`
    margin: 0 0 40px 0;
  `,
  SubscriptionTitle: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.GREY_100};
    text-transform: capitalize;
    text-align: center;
    margin: 0 0 16px 0;
  `,
  DiscountBlock: styled.div`
    display: flex;
    align-items: center;
    margin: 0 0 16px 0;
  `,
  DiscountBadge: styled.span`
    background-color: ${Color.ORANGE_200};
    border-radius: 16px;
    color: ${Color.WHITE};
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin: 0 auto;
    padding: 4px 16px;
    text-align: center;
    text-transform: uppercase;
  `,
  SubscriptionsBlock: styled(SubscriptionsBlock)`
    margin: 0 0 16px 0;
  `,
  Button: styled(Button)`
    text-transform: capitalize;
    text-align: center;
  `,
  Disclaimer: styled(DisclaimerV2)`
    color: ${Color.GREY_101};
    margin: 0 0 24px 0;

    a {
      color: ${Color.GREY_101};
    }
  `,
}
