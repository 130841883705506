import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectDynamicDiscount } from 'root-redux/selects/common'

import { DEFAULT_DYNAMIC_DISCOUNT_AMOUNT } from 'root-constants'

import { StyledDiscountBlockV1 as S } from './DiscountBlockV1.styles'

type TProps = {
  className?: string
}

export const DiscountBlockV1: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  return (
    <S.ContentBlock className={className} theme={dynamicDiscount?.theme}>
      <S.Discount theme={dynamicDiscount?.theme}>
        <Trans
          i18nKey="purchase.discountBlockV1.discount"
          values={{
            discount:
              dynamicDiscount?.amount || DEFAULT_DYNAMIC_DISCOUNT_AMOUNT,
          }}
        />
      </S.Discount>
      <S.DiscountLabel>
        {t('purchase.discountBlockV1.discountLabel')}
      </S.DiscountLabel>
    </S.ContentBlock>
  )
}
