import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledMoneyBackGuarantee = {
  Wrapper: styled.div`
    background-color: ${Color.WHITE};
    border: 1px solid ${Color.GREEN_400};
    border-radius: 24px;
    margin-bottom: 24px;
    padding: 8px;
  `,
  Title: styled.h2`
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
    text-align: center;
  `,
  Content: styled.div`
    border: 1px dashed ${Color.GREEN_400};
    border-radius: 16px;
    padding: 20px 16px;
  `,
  Text: styled.p`
    color: ${Color.GREY_101};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;

    a {
      color: ${Color.GREY_101};
      text-decoration: underline;
    }
  `,
}
