import styled, { css } from 'styled-components'

import { Input } from 'components/Input'
import { KitInfoBlock } from 'components/KitInfoBlock'

import exclaimationIcon from 'assets/images/exclaimation-circle-2.png'

import { Color } from 'root-constants'

export const StyledHeightV4 = {
  Actions: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 24px;
  `,
  InputContainer: styled.div`
    column-gap: 23px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  `,
  InputWrapper: styled.div`
    position: relative;
    width: 100%;
  `,
  HeightInput: styled(Input)<{ isMetricSystemSelected: boolean }>`
    background-color: ${Color.WHITE};
    border: 1px solid ${Color.GREY_1000};
    border-radius: 16px;
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
    padding: 0 54px;

    ${({ isMetricSystemSelected }) =>
      isMetricSystemSelected &&
      css`
        padding: 0 70px 0 70px;
      `}

    ${({ isValid, value }) =>
      value &&
      !isValid &&
      css`
        border: 1px solid ${Color.RED_300};
      `};

    &:focus,
    &:active {
      & ~ span {
        display: none;
      }
    }
  `,
  Placeholder: styled.span`
    background-color: rgba(0, 0, 0, 0.5);
    left: 50%;
    transform: translateX(-50%);
    height: 3px;
    pointer-events: none;
    position: absolute;
    top: 35px;
    width: 12px;
  `,
  Suffix: styled.span`
    color: ${Color.GREY_102};
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
    position: absolute;
    right: 16px;
    text-align: center;
    top: 13px;
  `,
  ErrorMessage: styled.p`
    background-image: url(${exclaimationIcon});
    background-position-x: 16px;
    background-repeat: no-repeat;
    background-size: 16px;
    color: ${Color.RED_300};
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 4px;
    padding-left: 36px;
    width: 100%;
  `,
  KitInfoBlock: styled(KitInfoBlock)`
    margin-bottom: 24px;
  `,
}
