import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CUSTOMER_REVIEWS } from 'modules/purchase/components/CustomerReviews/constants'
import { useDynamicPaywallConfig } from 'modules/purchase/hooks/useDynamicPaywallConfig'

import starsIcon from 'assets/images/kit-stars-icon.png'

import { StyledCustomerReviews as S } from './CustomerReviews.styles'

type TCustomerReviewsProps = {
  withTitle?: boolean
  className?: string
}

export const CustomerReviews: React.FC<TCustomerReviewsProps> = ({
  withTitle = true,
  className,
}) => {
  const { t } = useTranslation()
  const [activeReviewIndex, setActiveReviewIndex] = useState<number | null>(
    null,
  )

  const { customerReviewsTitle, customerReviews } = useDynamicPaywallConfig()

  const handleReviewClick = useCallback((index: number) => {
    setActiveReviewIndex(index)
  }, [])

  return (
    <S.Wrapper className={className}>
      {withTitle && (
        <S.Title>
          {customerReviewsTitle || t('purchase.customerReviews.title')}
        </S.Title>
      )}
      {!customerReviews?.length &&
        CUSTOMER_REVIEWS.map(
          ({ id, title, subtitle, author, review, shortReview }, index) => (
            <S.ReviewContainer key={id}>
              <S.ReviewHeader>
                <S.ReviewTitle>{t(title)}</S.ReviewTitle>
                <S.Date>{subtitle}</S.Date>
                <S.Rating>
                  <img src={starsIcon} alt="stars" />
                </S.Rating>
                <S.Author>{author}</S.Author>
              </S.ReviewHeader>
              <S.TextContent>
                <S.TextContainer>
                  <S.Text>
                    {activeReviewIndex !== index ? t(shortReview) : t(review)}
                    {activeReviewIndex !== index && (
                      <S.Button onClick={() => handleReviewClick(index)}>
                        {t('purchase.customerReviews.more')}
                      </S.Button>
                    )}
                  </S.Text>
                </S.TextContainer>
              </S.TextContent>
            </S.ReviewContainer>
          ),
        )}

      {!!customerReviews?.length &&
        customerReviews.map(
          ({ id, title, subtitle, author, review, shortReview }, index) => (
            <S.ReviewContainer key={id}>
              <S.ReviewHeader>
                <S.ReviewTitle>{title}</S.ReviewTitle>
                <S.Date>{subtitle}</S.Date>
                <S.Rating>
                  <img src={starsIcon} alt="stars" />
                </S.Rating>
                <S.Author>{author}</S.Author>
              </S.ReviewHeader>
              <S.TextContent>
                <S.TextContainer>
                  <S.Text>
                    {activeReviewIndex !== index ? shortReview : review}
                    {activeReviewIndex !== index && (
                      <S.Button onClick={() => handleReviewClick(index)}>
                        {t('purchase.customerReviews.more')}
                      </S.Button>
                    )}
                  </S.Text>
                </S.TextContainer>
              </S.TextContent>
            </S.ReviewContainer>
          ),
        )}
    </S.Wrapper>
  )
}
