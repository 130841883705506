import styled from 'styled-components'

import refundBgPrimary from 'assets/images/refund-bg-primary.png'
import refundBgSecondary from 'assets/images/refund-bg-secondary.png'

import { Color } from 'root-constants'

export const StyledRefundBanner = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    line-height: 24px;
    border-radius: 16px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &.primary {
      padding: 16px;
      height: 84px;
      background-image: url(${refundBgPrimary});
      margin: 24px 0;

      p {
        font-size: 15px;
        line-height: 20px;
      }
    }

    &.secondary {
      padding: 20px 16px;
      height: 112px;
      background-image: url(${refundBgSecondary});
      margin: 16px 0;

      p {
        font-size: 17px;
      }
    }
  `,

  Icon: styled.img`
    width: 65px;
  `,

  Text: styled.p`
    color: ${Color.WHITE};
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    font-size: 17px;
    font-weight: 600;

    strong {
      text-transform: uppercase;
    }
  `,
}
