import dayjs from 'dayjs'

import { SHORT_MONTH_FORMAT, getDateSequence } from 'helpers/dateHelper'

import { Language } from 'root-constants'

export const extractDateParts = (
  realPredictableDate: dayjs.Dayjs,
  language: Language,
) => {
  const daysCount = 8
  const dateSequence = getDateSequence({
    mainDate: realPredictableDate,
    sequenceLength: daysCount,
    beforeMainDate: true,
  })

  return dateSequence.reduce(
    (
      acc: { years: number[]; months: string[]; days: number[] },
      curr: dayjs.Dayjs,
    ) => {
      const curMonth: string = curr.locale(language).format(SHORT_MONTH_FORMAT)
      const curDay: number = curr.date()
      const curYear: number = curr.year()

      if (!acc.years.includes(curYear)) {
        acc.years.push(curYear)
      }
      if (!acc.months.includes(curMonth)) {
        acc.months.push(curMonth)
      }
      if (!acc.days.includes(curDay)) {
        acc.days.push(curDay)
      }

      return acc
    },
    { years: [], months: [], days: [] },
  )
}
