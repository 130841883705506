import { roundDown } from './numberHelper'

const centimetersInMeter = 100

export const getBmiInMetricSystem = (weight: number, height: number) =>
  roundDown(
    Number(
      (
        weight /
        (((height / centimetersInMeter) * height) / centimetersInMeter)
      ).toFixed(2),
    ),
    1,
  )
