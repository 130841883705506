import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  INITIAL_PROGRESS_VALUE,
  POST_PAYMENT_STEPS_MAP,
  PROGRESS_SECTION_STEP,
} from 'components/PostPaymentProgressBar/constants'

import { selectHasUpsellOffer } from 'root-redux/selects/common'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { PageId } from 'page-constants'

import { StyledPostPaymentProgressBar as S } from './PostPaymentProgressBar.styles'

type TProps = {
  className?: string
}

const tPrefix = 'commonComponents.postPaymentProgressBar'

export const PostPaymentProgressBar: React.FC<TProps> = ({ className }) => {
  const hasUpsellOffer = useSelector(selectHasUpsellOffer)
  const { currentPageId, currentUpsellPaywallPageId } = useGetPageInfo()

  const upsellProgressbarSteps = [
    [{ id: currentUpsellPaywallPageId, text: `${tPrefix}.buyPlan` }],
    [{ id: PageId.FINISHING_TOUCHES, text: `${tPrefix}.addOffer` }],
    [{ id: PageId.LOGIN, text: `${tPrefix}.confirmInformation` }],
    [{ id: PageId.DOWNLOAD, text: `${tPrefix}.createAccount` }],
  ]

  const stepsMap = hasUpsellOffer
    ? upsellProgressbarSteps
    : POST_PAYMENT_STEPS_MAP

  const currentSectionIndex = stepsMap.findIndex(
    (block) => !!block.find(({ id }) => id === currentPageId),
  )

  return (
    <S.Wrapper className={className}>
      {stepsMap.map(([{ id, text }], index, arr) => {
        const progressValue =
          currentSectionIndex >= index ? arr.length : INITIAL_PROGRESS_VALUE
        const nextSectionIndex = currentSectionIndex + PROGRESS_SECTION_STEP

        return (
          <S.StepBlock key={id}>
            <S.Step
              isActive={nextSectionIndex === index}
              isPassed={currentSectionIndex >= index}
            >
              <S.StepText>
                <Trans i18nKey={text} />
              </S.StepText>
              {currentSectionIndex < index && (
                <S.StepNumber>{index + PROGRESS_SECTION_STEP}</S.StepNumber>
              )}
            </S.Step>
            <S.StepBlockProgress
              value={progressValue}
              pointsNumber={arr.length}
              max={arr.length}
            />
          </S.StepBlock>
        )
      })}
      <S.Step isActive={currentPageId === PageId.DOWNLOAD}>
        <S.StepText>
          <Trans i18nKey={`${tPrefix}.accessPlan`} />
        </S.StepText>
        {currentSectionIndex < stepsMap.length + 1 && (
          <S.StepNumber>{stepsMap.length + PROGRESS_SECTION_STEP}</S.StepNumber>
        )}
      </S.Step>
    </S.Wrapper>
  )
}
