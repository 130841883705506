import { ICommonState } from 'root-redux/reducers/common'

import { TOnboardingDynamicConfig } from 'models/variant.model'

import { PERSONALIZATION_PLACEHOLDER_REGEXP } from 'root-constants'

/**
 @param value - A string that contains placeholders in the format {{key}}.
  These placeholders should be replaced with the corresponding values from the personalization object.
  The {{key}} must match the corresponding key in the userAnswers object.
 @param personalization - An object configured in Retool for a dynamic page.
  This object contains values that should replace the placeholders in the value string.
 @param userAnswers - A store of user answers used to find corresponding values to be substituted in place of the placeholders.
 */

type TArgs = {
  value?: string
  userAnswers: ICommonState['answers']
  personalization: TOnboardingDynamicConfig['en']['personalization']
}

export const getDynamicPersonalization = ({
  value,
  userAnswers,
  personalization,
}: TArgs) => {
  if (!value) return ''
  const placeholders = value.match(PERSONALIZATION_PLACEHOLDER_REGEXP)
  if (!placeholders || !userAnswers || !personalization) return value

  let transformedText = value

  Object.entries(personalization).forEach(([key, values]) => {
    const answer = userAnswers[key]

    if (answer && typeof answer !== 'object') {
      transformedText = transformedText.replace(
        PERSONALIZATION_PLACEHOLDER_REGEXP,
        String(values[answer]),
      )
    }
  })

  return transformedText
}
