import React from 'react'
import { useDispatch } from 'react-redux'

import { setLanguageAction } from 'root-redux/actions/common'

import { Color, LANG_QUERY_PARAM, SUPPORTED_LOCALES } from 'root-constants'

import { StyledLanguageSelector as S } from './LanguageSelector.styles'

type TProps = {
  options?: string[]
  defaultValue: string
}

export const LanguageSelector = ({
  defaultValue,
  options = SUPPORTED_LOCALES,
}: TProps) => {
  const dispatch = useDispatch()

  const currentOptions = options.map((lang) => ({
    value: lang,
    label: lang.toUpperCase(),
  }))

  const initValue = defaultValue
    ? currentOptions.find((option) => option.value === defaultValue)
    : currentOptions[0]

  const onChange = (option) => {
    const url = new URL(window.location.href)
    url.searchParams.set(LANG_QUERY_PARAM, option.value)
    window.history.pushState({}, '', url)
    dispatch(setLanguageAction(option.value))
  }

  return (
    <S.Wrapper>
      <S.Select
        defaultValue={initValue}
        name="language"
        options={currentOptions}
        onChange={onChange}
        classNamePrefix="language-select"
        isSearchable={false}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: Color.GREEN_400,
            primary25: Color.GREEN_600,
          },
        })}
      />
    </S.Wrapper>
  )
}
