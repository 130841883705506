import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { usePageInfo } from 'contexts/PageInfoProvider'

import { Spinner } from 'components/Spinner'

import {
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
  setHasHeaderSkipButton,
  setHeaderSkipButtonCallback,
} from 'root-redux/actions/common'
import { selectActionList } from 'root-redux/selects/common'
import { selectUserUpsellPages } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store'

import { useVatInfo } from 'hooks/useHasVatInfo'
import {
  ViewportScrollPercentage,
  useViewportValue,
} from 'hooks/useViewportValue'

import { IntroMediaV6 } from 'modules/purchase/components/IntroMediaV6'
import { PhotoResultV2 } from 'modules/purchase/components/PhotoResult'
import { StepsV1 } from 'modules/purchase/components/Steps'
import { UpsellDiscountBannerV1 } from 'modules/purchase/components/UpsellDiscountBanner'
import { UpsellDiscountModalV1 } from 'modules/purchase/components/UpsellDiscountModal'
import { UpsellFeedbacksV1 } from 'modules/purchase/components/UpsellFeedbacks'
import { UpsellSubscriptionBlockV2 } from 'modules/purchase/components/UpsellSubscriptionBlockV2'
import { WhatYouGetV2 } from 'modules/purchase/components/WhatYouGet'
import {
  MAKE_UPSELL,
  makeUpsellAction,
  setUpsellListInConfig,
} from 'modules/purchase/redux/actions/upsell'
import { selectSubscription } from 'modules/purchase/redux/selects'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import {
  FEATURE_KEYS,
  Gender,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { StyledUpsellPaywallV2 as S } from './UpsellPaywallV2.styles'

const discountModalDefaultState = {
  isVisible: false,
  isPreviouslyOpened: false,
}

type TDiscountModal = {
  isVisible: boolean
  isPreviouslyOpened: boolean
}

export const UpsellPaywallV2: React.FC<TPageProps> = () => {
  const dispatch: TAppDispatch = useDispatch()

  const [discountModal, setDiscountModal] = useState<TDiscountModal>(
    discountModalDefaultState,
  )
  const selectedSubscription = useSelector(selectSubscription)
  const fetchingActionsList = useSelector(selectActionList)
  const upsellPages = useSelector(selectUserUpsellPages)

  const hasVatInfo = useVatInfo()
  const { currentPageId } = usePageInfo()
  const isAlternativeVariant = useFeatureIsOn(FEATURE_KEYS.fas_530_new_upsell)
  const viewportValue = useViewportValue()
  const hasDiscount = discountModal.isPreviouslyOpened

  const screenName = hasDiscount
    ? ScreenName.UPSELL_COACHING_CANCEL
    : ScreenName.UPSELL_COACHING

  const isUpsellInProgress = (() =>
    fetchingActionsList?.includes(MAKE_UPSELL))()

  const arePricesReady = (() =>
    !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST))()

  useEffect(() => {
    eventLogger.logUpsellCoachingPurchaseShown(ScreenName.UPSELL_COACHING)
  }, [])

  useEffect(() => {
    if (
      viewportValue > ViewportScrollPercentage.V_0 &&
      !discountModal.isVisible &&
      screenName === ScreenName.UPSELL_COACHING
    ) {
      eventLogger.logUpsellCoachingPageScrolled(viewportValue, screenName)
    }
  }, [viewportValue, screenName, discountModal.isVisible])

  const handleSkip = () => {
    eventLogger.logUpsellCoachingPurchaseClosed(screenName)

    setDiscountModal((prevState) => {
      if (!prevState.isPreviouslyOpened && !isAlternativeVariant) {
        return {
          isVisible: true,
          isPreviouslyOpened: true,
        }
      }

      const restUpsellPages = upsellPages.filter(
        (page) => page !== currentPageId,
      )
      dispatch(setUpsellListInConfig(restUpsellPages))

      return {
        isVisible: false,
        isPreviouslyOpened: true,
      }
    })
  }

  useEffect(() => {
    dispatch(setHasHeaderSkipButton(true))
    dispatch(setHeaderSkipButtonCallback(handleSkip))

    return () => {
      dispatch(setHasHeaderSkipButton(false))
      dispatch(setHeaderSkipButtonCallback())
    }
  }, [dispatch, handleSkip])

  const handleCloseModal = useCallback(() => {
    const tags = [
      hasVatInfo ? SubscriptionTags.TAX : SubscriptionTags.NO_TAX,
      SubscriptionTags.UPSELL_LONG,
      SubscriptionTags.CANCEL_OFFER,
    ].join(',')

    dispatch(getSubscriptionListAction(SubscriptionListType.UPSELL, tags))

    setDiscountModal({
      isVisible: false,
      isPreviouslyOpened: true,
    })

    eventLogger.logUpsellCoachingPurchaseShown(
      ScreenName.UPSELL_COACHING_CANCEL,
    )
  }, [dispatch, setDiscountModal, hasVatInfo])

  const handleMakeUpsell = () => {
    dispatch(makeUpsellAction(screenName, currentPageId))
  }

  return (
    <S.PageContainer>
      {hasDiscount && <UpsellDiscountBannerV1 />}
      <IntroMediaV6 />
      <UpsellSubscriptionBlockV2
        buttonNumber={1}
        screenName={screenName}
        selectedSubscription={selectedSubscription}
        hasDiscount={hasDiscount}
        makeUpsell={handleMakeUpsell}
      />

      {!hasDiscount && (
        <>
          <WhatYouGetV2 />
          <StepsV1 />
          <PhotoResultV2
            titleI18nKey="upsellV2.photoResultTitle"
            gender={Gender.FEMALE}
          />
          <UpsellFeedbacksV1 />
          <IntroMediaV6 titleI18nKey="upsellV2.titleV2" />
          <UpsellSubscriptionBlockV2
            buttonNumber={2}
            screenName={screenName}
            selectedSubscription={selectedSubscription}
            makeUpsell={handleMakeUpsell}
          />
          <S.SecurityInfo topIndent={32} />
        </>
      )}

      {discountModal.isVisible && (
        <UpsellDiscountModalV1 onClose={handleCloseModal} />
      )}
      {(isUpsellInProgress || !arePricesReady) && <Spinner />}
    </S.PageContainer>
  )
}
