import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

const commonTitleStyle = css`
  color: ${Color.GREY_100};
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
`

export const StyledFeaturedBlock = {
  Wrapper: styled.div`
    margin-bottom: 24px;
  `,
  Title: styled.h2`
    ${commonTitleStyle};
    margin-bottom: 16px;
  `,
  Image: styled.div`
    aspect-ratio: 343 / 92;
    width: 100%;
  `,
}

export const StyledFeaturedBlockV2 = {
  Title: styled.h2`
    ${commonTitleStyle};
    margin-bottom: 16px;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 343 / 92;
    width: 100%;
  `,
}
