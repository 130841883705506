import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import {
  Content,
  CurrentPrice,
  CustomPrice,
  MostPopularBadge,
  PlanContainer,
  PlanPeriod,
  PreviousPrice,
  PriceValue,
  StyledSubscriptionItemV3 as S,
  TimePeriod,
} from 'modules/purchase/components/SubscriptionItemV3/SubscriptionItemV3.styles'
import { CURRENCY_SYMBOLS, PurchaseProduct } from 'modules/purchase/constants'
import { TSubscriptionItemProps } from 'modules/purchase/types'

import { PageId } from 'page-constants'

export const SubscriptionItemV3Trial: React.FC<TSubscriptionItemProps> = ({
  subscription: { id, product, mainPrices, trialPrices, isDefault, currency },
  isSelected,
}) => {
  const { t } = useTranslation()
  const { currentPageId } = useGetPageInfo()

  const isPurchaseV3Page = useMemo(
    () => currentPageId === PageId.PURCHASE_4,
    [currentPageId],
  )

  const oldPriceWithFractionalPart = useMemo(
    () =>
      product === PurchaseProduct.SEVEN_DAY
        ? trialPrices.oldPrices.beforeCustomDiscount?.fullPrice.toFixed(2)
        : mainPrices.fullPrice.toFixed(2),
    [product, mainPrices, trialPrices],
  )

  const trialCurrentPriceWithFractionalPart = useMemo(
    () => trialPrices.fullPrice.toFixed(2),
    [trialPrices],
  )

  const pricePerDayWithFractionalPart = useMemo(
    () => trialPrices.daily.toFixed(2),
    [trialPrices],
  )

  const oldPricePerDayWithFractionalPart = useMemo(
    () => trialPrices.oldPrices.beforeCustomDiscount?.daily.toFixed(2),
    [trialPrices],
  )

  const period = useMemo(
    () =>
      product === PurchaseProduct.SEVEN_DAY
        ? t('purchase.subscription.weeklyPeriod', {
            periodQuantity: trialPrices.durationDays,
          })
        : t('purchase.subscription.monthlyPeriod', {
            periodQuantity: mainPrices.periodQuantity,
            count: mainPrices.periodQuantity,
          }),
    [t, product, trialPrices, mainPrices],
  )

  return (
    <S.Wrapper isCustom={isPurchaseV3Page} key={id} isSelected={isSelected}>
      <Content isSelected={isSelected}>
        {isDefault && (
          <MostPopularBadge isCustom={isPurchaseV3Page}>
            {t('purchase.subscription.mostPopularBadge')}
          </MostPopularBadge>
        )}
        <PlanContainer>
          <PlanPeriod isSelected={isSelected}>{period}</PlanPeriod>

          <S.PriceContainer>
            <div>
              <PreviousPrice>
                {CURRENCY_SYMBOLS[currency]}
                {oldPriceWithFractionalPart}
              </PreviousPrice>{' '}
              <CurrentPrice>
                {CURRENCY_SYMBOLS[currency]}
                {trialCurrentPriceWithFractionalPart}
              </CurrentPrice>
            </div>

            <PreviousPrice fontSize={16}>
              {CURRENCY_SYMBOLS[currency]}
              {oldPricePerDayWithFractionalPart}
            </PreviousPrice>
          </S.PriceContainer>
        </PlanContainer>

        <CustomPrice isSelected={isSelected}>
          <PriceValue>
            {CURRENCY_SYMBOLS[currency]}
            {pricePerDayWithFractionalPart}
          </PriceValue>
          <TimePeriod>
            {t('purchase.subscription.interval')}{' '}
            {t('purchase.subscription.day')}
          </TimePeriod>
        </CustomPrice>
      </Content>
    </S.Wrapper>
  )
}
