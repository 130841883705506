import React from 'react'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { PrimerPayPalButton } from 'modules/purchase/components/PrimerPayPalButton'

import { StyledOneClickPayment as S } from './OneClickPayment.styles'

export const OneClickPayment: React.FC = () => {
  const isPrimerPaypalOff = useFeatureIsOn('fas_928_primer_paypal_es')

  return (
    <S.Container>
      <S.PaymentRequestButton />
      {!isPrimerPaypalOff && <PrimerPayPalButton />}
    </S.Container>
  )
}
