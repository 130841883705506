import percentIcon from 'assets/images/100-precent-icon.png'
import messageIcon from 'assets/images/message-icon.png'
import noEntryIcon from 'assets/images/no-entry-icon.png'
import sparklesIcon from 'assets/images/sparkles-icon.png'

const tAccordion = 'kitPurchaseV3.accordion'
export const ACCORDION_INFO = [
  {
    text: `${tAccordion}.instant`,
    icon: sparklesIcon,
  },
  {
    text: `${tAccordion}.money`,
    icon: percentIcon,
  },
  {
    text: `${tAccordion}.support`,
    icon: messageIcon,
  },
  {
    text: `${tAccordion}.food`,
    icon: noEntryIcon,
  },
] as const
