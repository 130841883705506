import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectIsInContextShown } from 'root-redux/selects/common'

import { getCurrentEnvironment } from 'helpers/getCurrentEnvironment'

export const useInContextInitialization = () => {
  const { isDevEnvironment } = getCurrentEnvironment()
  const isInContextShown = useSelector(selectIsInContextShown)

  useLayoutEffect(() => {
    if (!isDevEnvironment || !isInContextShown) return

    const initialScript = document.createElement('script')
    const externalScript = document.createElement('script')

    initialScript.type = 'text/javascript'
    initialScript.text = `
      var _jipt = [];
      _jipt.push(['project', '6e210e5132b0620fc07aca9f61804018']);
      _jipt.push(['domain', 'gismart']);
    `

    externalScript.type = 'text/javascript'
    externalScript.src = '//cdn.crowdin.com/jipt/jipt.js'
    externalScript.async = true

    document.body.appendChild(initialScript)
    document.body.appendChild(externalScript)
  }, [isDevEnvironment, isInContextShown])
}
