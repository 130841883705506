import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getDecoratedTimerValue } from 'modules/purchase/helpers/rootHelpers'
import { selectSubscriptionTimerValue } from 'modules/purchase/redux/selects'

import { StyledPositionedTimerV1 as S } from './PositionedTimerV1.styles'

export const PositionedTimerV1: React.FC = () => {
  const timerValue = useSelector(selectSubscriptionTimerValue)

  const { minutes, seconds } = useMemo(
    () => getDecoratedTimerValue(timerValue, false),
    [timerValue],
  )

  return (
    <S.Container>
      <S.TimerContent>
        <Trans
          i18nKey="purchase.positionedTimerV1.content"
          values={{ timerValue: `${minutes}:${seconds}` }}
        />
      </S.TimerContent>
    </S.Container>
  )
}
