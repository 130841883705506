import {
  ActivityDuringWorkValue,
  AgeRangeValue,
  BadHabitsValue,
  BodyPartValue,
  FastingValue,
  IdealWeightTimeValue,
  MotivationValue,
  PeriodValue,
  PrimaryGoalValue,
  SleepingTimeValue,
  WorkOutFrequencyValue,
} from 'value-constants'

import { IAppState } from 'models/store.model'
import { IStep } from 'models/variant.model'

import {
  Cohort,
  Gender,
  MEN_COHORTS,
  MeasurementSystem,
  WOMEN_COHORTS,
} from 'root-constants'

// Current Variant
export const selectCurrentVariant = (state: IAppState) =>
  state.common.currentVariant || null
export const selectCurrentVariantCohort = (state: IAppState) =>
  (state.common.currentVariant?.cohort || '') as Cohort
export const selectHasUpsellOffer = (state: IAppState) =>
  state.common.currentVariant?.hasUpsellOffer || false
export const selectHasCancelOffer = (state: IAppState) =>
  state.common.currentVariant?.hasCancelOffer || false
export const selectFacebookPixelIds = (state: IAppState) =>
  state.common.currentVariant?.facebookPixelIds || []
export const selectTiktokPixelId = (state: IAppState) =>
  state.common.currentVariant?.tiktokPixelId || ''
export const selectSnapPixelId = (state: IAppState) =>
  state.common.currentVariant?.snapPixelId || ''
export const selectOutbrainPixelId = (state: IAppState) =>
  state.common.currentVariant?.outbrainPixelId || ''
export const selectTaboolaPixelId = (state: IAppState) =>
  state.common.currentVariant?.taboolaPixelId || ''
export const selectCurrentVariantCohortToUse = (state: IAppState) =>
  (state.common.currentVariant?.parentCohort ||
    state.common.currentVariant?.cohort ||
    '') as Cohort
export const selectGrowthBookExperiments = (state: IAppState) =>
  state.common.currentVariant?.growthBookExperiments || null
export const selectGrowthBookDefaultValues = (state: IAppState) =>
  state.common.currentVariant?.growthBookDefaultValues || {}
export const selectCurrentVariantSteps = (state: IAppState): IStep[] =>
  state.common.currentVariant?.steps || []
export const selectHasInAppOffer = (state: IAppState): boolean =>
  state.common.currentVariant?.hasInAppOffer || false
export const selectCurrentVariantOnboardingProgressbarBreakpoints = (
  state: IAppState,
) => state.common.currentVariant?.onboardingProgressBreakpoints || {}
// App Config
export const selectConfig = (state: IAppState) => state.common.appConfig
export const selectAmplitudeApiKey = (state: IAppState) =>
  state.common.appConfig?.amplitudeApiKey || null
export const selectGiaApiKeys = (state: IAppState) =>
  state.common.appConfig?.giaApiKeys || null
export const selectFirebaseConfig = (state: IAppState) =>
  state.common.appConfig?.firebaseConfig || null
export const selectGoogleAnalyticsId = (state: IAppState) =>
  state.common.appConfig?.googleAnalyticsId || null
export const selectCookieConsentCountries = (state: IAppState): string[] =>
  state.common.appConfig?.cookiesConsentCountries || []
// Payment Config
export const selectPaymentConfig = (state: IAppState) =>
  state.common.paymentConfig
export const selectStripeAccountId = (state: IAppState) =>
  state.common.paymentConfig?.stripe.accountId || ''
export const selectStripeAccountName = (state: IAppState) =>
  state.common.paymentConfig?.stripe.accountName || ''
export const selectStripePublicKey = (state: IAppState) =>
  state.common.paymentConfig?.stripe.publicKey || ''
// Answers
export const selectAnswers = (state: IAppState) => state.common.answers
export const selectCurrentAge = (state: IAppState) =>
  (state.common.answers?.age as number) || 18
export const selectIdealWeightTime = (state: IAppState) =>
  (state.common.answers?.ideal_weight_time ||
    IdealWeightTimeValue.LESS_THAN_YEAR_AGO) as IdealWeightTimeValue
export const selectBadHabits = (state: IAppState) =>
  (state.common.answers?.bad_habits_1 as BadHabitsValue[]) || [
    BadHabitsValue.NONE,
  ]
export const selectSleepingTime = (state: IAppState) =>
  (state.common.answers?.sleeping_time_1 ||
    SleepingTimeValue.LESS_THAN_FIVE) as SleepingTimeValue
export const selectAgeRange = (state: IAppState) =>
  (state.common.answers?.age_range || AgeRangeValue.AR_18_25) as AgeRangeValue
export const selectCurrentUserCurrentWeight = (state: IAppState) =>
  (state.common.answers?.current_weight as number) || 70
export const selectPastTries = (state: IAppState) =>
  (state.common.answers?.past_tries as string[]) || []
export const selectCurrentUserGoalWeight = (state: IAppState) =>
  (state.common.answers?.goal_weight as number) || 60
export const selectCurrentUserHeight = (state: IAppState) =>
  state.common.answers?.height || 160
export const selectUserMeasurementSystem = (state: IAppState) =>
  (state.common.answers?.measurement_system ||
    MeasurementSystem.METRIC) as MeasurementSystem
export const selectCurrentUserName = (state: IAppState) =>
  state.common.answers?.name || ''
export const selectCurrentUserGender = (state: IAppState) => {
  const cohortToUse = selectCurrentVariantCohortToUse(state)

  switch (true) {
    case MEN_COHORTS.includes(cohortToUse):
      return Gender.MALE

    case WOMEN_COHORTS.includes(cohortToUse):
      return Gender.FEMALE

    default:
      return (state.common.answers?.gender as Gender) || Gender.MALE
  }
}
export const selectCurrentUserPrimaryGoal = (state: IAppState) =>
  (state.common.answers?.primary_goal ||
    PrimaryGoalValue.LOSE_WEIGHT) as PrimaryGoalValue
export const selectCurrentUserMotivation = (state: IAppState) =>
  state.common.answers?.choose_event || state.common.answers?.motivation || ''
export const selectChosenEvent = (state: IAppState) =>
  state.common.answers?.choose_event || MotivationValue.NO
export const selectEventDate = (state: IAppState) =>
  (state.common.answers?.event_date as string) || ''
export const selectCurrentFavouriteFoods = (state: IAppState) =>
  state.common.answers?.favourite_foods || []
export const selectCurrentRestrictions = (state: IAppState) =>
  state.common.answers?.restrictions || []
export const selectTargetAreas = (state: IAppState) =>
  (state.common.answers?.target_areas_1 as BodyPartValue[]) || []
export const selectActivityDuringWork = (state: IAppState) =>
  (state.common.answers?.activity_during_work as ActivityDuringWorkValue) ||
  null
export const selectPeriod = (state: IAppState) =>
  (state.common.answers?.period as PeriodValue) || PeriodValue.MENSTRUATION
export const selectFasting = (state: IAppState) =>
  (state.common.answers?.fasting as FastingValue) || FastingValue.NOTHING
export const selectWorkout = (state: IAppState) =>
  (state.common.answers?.workout as WorkOutFrequencyValue) ||
  WorkOutFrequencyValue.NEVER

// Others
export const selectIsFetching = (state: IAppState) => state.common.isFetching
export const selectActionList = (state: IAppState) => state.common.actionList
export const selectError = (state: IAppState) => state.common.error
export const selectSubscriptionList = (state: IAppState) =>
  state.common.subscriptions || []
export const selectLanguage = (state: IAppState) => state.common.language
export const selectScreenName = (state: IAppState) => state.common.screenName
export const selectHeaderSkipButton = (state: IAppState) =>
  state.common.header.skip
export const selectDynamicDiscount = (state: IAppState) =>
  state.common.dynamicDiscount || null
export const selectIsInContextShown = (state: IAppState): boolean =>
  state.common.isInContextShown
