import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { Cohort } from 'root-constants'

import { StyledFAQList as S } from './FAQList.styles'
import { QUESTIONS_LIST_ITEMS_V1, QUESTIONS_LIST_ITEMS_V2 } from './constants'

type TProps = {
  className?: string
}

export const FAQList: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const questionsListItems = useMemo(
    () =>
      cohortToUse === Cohort.MF_MEN_FLOW
        ? QUESTIONS_LIST_ITEMS_V2
        : QUESTIONS_LIST_ITEMS_V1,
    [cohortToUse],
  )

  return (
    <S.Wrapper className={className}>
      <S.Title>{t('purchase.faqList.title')}</S.Title>
      <S.List>
        {questionsListItems.map((item) => (
          <S.ListItem key={item.id}>
            <S.ListItemTitle>{t(item.question)}</S.ListItemTitle>
            <S.ListItemText>{t(item.answer)}</S.ListItemText>
          </S.ListItem>
        ))}
      </S.List>
    </S.Wrapper>
  )
}
