import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledDisclaimer = {
  Container: styled.p`
    position: relative;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: ${Color.GREY_102};

    a {
      color: ${Color.GREY_102};
      text-decoration: underline;
    }
  `,
}
