import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { DynamicDiscountThemeValue } from 'value-constants'

import { SALE_COUNT } from 'components/DynamicDiscountBanner/constants'
import { SvgImage } from 'components/SvgImage'

import { selectDynamicDiscount } from 'root-redux/selects/common'

import lightning from 'assets/images/sprite/lightning-v1.svg'

import { StyledDynamicDiscountBanner as S } from './DynamicDiscountBanner.styles'

export const DynamicDiscountBanner: React.FC = () => {
  const { t } = useTranslation()
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  const discountName =
    dynamicDiscount?.name ||
    t('commonComponents.dynamicDiscountBanner.discountName')

  return (
    <S.Wrapper
      theme={dynamicDiscount?.theme || DynamicDiscountThemeValue.ORANGE}
    >
      <S.Container>
        {Array.from({ length: SALE_COUNT })
          .map((_, i) => i + 1)
          .map((number) => (
            <S.Sale key={number}>
              <SvgImage svg={lightning} />
              {discountName}
            </S.Sale>
          ))}
      </S.Container>

      <S.Container aria-hidden>
        {Array.from({ length: SALE_COUNT })
          .map((_, i) => i + 1)
          .map((number) => (
            <S.Sale key={number}>
              <SvgImage svg={lightning} />
              {discountName}
            </S.Sale>
          ))}
      </S.Container>
    </S.Wrapper>
  )
}
