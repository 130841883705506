import React, { useEffect, useState } from 'react'

import { AccordionItem } from 'modules/purchase/components/AccordionItem'
import { PAYMENT_METHODS_INITIAL } from 'modules/purchase/components/PaymentMethods/constants'
import { PaymentMethod } from 'modules/purchase/constants'
import { useOneClickPaymentMethods } from 'modules/purchase/hooks/useOneClickPaymentMethods'
import { TPaymentMethodOption } from 'modules/purchase/types'

export const PaymentMethods = () => {
  const { method, option } = useOneClickPaymentMethods()

  const [activeMethod, setActiveMethod] = useState<string>(
    PaymentMethod.CREDIT_CARD,
  )
  const [options] = useState<TPaymentMethodOption[]>([
    option,
    PAYMENT_METHODS_INITIAL,
  ])

  useEffect(() => {
    if (method) {
      setActiveMethod(PaymentMethod.ONE_CLICK)
    }
  }, [method])

  return (
    <>
      {options.map(
        ({ value, title, images, form }) =>
          !!images.length && (
            <AccordionItem
              key={value}
              title={title}
              icons={images}
              isOpen={activeMethod === value}
              onClick={() => setActiveMethod(value)}
              value={value}
              Form={form}
            />
          ),
      )}
    </>
  )
}
