export const isWebView = () => {
  if (!navigator.userAgent) return false

  const ua = navigator.userAgent.toLowerCase()

  const webViewKeywords = [
    'instagram', // Instagram webview
    'fban', // Facebook
    'fbav', // Facebook
    'twitter', // Twitter
    'line', // LINE app
    'webview', // Android
    'wv', // Android
    'tiktok', // TikTok
    'whatsapp', // WhatsApp
    'messenger', // Facebook Messenger
    'snapchat', // Snapchat
    'telegram', // Telegram
  ]

  return webViewKeywords.some((keyword) => ua.includes(keyword))
}
