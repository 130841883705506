import refundCalendar from 'assets/images/refund-calendar.svg'
import refundCashback from 'assets/images/refund-cashback.svg'
import refundLaptop from 'assets/images/refund-laptop.svg'

export const T_PREFIX = 'purchase.howItWorks'
export const HOW_IT_WORKS_STEPS = [
  {
    title: `${T_PREFIX}.steps.finish.title`,
    text: `${T_PREFIX}.steps.finish.text`,
    icon: refundLaptop,
  },
  {
    title: `${T_PREFIX}.steps.share.title`,
    text: `${T_PREFIX}.steps.share.text`,
    icon: refundCalendar,
  },
  {
    title: `${T_PREFIX}.steps.get.title`,
    text: `${T_PREFIX}.steps.get.text`,
    icon: refundCashback,
  },
] as const
