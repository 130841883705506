import styled from 'styled-components'

import { PageTitle } from 'components/PageTitle'

import { Color } from 'root-constants'

export const StyledImageWithText = {
  ImageContainer: styled.div`
    width: 100%;
    max-width: 375px;
    height: 220px;
  `,
  Title: styled(PageTitle)`
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 16px;

    p {
      color: ${Color.ORANGE_200};
    }
  `,
  Subtitle: styled.p`
    color: ${Color.GREY_101};
    text-align: center;
    margin: 12px 0 16px 0;
    font-size: 17px;
    font-weight: 600;
  `,
  ButtonWrap: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
    align-items: center;
  `,
  OptionsContainer: styled.div`
    display: flex;
    gap: 9px;
    justify-content: center;
    width: max-content;

    @media (max-width: 374px) {
      flex-direction: column;
    }
  `,
  OptionButton: styled.button`
    max-width: 185px;
    min-height: 56px;
    min-width: 167px;
    background-color: ${Color.GREEN_400};
    color: ${Color.WHITE};
    font-size: 17px;
    font-weight: 700;
    padding: 12px 24px;
    border-radius: 24px;
    border: none;
    cursor: pointer;
    box-shadow: 0 8px 20px 0 #e8e8e8;

    &:hover {
      background-color: ${Color.GREEN_300};
    }

    @media (max-width: 374px) {
      max-width: 100%;
    }
  `,
  LastBtn: styled.button`
    cursor: pointer;
    width: 100%;
    outline: none;
    border: none;
    background: transparent;
    color: ${Color.GREEN_300};
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    text-decoration-line: underline;
    text-decoration-skip-ink: none;
  `,
}
