import React, {
  FC,
  RefObject,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useTimerEffect } from 'contexts/TimerProvider'
import lottie from 'lottie-web/build/player/lottie_light'

import {
  selectCurrentVariantCohortToUse,
  selectDynamicDiscount,
  selectLanguage,
} from 'root-redux/selects/common'

import { BUTTON_ANIMATION_PATH } from 'modules/purchase/components/KitDiscountTimer/constants'
import { getDecoratedTimerValue } from 'modules/purchase/helpers/rootHelpers'
import { OnboardingButtonText } from 'modules/purchase/pages/constants'
import { selectSubscriptionPaywallDiscountPercent } from 'modules/purchase/redux/selects'

import {
  CDN_FOLDER_LINK,
  Cohort,
  DEFAULT_DYNAMIC_DISCOUNT_AMOUNT,
  DEFAULT_TIMER_CONTENT,
} from 'root-constants'

import { StyledKitDiscountTimerV1 as S } from './KitDiscountTimerV1.styles'

type TProps = {
  elemRef: RefObject<HTMLDivElement>
  onButtonClick: (event: SyntheticEvent<HTMLButtonElement>) => void
  className?: string
}

export const KitDiscountTimerV1: FC<TProps> = ({
  elemRef,
  onButtonClick,
  className,
}) => {
  const locale = useSelector(selectLanguage)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const discountFromSubscriptions = useSelector(
    selectSubscriptionPaywallDiscountPercent,
  )

  const timerMinutesRef = useRef<HTMLSpanElement>(null)
  const timerSecondsRef = useRef<HTMLSpanElement>(null)

  useTimerEffect((time) => {
    if (timerMinutesRef.current && timerSecondsRef.current) {
      const { minutes, seconds } = getDecoratedTimerValue(time, false)
      timerMinutesRef.current.innerText = minutes
      timerSecondsRef.current.innerText = seconds
    }
  })

  useEffect(() => {
    return () => lottie.destroy('btnAnimation')
  }, [])

  const beforeAfterAnimationRef = useCallback(
    (node: HTMLButtonElement) => {
      if (node) {
        lottie.loadAnimation({
          container: node,
          path: `${CDN_FOLDER_LINK}${BUTTON_ANIMATION_PATH}-${locale}.json`,
          loop: true,
          name: 'btnAnimation',
        })
      }
    },
    [locale],
  )

  const discount = (() => {
    if (cohortToUse === Cohort.MF_INTRO_SALE) {
      return dynamicDiscount
        ? dynamicDiscount?.amount
        : DEFAULT_DYNAMIC_DISCOUNT_AMOUNT
    }

    return discountFromSubscriptions
  })()

  return (
    <S.Wrapper ref={elemRef} className={className}>
      <S.Timer>
        <S.TimerLabel>
          <Trans
            i18nKey="purchase.discountTimerV1.timerLabel"
            values={{
              discount,
            }}
          />
        </S.TimerLabel>
        <S.TimerValue>
          <span ref={timerMinutesRef}>{DEFAULT_TIMER_CONTENT}</span>:
          <span ref={timerSecondsRef}>{DEFAULT_TIMER_CONTENT}</span>
        </S.TimerValue>
      </S.Timer>
      <S.Button
        data-button-number="1"
        data-button-text={OnboardingButtonText.GET_PLAN_UPPER}
        ref={beforeAfterAnimationRef}
        onClick={onButtonClick}
      />
    </S.Wrapper>
  )
}
