import React from 'react'
import { useTranslation } from 'react-i18next'

import featuredIcon from 'assets/images/kit-grey-featured-icon.png'

import { StyledFeaturedBlock as S } from './FeaturedBlock.styles'

export const FeaturedBlock: React.FC = (props) => {
  const { t } = useTranslation()

  return (
    <S.Wrapper {...props}>
      <S.Title>{t('purchase.featuredBlockTitle')}</S.Title>
      <S.Image>
        <img src={featuredIcon} alt="featured-icon" />
      </S.Image>
    </S.Wrapper>
  )
}
