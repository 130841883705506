import React, { ReactNode, forwardRef, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Option, OptionType } from 'components/Option'

import { selectSubscriptionList } from 'root-redux/selects/common'

import { SUBSCRIPTION_ITEMS } from 'modules/purchase/constants'
import { useDynamicPaywallConfig } from 'modules/purchase/hooks/useDynamicPaywallConfig'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import { selectSubscriptionId } from 'modules/purchase/redux/selects'

import { StyledSubscriptionsBlock as S } from './SubscriptionsBlock.styles'

type TProps = {
  titleMargin?: number
  isCancelOffer?: boolean
  customTitle?: ReactNode
  hasTitle?: boolean
  className?: string
}

export const SubscriptionsBlock = forwardRef<HTMLDivElement, TProps>(
  (
    {
      titleMargin = 32,
      customTitle,
      hasTitle = true,
      isCancelOffer = false,
      className,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const subscriptions = useSelector(selectSubscriptionList)
    const selectedSubscriptionId = useSelector(selectSubscriptionId)

    const { subscriptionBlockTitle, subscriptionBlockType } =
      useDynamicPaywallConfig()

    const SubscriptionItem = useMemo(
      () => SUBSCRIPTION_ITEMS[subscriptionBlockType],
      [subscriptionBlockType],
    )

    const handleChange = useCallback(
      (value: string) => {
        const checkedSubscription = subscriptions.find(({ id }) => id === value)

        if (checkedSubscription) {
          dispatch(setSelectedSubscriptionAction(checkedSubscription))
        }
      },
      [dispatch, subscriptions],
    )

    return (
      <S.Wrapper {...props} className={className} ref={ref}>
        {hasTitle && (
          <S.Title titleMargin={titleMargin}>
            {customTitle ||
              subscriptionBlockTitle ||
              t('purchase.subscription.title')}
          </S.Title>
        )}
        {subscriptions.map((subscription) => (
          <Option
            type={OptionType.RADIO}
            name="subscription"
            value={subscription.id}
            key={subscription.id}
            onChange={handleChange}
          >
            <SubscriptionItem
              subscription={subscription}
              isSelected={subscription.id === selectedSubscriptionId}
              isCancelOffer={isCancelOffer}
            />
          </Option>
        ))}
      </S.Wrapper>
    )
  },
)
