import React from 'react'

import { Footer } from 'components/Footer'
import { KitPageContainer } from 'components/PageContainer'

import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledImageWithText as S } from './ImageCenterOptionsBelow.styles'

export const ImageCenterOptionsBelow: React.FC<TPageProps> = ({
  nextPagePath,
  pageId,
  isFirstPage,
}) => {
  const {
    title = '',
    subtitle = '',
    imageUrls = [''],
    pageTexts = [''],
    optionData = [],
    customPageId,
  } = useDynamicOBConfig()

  const firstPart = [...optionData].slice(0, 2)
  const secondPart = optionData[optionData.length - 1]

  const handleContinue = useNextStep({
    pageId: customPageId || pageId,
    question: title,
    nextPagePath,
    isFirstPage,
  })

  return (
    <KitPageContainer hasContinueButton={false} paddingTop={0}>
      <S.ImageContainer>
        <img src={imageUrls?.[0]} alt="banner" />
      </S.ImageContainer>
      <S.Title>
        <>
          {title}
          <p>{pageTexts[0]}</p>
        </>
      </S.Title>
      <S.Subtitle>{subtitle}</S.Subtitle>
      <S.ButtonWrap>
        <S.OptionsContainer>
          {firstPart.map((option) => (
            <S.OptionButton
              key={option.id}
              onClick={() => handleContinue(option.value)}
            >
              {option.text}
            </S.OptionButton>
          ))}
        </S.OptionsContainer>
        {secondPart && (
          <S.LastBtn onClick={() => handleContinue(secondPart.value)}>
            {secondPart.text}
          </S.LastBtn>
        )}
      </S.ButtonWrap>
      <Footer />
    </KitPageContainer>
  )
}
