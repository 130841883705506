import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Swiper, { Autoplay, Navigation, Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { SvgImage } from 'components/SvgImage'

import {
  selectCurrentUserGender,
  selectCurrentVariantCohortToUse,
  selectUserMeasurementSystem,
} from 'root-redux/selects/common'

import Emoji from 'assets/images/sprite/yummy-emoji.svg'

import { Cohort, Gender } from 'root-constants'

import { StyledPhotoResult as S } from './PhotoResult.styles'
import { SLIDES_FEMALE, SLIDES_MALE_V1, SLIDES_MALE_V2 } from './constants'

Swiper.use([Pagination, Autoplay, Navigation])

type TProps = {
  titlePath?: string
  className?: string
}

export const PhotoResult: React.FC<TProps> = ({ titlePath, className }) => {
  const { t } = useTranslation()

  const measurementSystem = useSelector(selectUserMeasurementSystem)
  const gender = useSelector(selectCurrentUserGender)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const isMale = useMemo(() => gender === Gender.MALE, [gender])

  const slidesMale = useMemo(
    () =>
      cohortToUse === Cohort.MF_MEN_FLOW ? SLIDES_MALE_V2 : SLIDES_MALE_V1,
    [cohortToUse],
  )

  const slideList = useMemo(
    () => (isMale ? slidesMale : SLIDES_FEMALE),
    [isMale, slidesMale],
  )

  return (
    <S.Wrapper className={className}>
      <S.ResultContainer>
        <S.ResultTitle>
          <Trans i18nKey={titlePath || 'purchase.photoResult.title'} />
        </S.ResultTitle>
        <S.CarouselContainer>
          <Carousel
            spaceBetween={20}
            slidesPerView={1}
            loop
            navigation
            pagination={{ clickable: true }}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
          >
            {slideList.map(
              ({ id, img, imgWebp, author, lostWeight, review }) => (
                <SwiperSlide key={id}>
                  <S.Cards>
                    <S.ImageContainer>
                      <picture>
                        <source srcSet={imgWebp.before} type="image/webp" />
                        <S.Image>
                          <img src={img.before} alt="before" />
                        </S.Image>
                      </picture>
                      <S.LabelBefore>
                        {t('purchase.photoResult.before')}
                      </S.LabelBefore>
                    </S.ImageContainer>
                    <S.ImageContainer>
                      <picture>
                        <source srcSet={imgWebp.after} type="image/webp" />
                        <S.Image>
                          <img src={img.after} alt="after" />
                        </S.Image>
                      </picture>
                      <S.LabelAfter>
                        {t('purchase.photoResult.after')}
                      </S.LabelAfter>
                    </S.ImageContainer>
                  </S.Cards>
                  <S.TextContainer>
                    <S.Author>
                      <span>{t(author)}</span>
                      <span>{t(`${lostWeight}.${measurementSystem}`)}</span>
                    </S.Author>
                    <S.Review>
                      <Trans
                        i18nKey={review}
                        values={{
                          units: t(`purchase.photoResult.${measurementSystem}`),
                          weight: t(`${lostWeight}.${measurementSystem}`),
                        }}
                        components={{
                          emoji: <SvgImage svg={Emoji} />,
                        }}
                      />
                    </S.Review>
                  </S.TextContainer>
                </SwiperSlide>
              ),
            )}
          </Carousel>
        </S.CarouselContainer>
      </S.ResultContainer>
      <S.Disclaimer>
        <Trans i18nKey="purchase.photoResult.disclaimer" />
      </S.Disclaimer>
    </S.Wrapper>
  )
}
