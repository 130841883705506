import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { PurchaseProduct } from 'modules/purchase/constants'

import { ISubscription } from 'models/subscriptions.model'

export const useSubscriptionPeriodName = (subscription: ISubscription) => {
  const { product, trialPrices, mainPrices } = subscription
  const { t } = useTranslation()

  const periodName = useMemo(
    () =>
      product === PurchaseProduct.SEVEN_DAY
        ? t('purchase.subscription.weeklyPeriod', {
            periodQuantity: trialPrices.durationDays,
          })
        : t('purchase.subscription.monthlyPeriod', {
            periodQuantity: mainPrices.periodQuantity,
            count: mainPrices.periodQuantity,
          }),
    [product, t, trialPrices.durationDays, mainPrices.periodQuantity],
  )

  return { periodName }
}
