import { useLayoutEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  selectUserCountryCode,
  selectUserOnboardingEmail,
} from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'

export const useTiktokPixelInitialization = (tiktokPixelId: string): void => {
  const email = useSelector(selectUserOnboardingEmail)
  const countryCode = useSelector(selectUserCountryCode)

  const {
    isEUUser,
    isPersonalDataAllowed,
    cookieConsent,
    hasAdvertisingAndTargetingCookie,
  } = useCookieConsentAnswer()

  const shouldTiktokPixelBePaused = useMemo(
    () =>
      (isEUUser && !hasAdvertisingAndTargetingCookie) ||
      (isEUUser && !cookieConsent?.length),
    [cookieConsent?.length, hasAdvertisingAndTargetingCookie, isEUUser],
  )

  useLayoutEffect(() => {
    if (!tiktokPixelId || !countryCode || shouldTiktokPixelBePaused) {
      return
    }

    if (!window.tiktokPixelInitialized) {
      window.ttq.load(tiktokPixelId)
      window.tiktokPixelInitialized = true
    }

    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
    window.ttq.page()
  }, [
    countryCode,
    email,
    isPersonalDataAllowed,
    shouldTiktokPixelBePaused,
    tiktokPixelId,
  ])
}
