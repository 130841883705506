import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { BMI_LABELS_MAP } from 'map-constants'

import {
  BMI_SCALE_MAP,
  INDEX_THRESHOLDS,
  INFO_BMI_MAP,
  VALUE_THRESHOLDS,
} from 'components/PersonalSummaryV3/constants'

import { useUsersBmi } from 'hooks/useUsersBmi'

import { getBMIPercentRange } from 'helpers/getBMIPercentRange'

import { TBMIValue } from 'models/common.model'

import { StyledPersonalSummaryV3 as S } from './PersonalSummaryV3.styles'

type TNumberThreshold = {
  value: number
  shiftPercent: number
}

const lowerBmiThresholdV2 = 18.5

export const PersonalSummaryV3 = () => {
  const { t } = useTranslation()
  const { currentBMI } = useUsersBmi()

  const indexThresholds: TNumberThreshold[] = INDEX_THRESHOLDS.reduce(
    (acc: TNumberThreshold[], curr) => {
      const bmiLabel = BMI_LABELS_MAP.find(
        (label) => curr >= label.lowerThreshold && curr <= label.upperThreshold,
      ) as TBMIValue

      return [
        ...acc,
        {
          value: curr,
          shiftPercent: getBMIPercentRange(
            BMI_SCALE_MAP,
            curr,
            bmiLabel,
            lowerBmiThresholdV2,
          ),
        },
      ]
    },
    [],
  )

  const shiftPercent = useMemo(
    () =>
      getBMIPercentRange(
        BMI_SCALE_MAP,
        currentBMI.bmiIndex,
        currentBMI.bmiValue,
        lowerBmiThresholdV2,
      ),
    [currentBMI],
  )

  const bmiInfo = useMemo(
    () => currentBMI.bmiValue.value && INFO_BMI_MAP[currentBMI.bmiValue.value],
    [currentBMI],
  )

  return (
    <div>
      <S.TitleRow>
        <S.Title>{t('onboarding.profileSummaryV2.bmiTitle')}</S.Title>
        <S.TitleLabel>
          {t('onboarding.profileSummaryV2.normalBMILabel')}
        </S.TitleLabel>
      </S.TitleRow>
      <S.Subtitle>{t('onboarding.profileSummaryV2.chartTitle')}</S.Subtitle>
      <S.RangeTrack
        isDynamic
        hasBMIInfo={!!bmiInfo}
        numberThresholds={indexThresholds}
        valueThresholds={VALUE_THRESHOLDS}
        value={currentBMI.bmiIndex.toFixed(1)}
        percentage={shiftPercent}
      />
      {bmiInfo && <S.BMIInfoCard {...bmiInfo} />}
    </div>
  )
}
