import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { FlagContainer } from 'components/FlagContainer'

import { selectDynamicDiscount } from 'root-redux/selects/common'

import {
  CURRENCY_SYMBOLS,
  Currency,
  PurchaseProduct,
} from 'modules/purchase/constants'
import { getPriceBeforeDiscount } from 'modules/purchase/helpers/getRecalculatedPrice'
import { useSubscriptionPeriodName } from 'modules/purchase/hooks/useSubscriptionPeriodName'
import { TSubscriptionItemProps } from 'modules/purchase/types'

import { Color, DEFAULT_DYNAMIC_DISCOUNT_AMOUNT } from 'root-constants'

import { StyledKitSubscriptionItemV1 as S } from './KitSubscriptionItemV1.styles'
import { StyledKitSubscriptionItemV1ComplexCurrency as C } from './KitSubscriptionItemV1ComplexCurrency.styles'

export const KitSubscriptionItemV2: FC<TSubscriptionItemProps> = ({
  subscription,
  isSelected,
}) => {
  const { t } = useTranslation()
  const { id, product, mainPrices, trialPrices, isDefault, currency } =
    subscription

  const dynamicDiscount = useSelector(selectDynamicDiscount)

  const { periodName } = useSubscriptionPeriodName(subscription)

  const discount = useMemo(
    () =>
      dynamicDiscount
        ? dynamicDiscount?.amount
        : DEFAULT_DYNAMIC_DISCOUNT_AMOUNT,
    [dynamicDiscount],
  )

  const isSubscriptionTrial = useMemo(
    () =>
      trialPrices.fullPrice && trialPrices.fullPrice !== mainPrices.fullPrice,
    [trialPrices, mainPrices],
  )

  const noTrialCurrentPriceWithFractionalPart = useMemo(
    () => mainPrices.fullPrice.toFixed(2),
    [mainPrices],
  )

  const trialCurrentPriceWithFractionalPart = useMemo(
    () => trialPrices.fullPrice.toFixed(2),
    [trialPrices],
  )

  const oldPriceWithFractionalPart = useMemo(
    () =>
      product === PurchaseProduct.SEVEN_DAY
        ? trialPrices.oldPrices.beforeCustomDiscount?.fullPrice.toFixed(2)
        : getPriceBeforeDiscount(
            Number(trialCurrentPriceWithFractionalPart),
            discount,
          ),
    [
      discount,
      product,
      trialPrices.oldPrices.beforeCustomDiscount?.fullPrice,
      trialCurrentPriceWithFractionalPart,
    ],
  )

  const pricePerDayWithFractionalPart = useMemo(
    () =>
      product === PurchaseProduct.SEVEN_DAY
        ? mainPrices.daily.toFixed(2)
        : trialPrices.daily.toFixed(2),
    [product, trialPrices, mainPrices],
  )

  const oldPricePerDayWithFractionalPart = useMemo(
    () =>
      getPriceBeforeDiscount(Number(pricePerDayWithFractionalPart), discount),
    [discount, pricePerDayWithFractionalPart],
  )

  const isMexican = useMemo(() => currency === Currency.MXN, [currency])

  return !isMexican ? (
    <S.PlanContainer key={id} isSelected={isSelected}>
      <S.PlanColumn>
        <S.PlanPeriod isSelected={isSelected}>{periodName}</S.PlanPeriod>
        <S.PlanPrice>
          {isSubscriptionTrial ? (
            <>
              <S.PreviousPrice>
                {t('purchase.subscriptionV2.price', {
                  price: oldPriceWithFractionalPart,
                  currency: CURRENCY_SYMBOLS[currency],
                  context: currency,
                })}
              </S.PreviousPrice>
              <S.CurrentPrice>
                {t('purchase.subscriptionV2.price', {
                  price: trialCurrentPriceWithFractionalPart,
                  currency: CURRENCY_SYMBOLS[currency],
                  context: currency,
                })}
              </S.CurrentPrice>
              <S.PreviousDayPrice>
                {t('purchase.subscriptionV2.price', {
                  price: oldPricePerDayWithFractionalPart,
                  currency: CURRENCY_SYMBOLS[currency],
                  context: currency,
                })}
              </S.PreviousDayPrice>
            </>
          ) : (
            <S.CurrentPrice>
              {t('purchase.subscriptionV2.price', {
                price: noTrialCurrentPriceWithFractionalPart,
                currency: CURRENCY_SYMBOLS[currency],
                context: currency,
              })}
            </S.CurrentPrice>
          )}
        </S.PlanPrice>
      </S.PlanColumn>
      <S.PlanColumn>
        <FlagContainer
          backgroundColor={isSelected ? Color.GREEN_300 : Color.GREY_130}
        >
          <S.DayPriceContainer isSelected={isSelected}>
            <S.CurrentDayPrice>
              {t('purchase.subscriptionV2.price', {
                price: pricePerDayWithFractionalPart,
                currency: CURRENCY_SYMBOLS[currency],
                context: currency,
              })}
            </S.CurrentDayPrice>
            <S.DayPricePeriod>
              {t('purchase.subscriptionV2.perDay')}
            </S.DayPricePeriod>
          </S.DayPriceContainer>
        </FlagContainer>
      </S.PlanColumn>
      {isDefault && (
        <S.PopularBadge isSelected={isSelected}>
          {t('purchase.subscriptionV2.mostPopularBadge')}
        </S.PopularBadge>
      )}
    </S.PlanContainer>
  ) : (
    <C.PlanContainer key={id} isSelected={isSelected}>
      <C.PlanColumn>
        <C.PlanPeriod isSelected={isSelected}>{periodName}</C.PlanPeriod>
        <C.PlanPrice>
          {isSubscriptionTrial ? (
            <>
              <C.PreviousPrice>
                {t('purchase.subscriptionV2.price', {
                  price: oldPriceWithFractionalPart,
                  currency: CURRENCY_SYMBOLS[currency],
                  context: currency,
                })}
              </C.PreviousPrice>
              <C.CurrentPrice>
                {t('purchase.subscriptionV2.price', {
                  price: trialCurrentPriceWithFractionalPart,
                  currency: CURRENCY_SYMBOLS[currency],
                  context: currency,
                })}
              </C.CurrentPrice>
            </>
          ) : (
            <C.CurrentPrice>
              {t('purchase.subscriptionV2.price', {
                price: noTrialCurrentPriceWithFractionalPart,
                currency: CURRENCY_SYMBOLS[currency],
                context: currency,
              })}
            </C.CurrentPrice>
          )}
        </C.PlanPrice>
      </C.PlanColumn>
      <C.PlanColumn>
        <FlagContainer
          backgroundColor={isSelected ? Color.GREEN_300 : Color.GREY_130}
        >
          <C.DayPriceContainer isSelected={isSelected}>
            {isSubscriptionTrial && (
              <C.PreviousDayPrice isSelected={isSelected}>
                {t('purchase.subscriptionV2.price', {
                  price: oldPricePerDayWithFractionalPart,
                  currency: CURRENCY_SYMBOLS[currency],
                  context: currency,
                })}
              </C.PreviousDayPrice>
            )}
            <C.CurrentDayPrice>
              {t('purchase.subscriptionV2.price', {
                price: pricePerDayWithFractionalPart,
                currency: CURRENCY_SYMBOLS[currency],
                context: currency,
              })}
            </C.CurrentDayPrice>
            <C.DayPricePeriod>
              {t('purchase.subscriptionV2.perDay')}
            </C.DayPricePeriod>
          </C.DayPriceContainer>
        </FlagContainer>
      </C.PlanColumn>
      {isDefault && (
        <C.PopularBadge isSelected={isSelected}>
          {t('purchase.subscriptionV2.mostPopularBadge')}
        </C.PopularBadge>
      )}
    </C.PlanContainer>
  )
}
