import React, { useCallback, useEffect, useLayoutEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Button, buttonTheme } from 'storybook-ui'

import { KitPageContainer } from 'components/PageContainer'
import { Spinner } from 'components/Spinner'

import {
  getSubscriptionListAction,
  setScreenNameAction,
} from 'root-redux/actions/common'
import {
  selectLanguage,
  selectSubscriptionList,
} from 'root-redux/selects/common'
import {
  selectIsPersonalDataAllowed,
  selectUserOnboardingEmail,
} from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useVatInfo } from 'hooks/useHasVatInfo'
import { usePayment } from 'hooks/usePayment'

import { createProductId } from 'helpers/createProductId'

import {
  CustomerReviews,
  Disclaimer,
  FAQList,
  MoneyBackGuarantee,
  PhotoResult,
  PlanHighlights,
  SubscriptionsBlock,
} from 'modules/purchase/components'
import { selectSubscriptionPaywallDiscountPercent } from 'modules/purchase/redux/selects'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import {
  CDN_FOLDER_LINK,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { StyledCancelOffer as S } from './CancelOffer.styles'
import { IMAGE_PATH } from './constants'

export const CancelOffer: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const { search } = useLocation()

  const subscriptions = useSelector(selectSubscriptionList)
  const email = useSelector(selectUserOnboardingEmail)
  const discountFromSubscriptions = useSelector(
    selectSubscriptionPaywallDiscountPercent,
  )
  const language = useSelector(selectLanguage)
  const isPersonalDataAllowed = useSelector(selectIsPersonalDataAllowed)

  const { hasPrices, handleShowPayment } = usePayment()

  const hasVatInfo = useVatInfo()
  const { currentPaymentPageId } = useGetPageInfo()

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.CANCEL_OFFER))

    const tags = `${SubscriptionTags.CANCEL_OFFER},${
      hasVatInfo ? SubscriptionTags.TAX : SubscriptionTags.NO_TAX
    }`

    dispatch(getSubscriptionListAction(SubscriptionListType.PURCHASE, tags))
  }, [dispatch, hasVatInfo])

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(({ mainPrices }) =>
        createProductId({
          periodName: mainPrices.periodName,
          periodQuantity: mainPrices.periodQuantity,
          price: mainPrices.fullPrice,
        }),
      ),
      screenName: ScreenName.CANCEL_OFFER,
    })
  }, [subscriptions])

  const handleGetPlan = useCallback(() => {
    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
    handleShowPayment()

    goTo(`${currentPaymentPageId}${search}`)
  }, [
    isPersonalDataAllowed,
    email,
    handleShowPayment,
    currentPaymentPageId,
    search,
  ])

  return !hasPrices ? (
    <Spinner />
  ) : (
    <KitPageContainer paddingTop={0}>
      <S.DiscountBanner
        imgSrc={`${CDN_FOLDER_LINK}${IMAGE_PATH}-${language}.png`}
      >
        <S.DiscountContent>
          <S.DiscountTitle>
            <Trans i18nKey="cancelOffer.discountTitle" />
          </S.DiscountTitle>
          <S.DiscountValue>
            {t('cancelOffer.discountValue', {
              discountValue: discountFromSubscriptions,
            })}
          </S.DiscountValue>
        </S.DiscountContent>
      </S.DiscountBanner>

      <S.PlanDescription>
        <Trans i18nKey="cancelOffer.planDescription" />
      </S.PlanDescription>

      <S.SubscriptionsContainer>
        <SubscriptionsBlock titleMargin={24} isCancelOffer />
        <MoneyBackGuarantee />
        <Button
          width="100%"
          margin="0 auto"
          style={{
            textTransform: 'capitalize',
          }}
          theme={buttonTheme.NUTRIMATE_PRIMARY}
          onClick={handleGetPlan}
        >
          {t('actions.getMyPlan')}
        </Button>
      </S.SubscriptionsContainer>

      <PlanHighlights />
      <PhotoResult />
      <FAQList />
      <CustomerReviews />

      <Button
        width="100%"
        margin="0 auto"
        style={{
          textTransform: 'capitalize',
        }}
        theme={buttonTheme.NUTRIMATE_PRIMARY}
        onClick={handleGetPlan}
      >
        {t('actions.getMyPlan')}
      </Button>
      <S.FeaturedBlock />
      <Disclaimer />
    </KitPageContainer>
  )
}
