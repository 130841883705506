import styled, { css } from 'styled-components'

import backArrowIcon from 'assets/images/back-arrow.svg'
import forwardArrowIcon from 'assets/images/forward-arrow.svg'

import { Color, MediaBreakpoint } from 'root-constants'

const commonLabelStyles = css`
  font-size: 12px;
  font-weight: 900;
  line-height: 14px;
  padding: 8px 0;
  text-align: center;
`

export const StyledPhotoResult = {
  Wrapper: styled.div`
    margin-bottom: 40px;
  `,
  ResultContainer: styled.div`
    align-items: center;
    background-color: ${Color.WHITE};
    border: 1px solid ${Color.GREY_800};
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    padding: 24px 16px;
  `,
  ResultTitle: styled.h2`
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 24px;
    text-align: center;

    strong {
      color: ${Color.GREEN_300};
    }
  `,
  CarouselContainer: styled.div`
    max-width: 100%;

    .swiper-container-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      display: flex;
      justify-content: center;
      bottom: 0;
    }

    .swiper-pagination-bullets {
      bottom: -5px;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet {
      background: ${Color.GREY_800};
      height: 8px;
      margin: 0 6px;
      opacity: 1;
      width: 8px;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet-active {
      opacity: 1;
      background-color: ${Color.GREEN_300};
    }

    .swiper-button-next,
    .swiper-button-prev {
      background-color: ${Color.WHITE};
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-repeat: no-repeat;
      top: 130px;
    }

    .swiper-button-next {
      background-image: url(${forwardArrowIcon});
      background-position: 55% 50%;
    }

    .swiper-button-prev {
      background-image: url(${backArrowIcon});
      background-position: 45% 50%;
    }
  `,
  Cards: styled.div`
    background-color: rgba(169, 205, 80, 0.2);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    overflow: hidden;
    padding: 30px 0 24px;
  `,
  ImageContainer: styled.div`
    padding: 4px 4px 0;
    background-color: ${Color.WHITE};
    border-radius: 14px;
    box-shadow: 0px 3.354px 67.086px 0px rgba(0, 0, 0, 0.16);

    &:first-of-type {
      transform: rotate(-7deg);
    }

    &:last-of-type {
      transform: rotate(7deg) translateY(-10px);
    }
  `,
  Image: styled.div`
    aspect-ratio: 35 / 52;
    width: 90px;

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      width: 100px;
    }
  `,
  LabelBefore: styled.p`
    ${commonLabelStyles};
    color: ${Color.GREY_101};
  `,
  LabelAfter: styled.p`
    ${commonLabelStyles};
    color: ${Color.GREY_100};
  `,
  TextContainer: styled.div`
    padding-bottom: 30px;
  `,
  Author: styled.p`
    display: flex;
    font-size: 16px;
    font-weight: 700;
    justify-content: space-between;
    line-height: 24px;
    margin-bottom: 8px;
  `,
  Review: styled.p`
    color: ${Color.GREY_101};
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    position: relative;

    svg {
      height: 16px;
      transform: translateY(3px);
    }
  `,
  Disclaimer: styled.p`
    color: ${Color.GREY_101};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
  `,
}
