import {
  IRawVariant,
  IVariant,
  TOnboardingDynamicConfig,
  TPaywallDynamicConfig,
} from 'models/variant.model'

import { Language } from 'root-constants'

export const getVariantFromRawVariant = (
  rawVariant: IRawVariant,
): IVariant => ({
  cohort: rawVariant.cohort_id,
  parentCohort: rawVariant.parent_cohort_id,
  facebookPixelIds: rawVariant.facebook_pixel_ids,
  tiktokPixelId: rawVariant.tiktok_pixel_id,
  snapPixelId: rawVariant.snap_pixel_id,
  outbrainPixelId: rawVariant.outbrain_pixel_id,
  onboardingProgressBreakpoints: rawVariant.onboarding_progress_breakpoints,
  taboolaPixelId: rawVariant.taboola_pixel_id,
  growthBookExperiments: rawVariant.optimize_map,
  growthBookDefaultValues: rawVariant.optimize_experiments,
  pinterestTagId: rawVariant.pinterest_tag_id,
  hasUpsellOffer: rawVariant.has_upsell_offer,
  hasInAppOffer: rawVariant.has_inapp_offer,
  hasCancelOffer: rawVariant.has_cancel_offer,
  abSegmentName: rawVariant.ab_segment_name,
  steps: rawVariant.steps.map((step) => ({
    id: step.page_id,
    isPayment: step.is_payment,
    isQuestion: step.is_question,
    isSkippable: step.is_skippable,
    isSubscriptions: step.is_subscriptions,
    hasHeader: step.has_header,
    hasProgressBar: step.has_progressbar,
    abSegment: step.optimize
      ? {
          id: step.optimize.id,
          isDefault: step.optimize.is_default,
        }
      : null,
    onboardingDynamicConfig: step.onboarding_configs
      ? (Object.keys(step.onboarding_configs) as Language[]).reduce(
          (acc, key) => {
            return {
              ...acc,
              [key]: {
                optionType: step.onboarding_configs?.[key].option_type,
                customPageId: step.onboarding_configs?.[key].page_id_to_mobile,
                title: step.onboarding_configs?.[key].title,
                subtitle: step.onboarding_configs?.[key].subtitle,
                buttonText: step.onboarding_configs?.[key].button_text,
                imageUrls: step.onboarding_configs?.[key].image_urls,
                pageTexts: step.onboarding_configs?.[key].page_texts,
                personalization: step.onboarding_configs?.[key].personalization,
                optionData: step.onboarding_configs?.[key].option_data?.map(
                  (data) => ({
                    id: data.id,
                    value: data.value,
                    text: data.block_text,
                    additionalText: data.additional_block_text,
                    imageUrl: data.image_url,
                    imageWidth: data.image_width,
                    imageHeight: data.image_height,
                    nextPageId: data.next_page_id,
                  }),
                ),
              },
            }
          },
          {} as TOnboardingDynamicConfig,
        )
      : null,
    paywallDynamicConfig: step.paywall_configs
      ? (Object.keys(step.paywall_configs) as Language[]).reduce((acc, key) => {
          return {
            ...acc,
            [key]: {
              title: step.paywall_configs?.[key].title,
              subtitle: step.paywall_configs?.[key].subtitle,
              buttonText: step.paywall_configs?.[key].button_text,
              subscriptionBlockTitle:
                step.paywall_configs?.[key].subscription_block_title,
              subscriptionBlockType:
                step.paywall_configs?.[key].subscription_block_type,
              benefitsBlockTitle:
                step.paywall_configs?.[key].benefits_block_title,
              benefits: step.paywall_configs?.[key].benefits?.map((data) => ({
                id: data.id,
                text: data.block_text,
              })),
              customerReviewsTitle:
                step.paywall_configs?.[key].customer_reviews_title,
              customerReviews: step.paywall_configs?.[
                key
              ].customer_reviews?.map((data) => ({
                id: data.id,
                title: data.title,
                subtitle: data.subtitle,
                author: data.author,
                shortReview: data.short_review,
                review: data.review,
                imageUrl: data.image_url,
              })),
            },
          }
        }, {} as TPaywallDynamicConfig)
      : null,
  })),
})
