import styled, { css } from 'styled-components'
import { DynamicDiscountThemeValue } from 'value-constants'

import { Color, Gradient } from 'root-constants'

type TTheme = {
  theme: DynamicDiscountThemeValue
}

export const StyledDiscountBlockV1 = {
  ContentBlock: styled.div<TTheme>`
    display: flex;
    align-items: center;
    border-radius: 16px;
    padding: 12px;

    ${({ theme }) => {
      switch (theme) {
        case DynamicDiscountThemeValue.RED:
          return css`
            color: ${Color.RED_400};
            background: linear-gradient(${Gradient.LINEAR_RED_CORAL_LIGHT});
          `
        case DynamicDiscountThemeValue.VIOLET:
          return css`
            color: #0091ff;
            border-radius: 16px;
            background: linear-gradient(${Gradient.LINEAR_VIOLET_BLUE_LIGHT});
          `
        case DynamicDiscountThemeValue.ORANGE:
          return css`
            color: ${Color.RED_300};
            background: linear-gradient(${Gradient.LINEAR_RED_YELLOW_LIGHT});
          `
        case DynamicDiscountThemeValue.BLACK:
          return css`
            color: ${Color.RED_300};
            background: linear-gradient(${Gradient.LINEAR_RED_YELLOW_LIGHT});
          `
        default:
          return css`
            color: ${Color.RED_300};
            background: linear-gradient(${Gradient.LINEAR_RED_YELLOW_LIGHT});
          `
      }
    }}
  `,
  Discount: styled.div<TTheme>`
    border-radius: 8px;
    margin: 0 16px 0 0;
    padding: 4px 12px;
    color: ${Color.WHITE};
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;

    ${({ theme }) => {
      switch (theme) {
        case DynamicDiscountThemeValue.RED:
          return css`
            background: radial-gradient(
                1446.02% 200.66% at 7.36% 145%,
                #c40202 0%,
                #ff5659 100%
              ),
              radial-gradient(
                1446.02% 200.66% at 7.36% 145%,
                #c40270 0%,
                #ff507c 100%
              );
          `
        case DynamicDiscountThemeValue.VIOLET:
          return css`
            background: linear-gradient(${Gradient.LINEAR_VIOLET_BLUE});
          `
        case DynamicDiscountThemeValue.ORANGE:
          return css`
            background: linear-gradient(${Gradient.LINEAR_RED_YELLOW});
          `
        case DynamicDiscountThemeValue.BLACK:
          return css`
            background: linear-gradient(${Gradient.LINEAR_RED_YELLOW});
          `
        default:
          return css`
            background: linear-gradient(${Gradient.LINEAR_RED_YELLOW});
          `
      }
    }};
  `,
  DiscountLabel: styled.p`
    font-weight: 700;
    font-size: 17px;
    line-height: 24px;
  `,
}
