import React, { PropsWithChildren } from 'react'
import { Trans } from 'react-i18next'

import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { StyledBoostResults as S } from './HowItWorks.styles'
import { HOW_IT_WORKS_STEPS, T_PREFIX } from './constant'

export const HowItWorks: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <S.Wrapper>
      <S.Banner>
        <Trans i18nKey={`${T_PREFIX}.banner`} />
      </S.Banner>
      <S.Title>
        <Trans i18nKey={`${T_PREFIX}.title`} />
      </S.Title>
      <S.Subtitle>
        <Trans i18nKey={`${T_PREFIX}.subtitle`} />
      </S.Subtitle>
      <S.Steps>
        {HOW_IT_WORKS_STEPS.map(({ icon, title, text }) => (
          <S.Item key={title}>
            <img src={icon} alt="icon" />
            <div>
              <h4>
                <Trans i18nKey={title} />
              </h4>
              <p>
                <Trans
                  i18nKey={text}
                  components={{
                    // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
                    a: <a href="mailto:support@nutrimate.fit" />,
                  }}
                />
              </p>
            </div>
          </S.Item>
        ))}
      </S.Steps>
      <S.MoreInfo>
        <Trans i18nKey={`${T_PREFIX}.moreInfo`} />
        <TermsOfUseLink />.
      </S.MoreInfo>
      {children}
    </S.Wrapper>
  )
}
