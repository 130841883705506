import { BmiValue } from 'value-constants'

import { Gender } from 'root-constants'

const MEN_ANIMATION_NORMAL = '/animations/kit-paywall-male-normal'
const MEN_ANIMATION_OVERWEIGHT = '/animations/kit-paywall-male-overweight'
const MEN_ANIMATION_OBESE = '/animations/kit-paywall-male-obese'

const WOMEN_ANIMATION_NORMAL = '/animations/kit-paywall-female-normal'
const WOMEN_ANIMATION_OVERWEIGHT = '/animations/kit-paywall-female-overweight'
const WOMEN_ANIMATION_OBESE = '/animations/kit-paywall-female-obese'

export const ANIMATION_PATH_MAP = {
  [Gender.MALE]: {
    [BmiValue.UNDERWEIGHT]: MEN_ANIMATION_NORMAL,
    [BmiValue.NORMAL]: MEN_ANIMATION_NORMAL,
    [BmiValue.OVERWEIGHT]: MEN_ANIMATION_OVERWEIGHT,
    [BmiValue.OBESITY]: MEN_ANIMATION_OBESE,
    [BmiValue.EXTREME_OBESITY]: MEN_ANIMATION_OBESE,
  },
  [Gender.FEMALE]: {
    [BmiValue.UNDERWEIGHT]: WOMEN_ANIMATION_NORMAL,
    [BmiValue.NORMAL]: WOMEN_ANIMATION_NORMAL,
    [BmiValue.OVERWEIGHT]: WOMEN_ANIMATION_OVERWEIGHT,
    [BmiValue.OBESITY]: WOMEN_ANIMATION_OBESE,
    [BmiValue.EXTREME_OBESITY]: WOMEN_ANIMATION_OBESE,
  },
}
