import styled from 'styled-components'

import { Option } from 'components/Option'

import { Color } from 'root-constants'

export const StyledCancelOfferQuestion = {
  Banner: styled.div`
    aspect-ratio: 1 / 1;
    margin: 0 auto 24px;
    width: 248px;
  `,
  Benefits: styled.ul`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    row-gap: 16px;
  `,
  Benefit: styled.li`
    color: ${Color.GREY_101};
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    padding-left: 32px;
    position: relative;

    &:before {
      background-color: ${Color.GREEN_300};
      border: 8px solid ${Color.GREEN_500};
      border-radius: 50%;
      content: '';
      display: block;
      height: 24px;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
    }
  `,
  Question: styled.h2`
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 24px;
  `,
  Actions: styled.div<{ hasAnswer: boolean }>`
    column-gap: 9px;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${({ hasAnswer }) => (hasAnswer ? 32 : 0)}px;
  `,
  Option: styled(Option)`
    width: 100%;
  `,
  AnswerContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  AnswerTitle: styled.h2`
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 16px;
    text-align: center;
  `,
  AnswerQuestion: styled.h3`
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 8px;
    text-align: center;
  `,
  AnswerCallToAction: styled.h4`
    color: ${Color.GREY_101};
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
    text-align: center;
  `,
  DiscountContainer: styled.div`
    background-color: ${Color.WHITE};
    border: 1px solid ${Color.GREY_800};
    border-radius: 16px;
    column-gap: 12px;
    display: flex;
    margin-bottom: 16px;
    padding: 16px;
  `,
  DiscountIcon: styled.div`
    align-items: center;
    background-color: ${Color.GREEN_600};
    border-radius: 12px;
    display: flex;
    justify-content: center;
    height: 48px;
    width: 48px;

    img {
      aspect-ratio: 25 / 29;
      min-width: 17px;
      width: 17px;
    }
  `,
  DiscountContent: styled.p`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;

    span {
      color: ${Color.RED_500};
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
    }
  `,
  AnswerInfo: styled.h4`
    color: ${Color.GREY_101};
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  `,
}
