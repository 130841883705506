import React from 'react'
import { Trans } from 'react-i18next'

import { Button, buttonTheme } from 'storybook-ui'

import { KitPageContainer } from 'components/PageContainer'
import { Spinner } from 'components/Spinner'

import {
  CustomerReviews,
  Disclaimer,
  FAQList,
  MoneyBackGuarantee,
  PhotoResult,
  PlanHighlights,
  SubscriptionsBlock,
} from 'modules/purchase/components'

import { CDN_FOLDER_LINK } from 'root-constants'

import { StyledCancelOffer as S } from './CancelOffer.styles'
import { IMAGE_PATH } from './constants'
import { useCancelOffer } from './useCancelOffer'

export const CancelOffer: React.FC = () => {
  const { hasPrices, language, handleGetPlan, discountFromSubscriptions } =
    useCancelOffer()

  if (!hasPrices) return <Spinner />

  const GetPlanButton = () => (
    <Button
      width="100%"
      margin="0 auto"
      style={{ textTransform: 'capitalize' }}
      theme={buttonTheme.NUTRIMATE_PRIMARY}
      onClick={handleGetPlan}
    >
      <Trans i18nKey="actions.getMyPlan" />
    </Button>
  )

  return (
    <KitPageContainer paddingTop={0}>
      <S.DiscountBanner
        imgSrc={`${CDN_FOLDER_LINK}${IMAGE_PATH}-${language}.png`}
      >
        <S.DiscountContent>
          <S.DiscountTitle>
            <Trans i18nKey="cancelOffer.discountTitle" />
          </S.DiscountTitle>
          <S.DiscountValue>
            <Trans
              i18nKey="cancelOffer.discountValue"
              values={{ discountValue: discountFromSubscriptions }}
            />
          </S.DiscountValue>
        </S.DiscountContent>
      </S.DiscountBanner>

      <S.PlanDescription>
        <Trans i18nKey="cancelOffer.planDescription" />
      </S.PlanDescription>

      <S.SubscriptionsContainer>
        <SubscriptionsBlock titleMargin={24} isCancelOffer />
        <MoneyBackGuarantee />
        <GetPlanButton />
      </S.SubscriptionsContainer>

      <PlanHighlights />
      <PhotoResult />
      <FAQList />
      <CustomerReviews />

      <GetPlanButton />
      <S.FeaturedBlock />
      <Disclaimer />
    </KitPageContainer>
  )
}
