import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'

import af from 'assets/i18n/af.json'
import en from 'assets/i18n/en.json'
import es from 'assets/i18n/es.json'
import pt from 'assets/i18n/pt.json'

i18n.use(initReactI18next).init({
  resources: {
    en,
    es,
    af,
    pt,
  },
  defaultNS: 'common',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
})
