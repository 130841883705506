import React, { useLayoutEffect, useState } from 'react'

export const useElementViewportVisibility = (
  targetEl: React.RefObject<HTMLElement> | null,
) => {
  const [isVisible, setIsVisible] = useState(false)

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach(({ isIntersecting }) => setIsVisible(isIntersecting)),
      { threshold: 0.01 },
    )

    if (targetEl?.current) observer.observe(targetEl.current)

    return () => observer.disconnect()
  }, [])

  return isVisible
}
