import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useDelayedAnimationPlaying } from 'components/LoseWeightGraph/hooks/useDelayedAnimationPlaying'

import {
  selectCurrentUserCurrentWeight,
  selectCurrentUserGender,
  selectCurrentUserGoalWeight,
  selectLanguage,
} from 'root-redux/selects/common'

import { useWeightMeasurementSystem } from 'hooks/useWeightMeasurementSystem'

import { CDN_FOLDER_LINK } from 'root-constants'

import { StyledLoseWeightGraphV4 as S } from './LoseWeightGraphV4.styles'

type TProps = {
  days: number
}

const animationPath = '/animations/weight-loss-date-animations'

export const LoseWeightGraphV4: React.FC<TProps> = ({ days }) => {
  const { t } = useTranslation()
  const gender = useSelector(selectCurrentUserGender)
  const currentWeight = useSelector(selectCurrentUserCurrentWeight)
  const goalWeight = useSelector(selectCurrentUserGoalWeight)
  const language = useSelector(selectLanguage)
  const [animationRef, isAnimationStarted] = useDelayedAnimationPlaying({
    animationPath: `${CDN_FOLDER_LINK}${animationPath}-${language}.json`,
  })
  const { measuringSystemLabel } = useWeightMeasurementSystem()
  const currentWeightLabel = `${currentWeight} ${measuringSystemLabel}`
  const targetWeightLabel = `${goalWeight} ${measuringSystemLabel}`

  return (
    <S.GraphWrapper>
      <S.Graph ref={animationRef}>
        <S.CurrentWeightLabel isAnimationStarted={isAnimationStarted}>
          {currentWeightLabel}
        </S.CurrentWeightLabel>
        <S.GoalWeightLabel isAnimationStarted={isAnimationStarted}>
          {targetWeightLabel}
        </S.GoalWeightLabel>
      </S.Graph>
      <S.GraphSignatureWrapper>
        <S.GraphSignature>{t`onboarding.weightLossDateV1.now`}</S.GraphSignature>
        <S.GraphSignature>
          <Trans
            i18nKey="onboarding.weightLossDateV1.days"
            values={{
              daysToGoalWeight: days,
            }}
          />
        </S.GraphSignature>
      </S.GraphSignatureWrapper>
      <S.BaseOnText>
        <Trans i18nKey="onboarding.weightLossDateV1.baseOn" context={gender} />
      </S.BaseOnText>
    </S.GraphWrapper>
  )
}
