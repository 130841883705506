import anotherFemale from 'assets/images/compare-another-female.png'
import anotherMale from 'assets/images/compare-another-male.png'
import nutrimateFemale from 'assets/images/compare-nutrimate-female.png'
import nutrimateMale from 'assets/images/compare-nutrimate-male.png'
import checkIcon from 'assets/images/sprite/check-icon-white.svg'
import closeIcon from 'assets/images/sprite/close-icon-white.svg'

const tAnother = 'nutrimateCompare.another'
const tNutrimate = 'nutrimateCompare.nutrimate'

export const COMPARISON_PROGRAMS_IDS = {
  another: 'another',
  nutrimate: 'nutrimate',
} as const

export const COMPARISON_PROGRAMS = {
  ids: [COMPARISON_PROGRAMS_IDS.another, COMPARISON_PROGRAMS_IDS.nutrimate],
  titles: [`${tAnother}.title`, `${tNutrimate}.title`],
  images: [
    { male: anotherMale, female: anotherFemale },
    { male: nutrimateMale, female: nutrimateFemale },
  ],
  icons: [closeIcon, checkIcon],
  options: [
    [
      `${tAnother}.options.first`,
      `${tAnother}.options.second`,
      `${tAnother}.options.thirty`,
    ],
    [
      `${tNutrimate}.options.first`,
      `${tNutrimate}.options.second`,
      `${tNutrimate}.options.thirty`,
    ],
  ],
} as const
