import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { IMG_SRC_MAP } from 'pages/questions/EasyToFollowPlan/constants'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { CDN_FOLDER_LINK, Language } from 'root-constants'

import { StyledEasyToFollowPlanV1 as S } from './EasyToFollowPlanV1.styles'

export const EasyToFollowPlanV1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const genderValue = useSelector(selectCurrentUserGender)

  const imgSrc = useMemo(
    () => `${CDN_FOLDER_LINK}${IMG_SRC_MAP[genderValue]}.png`,
    [genderValue],
  )

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.kitEasyToFollowPlanV1.title', {
      lng: Language.EN,
    }),
    nextPagePath,
  })

  return (
    <KitPageContainer
      hasContinueButton
      onContinueButtonClick={() => handleContinue('')}
    >
      <S.ImageContainer>
        <img src={imgSrc} alt="easy-to-follow-plan" />
      </S.ImageContainer>
      <PageTitle textAlign="left" marginBottom={16}>
        {t('onboarding.kitEasyToFollowPlanV1.title')}
      </PageTitle>
      <S.Text>{t('onboarding.kitEasyToFollowPlanV1.info')}</S.Text>
    </KitPageContainer>
  )
}
