import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { LoseWeightGraphV4 } from 'components/LoseWeightGraphV4'
import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentUserGoalWeight,
  selectLanguage,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'
import { usePredictableData } from 'hooks/usePredictableData'
import { useWeightMeasurementSystem } from 'hooks/useWeightMeasurementSystem'

import { extractDateParts } from 'helpers/extractDateParts'

import { TPageProps } from 'models/common.model'

import { Language } from 'root-constants'

import { StyledWeightLossDateV2 as S } from './WeightLossDateV2.styles'

export const WeightLossDateV2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const goalWeight = useSelector(selectCurrentUserGoalWeight)
  const language = useSelector(selectLanguage)
  const { measuringSystemLabel } = useWeightMeasurementSystem()
  const {
    realPredictableDate,
    hasWeekEarlyPredictableDate,
    earlyDaysToGoalWeight,
  } = usePredictableData()
  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.weightLossDateV2.title', {
      lng: Language.EN,
    }),
    nextPagePath,
  })
  const { years, months, days } = extractDateParts(
    realPredictableDate,
    language,
  )

  return (
    <KitPageContainer
      hasContinueButton
      onContinueButtonClick={() => handleContinue('')}
    >
      <PageTitle marginBottom={12}>
        <Trans i18nKey="onboarding.weightLossDateV2.title" />
      </PageTitle>
      <S.Subtitle>
        <Trans i18nKey="onboarding.weightLossDateV2.subtitle" />
      </S.Subtitle>
      <S.PredictionBlock>
        <S.Text>
          <Trans
            i18nKey="onboarding.weightLossDateV2.days"
            values={{ targetWeight: `${goalWeight} ${measuringSystemLabel}` }}
            components={{ span: <span /> }}
          />
        </S.Text>
        <S.Date>
          <S.MonthsContainer hasMultipleMonths={months.length > 1}>
            {months.map((month) => (
              <S.MonthsValue key={month}>{month}</S.MonthsValue>
            ))}
          </S.MonthsContainer>
          <S.DayContainer hasMultipleDays={hasWeekEarlyPredictableDate}>
            {days.map((date) => (
              <S.DayValue key={date}>{date},</S.DayValue>
            ))}
          </S.DayContainer>
          <S.YearContainer hasMultipleYears={years.length > 1}>
            {years.map((year) => (
              <S.YearValue key={year}>{year}</S.YearValue>
            ))}
          </S.YearContainer>
        </S.Date>
      </S.PredictionBlock>
      <LoseWeightGraphV4 days={earlyDaysToGoalWeight} />
    </KitPageContainer>
  )
}
