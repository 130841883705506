import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Button, buttonTheme } from 'storybook-ui'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'
import { KitToggle } from 'components/Toggle'

import { setMultipleAnswerAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'
import { selectUserCountryCode } from 'root-redux/selects/user'

import { convertFeetToInches } from 'helpers/convertFootsToInches'

import { IInputState, TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import calculatorIcon from 'assets/images/calculator-icon.png'

import { goTo } from 'browser-history'
import { CustomPageId } from 'page-constants'
import {
  COUNTRIES_WITH_IMPERIAL_MEASUREMENT_SYSTEM,
  FloatNumberRegex,
  INITIAL_INPUT_VALUE,
  MeasurementSystem,
} from 'root-constants'

import { StyledHeightV4 as S } from './HeightV4.styles'
import { MinMaxHeight, QUESTION } from './constants'

export const HeightV4: React.FC<TPageProps> = ({ nextPagePath }) => {
  const [heightCm, setHeightCm] = useState<IInputState>({
    ...INITIAL_INPUT_VALUE,
  })
  const [heightFt, setHeightFt] = useState<IInputState>({
    ...INITIAL_INPUT_VALUE,
  })
  const [heightIn, setHeightIn] = useState<IInputState>({
    ...INITIAL_INPUT_VALUE,
  })
  const [isMetricSystemSelected, setIsMetricSystemSelected] = useState(false)
  const [isIntegerFocused, setIsIntegerFocused] = useState(false)
  const [isFractionalFocused, setIsFractionalFocused] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const userCountryCode = useSelector(selectUserCountryCode)
  const metricInputRef = useRef<HTMLInputElement>(null)
  const imperialInputRef = useRef<HTMLInputElement>(null)
  const [cmLabel, ftLabel, inLabel] = [
    t(`commonComponents.centimeters`),
    t(`commonComponents.feet`),
    t(`commonComponents.inches`),
  ]

  const isCountryWithImperialSystem =
    COUNTRIES_WITH_IMPERIAL_MEASUREMENT_SYSTEM.includes(userCountryCode)

  const handleIntegerFocus = () => setIsIntegerFocused(true)
  const handleIntegerBlur = () => setIsIntegerFocused(false)
  const handleFractionalFocus = () => setIsFractionalFocused(true)
  const handleFractionalBlur = () => setIsFractionalFocused(false)
  const handleMeasurementChange = (isChecked) => {
    setIsMetricSystemSelected(isChecked)
    setHeightCm({ ...INITIAL_INPUT_VALUE })
    setHeightFt({ ...INITIAL_INPUT_VALUE })
    setHeightIn({ ...INITIAL_INPUT_VALUE })
  }

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()

    const inputValue = isMetricSystemSelected
      ? +heightCm.value
      : convertFeetToInches(+heightFt.value, +heightIn.value)

    dispatch(
      setMultipleAnswerAction({
        answers: {
          [CustomPageId.HEIGHT]: inputValue,
          measurement_system: isMetricSystemSelected
            ? MeasurementSystem.METRIC
            : MeasurementSystem.IMPERIAL,
        },
      }),
    )

    eventLogger.logQuestion({
      question: QUESTION,
      answers: `${inputValue},${
        isMetricSystemSelected
          ? MeasurementSystem.METRIC
          : MeasurementSystem.IMPERIAL
      }`,
    })
    dispatch(sendUserAnswersAction())
    goTo(nextPagePath)
  }

  const handleIntegerChange = ({ target: { value, validity } }) => {
    if (!value || FloatNumberRegex.test(value)) {
      isMetricSystemSelected
        ? setHeightCm({
            value,
            isValid: validity.valid,
          })
        : setHeightFt({
            value,
            isValid: validity.valid,
          })
    }
  }

  const handleFractionalChange = ({ target: { value, validity } }) => {
    if (!value || FloatNumberRegex.test(value)) {
      setHeightIn({
        value,
        isValid: validity.valid,
      })
    }
  }

  const isImperialHeightValid = (() => {
    const imperialValue = Number(`${heightFt.value}.${heightIn.value}`) || 0
    return (
      imperialValue >= MinMaxHeight.MIN_IMP_HEIGHT &&
      imperialValue <= MinMaxHeight.MAX_IMP_HEIGHT
    )
  })()

  useEffect(() => {
    if (isMetricSystemSelected && heightCm.isValid) {
      metricInputRef?.current?.blur()
    } else if (isImperialHeightValid) {
      imperialInputRef?.current?.blur()
      metricInputRef?.current?.blur()
    }
  }, [
    isMetricSystemSelected,
    heightCm.isValid,
    heightIn.value,
    isImperialHeightValid,
  ])

  useEffect(() => {
    if (!isCountryWithImperialSystem) {
      setIsMetricSystemSelected(true)
    }
  }, [isCountryWithImperialSystem])

  return (
    <form onSubmit={handleSubmit}>
      <KitPageContainer>
        <PageTitle marginBottom={24} textAlign="left">
          {t('onboarding.height.title')}
        </PageTitle>

        <S.Actions>
          <KitToggle
            labels={[ftLabel, cmLabel]}
            checked={isMetricSystemSelected}
            onChange={handleMeasurementChange}
          />
          <S.InputContainer>
            <S.InputWrapper>
              <S.HeightInput
                type="number"
                inputRef={metricInputRef}
                inputMode="numeric"
                value={isMetricSystemSelected ? heightCm.value : heightFt.value}
                isMetricSystemSelected={isMetricSystemSelected}
                isContentCentered
                isValid={
                  isMetricSystemSelected ? heightCm.isValid : heightFt.isValid
                }
                min={
                  isMetricSystemSelected
                    ? MinMaxHeight.MIN_HEIGHT_CM
                    : MinMaxHeight.MIN_HEIGHT_FT
                }
                max={
                  isMetricSystemSelected
                    ? MinMaxHeight.MAX_HEIGHT_CM
                    : MinMaxHeight.MAX_HEIGHT_FT
                }
                lang="en"
                step="0.1"
                allowFloatNumbers={isMetricSystemSelected}
                onChange={handleIntegerChange}
                onFocus={handleIntegerFocus}
                onBlur={handleIntegerBlur}
              />
              {!isIntegerFocused && !heightCm.value && !heightFt.value && (
                <S.Placeholder />
              )}
              <S.Suffix>{isMetricSystemSelected ? cmLabel : ftLabel}</S.Suffix>
              {(isMetricSystemSelected
                ? heightCm.value && !heightCm.isValid
                : heightFt.value && !heightFt.isValid) && (
                <S.ErrorMessage>
                  {t('onboarding.height.errorMessage')}
                </S.ErrorMessage>
              )}
            </S.InputWrapper>
            {!isMetricSystemSelected && (
              <S.InputWrapper>
                <S.HeightInput
                  type="number"
                  inputRef={imperialInputRef}
                  inputMode="numeric"
                  value={heightIn.value}
                  required={false}
                  isContentCentered
                  isValid={heightIn.isValid}
                  isMetricSystemSelected={isMetricSystemSelected}
                  min={MinMaxHeight.MIN_HEIGHT_IN}
                  max={MinMaxHeight.MAX_HEIGHT_IN}
                  lang="en"
                  step="1"
                  allowFloatNumbers={false}
                  onChange={handleFractionalChange}
                  onFocus={handleFractionalFocus}
                  onBlur={handleFractionalBlur}
                />
                {!isFractionalFocused && !heightIn.value && <S.Placeholder />}
                <S.Suffix>{inLabel}</S.Suffix>
                {!isMetricSystemSelected &&
                  heightIn.value &&
                  !heightIn.isValid && (
                    <S.ErrorMessage>
                      {t('onboarding.height.errorMessage')}
                    </S.ErrorMessage>
                  )}
              </S.InputWrapper>
            )}
          </S.InputContainer>
        </S.Actions>
        {!isIntegerFocused && !isFractionalFocused && (
          <S.KitInfoBlock
            iconSrc={calculatorIcon}
            title={t('onboarding.height.info.title')}
            text={t('onboarding.height.info.textV2')}
          />
        )}
        <Button
          width="100%"
          type="submit"
          margin="0 auto"
          theme={buttonTheme.NUTRIMATE_PRIMARY}
          disabled={
            isMetricSystemSelected ? !heightCm.isValid : !isImperialHeightValid
          }
        >
          {t('actions.continue')}
        </Button>
      </KitPageContainer>
    </form>
  )
}
