import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { OneClickPayment } from 'modules/purchase/components/OneClickPayment'
import { CHECKOUT_ICON_MAP, PaymentMethod } from 'modules/purchase/constants'
import { selectOneClickPaymentMethod } from 'modules/purchase/redux/selects'

import { FEATURE_KEYS } from 'root-constants'

export const useOneClickPaymentMethods = () => {
  const oneClickPaymentMethod = useSelector(selectOneClickPaymentMethod)
  const isPrimerPaypalOff = useFeatureIsOn(
    FEATURE_KEYS.fas_928_primer_paypal_es,
  )

  const icons: string[] = []
  if (oneClickPaymentMethod) {
    icons.push(CHECKOUT_ICON_MAP[oneClickPaymentMethod])
  }

  if (!isPrimerPaypalOff) {
    icons.push(CHECKOUT_ICON_MAP[PaymentMethod.PAYPAL])
  }

  return {
    method: oneClickPaymentMethod,
    option: {
      value: PaymentMethod.ONE_CLICK,
      title: 'kitCheckoutV1.oneClick',
      images: icons,
      form: OneClickPayment,
    },
  }
}
