import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { IMAGE_PATH_V1 } from 'modules/purchase/components/SecurityInfo/constants'

import { CDN_FOLDER_LINK } from 'root-constants'

import { StyledSecurityInfoV1 as S } from './SecurityInfoV1.styles'

export const SecurityInfoV1 = () => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)

  const badgeSrc = useMemo(
    () => `${CDN_FOLDER_LINK}${IMAGE_PATH_V1}-${language}.png`,
    [language],
  )

  return (
    <S.Container>
      <S.BadgeContainer>
        <S.Badge>
          <img src={badgeSrc} alt="security" />
        </S.Badge>
      </S.BadgeContainer>
      <S.InfoContainer>
        <S.InfoTitle>{t('purchase.securityInfo.titleFirst')}</S.InfoTitle>
        <S.InfoText>{t('purchase.securityInfo.textFirst')}</S.InfoText>
      </S.InfoContainer>
      <S.InfoContainer>
        <S.InfoTitle>{t('purchase.securityInfo.titleSecond')}</S.InfoTitle>
        <S.InfoText>{t('purchase.securityInfo.textSecond')}</S.InfoText>
      </S.InfoContainer>
      <S.InfoContainer>
        <S.InfoTitle>{t('purchase.securityInfo.titleThird')}</S.InfoTitle>
        <S.InfoText>
          {t('purchase.securityInfo.textThird')} <S.SupportActionLink />
        </S.InfoText>
      </S.InfoContainer>
    </S.Container>
  )
}
