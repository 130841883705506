import styled from 'styled-components'

import attentionIcon1 from 'assets/images/attention-icon.svg'

import { Color } from 'root-constants'

type TWrapperProps = {
  errorText: string
}

export const StyledErrorNotification = {
  Wrapper: styled.div<TWrapperProps>`
    width: 100%;
    padding: 10px 16px;
    margin: 24px 0 24px 0;
    background-color: ${Color.RED_400};
    border-radius: 10px;
    display: flex;
    align-items: center;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.2s, transform 0.2s;

    &.error-enter-active,
    &.error-enter-done {
      opacity: 1;
      transform: translateY(0);
    }

    &.error-exit-active {
      opacity: 0;
      transform: translateY(-20px);
    }
  `,
  Icon: styled.div`
    display: flex;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    background-image: url(${attentionIcon1});
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0;
    transition: opacity 0.2ms;

    .error-enter-active &,
    .error-enter-done & {
      opacity: 1;
    }

    .error-exit-active & {
      opacity: 0;
    }
  `,
  Text: styled.p`
    word-break: break-word;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    margin-left: 16px;
    color: ${Color.WHITE};
  `,
}
