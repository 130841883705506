import React, { useEffect, useLayoutEffect } from 'react'
import { Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { usePageInfo } from 'contexts/PageInfoProvider'

import { Option, OptionType } from 'components/Option'
import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'
import { Spinner } from 'components/Spinner'
import { SvgImage } from 'components/SvgImage'

import {
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
  setSubscriptionListAction,
} from 'root-redux/actions/common'
import {
  selectActionList,
  selectSubscriptionList,
} from 'root-redux/selects/common'
import { selectUserUpsellPages } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import {
  UPSELL_BENEFITS,
  UPSELL_EVENT_PROPERTY_MAP,
  UPSELL_IMAGES_MAP,
} from 'modules/purchase/pages/constants'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import {
  MAKE_UPSELL,
  createInAppAction,
  setSelectedUpsellProduct,
  setUpsellListInConfig,
} from 'modules/purchase/redux/actions/upsell'
import { selectSelectedUpsellProduct } from 'modules/purchase/redux/selects'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import arrowSrc from 'assets/images/sprite/right-arrow.svg'

import {
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
  UpsellProduct,
} from 'root-constants'

import { StyledUpsellPaywallV3 as S } from './UpsellPaywallV3.styles'

export const UpsellPaywallV3: React.FC<TPageProps> = () => {
  const dispatch: TAppDispatch = useDispatch()

  const upsellPages = useSelector(selectUserUpsellPages)
  const selectedUpsellProducts = useSelector(selectSelectedUpsellProduct)
  const fetchingActionsList = useSelector(selectActionList)
  const subscriptions = useSelector(selectSubscriptionList)
  const selectedProduct = useSelector(selectSelectedUpsellProduct)

  const { currentPageId } = usePageInfo()

  const isUpsellProductSelected = (product: string) =>
    selectedUpsellProducts.includes(product)

  const arePricesReady = (): boolean =>
    !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST)

  const isUpsellInProgress = (): boolean =>
    fetchingActionsList?.includes(MAKE_UPSELL)

  useLayoutEffect(() => {
    const tags = [
      SubscriptionTags.NO_TAX,
      SubscriptionTags.INAPP_CHANGES_DESSERTS,
    ].join(',')

    dispatch(getSubscriptionListAction(SubscriptionListType.ONE_TIME, tags))
  }, [dispatch])

  useEffect(() => {
    eventLogger.logUpsellPurchaseShown(ScreenName.UPSELL_LIST)
  }, [dispatch])

  useEffect(() => {
    const defaultSubscription = subscriptions.find(({ isDefault }) => isDefault)

    if (!defaultSubscription) return

    dispatch(setSelectedUpsellProduct([defaultSubscription.product]))

    // eslint-disable-next-line consistent-return
    return () => {
      dispatch(setSubscriptionListAction([]))
      dispatch(setSelectedUpsellProduct([]))
    }
  }, [dispatch, subscriptions])

  const handleSkipButton = () => {
    eventLogger.logUpsellPurchaseClose(ScreenName.UPSELL_LIST)

    const restUpsellPages = upsellPages.filter((page) => page !== currentPageId)
    dispatch(setUpsellListInConfig(restUpsellPages))
  }

  const handleAddButton = () => {
    dispatch(createInAppAction(ScreenName.UPSELL_LIST, currentPageId))
  }

  const handleChange = (product: string) => {
    const isPriceSelected = selectedProduct.includes(product)
    const selectedSubscription = subscriptions.find(
      (subscription) => subscription.product === product,
    )

    if (!isPriceSelected && selectedSubscription) {
      eventLogger.logUpsellSubscriptionSelected(
        UPSELL_EVENT_PROPERTY_MAP[selectedSubscription.product],
      )
      dispatch(setSelectedSubscriptionAction(selectedSubscription))
      dispatch(
        setSelectedUpsellProduct([...new Set([selectedSubscription.product])]),
      )
    }
  }

  return (
    <>
      <KitPageContainer
        paddingTop={0}
        hasContinueButton
        hasSkipButton
        continueButtonContent={<Trans i18nKey="upsell.addToMyProgram" />}
        skipButtonContent={<Trans i18nKey="upsell.noThanksLink" />}
        onContinueButtonClick={handleAddButton}
        onSkipButtonClick={handleSkipButton}
      >
        <PageTitle marginBottom={16}>
          <Trans i18nKey="upsellV3.title" />
        </PageTitle>
        <S.Subtitle>
          <Trans i18nKey="upsellV3.subtitle" />
        </S.Subtitle>
        <S.UpsellList>
          {subscriptions.map(
            ({ product, currency, trialPrices, mainPrices, id }) => (
              <Option
                type={OptionType.RADIO}
                name="upsell"
                value={product}
                key={id}
                onChange={handleChange}
              >
                <S.UpsellItem isActive={isUpsellProductSelected(product)}>
                  <S.ImageContainer>
                    <img src={UPSELL_IMAGES_MAP[product].img} alt="upsell" />
                  </S.ImageContainer>
                  <S.DataContainer>
                    <S.Info isActive={isUpsellProductSelected(product)}>
                      <S.Title>
                        <Trans i18nKey={UPSELL_IMAGES_MAP[product].title} />
                      </S.Title>
                      <S.PriceContainer>
                        <S.Price>
                          <S.PrevPrice>
                            <Trans
                              i18nKey="commonComponents.priceFormat.price"
                              values={{
                                price: mainPrices.fullPrice.toFixed(2),
                                currency: CURRENCY_SYMBOLS[currency],
                              }}
                              context={currency}
                            />
                          </S.PrevPrice>
                          <SvgImage svg={arrowSrc} height={12} width={12} />
                          <S.CurPrice>
                            <Trans
                              i18nKey="commonComponents.priceFormat.price"
                              values={{
                                price: trialPrices.fullPrice.toFixed(2),
                                currency: CURRENCY_SYMBOLS[currency],
                              }}
                              context={currency}
                            />
                            {product === UpsellProduct.CHANGES_SUGAR && (
                              <>
                                {' '}
                                <Trans i18nKey="upsellV3.subscriptionsBlock.only" />
                              </>
                            )}
                          </S.CurPrice>
                        </S.Price>
                        <S.Discount>
                          <Trans
                            i18nKey="upsellV3.subscriptionsBlock.discountLabel"
                            values={{
                              discount: UPSELL_IMAGES_MAP[product].discount,
                            }}
                          />
                        </S.Discount>
                      </S.PriceContainer>
                    </S.Info>
                    {isUpsellProductSelected(product) &&
                      UPSELL_IMAGES_MAP[product].info && (
                        <S.AdditionalContainer>
                          <S.AdditionalInfo>
                            <Trans i18nKey={UPSELL_IMAGES_MAP[product].info} />
                          </S.AdditionalInfo>
                        </S.AdditionalContainer>
                      )}
                  </S.DataContainer>
                </S.UpsellItem>
              </Option>
            ),
          )}
        </S.UpsellList>
        <ul>
          {UPSELL_BENEFITS.map((item) => (
            <S.BenefitItem key={item}>
              <Trans i18nKey={item} />
            </S.BenefitItem>
          ))}
        </ul>
      </KitPageContainer>
      {(isUpsellInProgress() || !arePricesReady()) && <Spinner />}
    </>
  )
}
