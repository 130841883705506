import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledFastSaleTimer = {
  Wrapper: styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background: linear-gradient(96deg, #ffa81d 0%, #ff7322 100%),
      linear-gradient(91deg, #ff4b4b -8.21%, #cf467b 95.97%);
    padding: 8px 12px;
  `,
  TimerLabel: styled.p`
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.WHITE};
  `,
  TimerValue: styled.p`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.WHITE};
  `,
}
