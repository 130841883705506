import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  selectUserCountryCode,
  selectUserOnboardingEmail,
} from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'

export const useSnapPixelInitialization = (snapPixelId: string): void => {
  const email = useSelector(selectUserOnboardingEmail)
  const countryCode = useSelector(selectUserCountryCode)

  const {
    isEUUser,
    hasAdvertisingAndTargetingCookie,
    isPersonalDataAllowed,
    cookieConsent,
  } = useCookieConsentAnswer()

  const shouldSnapBePaused = useMemo(
    () =>
      (isEUUser && !cookieConsent?.length) ||
      (isEUUser && !hasAdvertisingAndTargetingCookie),
    [isEUUser, hasAdvertisingAndTargetingCookie, cookieConsent?.length],
  )

  useEffect(() => {
    if (!snapPixelId || !window.snaptr || shouldSnapBePaused) return

    if (!window.snapPixelInitialized) {
      window.snaptr('init', snapPixelId, {
        user_email: isPersonalDataAllowed ? email : '',
      })
      window.snapPixelInitialized = true
    }

    window.snaptr('track', 'PAGE_VIEW', {
      user_email: isPersonalDataAllowed ? email : '',
    })
  }, [
    countryCode,
    email,
    isPersonalDataAllowed,
    shouldSnapBePaused,
    snapPixelId,
  ])
}
