import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setUpUUIDAction } from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectGiaApiKeys,
  selectLanguage,
} from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store'

import { getQueryParamsFromSearchString } from 'helpers/getQueryParamsFromSearchString'

import { TOU_AND_PP_LINKS } from 'root-constants'

export const useSetUuid = () => {
  const dispatch: TAppDispatch = useDispatch()
  const cohort = useSelector(selectCurrentVariantCohort)
  const giaApiKeys = useSelector(selectGiaApiKeys)
  const language = useSelector(selectLanguage)

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search)
    const uuidFromUrl = searchParams.get('uuid')
    const utmTags = getQueryParamsFromSearchString(document.location.search)
    const isPPOrToUPage = TOU_AND_PP_LINKS.includes(window.location.pathname)

    if (cohort && giaApiKeys && language && !isPPOrToUPage) {
      dispatch(
        setUpUUIDAction({
          cohort,
          uuid: uuidFromUrl,
          utmTags,
          giaApiKey: giaApiKeys[language],
          language,
        }),
      )
    }
  }, [dispatch, cohort, language, giaApiKeys])
}
