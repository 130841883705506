import { paymentApi } from 'api'

import { startFetching, stopFetching } from 'root-redux/actions/common'
import {
  getUserStatusAction,
  savePlanAdditionsAction,
  sendUserConfigAction,
} from 'root-redux/actions/user'
import { selectUUID, selectUserUpsellPages } from 'root-redux/selects/user'

import { getStripePurchaseFailedEventParams } from 'modules/purchase/helpers/getStripePurchaseFailedEventParams'
import { getStripePurchaseStartedEventParams } from 'modules/purchase/helpers/getStripePurchaseStartedEventParams'
import { getStripePurchaseSuccessEventParams } from 'modules/purchase/helpers/getStripePurchaseSuccessEventParams'
import { logSuccessfulPayment } from 'modules/purchase/helpers/rootHelpers'
import {
  selectCurrency,
  selectInAppId,
  selectPaymentMethod,
  selectProductId,
  selectSubscription,
  selectSubscriptionTrialLookupKey,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/purchase/redux/selects'

import { IAppState, TAppDispatchThunk } from 'models/store.model'

import { Events, eventLogger } from 'services/eventLogger.service'

import { CustomPageId, PageId } from 'page-constants'
import {
  IN_APP_PRODUCT_EVENT_NAMES_MAP,
  ScreenName,
  UpsellProduct,
} from 'root-constants'

const MODULE_NAME = 'UPSELL'

// actions types
export const MAKE_UPSELL = `${MODULE_NAME}/MAKE_UPSELL`
export const SET_UPSELL_SWITCHER_STATE_VALUE = `${MODULE_NAME}/SET_UPSELL_PRICES_VALUE`
export const SET_SELECTED_UPSELL_PRODUCT_VALUE = `${MODULE_NAME}/SET_UPSELL_PRODUCT_VALUE`

export const setSelectedUpsellProduct = (selectedUpsellProducts: string[]) => ({
  type: SET_SELECTED_UPSELL_PRODUCT_VALUE,
  payload: selectedUpsellProducts,
})

export const setUpsellSwitcherState = (switcherValue: boolean) => ({
  type: SET_UPSELL_SWITCHER_STATE_VALUE,
  payload: switcherValue,
})

export const makeUpsellAction =
  (screenName: ScreenName, currentPageId: PageId | CustomPageId): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)
    const productId = selectProductId(state)
    const trialPriceId = selectSubscriptionTrialLookupKey(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const trialCurrentPrice = selectSubscriptionTrialPeriodPrice(state)
    const currency = selectCurrency(state)
    const selectedInAppId = selectInAppId(state)
    const selectedSubscription = selectSubscription(state)
    const paymentMethod = selectPaymentMethod(state)
    const upsellPages = selectUserUpsellPages(state)

    dispatch(startFetching(MAKE_UPSELL))

    const commonPurchaseStartedParams =
      getStripePurchaseStartedEventParams(state)
    const commonPurchaseSuccessParams =
      getStripePurchaseSuccessEventParams(state)
    const commonPurchaseFailedParams = getStripePurchaseFailedEventParams(state)

    eventLogger.logPurchaseStarted({
      ...commonPurchaseStartedParams,
      paymentMethod,
      event: Events.PURCHASE_STARTED,
      screenName,
      priceDetails: { price: trialCurrentPrice, currency },
      productId: trialPriceId,
    })

    const response = await paymentApi.makeUpsell({
      uuid,
      productId: productId || UpsellProduct.BOTH,
      planId: Number(selectedInAppId),
    })

    const restUpsellPages = upsellPages.filter((page) => page !== currentPageId)

    await dispatch(
      sendUserConfigAction({
        upsell_pages: restUpsellPages,
      }),
    )

    if (response.success && response.data && selectedSubscription) {
      logSuccessfulPayment({
        ...commonPurchaseSuccessParams,
        isUpsell: true,
        price: trialCurrentPrice,
        trialPeriodDays,
        subscriptionId: response.data.subscription.subscription_id,
        paymentMethod,
        screenName,
        productId: response.data.subscription.product_id,
      })

      dispatch(savePlanAdditionsAction())
      dispatch(getUserStatusAction(uuid))
      dispatch(stopFetching(MAKE_UPSELL))
      return
    }

    eventLogger.logPurchaseFailed({
      ...commonPurchaseFailedParams,
      paymentMethod,
      error: { type: response.data, declineCode: response.data.decline_code },
      screenName,
      priceDetails: { price: trialCurrentPrice, currency },
      event: Events.PURCHASE_FAILED,
      productId: trialPriceId,
    })

    dispatch(getUserStatusAction(uuid))
    dispatch(stopFetching(MAKE_UPSELL))
  }

export const createInAppAction =
  (screenName: ScreenName, currentPageId: PageId | CustomPageId): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)
    const trialPriceId = selectSubscriptionTrialLookupKey(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const trialCurrentPrice = selectSubscriptionTrialPeriodPrice(state)
    const currency = selectCurrency(state)
    const selectedInAppId = selectInAppId(state)
    const selectedSubscription = selectSubscription(state)
    const paymentMethod = selectPaymentMethod(state)
    const upsellPages = selectUserUpsellPages(state)

    dispatch(startFetching(MAKE_UPSELL))

    const commonPurchaseStartedParams =
      getStripePurchaseStartedEventParams(state)
    const commonPurchaseSuccessParams =
      getStripePurchaseSuccessEventParams(state)
    const commonPurchaseFailedParams = getStripePurchaseFailedEventParams(state)

    eventLogger.logPurchaseStarted({
      ...commonPurchaseStartedParams,
      paymentMethod,
      event: Events.INAPP_PURCHASE_STARTED,
      screenName,
      priceDetails: { price: trialCurrentPrice, currency },
      productId: trialPriceId,
    })

    const response = await paymentApi.createInAppPurchase({
      uuid,
      planId: Number(selectedInAppId),
      productName: selectedSubscription?.product,
    })

    const restUpsellPages = upsellPages.filter((page) => page !== currentPageId)

    await dispatch(
      sendUserConfigAction({
        upsell_pages: restUpsellPages,
      }),
    )

    if (response.success && response.data && selectedSubscription) {
      logSuccessfulPayment({
        ...commonPurchaseSuccessParams,
        isInApp: true,
        eventName: Events.INAPP_PURCHASE_COMPLETED,
        price: trialCurrentPrice,
        trialPeriodDays,
        subscriptionId: response.data.inapp.payment_id,
        paymentMethod,
        screenName,
        productId: IN_APP_PRODUCT_EVENT_NAMES_MAP[selectedSubscription.product],
      })

      dispatch(savePlanAdditionsAction())
      dispatch(getUserStatusAction(uuid))
      dispatch(stopFetching(MAKE_UPSELL))
      return
    }

    eventLogger.logPurchaseFailed({
      ...commonPurchaseFailedParams,
      paymentMethod,
      error: { type: response.data, declineCode: response.data.decline_code },
      screenName,
      priceDetails: { price: trialCurrentPrice, currency },
      event: Events.INAPP_PURCHASE_FAILED,
      productId: trialPriceId,
    })

    dispatch(getUserStatusAction(uuid))
    dispatch(stopFetching(MAKE_UPSELL))
  }

export const setUpsellListInConfig =
  (upsellList: string[]): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)

    await dispatch(
      sendUserConfigAction({
        upsell_pages: upsellList,
      }),
    )

    dispatch(getUserStatusAction(uuid))
  }
