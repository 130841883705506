import React from 'react'
import { Trans } from 'react-i18next'

import likeIcon from 'assets/images/like-icon.svg'
import thumbsupIcon from 'assets/images/thumbsup-icon.svg'

import { StyledCustomerReviewsV4 as S } from './CustomerReviewsV4.styles'
import { TEXT_REVIEWS, T_PREFIX } from './constant'

export const CustomerReviewsV4 = () => {
  return (
    <S.Section>
      <S.Header>
        <Trans i18nKey={`${T_PREFIX}.titleV1`} />
      </S.Header>
      <S.Container>
        {TEXT_REVIEWS.map(
          ({ text, avatar, name, img, date, commentsCount }) => (
            <S.ReviewWrapper key={name}>
              <S.Head>
                <S.ProfileImage src={avatar} alt="avatar" />
                <S.Info>
                  <S.InfoName>
                    <Trans i18nKey={name} />
                  </S.InfoName>
                  <S.InfoDate>
                    <Trans i18nKey={date} />
                  </S.InfoDate>
                </S.Info>
              </S.Head>
              <S.ReviewText>
                <Trans i18nKey={text} />
              </S.ReviewText>
              <S.ImageContainer>
                <img src={img} alt={name} />
              </S.ImageContainer>
              <S.InteractionSection>
                <S.Like>
                  <img src={likeIcon} alt="like-icon" />
                  <img src={thumbsupIcon} alt="thumbsup-icon" />
                  <S.Comment>148</S.Comment>
                </S.Like>
                <S.Comment>
                  <Trans
                    i18nKey={`${T_PREFIX}.comments`}
                    values={{ count: commentsCount }}
                  />
                </S.Comment>
              </S.InteractionSection>
            </S.ReviewWrapper>
          ),
        )}
      </S.Container>
      <S.Disclaimer>
        <Trans i18nKey={`${T_PREFIX}.disclaimer`} />
      </S.Disclaimer>
    </S.Section>
  )
}
