import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Button, buttonTheme } from 'storybook-ui'

import { Modal } from 'components/Modal'
import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { resetErrorAction } from 'root-redux/actions/common'
import { sendUserEmailAction } from 'root-redux/actions/user'
import { selectError } from 'root-redux/selects/common'
import { selectUUID, selectUserStatus } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useEmailInputField } from 'hooks/useEmailInputField'
import { useUserStatus } from 'hooks/useUserStatus'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import lockIcon from 'assets/images/kit-lock-icon.png'

import { goTo } from 'browser-history'
import { OutbrainEventName, TaboolaEventName } from 'root-constants'

import { StyledEmailV3 as S } from './EmailV3.styles'

export const EmailV3: FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()

  const userStatus = useSelector(selectUserStatus)
  const uuid = useSelector(selectUUID)
  const error = useSelector(selectError)

  const [isErrorModalShown, setIsErrorModalShown] = useState(false)
  const [email, setEmail] = useEmailInputField()

  const { title } = useDynamicOBConfig()
  const availableRoute = useUserStatus()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const isEmailValid = useMemo(() => email.isValid, [email.isValid])

  const errorText = useMemo(
    () => (!email.isValid ? email.validationText : ''),
    [email.isValid, email.validationText],
  )

  useEffect(() => {
    eventLogger.logEmailPageShown()
  }, [])

  useLayoutEffect(() => {
    switch (true) {
      case userStatus?.hasSubscription:
        goTo({ pathname: availableRoute, search })
        break
      case userStatus?.email.hasEmail:
        goTo(nextPagePath)
        break
    }
  }, [search, userStatus, availableRoute, nextPagePath])

  const handleErrorModal = useCallback(() => setIsErrorModalShown(true), [])

  const handleSubmit = useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (!email.isValid) return

      goTo(nextPagePath)
      dispatch(
        sendUserEmailAction({
          email: email.value,
          unsuccessCallback: handleErrorModal,
        }),
      )

      googleAnalyticsLogger.logEnterEmailPageCompleted()

      eventLogger.logEmailPageCompleted({
        email: email.value,
      })
      window.fbq('track', 'Lead', {}, { eventID: uuid })
      window.ttq.identify({ email: isPersonalDataAllowed ? email.value : '' })
      window.ttq.track('CompleteRegistration')
      window.snaptr('track', 'SIGN_UP', {
        user_email: isPersonalDataAllowed ? email.value : '',
      })
      window.obApi &&
        window.obApi('track', OutbrainEventName.COMPLETE_REGISTRATION)
      window._tfa &&
        window._tfa.push({
          notify: 'event',
          name: TaboolaEventName.COMPLETE_REGISTRATION,
        })
    },
    [
      dispatch,
      email.isValid,
      email.value,
      handleErrorModal,
      nextPagePath,
      uuid,
    ],
  )

  const handleChange = useCallback(
    (event: SyntheticEvent<HTMLInputElement>) => {
      const { value } = event.target as HTMLInputElement
      const emailValue = value.toLowerCase().trim()

      setEmail((prevState) => ({
        ...prevState,
        value: emailValue,
      }))
    },
    [setEmail],
  )

  const handleFocus = useCallback(() => {
    setEmail((prevState) => ({
      ...prevState,
      isFocused: true,
    }))
  }, [setEmail])

  const handleBlur = useCallback(() => {
    setEmail((prevState) => ({
      ...prevState,
      isFocused: false,
    }))
  }, [setEmail])

  return (
    <>
      <KitPageContainer bottomContent={<S.Footer />}>
        <PageTitle textAlign="left" marginBottom={24}>
          {title || <Trans i18nKey="onboarding.emailV3.title" />}
        </PageTitle>

        <S.Form onSubmit={handleSubmit}>
          <S.Input
            value={email.value}
            maxLength={50}
            isValid={isEmailValid}
            label={t('onboarding.emailV3.placeholder')}
            shortLabel={t('onboarding.emailV3.shortPlaceholder')}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          >
            {errorText && <S.ErrorMessage>{errorText}</S.ErrorMessage>}
          </S.Input>
          <S.Disclaimer>
            <S.LockIcon>
              <img src={lockIcon} alt="lock-icon" />
            </S.LockIcon>
            {t('onboarding.emailV3.disclaimer')}
          </S.Disclaimer>
          <Button
            type="submit"
            width="100%"
            margin="0 auto"
            theme={buttonTheme.NUTRIMATE_PRIMARY}
            disabled={!email.value || !isEmailValid}
          >
            {t('actions.continue')}
          </Button>
        </S.Form>
      </KitPageContainer>
      <Modal
        onClose={() => {
          dispatch(resetErrorAction())
          setIsErrorModalShown(false)
        }}
        isShown={isErrorModalShown}
        error={error}
      />
    </>
  )
}
