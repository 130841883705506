import styled from 'styled-components'

import { Footer } from 'components/Footer'
import { PageTitle } from 'components/PageTitle'

import { Color } from 'root-constants'

export const StyledInfluencerProfile = {
  Footer: styled(Footer)`
    font-size: 11px;
    line-height: 14px;

    a {
      text-decoration: underline;
      text-decoration-color: ${Color.GREY_102};
    }
  `,
  Title: styled(PageTitle)`
    strong {
      color: ${Color.GREEN_300};
    }
  `,
  Subtitle: styled.h3`
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    margin: 0 0 24px 0;
    color: ${Color.GREY_101};
  `,
  InfluencerBlock: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 12px;
    width: 100%;
    margin: 0 0 32px 0;
  `,
  ImageContainer: styled.div`
    height: 152px;
    aspect-ratio: 152/152;
  `,
  InfoContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  InfoTitle: styled.h3`
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.GREY_100};
    margin: 0 0 8px 0;
  `,
  InfoText: styled.p`
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.GREY_101};
  `,
  SloganText: styled.p`
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    margin: 0 0 32px 0;
  `,
}
