import styled from 'styled-components'

import {
  Color,
  Gradient,
  KIT_MAX_COLUMN_WIDTH,
  MediaBreakpoint,
} from 'root-constants'

export const StyledPositionedTimerV1 = {
  Container: styled.div`
    position: fixed;
    top: 0;
    left: 50%;
    z-index: 100;
    transform: translateX(-50%);

    width: 100%;
    padding: 12px;

    background: radial-gradient(${Gradient.RADIAL_RED_YELLOW});

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: ${KIT_MAX_COLUMN_WIDTH}px;
    }
  `,
  TimerContent: styled.p`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: ${Color.WHITE};
  `,
}
