import React, { FC, ReactNode } from 'react'

import { Color } from 'root-constants'

import { StyledFlagContainer as S } from './FlagContainer.styles'

type TProps = {
  className?: string
  clipPath?: string
  children: ReactNode
  backgroundColor?: string
  // stdDeviation - border-radius radial value
  stdDeviation?: number
}

export const FlagContainer: FC<TProps> = ({
  className,
  children,
  backgroundColor = Color.PRIMARY,
  stdDeviation = 3,
  clipPath = 'polygon(15% 0%, 100% 0%, 100% 100%, 15% 100%, 0% 50%)',
}) => (
  <>
    <S.Container
      className={className}
      backgroundColor={backgroundColor}
      clipPath={clipPath}
    >
      {children}
    </S.Container>
    <S.SvgFilter xmlns="http://www.w3.org/2000/svg" version="1.1">
      <defs>
        <filter id="round">
          <feGaussianBlur
            in="SourceGraphic"
            result="blur"
            stdDeviation={stdDeviation}
          />
          <feColorMatrix
            in="blur"
            mode="matrix"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
            result="goo"
          />
          <feComposite in="SourceGraphic" in2="goo" operator="atop" />
        </filter>
      </defs>
    </S.SvgFilter>
  </>
)
