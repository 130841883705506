import styled from 'styled-components'

import earth from 'assets/images/earth.svg'
import star from 'assets/images/review-red-star.svg'

import { Color } from 'root-constants'

export const StyledCustomerReviewsV3 = {
  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: ${Color.GREY_100};
    margin: 0 0 24px 0;
  `,
  CardTitle: styled.div`
    padding: 12px 16px;
    border-radius: 16px 16px 0 0;
    background-color: ${Color.GREY_WHITE};
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  `,
  CardContent: styled.div`
    padding: 12px 16px 16px 16px;
  `,
  ReviewCard: styled.div`
    background-color: #f5f5f5;
    border-radius: 16px;
    border: 1px solid ${Color.GREY_WHITE};

    &:not(:last-of-type) {
      margin: 0 0 16px 0;
    }
  `,
  Author: styled.div`
    display: flex;
    margin: 0 0 8px 0;

    > :not(:last-child) {
      margin: 0 12px 0 0;
    }
  `,
  AuthorName: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Name: styled.h4`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${Color.GREY_100};
    position: relative;

    &::after {
      position: absolute;
      content: url(${star});
      top: 50%;
      transform: translateY(-50%);
      width: 19px;
      height: 17px;
      margin: 0 0 0 6px;
    }
  `,
  Recommendation: styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.GREY_101};
    position: relative;
    padding: 0 0 0 14px;

    &::before {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      content: url(${earth});
      width: 10px;
      height: inherit;
    }

    strong {
      font-weight: 700;
    }
  `,
  AuthorImg: styled.div`
    height: 40px;
    aspect-ratio: 40/40;
  `,
  Text: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${Color.GREY_100};
  `,
}
