import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, buttonTheme } from 'storybook-ui'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserCurrentWeight } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'
import { useWeightMeasurementSystem } from 'hooks/useWeightMeasurementSystem'

import { IInputState, TPageProps } from 'models/common.model'

import infoIcon from 'assets/images/information-circle.png'

import { CustomPageId } from 'page-constants'
import { FloatNumberRegex, INITIAL_INPUT_VALUE } from 'root-constants'

import { StyledGoalWeightV4 as S } from './GoalWeightV4.styles'
import {
  LOSE_WEIGHT_INFO_MAP,
  LoseWeightPercentageValue,
  MIN_KG_DIFF,
  MIN_LB_DIFF,
  MIN_WEIGHT_KG,
  MIN_WEIGHT_LB,
  QUESTION,
} from './constants'

export const GoalWeightV4: React.FC<TPageProps> = ({ nextPagePath }) => {
  const [weight, setWeight] = useState<IInputState>({
    ...INITIAL_INPUT_VALUE,
  })
  const [isFocused, setIsFocused] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()
  const currentWeightValue = +useSelector(selectCurrentUserCurrentWeight)
  const { measuringSystemLabel, isMetricSelected, measurementSystem } =
    useWeightMeasurementSystem()

  const { title, text } = useMemo(() => {
    const goalWeight = (weight.isValid && +weight.value) || 60
    const loseWeightPercentage = +(
      100 -
      (goalWeight * 100) / currentWeightValue
    ).toFixed()

    let loseWeightPercentageValue = LoseWeightPercentageValue.LESS_THAN_10

    if (loseWeightPercentage <= 10) {
      loseWeightPercentageValue = LoseWeightPercentageValue.LESS_THAN_10
    }

    if (loseWeightPercentage > 10 && loseWeightPercentage <= 20) {
      loseWeightPercentageValue = LoseWeightPercentageValue.FROM_10_TO_20
    }

    if (loseWeightPercentage > 20) {
      loseWeightPercentageValue = LoseWeightPercentageValue.MORE_THEN_20
    }

    const loseWeightInfoMap = LOSE_WEIGHT_INFO_MAP[loseWeightPercentageValue]

    return {
      title: t(loseWeightInfoMap.title, {
        loseWeightPercentage,
      }),
      text: t(loseWeightInfoMap.text, {
        loseWeight: t(
          `onboarding.goalWeight.info.${
            isMetricSelected ? 'kgValue' : 'lbsValue'
          }`,
        ),
      }),
    }
  }, [weight, currentWeightValue, isMetricSelected, t])

  const handleContinue = useNextStep({
    pageId: CustomPageId.GOAL_WEIGHT,
    question: QUESTION,
    nextPagePath,
  })

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleContinue(+weight.value, measurementSystem)
  }

  const handleFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleBlur = useCallback(() => {
    setIsFocused(false)
  }, [])

  const handleChange = useCallback(({ target: { value, validity } }) => {
    if (!value || FloatNumberRegex.test(value)) {
      setWeight((prevState) => ({
        ...prevState,
        value,
        isValid: validity.valid,
      }))
    }
  }, [])

  useEffect(() => {
    if (weight.isValid) inputRef?.current?.blur()
  }, [weight.isValid])

  return (
    <form onSubmit={handleSubmit}>
      <KitPageContainer>
        <PageTitle marginBottom={24} textAlign="left">
          {t('onboarding.goalWeight.title')}
        </PageTitle>
        <S.WeightLabelWrapper>
          <S.WeightLabel>{measuringSystemLabel}</S.WeightLabel>
        </S.WeightLabelWrapper>
        <S.Actions>
          <S.InputWrapper>
            <S.WeightInput
              inputRef={inputRef}
              type="number"
              inputMode="numeric"
              value={weight.value as string}
              isContentCentered
              isValid={weight.isValid}
              min={isMetricSelected ? MIN_WEIGHT_KG : MIN_WEIGHT_LB}
              max={
                currentWeightValue -
                (isMetricSelected ? MIN_KG_DIFF : MIN_LB_DIFF)
              }
              lang="en"
              step="1"
              allowFloatNumbers={false}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            {!isFocused && !weight.value && <S.Placeholder />}
            <S.Suffix>{measuringSystemLabel}</S.Suffix>
          </S.InputWrapper>
          {weight.value && !weight.isValid && (
            <S.ErrorMessage>
              {t('onboarding.goalWeight.errorMessage')}
            </S.ErrorMessage>
          )}
        </S.Actions>

        {weight.isValid && (
          <S.InfoContainer>
            <S.InfoImage>
              <img src={infoIcon} alt="info" />
            </S.InfoImage>
            <S.Info>
              <S.InfoTitle>{t(title)}</S.InfoTitle>
              <S.InfoText>{t(text)}</S.InfoText>
            </S.Info>
          </S.InfoContainer>
        )}

        <Button
          width="100%"
          type="submit"
          margin="0 auto"
          theme={buttonTheme.NUTRIMATE_PRIMARY}
          disabled={!weight.isValid}
        >
          {t('actions.continue')}
        </Button>
      </KitPageContainer>
    </form>
  )
}
