import styled, { css, keyframes } from 'styled-components'
import { DynamicDiscountThemeValue } from 'value-constants'

import { Color, MediaBreakpoint } from 'root-constants'

type TTheme = {
  theme: DynamicDiscountThemeValue
}

const GAP_VALUE = 4

const scroll = keyframes`
    to {
        transform: translateX(calc(-100% - ${GAP_VALUE}px));
    }
`

export const StyledDynamicDiscountBanner = {
  Wrapper: styled.div<TTheme>`
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${GAP_VALUE}px;
    position: relative;
    z-index: 2;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: ${MediaBreakpoint.MAX_PHONE}px;
    }

    ${({ theme }) => {
      switch (theme) {
        case DynamicDiscountThemeValue.RED:
          return css`
            background: linear-gradient(87deg, #c40202 -4.33%, #ff5659 111.17%);
          `
        case DynamicDiscountThemeValue.VIOLET:
          return css`
            background: linear-gradient(87deg, #7b00ff -3.7%, #1be4ff 119.47%);
          `
        case DynamicDiscountThemeValue.ORANGE:
          return css`
            background: linear-gradient(88deg, #ff0031 -0.53%, #ffbe1b 108.08%);
          `
        case DynamicDiscountThemeValue.BLACK:
          return css`
            background: ${Color.BLACK_100};
          `
        default:
          return css`
            background: linear-gradient(88deg, #ff0031 -0.53%, #ffbe1b 108.08%);
          `
      }
    }}
  `,
  Container: styled.ul`
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    gap: ${GAP_VALUE}px;
    list-style: none;
    user-select: none;
    animation: ${scroll} 50s linear infinite;
  `,
  Sale: styled.li`
    display: flex;
    align-items: center;

    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: ${Color.WHITE};
    text-transform: uppercase;
    position: relative;

    & svg {
      width: 24px;
      height: 24px;
      margin: 0 4px 0 0;
    }
  `,
}
