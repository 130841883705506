import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

type TPropsWrapper = {
  isShown: boolean
}

const commonTitleStyles = css`
  font-style: normal;
  font-weight: 700;
  text-align: center;
  color: ${Color.GREY_100};
`

export const StyledPaymentWaitingModal = {
  Wrapper: styled.div<TPropsWrapper>`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ isShown }) => (isShown ? 103 : -1)};
    width: max(100%, var(--min-screen-width));
    height: calc(var(--full-height) - calc(var(--full-height) - 100%));
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.25);
    opacity: ${({ isShown }) => (isShown ? 1 : 0)};
    transition-duration: 100ms;
    transition-property: opacity;
    transition-timing-function: ease-out;
  `,
  Content: styled.div`
    width: calc(100% - 40px);
    max-width: 320px;
    margin: auto;
    padding: 24px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-radius: 20px;
    background-color: ${Color.WHITE};
  `,
  LottieContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    > :not(:last-child) {
      margin-bottom: 24px;
    }
  `,
  Animation: styled.div`
    width: 80px;
    height: 80px;
  `,
  Title: styled.div`
    ${commonTitleStyles};
    color: ${Color.BLACK_100};
    font-size: 24px;
    line-height: 32px;
  `,
  Subtitle: styled.div`
    ${commonTitleStyles};
    color: ${Color.BLACK_300};
    opacity: 0.5;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  `,
}
