import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledHeaderV2 = {
  Wrapper: styled.header`
    align-items: center;
    background-color: ${Color.WHITE};
    box-shadow: 0 -0.5px 0 0 rgba(0, 0, 0, 0.25) inset;
    display: flex;
    height: 44px;
    justify-content: center;
    padding: 0 16px;
    position: relative;
    width: 100%;
  `,
  LogoContainer: styled.div`
    align-items: center;
    column-gap: 12px;
    display: flex;
  `,
  Logo: styled.div`
    aspect-ratio: 1 / 1;
    width: 24px;
  `,
  LogoText: styled.div`
    color: ${Color.BLACK_100};
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
  `,
  BottomBorder: styled.div`
    background: ${Color.LIGHT};
    height: 1px;
    width: 100%;
  `,
  BackButton: styled.button`
    align-items: center;
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    left: 16px;
    margin: 0;
    outline: none;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  `,
  SkipButton: styled.button`
    align-items: center;
    appearance: none;
    background-color: transparent;
    border: none;
    color: ${Color.PRIMARY};
    cursor: pointer;
    display: flex;
    font-size: 14px;
    margin: 0;
    outline: none;
    padding: 0;
    position: absolute;
    right: 16px;
    text-transform: uppercase;
    top: 50%;
    transform: translateY(-50%);
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    img {
      height: 20px;
      width: 20px;
    }
  `,
}
