import styled from 'styled-components'

import { COMPARISON_PROGRAMS_IDS } from 'modules/purchase/components/NutrimateComparison/constants'

import { Color } from 'root-constants'

export const StyledNutrimateComparison = {
  Container: styled.section`
    display: flex;
    flex-direction: column;
    margin: 16px 0 24px 0;
    padding: 16px 12px;
    gap: 16px;
    overflow: hidden;
    border-radius: 24px;
    background: linear-gradient(
      to right,
      ${Color.GREY_130} 50%,
      rgba(169, 205, 80, 0.16) 50%
    );
  `,

  Row: styled.div`
    display: flex;
    justify-content: space-between;

    & > div {
      width: 50%;

      & ~ div {
        padding-left: 11px;
      }

      &:first-child {
        padding-right: 11px;
      }
    }
  `,

  Title: styled.h2`
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
  `,

  ImageWrapper: styled.div`
    display: flex;
    justify-content: center;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  `,

  List: styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  `,

  ListItem: styled.li`
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    color: inherit;
  `,

  IconWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    border-radius: 50%;

    &.${COMPARISON_PROGRAMS_IDS.another} {
      background-color: ${Color.RED_400};
    }

    &.${COMPARISON_PROGRAMS_IDS.nutrimate} {
      background-color: ${Color.GREEN_300};
    }
  `,

  Text: styled.span`
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    color: inherit;
  `,
}
