import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { buttonTheme } from 'storybook-ui'
import { CookieConsentValue } from 'value-constants'

import {
  ALL_COOKIE_CONSENT_TYPES,
  Source,
} from 'components/CookieConsent/constants'
import { Logo } from 'components/Logo'
import { SvgImage } from 'components/SvgImage'
import { Switch } from 'components/Switch'

import {
  getUserStatusAction,
  sendUserConfigAction,
} from 'root-redux/actions/user'
import { selectUUID } from 'root-redux/selects/user'

import { eventLogger } from 'services/eventLogger.service'

import closeIcon from 'assets/images/sprite/close-icon-v3.svg'

import { Color } from 'root-constants'

import { StyledCookieConsent as S } from './CookieConsent.styles'

export const CookieConsent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)

  const [isCookieConsentVisible, setIsCookieConsentVisible] = useState(true)
  const [areSettingsOn, setAreSettingsOn] = useState(false)
  const [cookieConsentTypes, setCookieConsentTypes] = useState<string[]>([])

  useEffect(() => {
    isCookieConsentVisible && eventLogger.logCookiesConsentShown()
  }, [isCookieConsentVisible])

  const handleSubmit = useCallback(
    async (userAnswer: string[]) => {
      setIsCookieConsentVisible(false)
      await dispatch(
        sendUserConfigAction({
          cookie_consent: userAnswer,
        }),
      )
      dispatch(getUserStatusAction(uuid))
    },
    [dispatch, uuid],
  )

  const handleAcceptAllFromBanner = useCallback(() => {
    eventLogger.logCookiesConsentAcceptAllTap(Source.BANNER)
    handleSubmit(ALL_COOKIE_CONSENT_TYPES)
  }, [handleSubmit])

  const handleAcceptAllFromSettings = useCallback(() => {
    eventLogger.logCookiesConsentAcceptAllTap(Source.SETTINGS)
    handleSubmit(ALL_COOKIE_CONSENT_TYPES)
  }, [handleSubmit])

  const handleRejectAll = useCallback(() => {
    eventLogger.logCookiesConsentRejectAllTap(
      areSettingsOn ? Source.SETTINGS : Source.BANNER,
    )
    handleSubmit([])
  }, [areSettingsOn, handleSubmit])

  const handleConfirmChoice = useCallback(() => {
    eventLogger.logCookiesConsentSettingsConfirmChoice(
      `${cookieConsentTypes.toString() || CookieConsentValue.REJECT_ALL}`,
    )
    handleSubmit(cookieConsentTypes)
  }, [cookieConsentTypes, handleSubmit])

  const toggleSettingsVisibility = useCallback(() => {
    areSettingsOn
      ? eventLogger.logCookiesConsentSettingsScreenClose()
      : eventLogger.logCookiesConsentSettingsTap()
    setAreSettingsOn((prev) => !prev)
  }, [areSettingsOn])

  const handleChange = useCallback(
    ({ target: { value } }) => {
      if (cookieConsentTypes.includes(value)) {
        eventLogger.logCookiesConsentToggleDisable(value)
        setCookieConsentTypes((prev) => prev.filter((item) => item !== value))
        return
      }

      eventLogger.logCookiesConsentToggleEnable(value)
      setCookieConsentTypes((prev) => [...prev, value])
    },
    [cookieConsentTypes],
  )

  if (!isCookieConsentVisible) {
    return null
  }

  return (
    <S.Wrapper fullHeight={areSettingsOn}>
      {areSettingsOn && (
        <>
          <S.Header>
            <Logo marginBottom={0} />
            <S.CloseButton onClick={toggleSettingsVisibility}>
              <SvgImage svg={closeIcon} width={24} />
            </S.CloseButton>
          </S.Header>
          <S.Container>
            <S.Title>{t('cookieConsent.privacyCenterTitle')}</S.Title>
            <S.Description>
              {t('cookieConsent.privacyCenterDescription')}
            </S.Description>
            <S.Button
              theme={buttonTheme.NUTRIMATE_PRIMARY}
              margin="0 0 32px 0"
              onClick={handleAcceptAllFromSettings}
            >
              {t('cookieConsent.acceptAll')}
            </S.Button>
            <S.ManageTitle>
              {t('cookieConsent.consentManagement')}
            </S.ManageTitle>
            <S.Settings>
              <S.SettingItem>
                <S.Setting
                  onClick={(event) => {
                    if (event.currentTarget.hasAttribute('open')) return

                    eventLogger.logCookiesConsentExpandOptionTap(
                      CookieConsentValue.ADVERTISING_AND_TARGETING,
                    )
                  }}
                >
                  <S.SettingName>
                    <span>{t('cookieConsent.advertisingCookies')}</span>
                  </S.SettingName>
                  <S.SettingDetails>
                    {t('cookieConsent.advertisingCookiesDetails')}
                  </S.SettingDetails>
                </S.Setting>
                <Switch
                  value={CookieConsentValue.ADVERTISING_AND_TARGETING}
                  onChange={handleChange}
                  isChecked={cookieConsentTypes.includes(
                    CookieConsentValue.ADVERTISING_AND_TARGETING,
                  )}
                />
              </S.SettingItem>
              <S.SettingItem>
                <S.Setting
                  onClick={(event) => {
                    if (event.currentTarget.hasAttribute('open')) return

                    eventLogger.logCookiesConsentExpandOptionTap(
                      CookieConsentValue.FUNCTIONAL_COOKIES,
                    )
                  }}
                >
                  <S.SettingName>
                    <span>{t('cookieConsent.functionalCookies')}</span>
                  </S.SettingName>
                  <S.SettingDetails>
                    {t('cookieConsent.functionalCookiesDetails')}
                  </S.SettingDetails>
                </S.Setting>
                <Switch
                  value={CookieConsentValue.FUNCTIONAL_COOKIES}
                  onChange={handleChange}
                  isChecked={cookieConsentTypes.includes(
                    CookieConsentValue.FUNCTIONAL_COOKIES,
                  )}
                />
              </S.SettingItem>
              <S.SettingItem>
                <S.Setting
                  onClick={(event) => {
                    if (event.currentTarget.hasAttribute('open')) return

                    eventLogger.logCookiesConsentExpandOptionTap(
                      CookieConsentValue.ANALYTICS_AND_PERFORMANCE,
                    )
                  }}
                >
                  <S.SettingName>
                    <span>{t('cookieConsent.analyticalCookies')}</span>
                  </S.SettingName>
                  <S.SettingDetails>
                    {t('cookieConsent.analyticalCookiesDetails')}
                  </S.SettingDetails>
                </S.Setting>
                <Switch
                  value={CookieConsentValue.ANALYTICS_AND_PERFORMANCE}
                  onChange={handleChange}
                  isChecked={cookieConsentTypes.includes(
                    CookieConsentValue.ANALYTICS_AND_PERFORMANCE,
                  )}
                />
              </S.SettingItem>
              <S.SettingItem>
                <S.Setting>
                  <S.SettingName>
                    <span>{t('cookieConsent.necessaryCookies')}</span>
                    <S.ActiveLabel>
                      {t('cookieConsent.activeLabel')}
                    </S.ActiveLabel>
                  </S.SettingName>
                  <S.SettingDetails>
                    {t('cookieConsent.necessaryCookiesDetails')}
                  </S.SettingDetails>
                </S.Setting>
              </S.SettingItem>
            </S.Settings>
          </S.Container>
          <S.Divider />
        </>
      )}
      <S.Container>
        {!areSettingsOn && (
          <>
            <S.Title>{t('cookieConsent.title')}</S.Title>
            <S.Description>
              {t('cookieConsent.description')}{' '}
              <a
                href="/cookie_policy.pdf"
                onClick={() => eventLogger.logCookiePolicyClicked()}
                target="_blank"
              >
                {t('cookieConsent.cookieNotice')}
              </a>
            </S.Description>
            <S.Button
              theme={buttonTheme.NUTRIMATE_PRIMARY}
              margin="0 0 8px 0"
              onClick={handleAcceptAllFromBanner}
            >
              {t('cookieConsent.acceptAll')}
            </S.Button>
            <S.Button
              theme={buttonTheme.NUTRIMATE_PRIMARY}
              backgroundColor={Color.GREY_130}
              color={Color.GREY_100}
              margin="0 0 8px 0"
              onClick={toggleSettingsVisibility}
            >
              {t('cookieConsent.settings')}
            </S.Button>
          </>
        )}
        {areSettingsOn && (
          <>
            <S.Button
              theme={buttonTheme.NUTRIMATE_PRIMARY}
              margin="0 0 8px 0"
              onClick={handleAcceptAllFromSettings}
            >
              {t('cookieConsent.acceptAll')}
            </S.Button>
            <S.Button
              theme={buttonTheme.NUTRIMATE_PRIMARY}
              backgroundColor={Color.GREY_130}
              color={Color.GREY_100}
              margin="0 0 8px 0"
              onClick={handleConfirmChoice}
            >
              {t('cookieConsent.confirmChoice')}
            </S.Button>
          </>
        )}
        <S.Button
          theme={buttonTheme.NUTRIMATE_PRIMARY}
          backgroundColor={Color.GREY_130}
          color={Color.GREY_100}
          onClick={handleRejectAll}
        >
          {t('cookieConsent.rejectAll')}
        </S.Button>
      </S.Container>
    </S.Wrapper>
  )
}
