import styled from 'styled-components'

import { FeaturedBlock } from 'modules/purchase/components'

import { Color, PAGE_HORIZONTAL_PADDING } from 'root-constants'

export const StyledCancelOffer = {
  DiscountBanner: styled.div<{ imgSrc: string }>`
    align-items: center;
    aspect-ratio: 25 / 12;
    background-image: url(${({ imgSrc }) => imgSrc});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    margin: 0 0 24px 50%;
    padding: 16px;
    transform: translateX(-50%);
    width: calc(100% + ${PAGE_HORIZONTAL_PADDING * 2}px);
  `,
  DiscountContent: styled.div`
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    row-gap: 12px;
  `,
  DiscountTitle: styled.h1`
    color: ${Color.WHITE};
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  `,
  DiscountValue: styled.h2`
    background-color: ${Color.WHITE};
    border-radius: 24px;
    color: ${Color.GREEN_800};
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    padding: 8px 12px;
    text-transform: uppercase;
  `,
  PlanDescription: styled.h3`
    color: ${Color.GREY_101};
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;
    text-align: center;

    strong {
      color: ${Color.GREY_100};
      font-weight: 700;
    }
  `,
  SubscriptionsContainer: styled.div`
    background-color: ${Color.GREEN_700};
    margin: 0 0 24px 50%;
    padding: 24px 16px 32px;
    transform: translateX(-50%);
    width: calc(100% + ${PAGE_HORIZONTAL_PADDING * 2}px);
  `,
  FeaturedBlock: styled(FeaturedBlock)`
    margin-bottom: 40px;
    padding-top: 40px;
  `,
}
