import React, { useCallback, useEffect, useMemo } from 'react'
import { Trans } from 'react-i18next'

import { Button, buttonTheme } from 'storybook-ui'

import { KitPageContainer } from 'components/PageContainer'

import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { INFLUENCER_NAME_SESSION_STORAGE_KEY } from 'root-constants'

import { StyledInfluencerProfile as S } from './InfluencerProfile.styles'

export const InfluencerProfile: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const {
    title = '',
    subtitle,
    imageUrls,
    pageTexts,
    buttonText,
  } = useDynamicOBConfig()

  const handleContinue = useNextStep({
    pageId,
    question: title,
    nextPagePath,
    isFirstPage: true,
  })
  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  const { imageUrl, name, titleValue, infoValue, sloganValue } = useMemo(() => {
    const [influencerImg] = imageUrls || []

    const [influencerName, influencerTitle, infoText, sloganText] =
      pageTexts || []

    return {
      imageUrl: influencerImg,
      name: influencerName,
      titleValue: influencerTitle,
      infoValue: infoText,
      sloganValue: sloganText,
    }
  }, [imageUrls, pageTexts])

  useEffect(() => {
    if (name) {
      window.sessionStorage.setItem(INFLUENCER_NAME_SESSION_STORAGE_KEY, name)
    }
  }, [name])

  return (
    <KitPageContainer paddingTop={20} bottomContent={<S.Footer />}>
      <S.Title marginBottom={16}>
        <Trans>{title}</Trans>
      </S.Title>

      <S.Subtitle>{subtitle}</S.Subtitle>

      <S.InfluencerBlock>
        <S.ImageContainer>
          <img src={imageUrl} alt="influencer" />
        </S.ImageContainer>
        <S.InfoContainer>
          <S.InfoTitle>{titleValue}</S.InfoTitle>
          <S.InfoText>{infoValue}</S.InfoText>
        </S.InfoContainer>
      </S.InfluencerBlock>

      <S.SloganText>{sloganValue}</S.SloganText>

      <Button
        width="100%"
        type="button"
        margin="0 0 26px 0"
        theme={buttonTheme.NUTRIMATE_PRIMARY}
        onClick={handleNextClick}
      >
        {buttonText}
      </Button>
    </KitPageContainer>
  )
}
