import styled from 'styled-components'

import { PaymentRequestButtonV2 } from 'modules/purchase/components/PaymentRequestButton/PaymentRequestButtonV2'

export const StyledOneClickPayment = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,
  PaymentRequestButton: styled(PaymentRequestButtonV2)`
    margin: 0 0 20px 0;
  `,
}
