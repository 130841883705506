import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ACTIVITY_LEVEL_MAP, PRIMARY_GOAL_MAP } from 'map-constants'
import { BmiValue } from 'value-constants'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'
import { KitPersonalSummaryV1 } from 'components/PersonalSummary'

import {
  selectCurrentUserGender,
  selectCurrentUserPrimaryGoal,
  selectLanguage,
  selectWorkout,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'
import { useUsersBmi } from 'hooks/useUsersBmi'

import { convertTextFromSnakeCase } from 'helpers/convertTextFromSnakeCase'

import { TPageProps } from 'models/common.model'

import { Language } from 'root-constants'

import { StyledProfileSummaryV3 as S } from './ProfileSummaryV3.styles'
import { BMI_INFO_TO_MARKUP_MAP, EXERCISE_MAP } from './constants'

export const ProfileSummaryV3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const gender = useSelector(selectCurrentUserGender)
  const workoutValue = useSelector(selectWorkout)
  const primaryGoalValue = useSelector(selectCurrentUserPrimaryGoal)
  const language = useSelector(selectLanguage)

  const { t } = useTranslation()
  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.profileSummary.title', { lng: Language.EN }),
    nextPagePath,
  })
  const { currentBMI } = useUsersBmi()
  const bmiValue = currentBMI.bmiValue.value
  const bmiLabel = BMI_INFO_TO_MARKUP_MAP[bmiValue]
  const bmiDescription = (() => {
    if (bmiValue === BmiValue.NORMAL) {
      return `onboarding.profileSummary.description.normal.${gender}`
    }

    return bmiLabel.description
  })()
  const activityLevelText = convertTextFromSnakeCase(
    t(ACTIVITY_LEVEL_MAP[workoutValue]),
  )
  const exerciseText = t(EXERCISE_MAP[workoutValue])
  const goalText = t(PRIMARY_GOAL_MAP[primaryGoalValue])
  const imgSrc = bmiLabel.imgSrc[gender]

  return (
    <KitPageContainer
      hasContinueButton
      onContinueButtonClick={() => handleContinue('')}
    >
      <PageTitle textAlign="left" marginBottom={24}>
        {t('onboarding.profileSummary.title')}
      </PageTitle>

      <S.UserInfoBlockV3 />
      <KitPersonalSummaryV1 />
      {/* TODO: delete this logic after creating universal design that doesn't depend on locale */}
      {language === Language.EN ? (
        <S.Info>
          <S.InfoItem>
            <S.InfoLabel>{t('onboarding.profileSummary.goal')}</S.InfoLabel>
            <S.InfoValue>{goalText}</S.InfoValue>
          </S.InfoItem>
          <S.InfoItem>
            <S.InfoLabel>{t('onboarding.profileSummary.exercise')}</S.InfoLabel>
            <S.InfoValue>{exerciseText}</S.InfoValue>
          </S.InfoItem>
          <S.InfoItem>
            <S.InfoLabel>{t('onboarding.profileSummary.level')}</S.InfoLabel>
            <S.InfoValue>{activityLevelText}</S.InfoValue>
          </S.InfoItem>
          <S.ImageContainer>
            <img src={imgSrc} alt="person" />
          </S.ImageContainer>
        </S.Info>
      ) : (
        <S.InfoV2>
          <S.InfoList>
            <S.InfoItemV2>
              <S.InfoLabel>{t('onboarding.profileSummary.goal')}</S.InfoLabel>
              <S.InfoValue>{goalText}</S.InfoValue>
            </S.InfoItemV2>
            <S.InfoItemV2>
              <S.InfoLabel>
                {t('onboarding.profileSummary.exercise')}
              </S.InfoLabel>
              <S.InfoValue>{exerciseText}</S.InfoValue>
            </S.InfoItemV2>
            <S.InfoItemV2>
              <S.InfoLabel>{t('onboarding.profileSummary.level')}</S.InfoLabel>
              <S.InfoValue>{activityLevelText}</S.InfoValue>
            </S.InfoItemV2>
          </S.InfoList>
          <S.Banner src={imgSrc} alt="person" />
        </S.InfoV2>
      )}

      <S.BmiDescription
        backgroundColor={bmiLabel.backgroundColor}
        color={bmiLabel.color}
      >
        <S.BmiValueContainer>
          <S.BmiTitle>{t('onboarding.profileSummary.bmi')}</S.BmiTitle>
          <S.BmiValue>{currentBMI.bmiIndex.toFixed(1)}</S.BmiValue>
        </S.BmiValueContainer>
        <S.Description>
          <Trans i18nKey={bmiDescription} />
        </S.Description>
      </S.BmiDescription>
    </KitPageContainer>
  )
}
