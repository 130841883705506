import { useSelector } from 'react-redux'

import { usePageInfo } from 'contexts/PageInfoProvider'

import { OptionType } from 'components/Option'

import { selectAnswers, selectLanguage } from 'root-redux/selects/common'

import { useTitleFromUrl } from 'hooks/useTitleFromUrl'

import { getDynamicPersonalization as originalGetDynamicPersonalization } from 'helpers/getDynamicPersonalization'

export const useDynamicOBConfig = () => {
  const language = useSelector(selectLanguage)
  const userAnswers = useSelector(selectAnswers)
  const { currentPageIndex, steps } = usePageInfo()
  const { title: titleFromUrl, subText: subtitleFromUrl } = useTitleFromUrl()
  const dynamicOBConfig =
    steps[currentPageIndex]?.onboardingDynamicConfig?.[language]
  const personalization = dynamicOBConfig?.personalization
  const getDynamicPersonalization = (value?: string) =>
    originalGetDynamicPersonalization({
      value,
      userAnswers,
      personalization,
    })

  const title =
    titleFromUrl || getDynamicPersonalization(dynamicOBConfig?.title)
  const subtitle =
    subtitleFromUrl || getDynamicPersonalization(dynamicOBConfig?.subtitle)
  const buttonText = getDynamicPersonalization(dynamicOBConfig?.buttonText)
  const imageUrls = dynamicOBConfig?.imageUrls
  const pageTexts = dynamicOBConfig?.pageTexts?.map(getDynamicPersonalization)
  const optionType = dynamicOBConfig?.optionType || OptionType.RADIO
  const optionData = dynamicOBConfig?.optionData?.map(
    ({ additionalText, text, ...rest }) => {
      return {
        additionalText: getDynamicPersonalization(additionalText),
        text: getDynamicPersonalization(text),
        ...rest,
      }
    },
  )
  const customPageId = dynamicOBConfig?.customPageId

  return {
    title,
    subtitle,
    buttonText,
    imageUrls,
    pageTexts,
    optionType,
    optionData,
    customPageId,
    personalization,
  }
}
