import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useVatInfo } from 'hooks/useHasVatInfo'
import { usePaymentStore } from 'hooks/usePaymentStore'

import { StyledCheckoutSummaryV2 as S } from 'modules/purchase/components/CheckoutSummaryV2/CheckoutSummaryV2.styles'
import {
  CURRENCY_SYMBOLS,
  NO_TRIAL_PLAN_NAME_MAP,
} from 'modules/purchase/constants'
import { isComplexCurrencySymbol } from 'modules/purchase/helpers/currency'

type TProps = {
  className?: string
}
export const CheckoutSummaryV2: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  const { currency, fullPrice, periodQuantity, periodName, product } =
    usePaymentStore()

  const hasVatInfo = useVatInfo()

  const periodNameTrans = useMemo(
    () =>
      t(`commonComponents.timeInterval.${periodName}`, {
        count: periodQuantity,
      }),
    [periodName, periodQuantity, t],
  )

  return (
    <div className={className}>
      <S.DescriptionContainer>
        <S.PlanLabel>{t(NO_TRIAL_PLAN_NAME_MAP[product])}</S.PlanLabel>
        <S.PlanPrice>
          {t('checkout.checkoutSummaryV2.fullPrice', {
            currency: CURRENCY_SYMBOLS[currency],
            price: fullPrice.toFixed(2),
          })}
        </S.PlanPrice>
      </S.DescriptionContainer>
      <S.TotalContainer isComplex={isComplexCurrencySymbol(currency)}>
        <S.TotalLabel>
          {t('checkout.checkoutSummaryV2.totalLabel', {
            includingVat: hasVatInfo ? t('purchase.includingVat') : '',
          })}
        </S.TotalLabel>
        <S.TotalPrice>
          <Trans
            i18nKey="checkout.checkoutSummaryV2.noTrialTotalPrice"
            values={{
              currency: CURRENCY_SYMBOLS[currency],
              price: fullPrice.toFixed(2),
              periodQuantity,
              periodName: periodNameTrans,
            }}
            components={{ span: <span /> }}
          />
        </S.TotalPrice>
      </S.TotalContainer>
    </div>
  )
}
