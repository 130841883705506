import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SvgImage } from 'components/SvgImage'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { StyledNutrimateComparison as S } from './NutrimateComparison.styles'
import { COMPARISON_PROGRAMS } from './constants'

export const NutrimateComparison = () => {
  const userGender = useSelector(selectCurrentUserGender)
  const { ids, titles, images, icons, options } = COMPARISON_PROGRAMS

  return (
    <S.Container>
      <S.Row>
        {titles.map((title) => (
          <div>
            <S.Title>
              <Trans i18nKey={title} />
            </S.Title>
          </div>
        ))}
      </S.Row>
      <S.Row>
        {images.map((img) => (
          <S.ImageWrapper>
            <img src={img[userGender]} alt="person" />
          </S.ImageWrapper>
        ))}
      </S.Row>
      <S.Row>
        {options.map((list, idx) => (
          <div>
            <S.List key={list[idx]}>
              {list.map((item) => (
                <S.ListItem>
                  <S.IconWrapper className={ids[idx]}>
                    <SvgImage svg={icons[idx]} width={10} />
                  </S.IconWrapper>
                  <S.Text>
                    <Trans i18nKey={item} />
                  </S.Text>
                </S.ListItem>
              ))}
            </S.List>
          </div>
        ))}
      </S.Row>
    </S.Container>
  )
}
