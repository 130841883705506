import { PaymentMethod } from 'modules/purchase/constants'
import { TPaymentMethodOption } from 'modules/purchase/types'

import maestro from 'assets/images/maestro-icon.png'
import mastercard from 'assets/images/mastercard-icon.png'
import visa from 'assets/images/visa-icon.png'

import { CreditCardPayment } from '../CreditCardPayment'

export const PAYMENT_METHODS_INITIAL: TPaymentMethodOption = {
  value: PaymentMethod.CREDIT_CARD,
  title: 'kitCheckoutV1.creditCard',
  images: [visa, mastercard, maestro],
  form: CreditCardPayment,
}
