import React from 'react'
import { useDispatch } from 'react-redux'

import { setLanguageAction } from 'root-redux/actions/common'

import { Color, LANG_QUERY_PARAM, SUPPORTED_LOCALES } from 'root-constants'

import { StyledLanguageSelector as S } from './LanguageSelector.styles'

const options = SUPPORTED_LOCALES.map((lang) => ({
  value: lang,
  label: lang.toUpperCase(),
}))

export const LanguageSelector = ({
  defaultValue,
}: {
  defaultValue: string
}) => {
  const dispatch = useDispatch()
  const initValue = defaultValue
    ? options.find((option) => option.value === defaultValue)
    : options[0]

  const onChange = (option) => {
    if (!option) return
    const url = new URL(window.location.href)
    url.searchParams.set(LANG_QUERY_PARAM, option.value)
    window.history.pushState({}, '', url)
    dispatch(setLanguageAction(option.value))
  }

  return (
    <S.Wrapper>
      <S.Select
        defaultValue={initValue}
        name="language"
        options={options}
        onChange={onChange}
        classNamePrefix="language-select"
        isSearchable={false}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: Color.GREEN_400,
            primary25: Color.GREEN_600,
          },
        })}
      />
    </S.Wrapper>
  )
}
