import { TOldPrices, TRawPricesAmount } from 'models/subscriptions.model'

import { CENTS_IN_DOLLAR, INITIAL_PRICE } from 'root-constants'

export const getFormattedPrice = (price: number | undefined): number =>
  price ? price / CENTS_IN_DOLLAR : INITIAL_PRICE

export const getOldPricesList = (oldPrice: TRawPricesAmount): TOldPrices => ({
  fullPrice: getFormattedPrice(oldPrice?.amount),
  amountOfDiscount: getFormattedPrice(oldPrice?.amount_of_discount),
  weekly: getFormattedPrice(oldPrice?.in_the_box.weekly),
  daily: getFormattedPrice(oldPrice?.in_the_box.daily),
  monthly: getFormattedPrice(oldPrice?.in_the_box.monthly),
  yearly: getFormattedPrice(oldPrice?.in_the_box.yearly),
  percentOfDiscount: oldPrice?.percent_of_discount,
})
