import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { GET_SUBSCRIPTION_LIST, startFetching } from 'root-redux/actions/common'

import { useCommonStore } from 'hooks/useCommonStore'

import { OnboardingButtonText } from 'modules/purchase/pages/constants'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { PagePath } from 'root-constants'

export const usePayment = () => {
  const dispatch = useDispatch()

  const { cohort, screenName, fetchingActionsList, subscriptionsList } =
    useCommonStore()

  const hasPrices = useMemo(
    () =>
      !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST) &&
      !!subscriptionsList.length,
    [fetchingActionsList, subscriptionsList],
  )

  const handleAmplitudePaymentEvents = useCallback(
    (target: HTMLButtonElement) =>
      eventLogger.logPlansPageButtonTapped({
        screenName,
        buttonNumber: +(target.getAttribute('data-button-number') || 1),
        buttonText:
          (target.getAttribute('data-button-text') as OnboardingButtonText) ||
          OnboardingButtonText.GET_PLAN_UPPER,
      }),
    [screenName],
  )

  const handleShowPayment = useCallback(() => {
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))

    googleAnalyticsLogger.logPageView(`${PagePath.CHECKOUT}/${cohort}`)
  }, [dispatch, cohort])

  return {
    hasPrices,
    handleShowPayment,
    handleAmplitudePaymentEvents,
  }
}
