import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

const linkProps = css`
  text-decoration-line: underline;
  text-decoration-thickness: 0.5px;
  text-underline-offset: 1px;
`
export const StyledBoostResults = {
  Wrapper: styled.div`
    display: flex;
    padding: 32px 16px;
    margin: 24px -16px;
    background: #ffe4b64c;
    flex-direction: column;
    align-items: center;
  `,
  Banner: styled.h4`
    border-radius: 16px;
    background: linear-gradient(96deg, #ff7322 0%, #ffc106 100%),
      linear-gradient(273deg, #d190ff 33.19%, #9a64ff 94.54%), #fff;
    padding: 6px 16px;
    margin-bottom: 16px;
    text-align: center;
    color: ${Color.WHITE};
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
  `,
  Title: styled.h2`
    margin-bottom: 8px;
    color: ${Color.BLACK_200};
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  `,
  Subtitle: styled.h5`
    margin: 0 0 24px 0;
    color: ${Color.GREY_101};
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;

    strong {
      color: ${Color.GREY_100};
    }
  `,
  Steps: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
  Item: styled.div`
    display: flex;
    padding: 16px;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    border-radius: 16px;
    background: ${Color.WHITE};
    box-shadow: 0 4px 14px 0 rgba(8, 4, 23, 0.03);

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      flex: 1 0 0;

      h4 {
        color: ${Color.BLACK_200};
        font-size: 17px;
        font-weight: 600;
        line-height: 24px;
      }

      a {
        color: ${Color.GREEN_300};
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        ${linkProps}
      }

      p {
        color: ${Color.GREY_101};
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
      }
    }
  `,
  MoreInfo: styled.div`
    margin: 24px 0;
    color: ${Color.GREY_101};
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.09px;

    a {
      color: inherit;
      font-size: 13px;
      font-weight: inherit;
      line-height: inherit;
      ${linkProps}
    }
  `,
}
