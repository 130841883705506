import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { buttonTheme } from 'storybook-ui'

import { DynamicDiscountBanner } from 'components/DynamicDiscountBanner'
import { Spinner } from 'components/Spinner'

import {
  getSubscriptionListAction,
  setScreenNameAction,
} from 'root-redux/actions/common'
import {
  selectCurrentVariantCohortToUse,
  selectSubscriptionList,
} from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store/store'

import { useVatInfo } from 'hooks/useHasVatInfo'
import { usePayment } from 'hooks/usePayment'
import { useTimer } from 'hooks/useTimer'
import {
  ViewportScrollPercentage,
  useViewportValue,
} from 'hooks/useViewportValue'

import { createProductId } from 'helpers/createProductId'

import { SecurityInfoV1 } from 'modules/purchase/components/SecurityInfo'
import { TEN_MINUTES_IN_SECONDS } from 'modules/purchase/constants'
import { useDynamicPaywallConfig } from 'modules/purchase/hooks/useDynamicPaywallConfig'
import { TEXT_REVIEWS } from 'modules/purchase/pages/KitPurchaseVariant1/constants'
import { OnboardingButtonText } from 'modules/purchase/pages/constants'
import { setSubscriptionTimerValueAction } from 'modules/purchase/redux/actions/common'
import { selectSubscriptionTimerValue } from 'modules/purchase/redux/selects'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import {
  Cohort,
  INFLUENCER_NAME_SESSION_STORAGE_KEY,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { StyledKitPurchaseVariant1 as S } from './KitPurchaseVariant1.styles'

export const KitPurchaseVariant1: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()

  const hasVatInfo = useVatInfo()
  const timerValueFromStore = useSelector(selectSubscriptionTimerValue)
  const subscriptions = useSelector(selectSubscriptionList)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const { buttonText } = useDynamicPaywallConfig()
  const { hasPrices, handleShowPayment, handleAmplitudePaymentEvents } =
    usePayment()

  const isAlternativeDiscountVariant = useFeatureIsOn(
    'fas_801_dynamic_discount',
  )

  const viewportValue = useViewportValue()
  const firstSubscriptionsRef = useRef<HTMLDivElement>(null)
  const headerTimerRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.ONBOARDING))

    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        hasVatInfo ? SubscriptionTags.TAX : SubscriptionTags.NO_TAX,
      ),
    )
  }, [dispatch, hasVatInfo])

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(({ mainPrices }) =>
        createProductId({
          periodName: mainPrices.periodName,
          periodQuantity: mainPrices.periodQuantity,
          price: mainPrices.fullPrice,
        }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  useEffect(() => {
    if (viewportValue > ViewportScrollPercentage.V_0) {
      eventLogger.logScrollToBottom(viewportValue, ScreenName.ONBOARDING)
    }
  }, [viewportValue])

  useEffect(() => {
    if (timerValueFromStore === null) {
      dispatch(setSubscriptionTimerValueAction(TEN_MINUTES_IN_SECONDS))
    }
  }, [dispatch, timerValueFromStore])

  const hasDynamicDiscountBanner = useMemo(
    () => cohortToUse === Cohort.MF_INTRO_SALE,
    [cohortToUse],
  )

  const handleScrollOnSubscriptionBlock = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>) => {
      const top = firstSubscriptionsRef.current?.offsetTop
      const headerTimerHeight = headerTimerRef.current?.offsetHeight

      if (top && headerTimerHeight) {
        document.body.scrollTo(0, top - headerTimerHeight)
      }

      handleAmplitudePaymentEvents(event.target as HTMLButtonElement)
    },
    [handleAmplitudePaymentEvents],
  )

  const handleSetTimerValue = useCallback(
    (timerValue: number) =>
      dispatch(setSubscriptionTimerValueAction(timerValue)),
    [dispatch],
  )

  useTimer(timerValueFromStore, handleSetTimerValue)

  const handleGetPlan = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>) => {
      handleShowPayment()
      handleAmplitudePaymentEvents(event.target as HTMLButtonElement)

      goTo(nextPagePath)
    },
    [handleShowPayment, handleAmplitudePaymentEvents, nextPagePath],
  )

  const discountBlock = useMemo(() => {
    if (hasDynamicDiscountBanner) {
      return isAlternativeDiscountVariant ? (
        <S.DiscountBlockV1 />
      ) : (
        <S.DiscountBlockV2 />
      )
    }

    if (
      cohortToUse === Cohort.MF_INFLUENCER &&
      window.sessionStorage.getItem(INFLUENCER_NAME_SESSION_STORAGE_KEY)
    ) {
      return <S.DiscountBlockV3 />
    }

    return <S.DiscountBlock />
  }, [cohortToUse, hasDynamicDiscountBanner, isAlternativeDiscountVariant])

  return !hasPrices ? (
    <Spinner />
  ) : (
    <>
      {hasDynamicDiscountBanner && <DynamicDiscountBanner />}

      <div>
        <S.DiscountTimer
          elemRef={headerTimerRef}
          onButtonClick={handleScrollOnSubscriptionBlock}
        />
        <S.Wrapper>
          <S.IntroMedia />
          <S.PersonalizedPlanV1 />
          <S.DiscountTitle>{t('purchase.discountTitle')}</S.DiscountTitle>
          {discountBlock}
          <S.SubscriptionsBlock hasTitle={false} ref={firstSubscriptionsRef} />
          <S.MoneyBackText>
            {t('purchase.moneyBackGuaranteeV3.description')}
          </S.MoneyBackText>
          <S.Button
            margin="0 auto 24px"
            width="100%"
            data-button-number="2"
            data-button-text={OnboardingButtonText.GET_PLAN_MIDDLE}
            theme={buttonTheme.NUTRIMATE_PRIMARY}
            onClick={handleGetPlan}
          >
            {buttonText || t('actions.getMyPlan')}
          </S.Button>
          <S.DisclaimerV1 />
          <S.MoneyBackGuaranteeV3 />
        </S.Wrapper>
        <S.VideoReviews title="kitPurchaseV1.videoReviewTitle" />
        <S.Wrapper>
          <S.WeightLossJourney title="kitPurchaseV1.weightLossJourneyTitle" />
          <S.AppReached>
            <S.Button
              data-button-number="3"
              data-button-text={OnboardingButtonText.GET_PLAN_MIDDLE_2}
              margin="0 auto"
              onClick={handleScrollOnSubscriptionBlock}
              theme={buttonTheme.NUTRIMATE_PRIMARY}
            >
              {buttonText || t('actions.getMyPlan')}
            </S.Button>
          </S.AppReached>
          <S.BoostResults />
          <S.CustomerReviewsV3
            title="purchase.customerReviewsV5.title"
            reviews={TEXT_REVIEWS}
          />
          <S.FeaturedBlock />
          <S.DiscountTitle>{t('purchase.discountTitle')}</S.DiscountTitle>
          {discountBlock}
          <S.SubscriptionsBlock hasTitle={false} />
          <S.MoneyBackText>
            {t('purchase.moneyBackGuaranteeV3.description')}
          </S.MoneyBackText>
          <S.Button
            margin="0 auto 24px"
            width="100%"
            data-button-number="4"
            data-button-text={OnboardingButtonText.GET_PLAN_BOTTOM}
            theme={buttonTheme.NUTRIMATE_PRIMARY}
            onClick={handleGetPlan}
          >
            {buttonText || t('actions.getMyPlan')}
          </S.Button>
          <S.DisclaimerV2 />
          <SecurityInfoV1 />
        </S.Wrapper>
      </div>
    </>
  )
}
