import { roundToNearestFive } from 'helpers/numberHelper'

import { Currency, PaymentMethod } from 'modules/purchase/constants'

import { IAppState } from 'models/store.model'
import { ISubscription, TOldPrices } from 'models/subscriptions.model'

import { EMPTY_OLD_PRICE, PlanAddition, TimeInterval } from 'root-constants'

export const selectInAppId = (state: IAppState): string =>
  state.payment.selectedSubscription?.inAppId || ''
export const selectSubscriptionId = (state: IAppState): string =>
  state.payment.selectedSubscription?.id || ''
export const selectSubscriptionPlanId = (state: IAppState): number =>
  state.payment.selectedSubscription?.planId || 0
export const selectSubscriptionLookupKey = (state: IAppState): string =>
  state.payment.selectedSubscription?.lookupKey || ''
export const selectSubscriptionTrialLookupKey = (state: IAppState): string =>
  state.payment.selectedSubscription?.trialLookupKey || ''
export const selectSubscriptionPeriodQuantity = (state: IAppState): number =>
  state.payment.selectedSubscription?.mainPrices.periodQuantity || 0
export const selectSubscriptionPeriodName = (
  state: IAppState,
): TimeInterval | null =>
  state.payment.selectedSubscription?.mainPrices.periodName || null
export const selectSubscription = (state: IAppState): ISubscription | null =>
  state.payment.selectedSubscription || null
export const selectSubscriptionOldPriceBefore50PercentDiscount = (
  state: IAppState,
): TOldPrices =>
  state.payment.selectedSubscription?.mainPrices.oldPrices
    .before50PercentDiscount || EMPTY_OLD_PRICE
export const selectTrialSubscriptionFullPriceBeforeDiscount = (
  state: IAppState,
): number =>
  state.payment.selectedSubscription?.trialPrices.oldPrices.beforeCustomDiscount
    ?.fullPrice || 0
export const selectSubscriptionPercentOfDiscount = (state: IAppState): number =>
  state.payment.selectedSubscription?.mainPrices.oldPrices.beforeCustomDiscount
    ?.percentOfDiscount || 0
export const selectSubscriptionAmountOfDiscount = (state: IAppState): number =>
  state.payment.selectedSubscription?.mainPrices.oldPrices.beforeCustomDiscount
    ?.amountOfDiscount || 0
export const selectSubscriptionFullPriceBeforeDiscount = (
  state: IAppState,
): number =>
  state.payment.selectedSubscription?.mainPrices.oldPrices.beforeCustomDiscount
    ?.fullPrice || 0
export const selectSubscriptionFullPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.mainPrices.fullPrice || 0
export const selectSubscriptionFullPriceTax = (state: IAppState): number =>
  state.payment.selectedSubscription?.mainPrices.fullPriceTax || 0
export const selectSubscriptionDiscountAmount = (state: IAppState): number =>
  state.payment.selectedSubscription?.mainPrices.discountAmount || 0
export const selectSubscriptionTrialPeriodPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.trialPrices.fullPrice || 0
export const selectSubscriptionTrialPeriodPriceBefore50Discount = (
  state: IAppState,
): number =>
  state.payment.selectedSubscription?.trialPrices.oldPrices
    .before50PercentDiscount.fullPrice || 0
export const selectSubscriptionTrialPeriodPriceTax = (
  state: IAppState,
): number => state.payment.selectedSubscription?.trialPrices.fullPriceTax || 0
export const selectSubscriptionTrialPeriodDays = (state: IAppState): number =>
  state.payment.selectedSubscription?.trialPrices.durationDays || 0
export const selectCurrency = (state: IAppState): string =>
  state.payment.selectedSubscription?.currency.toLowerCase() || Currency.USD
export const selectProductId = (state: IAppState): string =>
  state.payment.selectedSubscription?.product || ''
export const selectIsPrimerPayPalButtonLoading = (state: IAppState): boolean =>
  state.payment.isPrimerPaypalButtonRendered || false
export const select3DSecureIframeUrl = (state: IAppState): string =>
  state.payment.threeDSecureIframeURL
export const selectPaymentClientSecret = (state: IAppState): string =>
  state.payment.paymentClientSecret
export const selectTrialPeriodDays = (state: IAppState): number =>
  state.payment.trialPeriodDays
export const selectPlanAdditions = (state: IAppState): PlanAddition[] =>
  state.payment.planAdditions
export const selectTaxAmount = (state: IAppState): number =>
  state.payment.taxAmount || 0
export const selectCreatedSubscriptionId = (state: IAppState): string =>
  state.payment.subscriptionId
export const selectSubscriptionIntroDifPercents = (state: IAppState) =>
  roundToNearestFive(
    state.payment.selectedSubscription?.introDiff?.discountPercentage || 0,
  )
export const selectSubscriptionPaywallDiscountPercent = (state: IAppState) =>
  state.payment.selectedSubscription?.paywallDiscountPercent || 0
export const selectSubscriptionIntroDifAmount = (state: IAppState) =>
  state.payment.selectedSubscription?.introDiff?.discountAmount || 0

export const selectOneClickPaymentMethod = (state: IAppState) =>
  state.payment.oneClickPaymentMethod || null
export const selectStripePaymentRequest = (state: IAppState) =>
  state.payment.stripePaymentRequest || null
export const selectIsCheckoutFlowShown = (state: IAppState): boolean =>
  state.payment.isCheckoutFlowShown
export const selectPaymentMethod = (state: IAppState): PaymentMethod =>
  state.payment.paymentMethod
export const selectIsFirstPaymentRetryPassed = (state: IAppState): boolean =>
  state.payment.isFirstPaymentRetryPassed
export const selectPrimerClientSessionToken = (state: IAppState): string =>
  state.payment.primerClientSessionToken

export const selectSelectedUpsellProduct = (state: IAppState) =>
  state.payment.upsellSelectedProduct || []
export const selectUpsellSwitcherState = (state: IAppState) =>
  state.payment.upsellSwitcherState || false
