import { ISubscription } from 'models/subscriptions.model'

export const compareSubscriptionList = (
  prevValue: ISubscription[],
  newValue: ISubscription[],
) =>
  prevValue.length === newValue.length &&
  prevValue.every((prevItem) =>
    newValue.find((newItem) => prevItem.id === newItem.id),
  )
