import { Button } from 'storybook-ui'
import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

const commonRowStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const StyledKitEmailSubscriptionItemV1 = {
  PlanRow: styled.div`
    ${commonRowStyle};
    margin: 0 0 16px 0;
  `,
  Period: styled.span`
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: ${Color.GREY_100};
    text-transform: uppercase;
  `,
  Price: styled.span`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${Color.GREEN_300};
  `,
  DiscountRow: styled.div`
    ${commonRowStyle};
  `,
  Button: styled(Button)`
    text-transform: uppercase;
  `,
  OldPrice: styled.span`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-decoration: line-through ${Color.RED_400};
    color: ${Color.GREY_102};
  `,
}
