import { PaymentSystem, TCardPaymentSystem } from 'modules/purchase/constants'

import { IAppState } from 'models/store.model'
import { IUserStatus } from 'models/user.model'

import { LoginMethod } from 'root-constants'

export const selectUUID = (state: IAppState): string => state.user.uuid
export const selectUserStatus = (state: IAppState): IUserStatus | null =>
  state.user.status
export const selectUserOnboardingEmail = (state: IAppState): string =>
  state.user.status?.email?.onboardingEmail || ''
export const selectIsOnboardingSkipped = (state: IAppState): boolean =>
  state.user.status?.isOnboardingSkipped || false
export const selectIsOnboardingFinished = (state: IAppState): boolean =>
  state.user.status?.isOnboardingFinished || false
export const selectUserAccountLoginMethod = (
  state: IAppState,
): LoginMethod | null => state.user.status?.account?.loginMethod || null
export const selectUserLoginEmail = (state: IAppState): string =>
  state.user.status?.account?.email || ''
export const selectTestEnvironmentQueryParam = (state: IAppState): string =>
  state.user.testEnvironmentQueryParam
export const selectUserCountryCode = (state: IAppState): string =>
  state.user.status?.countryCode || ''
export const selectUserUpsellPages = (state: IAppState) =>
  state.user.status?.config?.upsellPages || []
export const selectUtmTags = (state: IAppState): Record<string, string> =>
  state.user.utmTags || state.user.status?.config?.utmTags || {}
export const selectUserSubscriptionDuration = (state: IAppState) =>
  state.user.status?.config.subscriptionDuration || ''
export const selectUserPaymentId = (state: IAppState): string =>
  state.user.status?.config?.paymentId || ''
export const selectUserPaymentSystem = (state: IAppState): TCardPaymentSystem =>
  state.user.status?.config?.paymentSystem || PaymentSystem.STRIPE
export const selectCookieConsent = (state: IAppState): string[] | null =>
  state.user.status?.config?.cookieConsent || null
export const selectIsPersonalDataAllowed = (state: IAppState): boolean =>
  state.user.isPersonalDataAllowed || false
