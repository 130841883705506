/* eslint-disable max-lines */
import { EmailConsentV1, EmailConsentV2 } from 'pages/EmailConsent'
import { Download } from 'pages/download'
import { Email, EmailV2, EmailV3 } from 'pages/email'
import { FinishingTouches } from 'pages/finishingTouches'
import { PresaleV1, PresaleV2, PresaleV3 } from 'pages/presale'
import {
  AboutFeelingGood,
  AchieveResult,
  AchieveResultV2,
  ActivityDuringWork,
  ActivityDuringWorkV2,
  ActivityLevel,
  Age,
  AgeRange,
  AgeRangeV2,
  AgeRangeV3,
  AgeRangeV4,
  AgeRangeV5,
  AgeV2,
  AgeV4,
  AnalyzingAnswers,
  AnalyzingAnswersV2,
  BadHabits,
  BellyType,
  Benefits,
  BetweenMeals,
  BodyType,
  BodyTypeV2,
  BodyTypeV3,
  BodyTypeV4,
  BreakfastTime,
  BreathAfterClimbing,
  ChallengesYouFaced,
  ContributingEvents,
  ContributingEventsV2,
  CreatingPlan,
  CreatingPlanV2,
  CreatingPlanV3,
  CurrentWeight,
  CurrentWeightV2,
  CurrentWeightV4,
  CustomOptionsRightToLeft,
  CustomizeMealPlanV1,
  DailyMealsV1,
  DailyWater,
  DailyWaterV2,
  Determined,
  DietType,
  DinnerTime,
  DontExerciseV1,
  DreamBody,
  DreamBodyV2,
  EasyToFollowPlanV1,
  EatingPlace,
  EmotionalEatingV1,
  EventDate,
  EventDateV2,
  EventFeedback,
  EventFeedbackV3,
  FastingAtWeekend,
  FastingFeedbackV2,
  FavouriteFoodsV1,
  Feedback,
  FeedbackV2,
  FeelHungryV1,
  FeelingsToAchieve,
  GainWeightMenopause,
  GainWeightPeriod,
  Gender,
  GenderV2,
  GenderV3,
  GenderV4,
  GenderV5,
  GoalWeight,
  GoalWeightV2,
  GoalWeightV4,
  GoodHands,
  GoodHandsV2,
  HaveYouHeard,
  Height,
  HeightV2,
  HelpCalmCravings,
  IdealWeightTime,
  IdealWeightTimeV2,
  KitCommitV1,
  KitMotivationPreviewV1,
  LowerRisk,
  LunchTime,
  MealTimingV1,
  MealsPerDay,
  MealsPreparation,
  MealsV1,
  MenChoice,
  MenuV1,
  Motivation,
  MotivationV2,
  OptionsLeftToRight,
  OptionsLeftToRightV2,
  OptionsLeftToRightV3,
  OptionsRightToLeft,
  OptionsRightToLeftV3,
  PastTries,
  Period,
  PeriodAffectAppetite,
  PlanPace,
  PreparePlanV1,
  PreparePlanV2,
  PrimaryGoal,
  PrimaryGoalV2,
  PrimaryGoalV3,
  PrimaryGoalV4,
  ProfileSummaryV1,
  ProfileSummaryV2,
  ReasonToGiveUp,
  RestrictionsV1,
  SecondaryGoalV1,
  SleepingTime,
  SleepingTimeV2,
  Squats,
  StartPlanningMealsV1,
  StatementQuestion,
  StickingPlanV1,
  StickingPlanV3,
  StopHungerV1,
  TargetAreas,
  TargetAreasV2,
  ThankYouForYourTrust,
  TimeYouFeelHungry,
  UniquelyTailoredPlanV1,
  UsersChoice,
  UsersChoiceV2,
  UsersChoiceV3,
  UsersChoiceV4,
  UsersChoiceV5,
  Walking,
  WeAllGainWeight,
  WeightGain,
  WeightGainResult,
  WeightLossDateV1,
  WeightLossDateV2,
  WhatElse,
  WorkSchedule,
  Workout,
  YoEffect,
  YoEffectV2,
  YouAreNotAlone,
} from 'pages/questions'
import { AchieveResultV4 } from 'pages/questions/AchieveResult'
import { AchieveResultV3 } from 'pages/questions/AchieveResultV3'
import { AgeV3 } from 'pages/questions/Age'
import { BadHabitsFeedbackV1 } from 'pages/questions/BadHabitsFeedback'
import { BurnCaloriesV1 } from 'pages/questions/BurnCalories'
import { CreatingPlanV4 } from 'pages/questions/CreatingPlan'
import { CurrentWeightV3 } from 'pages/questions/CurrentWeight'
import { DailyWaterV3 } from 'pages/questions/DailyWater'
import { ImageOptionsV1, ImageWithTextV1 } from 'pages/questions/Dynamic'
import { InfluencerProfile } from 'pages/questions/Dynamic/InfluencerProfile'
import { EventV1 } from 'pages/questions/Event'
import { EventFeedbackV2 } from 'pages/questions/EventFeedback'
import { FastingV1 } from 'pages/questions/Fasting'
import { FastingFeedbackV1 } from 'pages/questions/FastingFeedback'
import { GoalWeightV3 } from 'pages/questions/GoalWeight'
import { HeightV3, HeightV4 } from 'pages/questions/Height'
import { IdealWeightFeedbackV1 } from 'pages/questions/IdealWeightFeedback'
import { ImprovementsV1 } from 'pages/questions/Improvements'
import { KitStatementQuestionV1 } from 'pages/questions/KitStatementQuestion'
import { NotAloneV1 } from 'pages/questions/NotAlone'
import {
  PerfectSolutionV1,
  PerfectSolutionV2,
  PerfectSolutionV3,
} from 'pages/questions/PerfectSolution'
import { PersonalizeYourPlanV1 } from 'pages/questions/PersonalizeYourPlan'
import { ProfileSummaryV3 } from 'pages/questions/ProfileSummary'
import { ProgramSuiteV1 } from 'pages/questions/ProgramSuite'
import { ReachGoalsV1 } from 'pages/questions/ReachGoals'
import { SleepingTimeFeedbackV1 } from 'pages/questions/SleepingTimeFeedback'
import { StartJourneyV1 } from 'pages/questions/StartJourney'
import { SupportV1 } from 'pages/questions/Support'
import { WorkoutV2 } from 'pages/questions/Workout'
import { Result } from 'pages/result'

import { Login } from 'modules/login'
import {
  CancelOffer,
  CancelOfferQuestionV1,
  CheckoutVariant1,
  CheckoutVariant2,
  KitCheckoutVariant1,
  KitPurchaseEmailVariant1,
  KitPurchaseEmailVariant2,
  KitPurchaseVariant1,
  PurchaseVariant1,
  PurchaseVariant3,
  PurchaseVariant4,
} from 'modules/purchase/pages'
import { UpsellPaywallProvider } from 'modules/purchase/pages/UpsellPaywall'

import { TIdToPageMap } from 'models/common.model'

export const enum CustomPageId {
  ACTIVITY_DURING_WORK = 'activity_during_work',
  AGE = 'age',
  AGE_RANGE = 'age_range',
  CURRENT_WEIGHT = 'current_weight',
  GENDER = 'gender',
  IDEAL_WEIGHT_TIME = 'ideal_weight_time',
  GOAL_WEIGHT = 'goal_weight',
  HEIGHT = 'height',
  PAST_TRIES = 'past_tries',
  PRIMARY_GOAL = 'primary_goal',
  PERIOD = 'period',
  EVENT_DATE = 'event_date',
  CHOOSE_EVENT = 'choose_event',
  MOTIVATION = 'motivation',
  FAVOURITE_FOODS = 'favourite_foods',
  RESTRICTIONS = 'restrictions',
  FASTING = 'fasting',
  TARGET_AREAS = 'target_areas_1',
}

export const enum PageId {
  GENDER_1 = 'gender_1',
  GENDER_2 = 'gender_2',
  GENDER_3 = 'gender_3',
  GENDER_4 = 'gender_4',
  GENDER_5 = 'gender_5',
  PRIMARY_GOAL_1 = 'primary_goal_1',
  PRIMARY_GOAL_2 = 'primary_goal_2',
  PRIMARY_GOAL_3 = 'primary_goal_3',
  SECONDARY_GOAL_1 = 'secondary_goal_1',
  BODY_TYPE_1 = 'body_type_1',
  BODY_TYPE_2 = 'body_type_2',
  BODY_TYPE_3 = 'body_type_3',
  KIT_BODY_TYPE_4 = 'kit_body_type_1',
  DREAM_BODY_1 = 'dream_body_1',
  KIT_DREAM_BODY_1 = 'kit_dream_body_1',
  BREAKFAST_TIME_1 = 'breakfast_time_1',
  LUNCH_TIME_1 = 'lunch_time_1',
  DINNER_TIME_1 = 'dinner_time_1',
  MENU_1 = 'menu_1',
  DAILY_MEALS_1 = 'daily_meals_1',
  MEAL_TIMING_1 = 'meal_timing_1',
  START_PLANNING_MEALS_1 = 'start_planning_meals_1',
  FAVOURITE_FOODS_1 = 'favourite_foods_1',
  RESTRICTIONS_1 = 'restrictions_1',
  CUSTOMIZE_MEAL_PLAN_1 = 'customize_meal_plan_1',
  FEEL_HUNGRY_1 = 'feel_hungry_1',
  STOP_HUNGER_1 = 'stop_hunger_1',
  EATING_PLACE_1 = 'eating_place_1',
  FASTING_AT_WEEKEND_1 = 'fasting_at_weekend_1',
  WORKOUT_1 = 'workout_1',
  WORKOUT_2 = 'workout_2',
  WORK_SCHEDULE_1 = 'work_schedule_1',
  ACTIVITY_DURING_WORK_1 = 'activity_during_work_1',
  ACTIVITY_DURING_WORK_2 = 'activity_during_work_2',
  SLEEPING_TIME_1 = 'sleeping_time_1',
  SLEEPING_TIME_2 = 'sleeping_time_2',
  SLEEPING_TIME_FEEDBACK_1 = 'sleeping_time_feedback_1',
  DAILY_WATER_1 = 'daily_water_1',
  DAILY_WATER_2 = 'daily_water_2',
  DAILY_WATER_3 = 'daily_water_3',
  MOTIVATION_1 = 'motivation_1',
  BAD_HABITS_FEEDBACK_1 = 'bad_habits_feedback_1',
  CHOOSE_EVENT_1 = 'choose_event_1',
  AGE_1 = 'age_1',
  AGE_2 = 'age_2',
  AGE_3 = 'age_3',
  KIT_AGE_1 = 'kit_age_1',
  AGE_RANGE_1 = 'age_range_1',
  AGE_RANGE_2 = 'age_range_2',
  AGE_RANGE_3 = 'age_range_3',
  AGE_RANGE_4 = 'age_range_4',
  HEIGHT_1 = 'height_1',
  HEIGHT_2 = 'height_2',
  HEIGHT_3 = 'height_3',
  CURRENT_WEIGHT_1 = 'current_weight_1',
  CURRENT_WEIGHT_2 = 'current_weight_2',
  CURRENT_WEIGHT_3 = 'current_weight_3',
  KIT_CURRENT_WEIGHT_1 = 'kit_current_weight_1',
  GOAL_WEIGHT_1 = 'goal_weight_1',
  GOAL_WEIGHT_2 = 'goal_weight_2',
  GOAL_WEIGHT_3 = 'goal_weight_3',
  KIT_GOAL_WEIGHT_1 = 'kit_goal_weight_1',
  IDEAL_WEIGHT_TIME_1 = 'ideal_weight_time_1',
  IDEAL_WEIGHT_TIME_2 = 'ideal_weight_time_2',
  IDEAL_WEIGHT_TIME_3 = 'ideal_weight_time_3',
  IDEAL_WEIGHT_FEEDBACK_1 = 'ideal_weight_feedback_1',
  USERS_CHOICE_1 = 'users_choice_1',
  USERS_CHOICE_2 = 'users_choice_2',
  USERS_CHOICE_3 = 'users_choice_3',
  USERS_CHOICE_4 = 'users_choice_4',
  USERS_CHOICE_5 = 'users_choice_5',
  TARGET_AREAS_1 = 'target_areas_1',
  PERFECT_SOLUTION_1 = 'perfect_solution_1',
  PERFECT_SOLUTION_2 = 'perfect_solution_2',
  ACHIEVE_RESULT_1 = 'achieve_result_1',
  ACHIEVE_RESULT_2 = 'achieve_result_2',
  ACHIEVE_RESULT_3 = 'achieve_result_3',
  STICKING_PLAN_1 = 'sticking_plan_1',
  ANALYZING_ANSWERS_1 = 'analyzing_answers_1',
  ANALYZING_ANSWERS_2 = 'analyzing_answers_2',
  PROFILE_SUMMARY_1 = 'profile_summary_1',
  PROFILE_SUMMARY_2 = 'profile_summary_2',
  YOU_ARE_IN_GOOD_HANDS_1 = 'you_are_in_good_hands_1',
  YOU_ARE_IN_GOOD_HANDS_2 = 'you_are_in_good_hands_2',
  YOYO_EFFECT_1 = 'yoyo_effect_1',
  YOYO_EFFECT_2 = 'yoyo_effect_2',
  EVENT_DATE_1 = 'event_date_1',
  EVENT_FEEDBACK_1 = 'event_feedback_1',
  KIT_WEIGHT_LOSS_DATE_1 = 'kit_weight_loss_date_1',
  PRESALE_1 = 'presale_1',
  PRESALE_2 = 'presale_2',
  KIT_PRESALE_1 = 'kit_presale_1',
  HAVE_YOU_HEARD_1 = 'have_you_heard_1',
  BENEFITS_1 = 'benefits_1',
  FEELINGS_TO_ACHIEVE_1 = 'feelings_to_achieve_1',
  CHALLENGES_YOU_FACED_1 = 'challenges_you_faced_1',
  WHAT_ELSE_1 = 'what_else_1',
  YOU_ARE_NOT_ALONE_1 = 'you_are_not_alone_1',
  MEALS_PER_DAY_1 = 'meals_per_day_1',
  TIME_YOU_FEEL_HUNGRY_1 = 'time_you_feel_hungry_1',
  MEALS_PREPARATION_1 = 'meals_preparation_1',
  BETWEEN_MEALS_1 = 'between_meals_1',
  BAD_HABITS_1 = 'bad_habits_1',
  PAST_TRIES_1 = 'past_tries_1',
  DIET_TYPE_1 = 'diet_type_1',
  ACTIVITY_LEVEL_1 = 'activity_level_1',
  WALKING_1 = 'walking_1',
  BREATH_AFTER_CLIMBING_1 = 'breath_after_climbing_1',
  CONTRIBUTING_EVENTS_1 = 'contributing_events_1',
  CONTRIBUTING_EVENTS_2 = 'contributing_events_2',
  WEIGHT_GAIN_TRIGGERS_1 = 'weight_gain_triggers_1',
  WEIGHT_GAIN_RESULT_1 = 'weight_gain_result_1',
  QUESTIONNAIRE_1 = 'questionnaire_1',
  QUESTIONNAIRE_2 = 'questionnaire_2',
  QUESTIONNAIRE_3 = 'questionnaire_3',
  QUESTIONNAIRE_4 = 'questionnaire_4',
  QUESTIONNAIRE_5 = 'questionnaire_5',
  QUESTIONNAIRE_6 = 'questionnaire_6',
  KIT_QUESTIONNAIRE_1 = 'kit_questionnaire_1',
  KIT_QUESTIONNAIRE_2 = 'kit_questionnaire_2',
  KIT_QUESTIONNAIRE_3 = 'kit_questionnaire_3',
  KIT_QUESTIONNAIRE_4 = 'kit_questionnaire_4',
  KIT_QUESTIONNAIRE_5 = 'kit_questionnaire_5',
  KIT_QUESTIONNAIRE_6 = 'kit_questionnaire_6',
  KIT_QUESTIONNAIRE_7 = 'kit_questionnaire_7',
  KIT_QUESTIONNAIRE_8 = 'kit_questionnaire_8',
  KIT_QUESTIONNAIRE_9 = 'kit_questionnaire_9',
  KIT_QUESTIONNAIRE_10 = 'kit_questionnaire_10',
  KIT_DONT_EXERCISE_1 = 'kit_dont_exercise_1',
  KIT_UNIQUELY_TAILORED_PLAN_1 = 'kit_uniquely_tailored_plan_1',
  KIT_EMOTIONAL_EATING_1 = 'kit_emotional_eating_1',
  KIT_EASY_TO_FOLLOW_PLAN_1 = 'kit_easy_to_follow_plan_1',
  KIT_MEALS_1 = 'kit_meals_1',
  HOW_DETERMINED_ARE_YOU_1 = 'how_determined_are_you_1',
  HOW_MANY_SQUATS_1 = 'how_many_squats_1',
  HELP_CALM_CRAVINGS = 'help_calm_cravings',
  PERIOD_1 = 'period_1',
  PLAN_PACE_1 = 'plan_pace_1',
  LOWER_RISK_1 = 'lower_risk_1',
  MEN_CHOICE_1 = 'men_choice_1',
  BELLY_TYPE_1 = 'belly_type_1',
  WE_ALL_GAIN_WEIGHT_1 = 'we_all_gain_weight_1',
  FEEDBACKS_1 = 'feedbacks_1',
  REASON_TO_GIVE_UP_1 = 'reason_to_give_up_1',
  PREPARE_PLAN_1 = 'prepare_plan_1',
  PREPARE_PLAN_2 = 'prepare_plan_2',
  CREATING_PLAN_1 = 'creating_plan_1',
  CREATING_PLAN_2 = 'creating_plan_2',
  CREATING_PLAN_3 = 'creating_plan_3',
  ENTER_EMAIL_1 = 'enter_email_1',
  ENTER_EMAIL_2 = 'enter_email_2',
  EMAIL_CONSENT_1 = 'email_consent_1',
  KIT_EMAIL_CONSENT_1 = 'kit_email_consent_1',
  RESULT_1 = 'result_1',
  PURCHASE_1 = 'purchase_1',
  PURCHASE_3 = 'purchase_3',
  PURCHASE_4 = 'purchase_4',
  PURCHASE_5 = 'purchase_5',
  KIT_PURCHASE_1 = 'kit_purchase_1',
  KIT_PURCHASE_EMAIL_1 = 'kit_purchase_email_1',
  KIT_PURCHASE_EMAIL_2 = 'kit_purchase_email_2',
  PURCHASE_PROVIDER = 'purchase_provider',
  CHECKOUT_1 = 'checkout_1',
  CHECKOUT_2 = 'checkout_2',
  KIT_CHECKOUT_1 = 'kit_checkout_1',
  CANCEL_OFFER_QUESTION_1 = 'cancel_offer_question_1',
  CANCEL_OFFER_1 = 'cancel_offer_1',
  UPSELL_PAYWALL_1 = 'upsell_paywall_1',
  UPSELL_PAYWALL_2 = 'upsell_paywall_2',
  FINISHING_TOUCHES = 'finishing_touches',
  LOGIN = 'login',
  DOWNLOAD = 'download',
  BURN_CALORIES_1 = 'burn_calories_1',
  PROGRAM_SUITE_1 = 'program_suite_1',
  IMPROVEMENTS_1 = 'improvements_1',
  START_JOURNEY_1 = 'start_journey_1',
  ABOUT_FEELING_GOOD = 'about_feeling_good_1',
  THANK_YOU_FOR_YOUR_TRUST = 'thank_you_for_your_trust_1',
  FASTING_1 = 'fasting_1',
  FASTING_FEEDBACK_1 = 'fasting_feedback_1',
  EVENT_1 = 'event_1',
  EVENT_FEEDBACK_2 = 'event_feedback_2',
  KIT_AGE_RANGE_1 = 'kit_age_range_1',
  KIT_GENDER_1 = 'kit_gender_1',
  KIT_USERS_CHOICE_1 = 'kit_users_choice_1',
  KIT_PRIMARY_GOAL_1 = 'kit_primary_goal_1',
  KIT_PERFECT_SOLUTION_1 = 'kit_perfect_solution_1',
  KIT_REACH_GOALS_1 = 'kit_reach_goals_1',
  KIT_HEIGHT_1 = 'kit_height_1',
  KIT_NOT_ALONE_1 = 'kit_not_alone_1',
  KIT_ACHIEVE_RESULT_1 = 'kit_achieve_result_1',
  KIT_SUPPORT_1 = 'kit_support_1',
  KIT_CREATING_PLAN_1 = 'kit_creating_plan_1',
  KIT_FASTING_FEEDBACK_1 = 'kit_fasting_feedback_1',
  KIT_FEEDBACKS_1 = 'kit_feedbacks_1',
  KIT_EVENT_DATE_1 = 'kit_event_date_1',
  KIT_EVENT_FEEDBACK_1 = 'kit_event_feedback_1',
  KIT_PROFILE_SUMMARY_1 = 'kit_profile_summary_1',
  KIT_ENTER_EMAIL_1 = 'kit_enter_email_1',
  KIT_EVENT_FEEDBACK = 'kit_event_feedback_1',
  // TODO: remove it after kit-header refactoring
  KIT_DYNAMIC_FEELINGS_TO_ACHIEVE_1 = 'kit_dynamic_options_left_to_right_feelingsToAchieve',
  KIT_DYNAMIC_FASTING_1 = 'kit_dynamic_options_left_to_right_fasting',
  KIT_WEIGHT_LOSS_DATE_2 = 'kit_weight_loss_date_2',
  PRIVACY_POLICY = 'privacy-policy',
  TERMS_OF_USE = 'terms-of-use',
  KIT_MOTIVATION_PREVIEW = 'kit_motivation_preview_1',
  KIT_COMMIT = 'kit_commit_1',
  KIT_MOTIVATION_TO_LOSE_WEIGHT = 'kit_motivation_to_lose_weight',
  KIT_REACH_IDEAL_WEIGHT_FIRST_TIME = 'kit_reach_ideal_weight_first_time',
  KIT_REACH_IDEAL_WEIGHT_SECOND_TIME = 'kit_reach_ideal_weight_second_time',
}

export const ID_TO_PAGE_MAP: TIdToPageMap = {
  dynamic_options_left_to_right: OptionsLeftToRight,
  dynamic_options_left_to_right_v2: OptionsLeftToRightV2,
  kit_dynamic_options_left_to_right: OptionsLeftToRightV3,
  kit_dynamic_options_right_to_left: OptionsRightToLeftV3,
  dynamic_options_right_to_left: OptionsRightToLeft,
  dynamic_custom_options_right_to_left: CustomOptionsRightToLeft,
  kit_dynamic_image_with_text: ImageWithTextV1,
  kit_dynamic_image_options: ImageOptionsV1,
  kit_dynamic_influencer: InfluencerProfile,
  start_journey_1: StartJourneyV1,
  gender_1: Gender,
  gender_2: GenderV2,
  gender_3: GenderV3,
  gender_4: GenderV4,
  gender_5: GenderV4,
  kit_gender_1: GenderV5,
  primary_goal_1: PrimaryGoal,
  primary_goal_2: PrimaryGoalV2,
  primary_goal_3: PrimaryGoalV3,
  kit_primary_goal_1: PrimaryGoalV4,
  secondary_goal_1: SecondaryGoalV1,
  body_type_1: BodyType,
  burn_calories_1: BurnCaloriesV1,
  program_suite_1: ProgramSuiteV1,
  improvements_1: ImprovementsV1,
  body_type_2: BodyTypeV2,
  body_type_3: BodyTypeV3,
  kit_body_type_1: BodyTypeV4,
  dream_body_1: DreamBody,
  kit_dream_body_1: DreamBodyV2,
  breakfast_time_1: BreakfastTime,
  lunch_time_1: LunchTime,
  dinner_time_1: DinnerTime,
  eating_place_1: EatingPlace,
  menu_1: MenuV1,
  daily_meals_1: DailyMealsV1,
  meal_timing_1: MealTimingV1,
  start_planning_meals_1: StartPlanningMealsV1,
  favourite_foods_1: FavouriteFoodsV1,
  restrictions_1: RestrictionsV1,
  customize_meal_plan_1: CustomizeMealPlanV1,
  feel_hungry_1: FeelHungryV1,
  stop_hunger_1: StopHungerV1,
  fasting_at_weekend_1: FastingAtWeekend,
  workout_1: Workout,
  workout_2: WorkoutV2,
  work_schedule_1: WorkSchedule,
  activity_during_work_1: ActivityDuringWork,
  activity_during_work_2: ActivityDuringWorkV2,
  sleeping_time_1: SleepingTime,
  sleeping_time_2: SleepingTimeV2,
  sleeping_time_feedback_1: SleepingTimeFeedbackV1,
  daily_water_1: DailyWater,
  daily_water_2: DailyWaterV2,
  daily_water_3: DailyWaterV3,
  motivation_1: Motivation,
  event_1: EventV1,
  event_feedback_2: EventFeedbackV2,
  bad_habits_feedback_1: BadHabitsFeedbackV1,
  choose_event_1: MotivationV2,
  age_1: Age,
  age_2: AgeV2,
  age_3: AgeV3,
  kit_age_1: AgeV4,
  age_range_1: AgeRange,
  age_range_2: AgeRangeV2,
  age_range_3: AgeRangeV3,
  age_range_4: AgeRangeV4,
  kit_age_range_1: AgeRangeV5,
  about_feeling_good_1: AboutFeelingGood,
  kit_reach_goals_1: ReachGoalsV1,
  height_1: Height,
  height_2: HeightV2,
  height_3: HeightV3,
  kit_height_1: HeightV4,
  kit_questionnaire_1: KitStatementQuestionV1,
  kit_questionnaire_2: KitStatementQuestionV1,
  kit_questionnaire_3: KitStatementQuestionV1,
  kit_questionnaire_4: KitStatementQuestionV1,
  kit_questionnaire_5: KitStatementQuestionV1,
  kit_questionnaire_6: KitStatementQuestionV1,
  kit_questionnaire_7: KitStatementQuestionV1,
  kit_questionnaire_8: KitStatementQuestionV1,
  kit_questionnaire_9: KitStatementQuestionV1,
  kit_questionnaire_10: KitStatementQuestionV1,
  kit_uniquely_tailored_plan_1: UniquelyTailoredPlanV1,
  kit_emotional_eating_1: EmotionalEatingV1,
  kit_easy_to_follow_plan_1: EasyToFollowPlanV1,
  kit_meals_1: MealsV1,
  kit_not_alone_1: NotAloneV1,
  kit_achieve_result_1: AchieveResultV4,
  kit_support_1: SupportV1,
  current_weight_1: CurrentWeight,
  current_weight_2: CurrentWeightV2,
  current_weight_3: CurrentWeightV3,
  kit_current_weight_1: CurrentWeightV4,
  goal_weight_1: GoalWeight,
  goal_weight_2: GoalWeightV2,
  goal_weight_3: GoalWeightV3,
  kit_goal_weight_1: GoalWeightV4,
  ideal_weight_time_1: IdealWeightTime,
  ideal_weight_time_2: IdealWeightTimeV2,
  ideal_weight_time_3: IdealWeightTime,
  ideal_weight_feedback_1: IdealWeightFeedbackV1,
  fasting_1: FastingV1,
  fasting_feedback_1: FastingFeedbackV1,
  kit_fasting_feedback_1: FastingFeedbackV2,
  users_choice_1: UsersChoice,
  users_choice_2: UsersChoiceV2,
  users_choice_3: UsersChoiceV3,
  users_choice_4: UsersChoiceV4,
  users_choice_5: UsersChoiceV3,
  kit_users_choice_1: UsersChoiceV5,
  target_areas_1: TargetAreas,
  kit_target_areas_1: TargetAreasV2,
  thank_you_for_your_trust_1: ThankYouForYourTrust,
  perfect_solution_1: PerfectSolutionV1,
  perfect_solution_2: PerfectSolutionV2,
  kit_perfect_solution_1: PerfectSolutionV3,
  kit_personalize_your_plan_1: PersonalizeYourPlanV1,
  kit_creating_plan_1: CreatingPlanV4,
  period_1: Period,
  gain_weight_period_1: GainWeightPeriod,
  gain_weight_menopause_1: GainWeightMenopause,
  period_affect_appetite_1: PeriodAffectAppetite,
  help_calm_cravings: HelpCalmCravings,
  achieve_result_1: AchieveResult,
  achieve_result_2: AchieveResultV2,
  achieve_result_3: AchieveResultV3,
  profile_summary_1: ProfileSummaryV1,
  profile_summary_2: ProfileSummaryV2,
  kit_profile_summary_1: ProfileSummaryV3,
  you_are_in_good_hands_1: GoodHands,
  you_are_in_good_hands_2: GoodHandsV2,
  sticking_plan_1: StickingPlanV1,
  sticking_plan_3: StickingPlanV3,
  yoyo_effect_1: YoEffect,
  yoyo_effect_2: YoEffectV2,
  have_you_heard_1: HaveYouHeard,
  benefits_1: Benefits,
  feelings_to_achieve_1: FeelingsToAchieve,
  challenges_you_faced_1: ChallengesYouFaced,
  what_else_1: WhatElse,
  you_are_not_alone_1: YouAreNotAlone,
  meals_per_day_1: MealsPerDay,
  time_you_feel_hungry_1: TimeYouFeelHungry,
  meals_preparation_1: MealsPreparation,
  between_meals_1: BetweenMeals,
  bad_habits_1: BadHabits,
  past_tries_1: PastTries,
  diet_type_1: DietType,
  activity_level_1: ActivityLevel,
  walking_1: Walking,
  breath_after_climbing_1: BreathAfterClimbing,
  how_many_squats_1: Squats,
  analyzing_answers_1: AnalyzingAnswers,
  analyzing_answers_2: AnalyzingAnswersV2,
  contributing_events_1: ContributingEvents,
  contributing_events_2: ContributingEventsV2,
  weight_gain_triggers_1: WeightGain,
  weight_gain_result_1: WeightGainResult,
  questionnaire_1: StatementQuestion,
  questionnaire_2: StatementQuestion,
  questionnaire_3: StatementQuestion,
  questionnaire_4: StatementQuestion,
  questionnaire_5: StatementQuestion,
  questionnaire_6: StatementQuestion,
  how_determined_are_you_1: Determined,
  plan_pace_1: PlanPace,
  lower_risk_1: LowerRisk,
  prepare_plan_1: PreparePlanV1,
  prepare_plan_2: PreparePlanV2,
  men_choice_1: MenChoice,
  belly_type_1: BellyType,
  we_all_gain_weight_1: WeAllGainWeight,
  feedbacks_1: Feedback,
  kit_feedbacks_1: FeedbackV2,
  reason_to_give_up_1: ReasonToGiveUp,
  event_date_1: EventDate,
  kit_event_date_1: EventDateV2,
  event_feedback_1: EventFeedback,
  kit_event_feedback_1: EventFeedbackV3,
  kit_weight_loss_date_1: WeightLossDateV1,
  kit_weight_loss_date_2: WeightLossDateV2,
  kit_dont_exercise_1: DontExerciseV1,
  kit_motivation_preview_1: KitMotivationPreviewV1,
  kit_commit_1: KitCommitV1,
  presale_1: PresaleV1,
  presale_2: PresaleV2,
  kit_presale_1: PresaleV3,
  creating_plan_1: CreatingPlan,
  creating_plan_2: CreatingPlanV2,
  creating_plan_3: CreatingPlanV3,
  enter_email_1: Email,
  enter_email_2: EmailV2,
  kit_enter_email_1: EmailV3,
  email_consent_1: EmailConsentV1,
  kit_email_consent_1: EmailConsentV2,
  result_1: Result,
  purchase_1: PurchaseVariant1,
  purchase_3: PurchaseVariant3,
  purchase_4: PurchaseVariant4,
  kit_purchase_1: KitPurchaseVariant1,
  kit_purchase_email_1: KitPurchaseEmailVariant1,
  kit_purchase_email_2: KitPurchaseEmailVariant2,
  checkout_1: CheckoutVariant1,
  checkout_2: CheckoutVariant2,
  kit_checkout_1: KitCheckoutVariant1,
  cancel_offer_question_1: CancelOfferQuestionV1,
  cancel_offer_1: CancelOffer,
  upsell_paywall_1: UpsellPaywallProvider,
  upsell_paywall_2: UpsellPaywallProvider,
  finishing_touches: FinishingTouches,
  login: Login,
  download: Download,
}
