import styled from 'styled-components'

import infoIconSrc from 'assets/images/info-icon.svg'
import iconTickSrc from 'assets/images/kit-check-icon-v2.svg'
import iconSrc from 'assets/images/kit-check-icon.svg'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledUpsellPaywallV3 = {
  Subtitle: styled.p`
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: ${Color.GREY_101};
    margin: 0 0 24px 0;
  `,
  UpsellList: styled.ul`
    margin: 0 0 24px 0;
  `,
  UpsellItem: styled.div<{ isActive: boolean }>`
    min-height: 84px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: stretch;
    border: 1px solid;
    border-radius: 16px;
    border-color: ${({ isActive }) =>
      isActive ? Color.GREEN_400 : Color.GREY_800};
  `,
  ImageContainer: styled.div`
    min-width: 72px;
    background: radial-gradient(58.33% 50% at 50% 50%, #fff 55%, #efefef 100%);
    position: relative;

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 50px;
      height: auto;
    }

    @media screen and (max-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      min-width: 54px;

      img {
        width: 34px;
      }
    }
  `,
  DataContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px 16px 12px 8px;
  `,
  Info: styled.div<{ isActive: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    &::after {
      position: absolute;
      content: url(${({ isActive }) => (isActive ? iconTickSrc : '')});
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      border: 2px solid;
      border-radius: 50%;
      border-color: ${Color.GREY_800};
      border-color: ${({ isActive }) =>
        isActive ? 'transparent' : Color.GREY_800};
    }
  `,
  Title: styled.h4`
    max-width: 215px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: ${Color.GREY_100};
    margin: 0 0 4px 0;

    @media screen and (max-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      max-width: 180px;
    }
  `,
  PriceContainer: styled.div`
    display: flex;
    align-items: center;
    max-width: 215px;

    @media screen and (max-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      max-width: 180px;
    }
  `,
  Price: styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 2px;

    margin: 0 4px 0 0;

    > :not(:last-child) {
      margin: 0 2px 0 0;
    }
  `,
  PrevPrice: styled.span`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.GREY_101};
    text-decoration: line-through;
    text-decoration-thickness: 0.5px;
    text-wrap: nowrap;

    @media screen and (max-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      font-size: 11px;
      line-height: 14px;
    }
  `,
  CurPrice: styled.span`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.ORANGE_200};
    text-wrap: nowrap;

    @media screen and (max-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      font-size: 11px;
      line-height: 14px;
    }
  `,
  Discount: styled.div`
    align-self: end;
    padding: 0 6px;
    border: 1px solid ${Color.ORANGE_200};
    border-radius: 4px;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: ${Color.ORANGE_200};
    text-transform: uppercase;
    text-wrap: nowrap;

    @media screen and (max-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      font-size: 11px;
      line-height: 14px;
    }
  `,
  AdditionalContainer: styled.div`
    margin: 12px 32px 0 0;
    padding: 12px 0 0 0;
    border-top: 1px solid ${Color.GREY_800};
  `,
  AdditionalInfo: styled.p`
    padding: 0 0 0 20px;
    font-weight: 400;
    font-size: 13px;
    color: ${Color.GREY_101};
    position: relative;
    line-height: 16px;

    &::before {
      position: absolute;
      left: 0;
      width: 16px;
      height: 16px;
      content: url(${infoIconSrc});
    }
  `,
  BenefitItem: styled.li`
    min-height: 24px;
    position: relative;
    padding: 0 0 0 32px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${Color.GREY_101};

    &:not(:last-of-type) {
      margin: 0 0 8px 0;
    }

    &:before {
      position: absolute;
      content: url(${iconSrc});
      width: 18px;
      height: 18px;
      padding: 0 0 0 2px;
      left: 0;
    }
  `,
}
