import { useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { usePageInfo } from 'contexts/PageInfoProvider'

import {
  getSubscriptionListAction,
  setScreenNameAction,
} from 'root-redux/actions/common'
import {
  selectLanguage,
  selectSubscriptionList,
} from 'root-redux/selects/common'
import {
  selectIsPersonalDataAllowed,
  selectUserOnboardingEmail,
} from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store'

import { useVatInfo } from 'hooks/useHasVatInfo'
import { usePayment } from 'hooks/usePayment'

import { createProductId } from 'helpers/createProductId'

import { compareSubscriptionList } from 'modules/purchase/helpers/compareSubscriptionList'
import { selectSubscriptionPaywallDiscountPercent } from 'modules/purchase/redux/selects'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import {
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

export const useCancelOffer = () => {
  const subscriptions = useSelector(
    selectSubscriptionList,
    compareSubscriptionList,
  )
  const email = useSelector(selectUserOnboardingEmail)
  const discountFromSubscriptions = useSelector(
    selectSubscriptionPaywallDiscountPercent,
  )
  const language = useSelector(selectLanguage)
  const isPersonalDataAllowed = useSelector(selectIsPersonalDataAllowed)
  const dispatch: TAppDispatch = useDispatch()
  const { search } = useLocation()
  const { hasPrices, handleShowPayment } = usePayment()
  const hasVatInfo = useVatInfo()
  const { currentPaymentPageId } = usePageInfo()

  const handleGetPlan = () => {
    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
    handleShowPayment()

    goTo(`${currentPaymentPageId}${search}`)
  }

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.CANCEL_OFFER))

    const tags = `${SubscriptionTags.CANCEL_OFFER},${
      hasVatInfo ? SubscriptionTags.TAX : SubscriptionTags.NO_TAX
    }`

    dispatch(getSubscriptionListAction(SubscriptionListType.PURCHASE, tags))
  }, [dispatch, hasVatInfo])

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(({ mainPrices }) =>
        createProductId({
          periodName: mainPrices.periodName,
          periodQuantity: mainPrices.periodQuantity,
          price: mainPrices.fullPrice,
        }),
      ),
      screenName: ScreenName.CANCEL_OFFER,
    })
  }, [subscriptions])

  return { language, hasPrices, discountFromSubscriptions, handleGetPlan }
}
