export const BENEFITS = [
  'cancelOffer.questionPage.eatAfterSix',
  'cancelOffer.questionPage.eatingFat',
  'cancelOffer.questionPage.bananas',
  'cancelOffer.questionPage.treats',
]

export const IMAGE_PATH = '/images/question-intro'

export const DISCOUNT_AMOUNT = 65
