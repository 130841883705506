import React, { FC, ReactNode, SyntheticEvent, useEffect, useRef } from 'react'

import { usePageInfo } from 'contexts/PageInfoProvider'
import { t } from 'i18next'
import { Button, buttonTheme } from 'storybook-ui'

import {
  HEADER_HEIGHT_WITHOUT_PROGRESSBAR,
  POST_PAYMENT_PAGES,
} from 'root-constants'

import { StyledKitPageContainer as S } from './KitPageContainer.styles'
import { CONTINUE_BUTTON_IS_STICKY } from './constants'

type TProps = {
  children: ReactNode
  paddingTop?: number
  className?: string
  hasContinueButton?: boolean
  hasSkipButton?: boolean
  isActionButtonsVisible?: boolean
  continueButtonType?: 'submit' | 'button'
  continueButtonDeepLink?: string
  isContinueButtonDisabled?: boolean
  onContinueButtonClick?: (event: SyntheticEvent<HTMLButtonElement>) => void
  onSkipButtonClick?: (event: SyntheticEvent<HTMLButtonElement>) => void
  continueButtonContent?: ReactNode
  skipButtonContent?: ReactNode
  bottomContent?: ReactNode
}

const progressBarHeightPostOnboarding = 84
const headerHeightOnboarding = 54

export const KitPageContainer: FC<TProps> = ({
  children,
  paddingTop,
  className,
  hasContinueButton = false,
  hasSkipButton = false,
  isActionButtonsVisible = true,
  isContinueButtonDisabled = false,
  continueButtonContent = t('actions.continue'),
  continueButtonType = 'button',
  continueButtonDeepLink,
  skipButtonContent = t('actions.skipQuestion'),
  bottomContent,
  onContinueButtonClick,
  onSkipButtonClick,
}) => {
  const stickyButtonContainerRef = useRef<HTMLDivElement>(null)
  const { hasHeader, hasProgressbar, currentPageId } = usePageInfo()

  const headerWithProgressBarHeight = () => {
    if (
      hasHeader &&
      hasProgressbar &&
      !POST_PAYMENT_PAGES.includes(currentPageId)
    ) {
      return headerHeightOnboarding
    }

    if (hasHeader && !POST_PAYMENT_PAGES.includes(currentPageId)) {
      return HEADER_HEIGHT_WITHOUT_PROGRESSBAR
    }

    if (POST_PAYMENT_PAGES.includes(currentPageId)) {
      return HEADER_HEIGHT_WITHOUT_PROGRESSBAR + progressBarHeightPostOnboarding
    }

    return 0
  }

  useEffect(() => {
    const stickyButtonContainer = stickyButtonContainerRef.current
    let observer: IntersectionObserver | null = null

    if (stickyButtonContainer) {
      observer = new IntersectionObserver(
        ([e]) =>
          e.target.classList.toggle(
            CONTINUE_BUTTON_IS_STICKY,
            e.intersectionRatio < 1,
          ),
        { threshold: [1], rootMargin: '0px 0px -1px 0px' },
      )

      observer.observe(stickyButtonContainer)
    }

    return () => {
      observer?.disconnect()
    }
  }, [])

  return (
    <S.Wrapper
      headerWithProgressBarHeight={headerWithProgressBarHeight()}
      className={className}
    >
      <S.Container
        paddingTop={paddingTop}
        hasContinueButton={hasContinueButton}
      >
        {hasContinueButton ? (
          <>
            <S.Content isActionButtonsVisible={isActionButtonsVisible}>
              {children}
              {!!bottomContent && (
                <S.BottomContent>{bottomContent}</S.BottomContent>
              )}
            </S.Content>
            <S.StickyButtonContainer
              ref={stickyButtonContainerRef}
              isActionButtonsVisible={isActionButtonsVisible}
            >
              <Button
                width="100%"
                type={continueButtonType}
                margin={hasSkipButton ? '0 auto 24px auto' : '0 auto'}
                theme={buttonTheme.NUTRIMATE_PRIMARY}
                disabled={isContinueButtonDisabled}
                data-deep-link={continueButtonDeepLink}
                onClick={onContinueButtonClick}
              >
                {continueButtonContent}
              </Button>
              {hasSkipButton && (
                <S.SkipButton onClick={onSkipButtonClick}>
                  {skipButtonContent}
                </S.SkipButton>
              )}
            </S.StickyButtonContainer>
          </>
        ) : (
          <>
            {children}
            {!!bottomContent && (
              <S.BottomContent hasContinueButton={false}>
                {bottomContent}
              </S.BottomContent>
            )}
          </>
        )}
      </S.Container>
    </S.Wrapper>
  )
}
