import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Button, buttonTheme } from 'storybook-ui'

import { KitPageContainer } from 'components/PageContainer'
import { Spinner } from 'components/Spinner'

import { selectSubscriptionList } from 'root-redux/selects/common'

import {
  UpsellGuides,
  UpsellSubscriptionBlock,
} from 'modules/purchase/components'
import { NUMBER_OF_SINGLE_SUBSCRIPTION } from 'modules/purchase/components/UpsellSubscriptionBlock/constants'
import {
  setSelectedSubscriptionAction,
  setUpsellSwitcherState,
} from 'modules/purchase/redux/actions/common'
import { selectSelectedUpsellProduct } from 'modules/purchase/redux/selects'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { ScreenName, UpsellProduct } from 'root-constants'

import { StyledUpsellPaywallV1 as S } from './UpsellPaywallV1.styles'

type TProps = {
  isUpsellInProgress: boolean
  arePricesReady: boolean
  makeUpsell: (screenName: ScreenName) => void
}

export const UpsellPaywallV1: FC<TProps> = ({
  makeUpsell,
  isUpsellInProgress,
  arePricesReady,
}) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()

  const selectedUpsellProducts = useSelector(selectSelectedUpsellProduct)
  const subscriptions = useSelector(selectSubscriptionList)

  const isSingleSubscriptionSelected = useMemo(
    () => selectedUpsellProducts.length === NUMBER_OF_SINGLE_SUBSCRIPTION,
    [selectedUpsellProducts],
  )

  useEffect(() => {
    eventLogger.logUpsellPurchaseShown(ScreenName.UPSELL_SWITCHER)

    return () => {
      dispatch(setUpsellSwitcherState(false))
    }
  }, [dispatch])

  const fitnessAndSelfcare = subscriptions.find(
    (subscription) => subscription.product === UpsellProduct.BOTH,
  )

  const handleSkipButton = useCallback(() => {
    eventLogger.logUpsellPurchaseClose(ScreenName.UPSELL_SWITCHER)
    goTo({ pathname: PageId.FINISHING_TOUCHES, search })
  }, [search])

  const handleMakeUpsell = useCallback(() => {
    makeUpsell(ScreenName.UPSELL_SWITCHER)
  }, [makeUpsell])

  const handleContinueWithAll = useCallback(() => {
    dispatch(setUpsellSwitcherState(true))
    fitnessAndSelfcare &&
      dispatch(setSelectedSubscriptionAction(fitnessAndSelfcare))
    handleMakeUpsell()
  }, [dispatch, fitnessAndSelfcare, handleMakeUpsell])

  const { continueButtonText, handleContinueFlow } = useMemo(() => {
    if (isSingleSubscriptionSelected) {
      return {
        continueButtonText: t('upsell.addToMyProgram'),
        handleContinueFlow: handleMakeUpsell,
      }
    }

    return {
      continueButtonText: t('upsell.addAllToMyProgram'),
      handleContinueFlow: handleContinueWithAll,
    }
  }, [handleContinueWithAll, handleMakeUpsell, isSingleSubscriptionSelected, t])

  return (
    <>
      <KitPageContainer paddingTop={0}>
        <S.Title>{t('upsell.title')}</S.Title>
        <S.Subtitle>{t('upsell.subTitle')}</S.Subtitle>
        <UpsellSubscriptionBlock
          makeUpsell={handleMakeUpsell}
          makeUpsellWithAll={handleContinueWithAll}
          skip={handleSkipButton}
        />
        <S.UpsellBenefits />
        <UpsellGuides />
        <S.ButtonContainer>
          <Button
            width="100%"
            type="submit"
            margin="0 auto 24px"
            theme={buttonTheme.NUTRIMATE_PRIMARY}
            onClick={handleContinueFlow}
          >
            {continueButtonText}
          </Button>
          <S.SkipButton onClick={handleSkipButton}>
            {t('upsell.noThanksLink')}
          </S.SkipButton>
        </S.ButtonContainer>
      </KitPageContainer>
      {(isUpsellInProgress || !arePricesReady) && <Spinner />}
    </>
  )
}
