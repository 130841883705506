import React, { useCallback, useEffect, useLayoutEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { StyledContributingEvents as C } from 'pages/questions/ContributingEvents/ContributingEvents.styles'
import { MotivationValue } from 'value-constants'

import { BodyChange } from 'components/BodyChange'
import { LoseWeightGraphV2 } from 'components/LoseWeightGraphV2'
import { PercentageProgress } from 'components/PercentageProgress'
import { PersonalSummary } from 'components/PersonalSummary'
import { WaterConsumption } from 'components/WaterConsumption'

import {
  selectCurrentUserGoalWeight,
  selectCurrentUserMotivation,
  selectCurrentVariantCohortToUse,
} from 'root-redux/selects/common'
import { selectUserStatus } from 'root-redux/selects/user'

import { useEventWeightInfo } from 'hooks/useEventWeightInfo'
import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useUserStatus } from 'hooks/useUserStatus'
import { useWeightMeasurementSystem } from 'hooks/useWeightMeasurementSystem'

import { PurchasePageType } from 'modules/purchase/constants'

import { eventLogger } from 'services/eventLogger.service'

import hero from 'assets/images/result-hero.png'

import { goTo } from 'browser-history'

import {
  Action,
  Hero,
  StyledResult as S,
  StyledContainer,
  StyledLogo,
  StyledLoseWeightGraph,
  WeightItem,
  WeightMeasurement,
  WeightRange,
  WeightRangeTitle,
} from './Result.styles'
import { COHORTS_WITH_ALTERNATIVE_UI, PERCENTAGE } from './constants'

export const Result: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()

  const goalUserWeight = useSelector(selectCurrentUserGoalWeight)
  const userStatus = useSelector(selectUserStatus)
  const motivation = useSelector(selectCurrentUserMotivation)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const availableRoute = useUserStatus()
  const {
    eventDate,
    goalWeight,
    shortenedGoalDate,
    weightDifference,
    currentWeight,
    isEventWithinGoal,
  } = useEventWeightInfo()
  const { currentSubscriptionPageId } = useGetPageInfo()
  const { measuringSystemLabel } = useWeightMeasurementSystem()

  const renderedWeightRange = [
    Number(goalUserWeight) - 2,
    Number(goalUserWeight) - 1,
    Number(goalUserWeight),
    Number(goalUserWeight) + 1,
    Number(goalUserWeight) + 2,
  ]

  const hasMotivationToShow = useMemo(
    () =>
      motivation !== MotivationValue.NO && motivation !== MotivationValue.OTHER,
    [motivation],
  )

  const lostWeightToShow = useMemo(
    () =>
      isEventWithinGoal
        ? weightDifference
        : (Number(currentWeight) - Number(goalWeight)).toFixed(1),
    [currentWeight, goalWeight, isEventWithinGoal, weightDifference],
  )

  useEffect(() => {
    eventLogger.logPresalePageShown(PurchasePageType.WEB_LANDING)
  }, [])

  useLayoutEffect(() => {
    if (userStatus?.hasSubscription) {
      goTo({ pathname: availableRoute, search })
    }
  }, [search, userStatus, availableRoute])

  const handleClick = useCallback(() => {
    eventLogger.logPresalePageCompleted()

    goTo({ pathname: currentSubscriptionPageId, search })
  }, [currentSubscriptionPageId, search])

  return (
    <S.Root>
      <StyledLogo />

      <Hero>
        <S.Image src={hero} alt="" />
        <S.Title>{t('result.title')}</S.Title>
      </Hero>

      <StyledContainer>
        {COHORTS_WITH_ALTERNATIVE_UI.includes(cohortToUse) ? (
          <>
            <C.DateTitle>
              <Trans
                i18nKey="onboarding.contributingEvents.dateTitle"
                values={{
                  date: shortenedGoalDate,
                  goal: goalWeight,
                  metric: measuringSystemLabel,
                }}
              />
            </C.DateTitle>
            {eventDate && hasMotivationToShow && (
              <C.DateSubTitle>
                <Trans
                  i18nKey="onboarding.contributingEvents.dateSubtitle"
                  values={{
                    weight: lostWeightToShow,
                    metric: measuringSystemLabel,
                    answer: motivation,
                  }}
                />
              </C.DateSubTitle>
            )}
            <LoseWeightGraphV2 />
            <S.Footnote>{t('result.graph.footnote')}</S.Footnote>
          </>
        ) : (
          <>
            <WeightRangeTitle>{t('result.weightRangeTitle')}</WeightRangeTitle>
            <WeightRange>
              {!!renderedWeightRange.length &&
                renderedWeightRange.map((value) => (
                  <WeightItem key={value}>{value}</WeightItem>
                ))}
            </WeightRange>
            <WeightMeasurement>{measuringSystemLabel}</WeightMeasurement>

            <StyledLoseWeightGraph />
          </>
        )}
        <PercentageProgress
          title="result.percentageProgress.title"
          note="result.percentageProgress.footnote"
          percentageValue={PERCENTAGE}
        />
        <PersonalSummary />
        <WaterConsumption />
        <BodyChange />
        <Action onClick={handleClick}>{t('result.action')}</Action>
      </StyledContainer>
    </S.Root>
  )
}
