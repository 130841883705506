import React, {
  SyntheticEvent,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { TimerProvider } from 'contexts/TimerProvider'
import { buttonTheme } from 'storybook-ui'

import { DynamicDiscountBanner } from 'components/DynamicDiscountBanner'
import { Spinner } from 'components/Spinner'

import {
  getSubscriptionListAction,
  setScreenNameAction,
} from 'root-redux/actions/common'
import {
  selectCurrentVariantCohortToUse,
  selectSubscriptionList,
} from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store'

import { useVatInfo } from 'hooks/useHasVatInfo'
import { usePayment } from 'hooks/usePayment'
import {
  ViewportScrollPercentage,
  useViewportValue,
} from 'hooks/useViewportValue'

import { createProductId } from 'helpers/createProductId'

import { CustomerReviewsV4 } from 'modules/purchase/components/CustomerReviewsV4'
import { HowItWorks } from 'modules/purchase/components/HowItWorks'
import { RefundBanner } from 'modules/purchase/components/RefundBanner'
import { SecurityInfoV1 } from 'modules/purchase/components/SecurityInfo'
import { TEN_MINUTES_IN_SECONDS } from 'modules/purchase/constants'
import { useDynamicPaywallConfig } from 'modules/purchase/hooks/useDynamicPaywallConfig'
import { OnboardingButtonText } from 'modules/purchase/pages/constants'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import {
  Cohort,
  FEATURE_KEYS,
  INFLUENCER_NAME_SESSION_STORAGE_KEY,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { StyledKitPurchaseVariant1 as S } from './KitPurchaseVariant1.styles'

export const KitPurchaseVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()

  const hasVatInfo = useVatInfo()
  const subscriptions = useSelector(selectSubscriptionList)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const { buttonText } = useDynamicPaywallConfig()
  const { hasPrices, handleShowPayment, handleAmplitudePaymentEvents } =
    usePayment()
  const isAlternativeDiscountVariant = useFeatureIsOn(
    FEATURE_KEYS.fas_801_dynamic_discount,
  )
  const viewportValue = useViewportValue()
  const firstSubscriptionsRef = useRef<HTMLDivElement>(null)
  const headerTimerRef = useRef<HTMLDivElement>(null)
  const hasDynamicDiscountBanner = cohortToUse === Cohort.MF_INTRO_SALE

  const handleScrollOnSubscriptionBlock = (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    const top = firstSubscriptionsRef.current?.offsetTop
    const headerTimerHeight = headerTimerRef.current?.offsetHeight

    if (top && headerTimerHeight) {
      document.body.scrollTo(0, top - headerTimerHeight)
    }

    handleAmplitudePaymentEvents(event.target as HTMLButtonElement)
  }

  const handleGetPlan = (event: SyntheticEvent<HTMLButtonElement>) => {
    handleShowPayment()
    handleAmplitudePaymentEvents(event.target as HTMLButtonElement)

    goTo(nextPagePath)
  }

  const discountBlock = (() => {
    if (hasDynamicDiscountBanner) {
      return isAlternativeDiscountVariant ? (
        <S.DiscountBlockV1 />
      ) : (
        <S.DiscountBlockV2 />
      )
    }

    if (
      cohortToUse === Cohort.MF_INFLUENCER &&
      window.sessionStorage.getItem(INFLUENCER_NAME_SESSION_STORAGE_KEY)
    ) {
      return <S.DiscountBlockV3 />
    }

    return <S.DiscountBlock />
  })()

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.ONBOARDING))

    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        hasVatInfo ? SubscriptionTags.TAX : SubscriptionTags.NO_TAX,
      ),
    )
  }, [dispatch, hasVatInfo])

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(({ mainPrices }) =>
        createProductId({
          periodName: mainPrices.periodName,
          periodQuantity: mainPrices.periodQuantity,
          price: mainPrices.fullPrice,
        }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  useEffect(() => {
    if (viewportValue > ViewportScrollPercentage.V_0) {
      eventLogger.logScrollToBottom(viewportValue, ScreenName.ONBOARDING)
    }
  }, [viewportValue])

  if (!hasPrices) return <Spinner />

  return (
    <>
      {hasDynamicDiscountBanner && <DynamicDiscountBanner />}
      <div>
        <TimerProvider id={pageId} time={TEN_MINUTES_IN_SECONDS}>
          <S.DiscountTimer
            elemRef={headerTimerRef}
            onButtonClick={handleScrollOnSubscriptionBlock}
          />
          <div>
            <S.Wrapper>
              <RefundBanner variant="secondary" />
              <S.IntroMedia />
              <S.PersonalizedPlanV1 />
              <S.DiscountTitle>{t('purchase.discountTitle')}</S.DiscountTitle>
              {discountBlock}
              <S.SubscriptionsBlock
                hasTitle={false}
                ref={firstSubscriptionsRef}
              />
              <S.MoneyBackText>
                {t('purchase.moneyBackGuaranteeV3.description')}
              </S.MoneyBackText>
              <S.Button
                margin="0 auto 24px"
                width="100%"
                data-button-number="2"
                data-button-text={OnboardingButtonText.GET_PLAN_MIDDLE}
                theme={buttonTheme.NUTRIMATE_PRIMARY}
                onClick={handleGetPlan}
              >
                {buttonText || t('actions.getMyPlan')}
              </S.Button>
              <S.DisclaimerV1 />
              <RefundBanner />
              <S.HowItWorksWrap>
                <HowItWorks>
                  <S.Button
                    width="100%"
                    data-button-number="3"
                    data-button-text={OnboardingButtonText.GET_PLAN_MIDDLE}
                    theme={buttonTheme.NUTRIMATE_PRIMARY}
                    onClick={handleGetPlan}
                  >
                    {buttonText || t('actions.getMyPlan')}
                  </S.Button>
                </HowItWorks>
              </S.HowItWorksWrap>
              <S.MoneyBackGuaranteeV3 />
            </S.Wrapper>
            <S.VideoReviews title="kitPurchaseV1.videoReviewTitle" />
            <S.Wrapper>
              <S.WeightLossJourney title="kitPurchaseV1.weightLossJourneyTitle" />
              <S.AppReached>
                <S.Button
                  data-button-number="4"
                  data-button-text={OnboardingButtonText.GET_PLAN_MIDDLE_2}
                  margin="0 auto"
                  onClick={handleScrollOnSubscriptionBlock}
                  theme={buttonTheme.NUTRIMATE_PRIMARY}
                >
                  {buttonText || t('actions.getMyPlan')}
                </S.Button>
              </S.AppReached>
              <S.BoostResults />
              <CustomerReviewsV4 />
              <S.FeaturedBlock />
              <RefundBanner />
              <S.DiscountTitle>{t('purchase.discountTitle')}</S.DiscountTitle>
              {discountBlock}
              <S.SubscriptionsBlock hasTitle={false} />
              <S.MoneyBackText>
                {t('purchase.moneyBackGuaranteeV3.description')}
              </S.MoneyBackText>
              <S.Button
                margin="0 auto 24px"
                width="100%"
                data-button-number="5"
                data-button-text={OnboardingButtonText.GET_PLAN_BOTTOM}
                theme={buttonTheme.NUTRIMATE_PRIMARY}
                onClick={handleGetPlan}
              >
                {buttonText || t('actions.getMyPlan')}
              </S.Button>
              <S.DisclaimerV2 />
              <SecurityInfoV1 />
            </S.Wrapper>
          </div>
        </TimerProvider>
      </div>
    </>
  )
}
