import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { usePageInfo } from 'contexts/PageInfoProvider'

import { Container } from 'components/Container'
import { Spinner } from 'components/Spinner'

import {
  resetErrorAction,
  setSubscriptionListAction,
} from 'root-redux/actions/common'

import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useCommonStore } from 'hooks/useCommonStore'
import { usePaymentStore } from 'hooks/usePaymentStore'
import { useUserStore } from 'hooks/useUserStore'

import { createProductId } from 'helpers/createProductId'

import {
  CreditCardForm,
  PaymentRequestButton,
  PaymentWaitingModal,
  Separator,
  ThreeDSecureIframe,
} from 'modules/purchase/components'
import { PrimerPayPalButton } from 'modules/purchase/components/PrimerPayPalButton'
import { logGeneralCheckoutStartedEvents } from 'modules/purchase/helpers/logGeneralCheckoutStartedEvents'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'

import securitySystems from 'assets/images/security-systems.png'

import { goTo, replaceHistory } from 'browser-history'
import { PageId } from 'page-constants'
import { ScreenName } from 'root-constants'

import { StyledCheckoutV2 as S } from './CheckoutVariant2.styles'

export const CheckoutVariant2: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()
  const { currentSubscriptionPageId } = usePageInfo()

  const {
    fullPrice,
    periodName,
    periodQuantity,
    selectedSubscriptionId,
    threeDSecureIframeUrl,
    trialCurrentPrice,
  } = usePaymentStore()

  const {
    cohort,
    fetchingActionsList,
    screenName,
    stripeAccountId,
    stripeAccountName,
    subscriptionsList,
  } = useCommonStore()

  const { uuid } = useUserStore()

  const creditCardPaymentRef = useRef<HTMLDivElement>(null)

  useAmplitudeInitialization(cohort, ScreenName.CHECKOUT)

  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState(false)

  const productId = useMemo(
    () =>
      createProductId({
        periodName,
        periodQuantity,
        price: trialCurrentPrice || fullPrice,
      }),
    [periodName, periodQuantity, trialCurrentPrice, fullPrice],
  )

  const isCheckoutReady = useMemo(
    () => !fetchingActionsList.includes(CHECK_PAYMENT_REQUEST_BUTTON),
    [fetchingActionsList],
  )

  useEffect(() => {
    if (productId) {
      eventLogger.logPurchaseShown({
        productId,
        screenName,
        stripeAccountId,
        stripeAccountName,
      })
    }

    logGeneralCheckoutStartedEvents({ uuid, subscriptionsList })
  }, [
    productId,
    screenName,
    stripeAccountId,
    stripeAccountName,
    uuid,
    subscriptionsList,
  ])

  useEffect(() => {
    const { current: elem } = creditCardPaymentRef
    if (elem) {
      const yCoordinate = elem.getBoundingClientRect().top + window.scrollY
      window.scrollTo({ top: yCoordinate, behavior: 'smooth' })
    }
  }, [])

  useLayoutEffect(() => {
    if (selectedSubscriptionId) return

    replaceHistory({
      pathname: currentSubscriptionPageId,
      search,
    })
  }, [currentSubscriptionPageId, search, selectedSubscriptionId])

  const handleCloseCheckout = useCallback(() => {
    dispatch(resetErrorAction())
    dispatch(setSubscriptionListAction([]))

    eventLogger.logPurchaseScreenClosed({
      productId,
      screenName,
    })

    screenName === ScreenName.CANCEL_OFFER
      ? goTo({ pathname: PageId.CANCEL_OFFER_1, search })
      : goTo({ pathname: PageId.CANCEL_OFFER_QUESTION_1, search })
  }, [dispatch, productId, screenName, search])

  return (
    <Container>
      {threeDSecureIframeUrl ? (
        <ThreeDSecureIframe />
      ) : (
        <>
          <S.Wrapper isVisible={isCheckoutReady}>
            <S.Title>{t('purchase.checkoutV2.title')}</S.Title>
            <S.CloseButton onClick={handleCloseCheckout} />

            <S.PaymentContainer>
              <div ref={creditCardPaymentRef}>
                <S.CreditCardTitleV1 />
                {isCheckoutReady && <CreditCardForm />}
              </div>
              <Separator />
              <PrimerPayPalButton />
              <S.ButtonContainer>
                <PaymentRequestButton />
              </S.ButtonContainer>
              <S.Image src={securitySystems} alt="security-systems" />
              <S.Text>{t('purchase.checkout.moneyBackGuarantee')}</S.Text>
            </S.PaymentContainer>
          </S.Wrapper>
          <PaymentWaitingModal
            isPaymentWaitingShown={isPaymentWaitingShown}
            setIsPaymentWaitingShown={setIsPaymentWaitingShown}
          />
          {!isCheckoutReady && <Spinner />}
        </>
      )}
    </Container>
  )
}
