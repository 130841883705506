import styled from 'styled-components'

import { Color, Gradient } from 'root-constants'

export const StyledPersonalizedPlanTimer = {
  Container: styled.div`
    padding: 1px;
    border-radius: 16px;
    background: radial-gradient(${Gradient.RADIAL_RED_YELLOW});
  `,
  InfoBlock: styled.div`
    width: 100%;
    padding: 16px;
    background-color: #fff5e6;
    border-radius: 16px;
  `,
  Info: styled.p`
    font-weight: 700;
    font-size: 17px;
    line-height: 24px;
    text-align: center;
    color: ${Color.GREY_100};
  `,
  TimerBlock: styled.div`
    width: 100%;
    padding: 4px;
  `,
  TimerContent: styled.p`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: ${Color.WHITE};
  `,
}
