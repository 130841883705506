import styled from 'styled-components'

import check from 'assets/images/kit-check-icon.svg'

import { Color } from 'root-constants'

export const StyledUpsellBenefits = {
  Container: styled.div`
    width: 100%;
    background-color: ${Color.WHITE};
    border: 1px solid ${Color.GREY_800};
    border-radius: 16px;
    padding: 24px 16px;
  `,
  Title: styled.h2`
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
    color: ${Color.GREY_100};
  `,

  List: styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `,
  ListItem: styled.li`
    position: relative;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    padding: 0 0 0 32px;

    &::before {
      content: url(${check});
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
    }

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  `,
}
