import React from 'react'
import { Trans } from 'react-i18next'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'

type TProps = {
  value?: string
  i18nKey?: string
  currency: string
}

export const PriceValue: React.FC<TProps> = ({
  value,
  currency,
  i18nKey = 'commonComponents.priceFormat.price',
}) => (
  <Trans
    i18nKey={i18nKey}
    values={{
      price: value,
      currency: CURRENCY_SYMBOLS[currency],
    }}
    context={currency}
  />
)
