import styled, { css } from 'styled-components'

import checkIcon from 'assets/images/check-white-v2.svg'

import { Color } from 'root-constants'

type TProps = {
  isSelected?: boolean
}

const commonPriceStyles = css`
  color: ${Color.GREY_101};
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  white-space: nowrap;
`

export const StyledKitSubscriptionItemV1 = {
  PlanContainer: styled.div<TProps>`
    align-items: center;
    background-color: ${Color.WHITE};
    border: 1px solid;
    border-color: ${({ isSelected }) =>
      isSelected ? Color.GREEN_300 : Color.GREY_800};
    border-radius: 20px;
    column-gap: 4px;
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 16px 44px;
    position: relative;

    &::before {
      border: 2px solid;
      border-color: ${({ isSelected }) =>
        isSelected ? Color.GREEN_300 : Color.GREY_WHITE};
      border-radius: 50%;
      content: '';
      height: 20px;
      left: 16px;
      mix-blend-mode: normal;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;

      ${({ isSelected }) =>
        isSelected &&
        `
          background-color: ${Color.GREEN_300};
          background-image: url(${checkIcon});
          background-position: center center;
          background-repeat: no-repeat;
        `};
    }
  `,
  PopularBadge: styled.div<TProps>`
    background-color: ${({ isSelected }) =>
      isSelected ? Color.GREEN_300 : Color.GREY_1000};
    border-radius: 24px;
    color: ${Color.WHITE};
    font-size: 10px;
    font-weight: 700;
    left: 50%;
    letter-spacing: 1px;
    line-height: 12px;
    padding: 4px 12px;
    position: absolute;
    text-transform: uppercase;
    top: -10px;
    transform: translateX(-50%);
  `,
  PlanColumn: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:first-of-type {
      flex-grow: 1;
    }
  `,
  PlanPeriod: styled.span<TProps>`
    color: ${({ isSelected }) =>
      isSelected ? Color.GREY_100 : Color.GREY_101};
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
    text-transform: uppercase;
    white-space: nowrap;
  `,
  PlanPrice: styled.div`
    column-gap: 8px;
    display: flex;
    align-items: flex-end;
  `,
  PreviousPrice: styled.span`
    ${commonPriceStyles};
    color: ${Color.GREY_101};
    position: relative;
    text-decoration: line-through;
    text-decoration-color: ${Color.RED_300};
  `,
  CurrentPrice: styled.span`
    ${commonPriceStyles};
    flex-grow: 1;
  `,
  DayPriceContainer: styled.div<TProps>`
    color: ${({ isSelected }) => (isSelected ? Color.WHITE : Color.GREY_101)};
    display: flex;
    flex-direction: column;
    padding: 6px 14px 8px 26px;
  `,
  CurrentDayPrice: styled.span`
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    white-space: nowrap;
  `,
  PreviousDayPrice: styled.span`
    ${commonPriceStyles};
    font-size: 17px;
    line-height: 24px;
    text-decoration: line-through;
    text-decoration-color: ${Color.RED_300};
  `,
  DayPricePeriod: styled.span`
    font-size: 8px;
    font-weight: 600;
    line-height: 12px;
  `,
}
