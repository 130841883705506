import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { GAConsentValue } from 'value-constants'

import {
  selectCookieConsentCountries,
  selectGoogleAnalyticsId,
} from 'root-redux/selects/common'
import { selectUserCountryCode } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'

export const useGoogleAnalytics = (): void => {
  const googleAnalyticsId = useSelector(selectGoogleAnalyticsId)
  const userCountryCode = useSelector(selectUserCountryCode)
  const cookieConsentCountries = useSelector(selectCookieConsentCountries)

  const {
    isEUUser,
    cookieConsent,
    hasAdvertisingAndTargetingCookie,
    hasFunctionalCookie,
    hasAnalyticsAndPerformanceCookie,
  } = useCookieConsentAnswer()

  const shouldGABePaused = useMemo(
    () => isEUUser && !hasAnalyticsAndPerformanceCookie,
    [hasAnalyticsAndPerformanceCookie, isEUUser],
  )

  const setDefaultConsentOptions = useCallback(() => {
    if (!isEUUser) return

    window.gtag('consent', 'default', {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'granted',
      region: cookieConsentCountries,
    })
  }, [cookieConsentCountries, isEUUser])

  useEffect(() => {
    if (!googleAnalyticsId || !userCountryCode || shouldGABePaused) return

    const initGtag = () => {
      const script = document.createElement('script')
      script.text = `window.dataLayer = window.dataLayer || [];
        gtag = function () { dataLayer.push(arguments); }
        gtag('js', new Date());`
      script.async = true
      document.head.appendChild(script)
    }

    const setUpGTM = () => {
      const script = document.createElement('script')
      script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`
      script.async = true
      document.head.appendChild(script)
    }

    initGtag()
    setUpGTM()
    setDefaultConsentOptions()

    window.gtag('config', googleAnalyticsId, {
      send_page_view: false,
    })
  }, [
    googleAnalyticsId,
    setDefaultConsentOptions,
    shouldGABePaused,
    userCountryCode,
  ])

  useEffect(() => {
    if (!isEUUser || !cookieConsent?.length || !window.gtag) return

    window.gtag('consent', 'update', {
      ad_user_data: hasAdvertisingAndTargetingCookie
        ? GAConsentValue.GRANTED
        : GAConsentValue.DENIED,
      ad_personalization:
        hasAdvertisingAndTargetingCookie && hasFunctionalCookie
          ? GAConsentValue.GRANTED
          : GAConsentValue.DENIED,
      ad_storage:
        hasAdvertisingAndTargetingCookie && hasFunctionalCookie
          ? GAConsentValue.GRANTED
          : GAConsentValue.DENIED,
      region: cookieConsentCountries,
    })
  }, [
    cookieConsent?.length,
    cookieConsentCountries,
    hasAdvertisingAndTargetingCookie,
    hasFunctionalCookie,
    isEUUser,
  ])
}
