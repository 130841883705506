import React, { useEffect, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { usePageInfo } from 'contexts/PageInfoProvider'
import { Button, buttonTheme } from 'storybook-ui'

import { KitPageContainer } from 'components/PageContainer'
import { Spinner } from 'components/Spinner'

import {
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
  setSubscriptionListAction,
} from 'root-redux/actions/common'
import {
  selectActionList,
  selectSubscriptionList,
} from 'root-redux/selects/common'
import { selectUserUpsellPages } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store'

import {
  UpsellGuides,
  UpsellSubscriptionBlock,
} from 'modules/purchase/components'
import { NUMBER_OF_SINGLE_SUBSCRIPTION } from 'modules/purchase/components/UpsellSubscriptionBlock/constants'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import {
  MAKE_UPSELL,
  createInAppAction,
  setSelectedUpsellProduct,
  setUpsellListInConfig,
  setUpsellSwitcherState,
} from 'modules/purchase/redux/actions/upsell'
import { selectSelectedUpsellProduct } from 'modules/purchase/redux/selects'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import {
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
  UpsellProduct,
} from 'root-constants'

import { StyledUpsellPaywallV1 as S } from './UpsellPaywallV1.styles'

export const UpsellPaywallV1: React.FC<TPageProps> = () => {
  const dispatch: TAppDispatch = useDispatch()
  const { t } = useTranslation()

  const { currentPageId } = usePageInfo()

  const subscriptions = useSelector(selectSubscriptionList)
  const selectedUpsellProducts = useSelector(selectSelectedUpsellProduct)
  const fetchingActionsList = useSelector(selectActionList)
  const upsellPages = useSelector(selectUserUpsellPages)

  const arePricesReady = (() =>
    !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST))()

  const isUpsellInProgress = (() =>
    fetchingActionsList?.includes(MAKE_UPSELL))()

  const fitnessAndSelfCare = subscriptions.find(
    (subscription) => subscription.product === UpsellProduct.BOTH,
  )

  const isSingleSubscriptionSelected =
    selectedUpsellProducts.length === NUMBER_OF_SINGLE_SUBSCRIPTION

  useLayoutEffect(() => {
    const tags = [
      SubscriptionTags.NO_TAX,
      SubscriptionTags.INAPP_FITNESS_SELFCARE,
    ].join(',')

    dispatch(getSubscriptionListAction(SubscriptionListType.ONE_TIME, tags))
  }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch(setSubscriptionListAction([]))
      dispatch(setSelectedUpsellProduct([]))
    }
  }, [dispatch])

  const createInApp = (screenName: ScreenName) => {
    dispatch(createInAppAction(screenName, currentPageId))
  }

  const handleContinueWithAll = () => {
    dispatch(setUpsellSwitcherState(true))
    fitnessAndSelfCare &&
      dispatch(setSelectedSubscriptionAction(fitnessAndSelfCare))
    createInApp(ScreenName.UPSELL_SWITCHER)
  }

  const { continueButtonText, handleContinueFlow } = (() => {
    if (isSingleSubscriptionSelected) {
      return {
        continueButtonText: t('upsell.addToMyProgram'),
        handleContinueFlow: () => createInApp(ScreenName.UPSELL_SWITCHER),
      }
    }

    return {
      continueButtonText: t('upsell.addAllToMyProgram'),
      handleContinueFlow: handleContinueWithAll,
    }
  })()

  const handleSkipButton = () => {
    eventLogger.logUpsellPurchaseClose(ScreenName.UPSELL_SWITCHER)

    const restUpsellPages = upsellPages.filter((page) => page !== currentPageId)
    dispatch(setUpsellListInConfig(restUpsellPages))
  }

  useEffect(() => {
    eventLogger.logUpsellPurchaseShown(ScreenName.UPSELL_SWITCHER)
  }, [dispatch])

  return (
    <>
      <KitPageContainer paddingTop={0}>
        <S.Title>{t('upsell.title')}</S.Title>
        <S.Subtitle>{t('upsell.subTitle')}</S.Subtitle>
        <UpsellSubscriptionBlock
          makeUpsell={() => createInApp(ScreenName.UPSELL_SWITCHER)}
          makeUpsellWithAll={handleContinueWithAll}
          skip={handleSkipButton}
        />
        <S.UpsellBenefits />
        <UpsellGuides />
        <S.ButtonContainer>
          <Button
            width="100%"
            type="submit"
            margin="0 auto 24px"
            theme={buttonTheme.NUTRIMATE_PRIMARY}
            onClick={handleContinueFlow}
          >
            {continueButtonText}
          </Button>
          <S.SkipButton onClick={handleSkipButton}>
            {t('upsell.noThanksLink')}
          </S.SkipButton>
        </S.ButtonContainer>
      </KitPageContainer>
      {(isUpsellInProgress || !arePricesReady) && <Spinner />}
    </>
  )
}
