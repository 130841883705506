import React, {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'

import { InputWithFloatPlaceholder } from 'storybook-ui'

import { IInputFieldState } from 'models/inputField.model'

import checkIcon from 'assets/images/kit-check-icon.svg'

import { Color } from 'root-constants'

type TProps = {
  email: IInputFieldState
  setEmail: Dispatch<SetStateAction<IInputFieldState>>
  password: IInputFieldState
  setPassword: Dispatch<SetStateAction<IInputFieldState>>
}

export const EmailLogin: React.FC<TProps> = ({
  email,
  setEmail,
  password,
  setPassword,
}) => {
  const { t } = useTranslation()

  const handleChange = useCallback(
    (
      stateCallback: Dispatch<SetStateAction<IInputFieldState>>,
      newData: Partial<IInputFieldState>,
    ) => {
      stateCallback((prevState) => ({ ...prevState, ...newData }))
    },
    [],
  )

  const handleEmailChange = useCallback(
    (e: SyntheticEvent<HTMLInputElement>) => {
      handleChange(setEmail, {
        value: (e.target as HTMLInputElement).value.trim(),
      })
    },
    [setEmail, handleChange],
  )

  const handlePasswordChange = useCallback(
    (e: SyntheticEvent<HTMLInputElement>) => {
      handleChange(setPassword, {
        value: (e.target as HTMLInputElement).value,
      })
    },
    [setPassword, handleChange],
  )

  const passwordInputBorder = useMemo(
    () =>
      password.isValid
        ? `1px solid ${Color.GREY_102}`
        : `1px solid ${Color.RED_300}`,
    [password],
  )

  return (
    <>
      <InputWithFloatPlaceholder
        width="100%"
        padding="30px 40px 10px 16px"
        marginBottom={24}
        borderRadius="16px"
        border="1px solid #A9A9A9"
        focusedBorderColor="#A9A9A9"
        backgroundColor={Color.WHITE}
        color={Color.BLACK_100}
        fontSize="17px"
        fontWeight="400"
        lineHeight="24px"
        labelColor={Color.GREY_101}
        focusedLabelColor={Color.GREY_101}
        focusedLabelFontSize="14px"
        value={email.value}
        maxLength={50}
        isValid={email.isValid}
        label={t('login.emailPlaceholder')}
        shortLabel={t('login.shortEmailPlaceholder')}
        hasValidationIcon
        checkIconSvg={checkIcon}
        onChange={handleEmailChange}
        onFocus={() => handleChange(setEmail, { isFocused: true })}
        onBlur={() => handleChange(setEmail, { isFocused: false })}
      />
      <InputWithFloatPlaceholder
        width="100%"
        padding="30px 16px 10px"
        marginBottom={24}
        borderRadius="16px"
        border={passwordInputBorder}
        focusedBorderColor="#A9A9A9"
        backgroundColor={Color.WHITE}
        color={Color.BLACK_100}
        fontSize="17px"
        fontWeight="400"
        lineHeight="24px"
        labelColor={Color.GREY_101}
        focusedLabelColor={Color.GREY_101}
        focusedLabelFontSize="14px"
        value={password.value}
        isValid={password.isValid}
        label={t('login.passwordPlaceholder')}
        type="password"
        hasValidationIcon
        checkIconSvg={checkIcon}
        onChange={handlePasswordChange}
        onFocus={() => handleChange(setPassword, { isFocused: true })}
        onBlur={() => handleChange(setPassword, { isFocused: false })}
      />
    </>
  )
}
