import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectCurrentVariantCohortToUse,
  selectDynamicDiscount,
  selectScreenName,
} from 'root-redux/selects/common'

import { useVatInfo } from 'hooks/useHasVatInfo'
import { usePaymentStore } from 'hooks/usePaymentStore'

import { StyledCheckoutSummaryV2 as S } from 'modules/purchase/components/CheckoutSummaryV2/CheckoutSummaryV2.styles'
import { CURRENCY_SYMBOLS, PurchaseProduct } from 'modules/purchase/constants'
import { isComplexCurrencySymbol } from 'modules/purchase/helpers/currency'
import { getPriceBeforeDiscount } from 'modules/purchase/helpers/getRecalculatedPrice'

import {
  Cohort,
  Color,
  DEFAULT_DYNAMIC_DISCOUNT_AMOUNT,
  DEFAULT_DYNAMIC_DISCOUNT_CHECKOUT,
  ScreenName,
} from 'root-constants'

type TProps = {
  className?: string
}
export const TrialCheckoutSummaryV2: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const screenName = useSelector(selectScreenName)

  const {
    currency,
    fullPrice,
    trialCurrentPrice,
    trialPriceBeforeDiscount,
    product,
    discountAmount,
    paywallDiscountPercentage,
  } = usePaymentStore()

  const hasVatInfo = useVatInfo()

  const hasDynamicDiscount = useMemo(
    () =>
      cohortToUse === Cohort.MF_INTRO_SALE &&
      screenName !== ScreenName.CANCEL_OFFER,
    [cohortToUse, screenName],
  )

  const discount = useMemo(() => {
    if (hasDynamicDiscount) {
      return dynamicDiscount
        ? dynamicDiscount?.amount
        : DEFAULT_DYNAMIC_DISCOUNT_AMOUNT
    }

    return paywallDiscountPercentage
  }, [paywallDiscountPercentage, dynamicDiscount, hasDynamicDiscount])

  const priceBeforeDiscount = useMemo(() => {
    if (!hasDynamicDiscount) {
      return product === PurchaseProduct.SEVEN_DAY
        ? trialPriceBeforeDiscount.toFixed(2)
        : fullPrice.toFixed(2)
    }

    return product === PurchaseProduct.SEVEN_DAY
      ? getPriceBeforeDiscount(
          Number(trialPriceBeforeDiscount.toFixed(2)),
          discount,
        )
      : getPriceBeforeDiscount(Number(trialCurrentPrice.toFixed(2)), discount)
  }, [
    discount,
    fullPrice,
    hasDynamicDiscount,
    product,
    trialCurrentPrice,
    trialPriceBeforeDiscount,
  ])

  const discountLabel = useMemo(() => {
    if (hasDynamicDiscount) {
      return `${discount}% ${
        dynamicDiscount
          ? dynamicDiscount?.checkout
          : t(DEFAULT_DYNAMIC_DISCOUNT_CHECKOUT)
      }`
    }

    return t('checkout.checkoutSummaryV2.discountLabel', { value: discount })
  }, [discount, dynamicDiscount, hasDynamicDiscount, t])

  const savedMoneyAmount = useMemo(() => {
    if (hasDynamicDiscount) {
      return (Number(priceBeforeDiscount) - trialCurrentPrice).toFixed(2)
    }

    return discountAmount
  }, [
    discountAmount,
    hasDynamicDiscount,
    priceBeforeDiscount,
    trialCurrentPrice,
  ])

  return (
    <div className={className}>
      <S.DescriptionContainer>
        <S.PlanLabel>{t('checkout.checkoutSummaryV2.planLabel')}</S.PlanLabel>
        <S.PlanPrice>
          {t('checkout.checkoutSummaryV2.fullPrice', {
            currency: CURRENCY_SYMBOLS[currency],
            price: priceBeforeDiscount,
          })}
        </S.PlanPrice>
      </S.DescriptionContainer>
      <S.DescriptionContainer>
        <S.PlanLabel>{discountLabel}</S.PlanLabel>
        <S.PlanPrice color={Color.GREEN_300}>
          -{CURRENCY_SYMBOLS[currency]}
          {savedMoneyAmount}
        </S.PlanPrice>
      </S.DescriptionContainer>
      <S.TotalContainer isComplex={isComplexCurrencySymbol(currency)}>
        <S.TotalLabel>
          {t('checkout.checkoutSummaryV2.totalLabel', {
            includingVat: hasVatInfo ? t('purchase.includingVat') : '',
          })}
        </S.TotalLabel>
        <S.TotalPrice>
          <Trans
            i18nKey="checkout.checkoutSummaryV2.totalPrice"
            values={{
              currency: CURRENCY_SYMBOLS[currency],
              price: trialCurrentPrice.toFixed(2),
            }}
            components={{ span: <span /> }}
          />
        </S.TotalPrice>
      </S.TotalContainer>
      <S.SaveContainer>
        <Trans
          i18nKey="checkout.checkoutSummaryV2.saveMoney"
          values={{
            currency: CURRENCY_SYMBOLS[currency],
            price: savedMoneyAmount,
            discount,
          }}
        />
      </S.SaveContainer>
    </div>
  )
}
