import { COMPLEX_CURRENCY_CN } from 'modules/purchase/components/KitSubscriptionItem/constants'
import { PurchaseProduct } from 'modules/purchase/constants'
import { isComplexCurrencySymbol } from 'modules/purchase/helpers/currency'
import { useSubscriptionPeriodName } from 'modules/purchase/hooks/useSubscriptionPeriodName'
import { TSubscriptionItemProps } from 'modules/purchase/types'

const formatPrice = (price?: number) => price?.toFixed(2)

export const useSubscriptionItem = (
  subscription: TSubscriptionItemProps['subscription'],
) => {
  const { product, mainPrices, trialPrices, currency } = subscription
  const { periodName } = useSubscriptionPeriodName(subscription)

  const isSevenDay = product === PurchaseProduct.SEVEN_DAY
  const isSubscriptionTrial = trialPrices.fullPrice !== mainPrices.fullPrice
  const isComplexCurrency = isComplexCurrencySymbol(currency)

  const className = isComplexCurrency ? COMPLEX_CURRENCY_CN : ''
  const noTrialCurrentPrice = formatPrice(mainPrices.fullPrice)
  const trialCurrentPrice = formatPrice(trialPrices.fullPrice)
  const oldPrice = formatPrice(
    isSevenDay
      ? trialPrices.oldPrices.beforeCustomDiscount?.fullPrice
      : mainPrices.fullPrice,
  )
  const pricePerDay = formatPrice(
    isSevenDay ? mainPrices.daily : trialPrices.daily,
  )
  const oldPricePerDay = formatPrice(
    trialPrices.oldPrices.beforeCustomDiscount?.daily,
  )

  return {
    periodName,
    isSubscriptionTrial,
    className,
    noTrialCurrentPrice,
    trialCurrentPrice,
    oldPrice,
    pricePerDay,
    oldPricePerDay,
    isComplexCurrency,
  }
}
