import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { PaymentRequest } from '@stripe/stripe-js/types/stripe-js/payment-request'

import { resetErrorAction, stopFetching } from 'root-redux/actions/common'
import { selectLanguage } from 'root-redux/selects/common'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { usePaymentStore } from 'hooks/usePaymentStore'

import { Separator } from 'modules/purchase/components/Separator'
import {
  LANGUAGE_STRIPE_LOCALE_MAP,
  PaymentMethod,
  PurchaseProduct,
} from 'modules/purchase/constants'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  purchaseAction,
  setOneClickPaymentMethodAction,
  setPaymentMethodAction,
} from 'modules/purchase/redux/actions/common'
import { selectSubscriptionPeriodQuantity } from 'modules/purchase/redux/selects'
import { TPaymentRequestButton } from 'modules/purchase/types'

import { eventLogger } from 'services/eventLogger.service'

import { CENTS_IN_DOLLAR } from 'root-constants'

import { StyledPaymentRequestButton as S } from './PaymentRequestButton.styles'

export const PaymentRequestButton: React.FC = () => {
  const stripe = useStripe()
  const elements = useElements()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { currentPageId } = useGetPageInfo()

  const language = useSelector(selectLanguage)
  const mainPriceDuration = useSelector(selectSubscriptionPeriodQuantity)

  const {
    currency,
    periodQuantity,
    periodName,
    fullPrice,
    trialCurrentPrice,
    product,
    trialPeriodDays,
  } = usePaymentStore()

  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null,
  )
  const [buttonTypes, setButtonTypes] = useState<TPaymentRequestButton | null>(
    null,
  )

  const planPeriodDescription = useMemo(
    () =>
      t('purchase.checkoutV1.planDescription', {
        periodQuantity,
        periodName,
      }),
    [t, periodName, periodQuantity],
  )

  const calculatedPrice = useMemo(
    () => +((trialCurrentPrice || fullPrice) * CENTS_IN_DOLLAR).toFixed(),
    [trialCurrentPrice, fullPrice],
  )

  const label = useMemo(
    () =>
      product === PurchaseProduct.SEVEN_DAY
        ? t('purchase.subscription.weeklyPeriod', {
            periodQuantity: trialPeriodDays,
          })
        : t('purchase.subscription.monthlyPeriod', {
            periodQuantity: mainPriceDuration,
            count: mainPriceDuration,
          }),
    [t, product, trialPeriodDays, mainPriceDuration],
  )

  useEffect(() => {
    if (!stripe || !elements) {
      return
    }

    const pr = stripe?.paymentRequest({
      currency,
      country: LANGUAGE_STRIPE_LOCALE_MAP[language],
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        label: label.toLowerCase(),
        amount: calculatedPrice,
      },
    })

    pr?.canMakePayment().then((result) => {
      if (result) {
        setButtonTypes(result as TPaymentRequestButton)
        setPaymentRequest(pr)
      }
      dispatch(stopFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    })

    pr?.on('paymentmethod', (event) => {
      dispatch(resetErrorAction())
      dispatch(
        purchaseAction({
          stripe,
          paymentPageId: currentPageId,
          createPaymentResFromDigitalWallet: event,
        }),
      )
    })
  }, [
    dispatch,
    currency,
    stripe,
    elements,
    planPeriodDescription,
    calculatedPrice,
    currentPageId,
    language,
    label,
  ])

  const handleButtonClick = useCallback(() => {
    const shownButtonType = buttonTypes?.applePay
      ? PaymentMethod.APPLE_PAY
      : PaymentMethod.GOOGLE_PAY

    dispatch(setPaymentMethodAction(shownButtonType))
    dispatch(setOneClickPaymentMethodAction(shownButtonType))
    eventLogger.logPaymentMethodSelected(shownButtonType)
  }, [buttonTypes, dispatch])

  if (paymentRequest) {
    return (
      <S.Wrapper>
        <Separator />
        <PaymentRequestButtonElement
          onClick={handleButtonClick}
          options={{
            paymentRequest,
            style: {
              paymentRequestButton: {
                height: '56px',
              },
            },
          }}
        />
      </S.Wrapper>
    )
  }

  return null
}
