import React from 'react'
import { Trans } from 'react-i18next'

import refundIconPrimary from 'assets/images/refund-icon-primary.png'
import refundIconSecondary from 'assets/images/refund-icon-secondary.png'

import { StyledRefundBanner as S } from './RefundBanner.styles'

type TRefundBannerProps = {
  variant?: 'primary' | 'secondary'
}

export const RefundBanner = ({ variant = 'primary' }: TRefundBannerProps) => {
  const icon = {
    primary: refundIconPrimary,
    secondary: refundIconSecondary,
  } as const

  return (
    <S.Wrapper className={variant}>
      <S.Icon src={icon[variant]} alt="icon" />
      <S.Text>
        <Trans i18nKey="purchase.refund.banner" />
      </S.Text>
    </S.Wrapper>
  )
}
