import styled, { css } from 'styled-components'

import { Color, HEADER_HEIGHT_WITHOUT_PROGRESSBAR } from 'root-constants'

type TMenu = {
  isMenuVisible: boolean
}

const progressValueStyles = css`
  background-color: ${Color.GREEN_400};
  transition: all 0.2s ease-out;
`

const skipButtonStyles = css`
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: none;
  color: ${Color.PRIMARY};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  right: 16px;
  text-transform: uppercase;
  top: 50%;
  transform: translateY(-50%);
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  img {
    height: 20px;
    width: 20px;
  }
`

export const StyledHeaderWithBurger = {
  Wrapper: styled.header`
    align-items: center;
    background-color: ${Color.WHITE};
    box-shadow: 0 -0.5px 0 0 rgba(0, 0, 0, 0.25) inset;
    display: flex;
    height: ${HEADER_HEIGHT_WITHOUT_PROGRESSBAR}px;
    justify-content: center;
    padding: 0 16px;
    position: relative;
    width: 100%;
  `,
  LogoContainer: styled.div`
    align-items: center;
    column-gap: 12px;
    display: flex;
  `,
  Logo: styled.div`
    aspect-ratio: 1 / 1;
    width: 24px;
  `,
  LogoText: styled.div`
    color: ${Color.BLACK_100};
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
  `,
  BottomBorder: styled.div`
    background: ${Color.LIGHT};
    height: 1px;
    width: 100%;
  `,
  BackButton: styled.button`
    align-items: center;
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    left: 16px;
    margin: 0;
    outline: none;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  `,
  HeaderActions: styled.div`
    align-items: center;
    display: flex;
    margin-left: 4px;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  `,
  Burger: styled.button`
    align-self: center;
    background: transparent;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 14px;
    justify-content: space-between;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 16px;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  `,
  BurgerLine: styled.span`
    background-color: ${Color.BLACK_100};
    border-radius: 14px;
    display: block;
    height: 2px;
    width: 100%;
  `,
  Backdrop: styled.div<TMenu>`
    backdrop-filter: blur(8px);
    background: rgba(0, 0, 0, 0.1);
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: ${({ isMenuVisible }) => (isMenuVisible ? '1' : '0')};
    pointer-events: ${({ isMenuVisible }) =>
      isMenuVisible ? 'default' : 'none'};
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 0.25s;
    width: 100%;
    z-index: ${({ isMenuVisible }) => (isMenuVisible ? '3' : '-1')};
  `,
  Menu: styled.nav<TMenu>`
    background-color: ${Color.WHITE};
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 88px 24px;
    position: absolute;
    right: 0;
    row-gap: 24px;
    top: 0;
    transform: ${({ isMenuVisible }) =>
      isMenuVisible ? 'translateX(0)' : 'translateX(100%)'};
    transition: transform 0.25s;
    width: 247px;
    z-index: 4;
  `,
  MenuCloseButton: styled.button`
    appearance: none;
    background-color: ${Color.GREY_130};
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: 40px;
    left: 24px;
    outline: none;
    padding: 0;
    position: absolute;
    top: 16px;
    width: 40px;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    &::after,
    &::before {
      background-color: ${Color.BLACK_100};
      content: '';
      display: block;
      height: 2px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      width: 20px;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  `,
  StyledMenuLinkContainer: styled.div`
    color: ${Color.BLACK_200};
    display: flex;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;

    a {
      color: inherit;
    }
  `,
  SkipButton: styled.button`
    ${skipButtonStyles};
  `,
}

export const StyledHeader = {
  Wrapper: styled.header`
    align-items: center;
    background-color: ${Color.WHITE};
    box-shadow: 0 -0.5px 0 0 #00000040 inset;
    padding: 10px 8px;
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
  `,
  BackButton: styled.button`
    align-items: center;
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    left: 8px;
    margin: 0;
    outline: none;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  `,
  CategoryBlock: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Title: styled.h4`
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: ${Color.BLACK_100};
    margin: 0 0 8px 0;
  `,

  ProgressContainer: styled.div`
    display: flex;
    justify-content: center;
    column-gap: 8px;
  `,
  Progress: styled.progress`
    width: 58px;
    height: 4px;
    appearance: none;
    background-color: ${Color.GREY_800};
    border-radius: 4px;
    overflow: hidden;
    border: none;
    display: block;

    &::-webkit-progress-bar {
      background-color: ${Color.GREY_800};
    }
    &::-webkit-progress-value {
      ${progressValueStyles}
    }
    &::-moz-progress-bar {
      ${progressValueStyles}
    }
    &::-ms-fill {
      ${progressValueStyles}
    }
  `,
  SkipButton: styled.button`
    ${skipButtonStyles};
  `,
}
