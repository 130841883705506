import React from 'react'
import { useTranslation } from 'react-i18next'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { CDN_FOLDER_LINK, Language } from 'root-constants'

import { StyledMealsV1 as S } from './MealsV1.styles'
import { IMG_SRC } from './constants'

export const MealsV1: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.kitMealsV1.title', {
      lng: Language.EN,
    }),
    nextPagePath,
  })

  return (
    <KitPageContainer
      hasContinueButton
      onContinueButtonClick={() => handleContinue('')}
    >
      <S.ImageContainer>
        <img src={`${CDN_FOLDER_LINK}${IMG_SRC}.png`} alt="meals" />
      </S.ImageContainer>
      <PageTitle textAlign="left" marginBottom={16}>
        {t('onboarding.kitMealsV1.title')}
      </PageTitle>
      <S.Text>{t('onboarding.kitMealsV1.info')}</S.Text>
    </KitPageContainer>
  )
}
