import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import bannerMen from 'assets/images/commit-men.png'
import bannerWomen from 'assets/images/commit-women.png'

import { Gender, Language } from 'root-constants'

import { StyledKitCommitV1 as S } from './KitCommitV1.styles'

export const KitCommitV1: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const gender = useSelector(selectCurrentUserGender)

  const imgSrc = useMemo(
    () => (gender === Gender.MALE ? bannerMen : bannerWomen),
    [gender],
  )

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.supportV1.title', { lng: Language.EN }),
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <KitPageContainer
      hasContinueButton
      continueButtonContent={t('actions.commitToTry')}
      onContinueButtonClick={handleNextClick}
    >
      <S.Banner>
        <img src={imgSrc} alt="commit-banner" />
      </S.Banner>
      <PageTitle marginBottom={16} textAlign="left">
        {t('onboarding.kitCommitV1.title')}
      </PageTitle>
      <S.Info>{t('onboarding.kitCommitV1.info')}</S.Info>
    </KitPageContainer>
  )
}
