import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useLocation } from 'react-router-dom'

import { usePageInfo } from 'contexts/PageInfoProvider'

import {
  selectCurrentVariantCohort,
  selectLanguage,
} from 'root-redux/selects/common'
import {
  selectTestEnvironmentQueryParam,
  selectUUID,
} from 'root-redux/selects/user'

import { useGenderCohortInitialization } from 'hooks/useGenderCohortInitialization'

import { getPathFromPageId } from 'helpers/getPathFromPageId'

import { goTo } from 'browser-history'

export const OnboardingFlow: React.FC = () => {
  const { search } = useLocation()

  const cohort = useSelector(selectCurrentVariantCohort)
  const uuid = useSelector(selectUUID)
  const testEnvironmentQueryParam = useSelector(selectTestEnvironmentQueryParam)
  const language = useSelector(selectLanguage)

  const { steps } = usePageInfo()

  useGenderCohortInitialization()

  const firstPagePath = useMemo(
    () =>
      getPathFromPageId({
        pageId: steps[0].id,
        cohort,
        uuid,
        testEnvironmentQueryParam,
        language,
        currentSearch: search,
      }),
    [steps, cohort, uuid, testEnvironmentQueryParam, search],
  )

  useEffect(() => {
    const URLParams = new URLSearchParams(search)
    const uuidFromUrl = URLParams.get('uuid')

    if (!uuidFromUrl) {
      goTo(firstPagePath)
    }
  }, [search, firstPagePath])

  return <Outlet />
}
