import { useEffect } from 'react'
import { registerLocale } from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'

import 'dayjs/locale/es'
import 'dayjs/locale/pt'

import { setLanguageAction } from 'root-redux/actions/common'
import { selectIsInContextShown } from 'root-redux/selects/common'

import { getCurrentEnvironment } from 'helpers/getCurrentEnvironment'

import {
  DATEPICKER_SUPPORTED_LOCALES,
  INITIAL_LANGUAGE,
  LANG_QUERY_PARAM,
  Language,
  SUPPORTED_LOCALES,
} from 'root-constants'

export const useLanguage = (): void => {
  const dispatch = useDispatch()
  const isInContextShown = useSelector(selectIsInContextShown)
  const { isDevEnvironment } = getCurrentEnvironment()

  const isInContextActive = isDevEnvironment && isInContextShown

  useEffect(() => {
    const langQueryParam =
      new URL(window.location.href).searchParams.get(LANG_QUERY_PARAM) || ''
    const isSupportedLang = SUPPORTED_LOCALES.includes(langQueryParam)
    const langValue = isSupportedLang ? langQueryParam : INITIAL_LANGUAGE
    const newLangValue = isInContextActive ? Language.IN_CONTEXT : langValue

    dispatch(setLanguageAction(newLangValue))
    registerLocale(langValue, DATEPICKER_SUPPORTED_LOCALES[langValue])
  }, [dispatch, isInContextActive])
}
