import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectUserMeasurementSystem } from 'root-redux/selects/common'

import { MeasurementSystem } from 'root-constants'

export const useWeightMeasurementSystem = () => {
  const { t } = useTranslation()
  const measurementSystem = useSelector(selectUserMeasurementSystem)
  const isMetricSelected = measurementSystem === MeasurementSystem.METRIC
  const [kgLabel, lbsLabel] = [
    t(`commonComponents.kg`),
    t(`commonComponents.lbs`),
  ]
  const measuringSystemLabel = isMetricSelected ? kgLabel : lbsLabel

  return {
    measuringSystemLabel,
    isMetricSelected,
    measurementSystem,
  }
}
