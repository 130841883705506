import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledBoostResults = {
  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: ${Color.GREY_100};
    margin: 0 0 16px 0;
  `,
  Subtitle: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${Color.GREY_101};
    margin: 0 0 16px 0;
  `,
  GoalsTitle: styled.h4`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${Color.GREY_100};
    margin: 0 0 16px 0;
  `,
  Goals: styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
      'item item'
      'item item';
    grid-gap: 8px;
  `,
  GoalItem: styled.div<{ icon: string }>`
    grid-area: auto;
    background-color: rgba(255, 153, 0, 0.1);
    border-radius: 16px;
    padding: 68px 16px 16px 16px;
    position: relative;

    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: ${Color.GREY_101};

    &:before {
      position: absolute;
      top: 16px;
      content: url(${({ icon }) => icon});
      width: 40px;
      height: 40px;
    }
  `,
}
