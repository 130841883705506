import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useGrowthBook } from '@growthbook/growthbook-react'

import {
  selectCurrentVariantCohortToUse,
  selectCurrentVariantSteps,
  selectGrowthBookDefaultValues,
} from 'root-redux/selects/common'

import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import { PageId } from 'page-constants'
import {
  Cohort,
  POST_PAYMENT_PAGES,
  UPSELL_PAYWALL_PAGES,
} from 'root-constants'

export const useGetPageInfo = () => {
  const { pathname } = useLocation()
  const growthBook = useGrowthBook()
  const steps = useSelector(selectCurrentVariantSteps)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const growthBookDefaultValues = useSelector(selectGrowthBookDefaultValues)

  const unnecessaryPages = useMemo(() => {
    if (!growthBook?.ready) return []
    const pagesData = Object.entries(growthBookDefaultValues)

    return pagesData.reduce((acc, [experimentKey, experimentDefaultValues]) => {
      const experimentValue = growthBook.getFeatureValue(
        experimentKey,
        JSON.stringify(experimentDefaultValues),
      )
      return [...acc, ...JSON.parse(experimentValue)]
    }, [] as string[])
  }, [growthBook, growthBookDefaultValues])

  const filteredSteps = useMemo(() => {
    if (!unnecessaryPages.length) return steps
    return steps.filter(({ id }) => !unnecessaryPages.includes(id))
  }, [steps, unnecessaryPages])

  const currentPageId = useMemo(
    () => getPageIdFromPathName(pathname),
    [pathname],
  )

  const currentPageIndex = useMemo(
    () => filteredSteps.findIndex(({ id }) => id === currentPageId),
    [currentPageId, filteredSteps],
  )

  const isSecondPage = useMemo(
    () => filteredSteps.findIndex(({ id }) => id === currentPageId) === 1,
    [currentPageId, filteredSteps],
  )

  const isPostPaymentPage = useMemo(
    () => POST_PAYMENT_PAGES.includes(currentPageId),
    [currentPageId],
  )

  const { hasHeader, hasProgressbar, hasBurgerMenu } = useMemo(
    () => ({
      hasHeader:
        filteredSteps[currentPageIndex]?.hasHeader || isPostPaymentPage,
      hasProgressbar: filteredSteps[currentPageIndex]?.hasProgressBar,
      hasBurgerMenu:
        !currentPageIndex && !filteredSteps[currentPageIndex].isSubscriptions,
    }),
    [currentPageIndex, isPostPaymentPage, filteredSteps],
  )

  const pagesWithProgressBar = useMemo(
    () => filteredSteps.filter(({ hasProgressBar }) => hasProgressBar),
    [filteredSteps],
  )

  const amountOfAllPages = useMemo(
    () =>
      filteredSteps.filter(
        ({ isSkippable, hasProgressBar }) => !isSkippable && hasProgressBar,
      ),
    [filteredSteps],
  )

  const currentPageNumber = useMemo(() => {
    const pageIndex = pagesWithProgressBar.findIndex(
      ({ id }) => id === currentPageId,
    )

    // it only MF_WOMEN_45 case with skippable pages
    if (cohortToUse === Cohort.MF_WOMEN_45) {
      const amountOfSkippablePagesBefore = pagesWithProgressBar.reduce(
        (acc, curr, currentIndex) => {
          if (
            currentIndex < pageIndex &&
            curr.isSkippable &&
            !pagesWithProgressBar[pageIndex].isSkippable
          ) {
            return acc + 1
          }

          return acc
        },
        0,
      )

      return pageIndex - amountOfSkippablePagesBefore + 1
    }

    return pageIndex + 1
  }, [currentPageId, pagesWithProgressBar, cohortToUse])

  const currentSubscriptionPageId = useMemo(
    () => filteredSteps.find((page) => page.isSubscriptions)?.id as PageId,
    [filteredSteps],
  )

  // TODO: recreate logic after finishing task https://gismart.atlassian.net/browse/GB-1629
  const currentUpsellPaywallPageId = useMemo(
    () =>
      filteredSteps.find(({ id }) => UPSELL_PAYWALL_PAGES.includes(id))?.id ||
      PageId.UPSELL_PAYWALL_1,
    [filteredSteps],
  )

  const currentPaymentPageId = useMemo(
    () => filteredSteps.find((page) => page.isPayment)?.id as PageId,
    [filteredSteps],
  )

  const isPaymentPage = useMemo(
    () => currentPaymentPageId === currentPageId,
    [currentPageId, currentPaymentPageId],
  )

  return {
    steps: filteredSteps,
    currentPageId,
    currentPageIndex,
    isSecondPage,
    hasHeader,
    hasProgressbar,
    hasBurgerMenu,
    pagesWithProgressBar: amountOfAllPages,
    currentPageNumber,
    currentSubscriptionPageId,
    currentPaymentPageId,
    currentUpsellPaywallPageId,
    isPaymentPage,
    cohortToUse,
  }
}
