import React, { useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useTimerEffect } from 'contexts/TimerProvider'

import { StyledDefaultDiscountBlock as S } from 'modules/purchase/components/DefaultDiscountBlock/DefaultDiscountBlock.styles'
import { getDecoratedTimerValue } from 'modules/purchase/helpers/rootHelpers'
import { selectSubscriptionPaywallDiscountPercent } from 'modules/purchase/redux/selects'

import {
  DEFAULT_TIMER_CONTENT,
  INFLUENCER_NAME_SESSION_STORAGE_KEY,
} from 'root-constants'

type TProps = {
  className?: string
}

export const DiscountBlockV3: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()
  const discount = useSelector(selectSubscriptionPaywallDiscountPercent)

  const [influencerName, setInfluencerName] = useState<string>('')

  const timerMinutesRef = useRef<HTMLSpanElement>(null)
  const timerSecondsRef = useRef<HTMLSpanElement>(null)

  useTimerEffect((time) => {
    if (timerMinutesRef.current && timerSecondsRef.current) {
      const { minutes, seconds } = getDecoratedTimerValue(time, false)
      timerMinutesRef.current.innerText = minutes
      timerSecondsRef.current.innerText = seconds
    }
  })

  useEffect(() => {
    const valueFromSessionStorage = window.sessionStorage.getItem(
      INFLUENCER_NAME_SESSION_STORAGE_KEY,
    )

    valueFromSessionStorage && setInfluencerName(valueFromSessionStorage)
  }, [])

  return (
    <S.ContentBlock className={className}>
      <S.ContentColumn>
        <S.ColumnTitle>
          {t('purchase.discountBlockV3.discountApplied')}
        </S.ColumnTitle>
        <S.Offer>
          <S.DiscountValueBlock>
            <Trans
              i18nKey="purchase.discountBlockV3.discount"
              values={{
                discount,
              }}
            />
          </S.DiscountValueBlock>
          <S.DiscountCodeBlock>
            <S.Code>
              <Trans
                i18nKey="purchase.discountBlockV3.discountName"
                values={{
                  name: influencerName,
                }}
              />
            </S.Code>
          </S.DiscountCodeBlock>
        </S.Offer>
      </S.ContentColumn>
      <S.ContentColumn>
        <S.ColumnTitle>
          {t('purchase.discountBlockV3.applicable')}
        </S.ColumnTitle>
        <S.TimerBlock>
          <S.TimerColumn>
            <S.TimerValue ref={timerMinutesRef}>
              {DEFAULT_TIMER_CONTENT}
            </S.TimerValue>
            <S.TimerUnits>{t('commonComponents.timer.minutes')}</S.TimerUnits>
          </S.TimerColumn>
          <S.TimerColumn>
            <S.TimerValue ref={timerSecondsRef}>
              {DEFAULT_TIMER_CONTENT}
            </S.TimerValue>
            <S.TimerUnits>{t('commonComponents.timer.seconds')}</S.TimerUnits>
          </S.TimerColumn>
        </S.TimerBlock>
      </S.ContentColumn>
    </S.ContentBlock>
  )
}
