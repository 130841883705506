import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledDisclaimer = {
  Disclaimer: styled.p`
    color: ${Color.GREY_101};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    padding-bottom: 12px;
    text-align: center;

    a {
      color: ${Color.GREY_101};
      font-size: 13px;
      text-decoration: underline;
    }

    span {
      white-space: nowrap;
    }
  `,
}
