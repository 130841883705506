import { createProductId } from 'helpers/createProductId'

import { ISubscription } from 'models/subscriptions.model'

import { ScreenName, TimeInterval } from 'root-constants'

export const enum GoogleAnalyticsAction {
  EVENT = 'event',
}

export const enum GoogleAnalyticsEvent {
  PAGE_VIEW = 'page_view',
  BEGIN_CHECKOUT = 'begin_checkout',
  ADD_TO_CART = 'add_to_cart',
  PURCHASE = 'purchase',
  FIRST_PAGE_COMPLETED = 'first_page_completed',
  ENTER_EMAIL_PAGE_COMPLETED = 'enter_email_page_completed',
  ACCOUNT_CREATED = 'account_created',
  DOWNLOAD_BUTTON_PRESSED = 'download_button_press',
}

export const enum GoogleAnalyticsEventCategory {
  TRANSITION = 'transition',
}

class GoogleAnalyticsService {
  logPageView(location: string): void {
    if (!window.gtag) return

    window.gtag(GoogleAnalyticsAction.EVENT, GoogleAnalyticsEvent.PAGE_VIEW, {
      page_path: `/${location}`,
    })
  }

  logCheckoutStarted(subscriptions: ISubscription[]): void {
    if (!window.gtag) return

    window.gtag(
      GoogleAnalyticsAction.EVENT,
      GoogleAnalyticsEvent.BEGIN_CHECKOUT,
      {
        items: subscriptions.map(
          ({ mainPrices: { periodName, periodQuantity, fullPrice } }) => ({
            id: createProductId({
              periodName,
              periodQuantity,
              price: fullPrice,
            }),
            price: fullPrice,
          }),
        ),
      },
    )
  }

  logAddingToCart({
    mainPrices: { periodName, periodQuantity, fullPrice },
  }: ISubscription): void {
    if (!window.gtag) return

    window.gtag(GoogleAnalyticsAction.EVENT, GoogleAnalyticsEvent.ADD_TO_CART, {
      items: [
        {
          id: createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
          price: fullPrice,
        },
      ],
    })
  }

  logPurchaseCompleted({
    subscriptionId,
    price,
    periodName,
    periodQuantity,
    currency,
    screenName,
    isUpsell,
    isInApp,
  }: {
    subscriptionId: string
    price: number
    periodName: TimeInterval | null
    periodQuantity: number | null
    currency: string
    screenName: ScreenName
    isUpsell?: boolean
    isInApp?: boolean
  }): void {
    if (!window.gtag || isUpsell || isInApp) return

    window.gtag(GoogleAnalyticsAction.EVENT, GoogleAnalyticsEvent.PURCHASE, {
      event_label: screenName,
      transaction_id: subscriptionId,
      value: price,
      currency,
      items: [
        {
          id: createProductId({ periodName, periodQuantity, price }),
          price,
        },
      ],
    })
  }

  logFirstPageCompleted(): void {
    if (!window.gtag) return

    window.gtag(
      GoogleAnalyticsAction.EVENT,
      GoogleAnalyticsEvent.FIRST_PAGE_COMPLETED,
      {
        event_category: GoogleAnalyticsEventCategory.TRANSITION,
      },
    )
  }

  logEnterEmailPageCompleted(): void {
    if (!window.gtag) return

    window.gtag(
      GoogleAnalyticsAction.EVENT,
      GoogleAnalyticsEvent.ENTER_EMAIL_PAGE_COMPLETED,
      {
        event_category: GoogleAnalyticsEventCategory.TRANSITION,
      },
    )
  }

  logAccountCreated(): void {
    if (!window.gtag) return

    window.gtag(
      GoogleAnalyticsAction.EVENT,
      GoogleAnalyticsEvent.ACCOUNT_CREATED,
    )
  }

  logDownloadButtonPressed(): void {
    if (!window.gtag) return

    window.gtag(
      GoogleAnalyticsAction.EVENT,
      GoogleAnalyticsEvent.DOWNLOAD_BUTTON_PRESSED,
    )
  }
}

export const googleAnalyticsLogger = new GoogleAnalyticsService()
