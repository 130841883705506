import React from 'react'
import { Trans } from 'react-i18next'

import { StyledVideoReviews as S } from './VideoReviews.styles'
import { VIDEO_REVIEWS } from './constants'
import { useVideoReviews } from './useVideoReviews'

type TProps = {
  title: string
  className?: string
}

export const VideoReviews: React.FC<TProps> = ({ title, className }) => {
  const { videoBlock, videoRefs, isVideosInViewport, activeVideoID, onClick } =
    useVideoReviews()

  return (
    <S.Container className={className} ref={videoBlock}>
      <S.Title>
        <Trans i18nKey={title} />
      </S.Title>
      <S.VideosContainer>
        {VIDEO_REVIEWS.map(({ id, userName, videoUrl, posterUrl }, index) => {
          const ref = videoRefs.current[index]
          const isMuted = !isVideosInViewport || activeVideoID !== id

          if (ref?.current) ref.current.defaultMuted = true

          return (
            <S.Card key={id}>
              <S.VideoWrapper posterUrl={posterUrl}>
                <video
                  id={id}
                  ref={ref}
                  width="260px"
                  height="260px"
                  playsInline
                  preload="auto"
                  src={videoUrl}
                  poster={posterUrl}
                  muted={isMuted}
                  autoPlay
                  loop
                >
                  <track kind="captions" srcLang="en" label="English" />
                </video>
                <S.VideoButton
                  onClick={() => onClick(ref)}
                  isMuted={isMuted}
                  name={id}
                />
              </S.VideoWrapper>
              <S.UserName>{userName}</S.UserName>
            </S.Card>
          )
        })}
      </S.VideosContainer>
    </S.Container>
  )
}
