import styled, { css } from 'styled-components'

import { Color, PROGRESS_BAR_MARGIN_BOTTOM } from 'root-constants'

const progressValueStyles = css`
  background-color: currentColor;
  transition: all 0.2s ease-out;
`

export const StyledOnboardingProgressBar = {
  Wrapper: styled.div`
    margin: 0 auto ${PROGRESS_BAR_MARGIN_BOTTOM}px;
    width: 100%;
  `,
  Progress: styled.progress<{ backroundColor: Color; color: Color }>`
    ${({ backroundColor, color }) => `
      appearance: none;
      background-color: ${backroundColor};
      border: none;
      color: ${color};
      display: block;
      height: 2px;
      width: 100%;

      &::-webkit-progress-bar {
        background-color: ${backroundColor};
        color: ${color};
      }
      &::-webkit-progress-value {
        ${progressValueStyles}
      }
      &::-moz-progress-bar {
        ${progressValueStyles}
      }
      &::-ms-fill {
        ${progressValueStyles}
      }
    `}
  `,
}
