import React, { useEffect, useState } from 'react'

export const useTargetHiddenOnScroll = (
  targetScrollElementRef: React.RefObject<HTMLElement>,
): boolean => {
  const [isTargetHidden, setIsTargetHidden] = useState<boolean>(false)

  useEffect(() => {
    const observerOptions = {
      rootMargin: '-80px 0px 0px 0px',
      threshold: 0.4,
    }

    const scrollTargetCallback = (entries) => {
      entries.forEach((entry) => {
        const rootTopOffset = entry?.rootBounds?.top
        const targetTopOffset = entry?.boundingClientRect?.top

        const isUpperBorderCrossed = rootTopOffset >= targetTopOffset
        const isTargetInViewport = entry?.isIntersecting

        setIsTargetHidden(!isTargetInViewport && isUpperBorderCrossed)
      })
    }

    const observer = new IntersectionObserver(
      scrollTargetCallback,
      observerOptions,
    )
    const target = targetScrollElementRef.current

    target && observer.observe(target)

    return () => {
      observer.disconnect()
    }
  }, [targetScrollElementRef, targetScrollElementRef.current])

  return isTargetHidden
}
