import changesIconSrc from 'assets/images/upsell-simple-changes.png'
import giftSrc from 'assets/images/upsell-sugar-changes.png'
import sugarIconSrc from 'assets/images/upsell-sugar-free.png'

import { UpsellProduct } from 'root-constants'

export const enum OnboardingButtonText {
  GET_PLAN_UPPER = 'get_plan_upper',
  GET_PLAN_MIDDLE = 'get_plan_middle',
  GET_PLAN_MIDDLE_2 = 'get_plan_middle2',
  GET_PLAN_BOTTOM = 'get_plan_bottom',
}

export const PURCHASE_OFFSET_TOP = 150

export const UPSELL_BENEFITS = [
  'upsellV3.benefits.payment',
  'upsellV3.benefits.access',
  'upsellV3.benefits.advice',
]

export const UPSELL_IMAGES_MAP = {
  [UpsellProduct.SIMPLE_CHANGES]: {
    title: 'upsellV3.subscriptionsBlock.pdf_SimpleChangesTitle',
    info: 'upsellV3.subscriptionsBlock.pdf_SimpleChangesInfo',
    discount: 32,
    img: changesIconSrc,
  },
  [UpsellProduct.SUGAR_FREE]: {
    title: 'upsellV3.subscriptionsBlock.pdf_SugarFreeDessertsTitle',
    info: 'upsellV3.subscriptionsBlock.pdf_SugarFreeDessertsInfo',
    discount: 32,
    img: sugarIconSrc,
  },
  [UpsellProduct.CHANGES_SUGAR]: {
    title: 'upsellV3.subscriptionsBlock.pdf_WeightLossGuidesTitle',
    discount: 50,
    img: giftSrc,
  },
}

export const UPSELL_EVENT_PROPERTY_MAP = {
  [UpsellProduct.SIMPLE_CHANGES]: 'pdf_weightloss',
  [UpsellProduct.SUGAR_FREE]: 'pdf_desserts',
  [UpsellProduct.CHANGES_SUGAR]: 'pdf_bundle',
}
