import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { useSelector } from 'react-redux'

import { selectUUID } from 'root-redux/selects/user'

type TTimerProviderProps = {
  id: string
  time: number
  children: React.ReactNode
}

type TTimerContextProps = {
  subscribe: (callback: (time: number) => void) => void
  unsubscribe: (callback: (time: number) => void) => void
}

export const TimerContext = createContext<TTimerContextProps | null>(null)

export const TimerProvider: React.FC<TTimerProviderProps> = ({
  id,
  time,
  children,
}) => {
  const uuid = useSelector(selectUUID)
  const sessionStorageKey = `${uuid}-${id}-timer`
  const sessionStorageValue = sessionStorage.getItem(sessionStorageKey)

  const initialTime =
    sessionStorageValue === null ? time : Number(sessionStorageValue)

  const secondsRef = useRef<number>(initialTime)
  const subscribersRef = useRef<((time: number) => void)[]>([])
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (secondsRef.current === initialTime) {
      sessionStorage.setItem(sessionStorageKey, initialTime.toString())
      subscribersRef.current.forEach((callback) => callback(secondsRef.current))
    }

    if (secondsRef.current > 0) {
      intervalRef.current = setInterval(() => {
        const newSeconds = secondsRef.current - 1
        sessionStorage.setItem(sessionStorageKey, newSeconds.toString())

        secondsRef.current = newSeconds
        subscribersRef.current.forEach((callback) => callback(newSeconds))

        if (newSeconds <= 0 && intervalRef.current) {
          clearInterval(intervalRef.current)
        }
      }, 1000)
    }

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current)
    }
  }, [sessionStorageKey])

  const subscribe = useCallback((callback: (time: number) => void) => {
    subscribersRef.current.push(callback)
  }, [])

  const unsubscribe = useCallback((callback: (time: number) => void) => {
    subscribersRef.current = subscribersRef.current.filter(
      (fn) => fn !== callback,
    )
  }, [])

  const contextValue = useMemo(
    () => ({
      subscribe,
      unsubscribe,
    }),
    [subscribe, unsubscribe],
  )

  return (
    <TimerContext.Provider value={contextValue}>
      {children}
    </TimerContext.Provider>
  )
}

export const useTimerEffect = (updateFn: (time: number) => void) => {
  const context = useContext(TimerContext)

  if (!context) {
    throw new Error('useTimer must be used within a TimerProvider')
  }

  const { subscribe, unsubscribe } = context

  useEffect(() => {
    subscribe(updateFn)

    return () => unsubscribe(updateFn)
  }, [subscribe, unsubscribe, updateFn])
}
