import { Button } from 'storybook-ui'
import styled, { css } from 'styled-components'

import { PresaleGraph } from 'components/PresaleGraph'

import {
  AppReachedV1,
  BoostResults,
  CustomerReviewsV3,
  DefaultDiscountBlock,
  DisclaimerV2,
  DiscountBlockV1,
  DiscountBlockV2,
  FeaturedBlockV2,
  IntroMediaV1,
  SubscriptionsBlock,
  VideoReviews,
  WeightLossJourney,
} from 'modules/purchase/components'
import { DiscountBlockV3 } from 'modules/purchase/components/DefaultDiscountBlock'
import { KitDiscountTimerV1 } from 'modules/purchase/components/KitDiscountTimer'
import { MoneyBackGuaranteeV3 } from 'modules/purchase/components/MoneyBackGuaranteeV3'
import { PersonalizedPlanV1 } from 'modules/purchase/components/PersonalizedPlan/PersonalizedPlanV1'

import { Color, KIT_MAX_COLUMN_WIDTH, MediaBreakpoint } from 'root-constants'

export const commonWrapperStyle = css`
  width: 100%;
  margin: 0 auto;
  padding: 0 16px 16px 16px;
  background-color: ${Color.GREY_700};

  @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
    width: ${KIT_MAX_COLUMN_WIDTH}px;
  }
`

export const StyledKitPurchaseVariant1 = {
  Wrapper: styled.div`
    ${commonWrapperStyle};
  `,
  DiscountTimer: styled(KitDiscountTimerV1)`
    position: sticky;
    top: 0;
    z-index: 100;
    margin: 0 auto 16px;
    background-color: ${Color.WHITE};
    box-shadow: 0 8px 20px 0 #e8e8e8;
  `,
  IntroMedia: styled(IntroMediaV1)`
    margin: 0 0 40px 0;
  `,
  PersonalizedPlanV1: styled(PersonalizedPlanV1)`
    margin: 0 0 32px 0;
  `,
  Graph: styled(PresaleGraph)`
    margin: 16px 0;
  `,
  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.GREY_100};
    margin: 24px 0;

    &.v3 {
      margin: 16px 0;
    }
  `,
  DiscountTitle: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.GREY_100};
    margin: 0 0 16px 0;

    &.v3 {
      margin: 40px 0 16px 0;
    }
  `,
  DiscountBlock: styled(DefaultDiscountBlock)`
    margin: 0 0 16px 0;
  `,
  HowItWorksWrap: styled.div`
    margin-top: 40px;
    margin-bottom: 40px;
  `,
  DiscountBlockV1: styled(DiscountBlockV1)`
    margin: 0 0 16px 0;
  `,
  DiscountBlockV2: styled(DiscountBlockV2)`
    margin: 0 0 16px 0;
  `,
  DiscountBlockV3: styled(DiscountBlockV3)`
    margin: 0 0 16px 0;
  `,
  SubscriptionsBlock: styled(SubscriptionsBlock)`
    margin: 0 0 16px 0;
  `,
  MoneyBackText: styled.p`
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: ${Color.GREY_101};
    text-decoration: underline;
    text-align: center;
    margin: 0 0 24px 0;
  `,
  Button: styled(Button)`
    text-transform: capitalize;

    &.v3 {
      margin: 0 auto;
    }
  `,
  DisclaimerV1: styled(DisclaimerV2)`
    margin: 0 0 40px 0;
  `,
  DisclaimerV2: styled(DisclaimerV2)`
    height: 86px;
    margin: 0 0 64px 0;
  `,
  MoneyBackGuaranteeV3: styled(MoneyBackGuaranteeV3)`
    margin: 0 0 40px 0;

    &.v3 {
      margin: 40px 0 40px 0;
    }
  `,
  VideoReviews: styled(VideoReviews)`
    margin: 0 auto 40px;
  `,
  WeightLossJourney: styled(WeightLossJourney)`
    margin: 0 0 40px 0;
  `,
  AppReached: styled(AppReachedV1)`
    margin: 0 0 40px 0;
  `,
  BoostResults: styled(BoostResults)`
    margin: 0 0 40px 0;
  `,
  CustomerReviewsV3: styled(CustomerReviewsV3)`
    margin: 0 0 40px 0;
  `,
  FeaturedBlock: styled(FeaturedBlockV2)`
    margin: 0 0 40px 0;
  `,
}
