import styled from 'styled-components'

import questionIcon from 'assets/images/kit-question-icon.png'

import { Color } from 'root-constants'

export const StyledFAQList = {
  Wrapper: styled.div`
    background-color: ${Color.WHITE};
    border: 1px solid ${Color.GREY_800};
    border-radius: 16px;
    margin-bottom: 40px;
    padding: 24px 16px 32px;
  `,
  Title: styled.h2`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 20px;
    text-align: center;
  `,
  List: styled.ul`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  `,
  ListItem: styled.li`
    display: flex;
    flex-direction: column;
    padding-left: 32px;
    position: relative;
    row-gap: 8px;

    &:before {
      background-image: url(${questionIcon});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      content: '';
      display: flex;
      height: 24px;
      left: 0;
      position: absolute;
      top: 0;
      width: 24px;
    }
  `,
  ListItemTitle: styled.h3`
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
  `,
  ListItemText: styled.p`
    color: ${Color.GREY_101};
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  `,
}
