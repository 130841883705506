import { useSelector } from 'react-redux'

import { usePageInfo } from 'contexts/PageInfoProvider'

import {
  selectUserStatus,
  selectUserUpsellPages,
} from 'root-redux/selects/user'

import { PageId } from 'page-constants'

export const useUserStatus = () => {
  const userStatus = useSelector(selectUserStatus)
  const upsellPages = useSelector(selectUserUpsellPages)

  const { steps, currentSubscriptionPageId } = usePageInfo()

  if (userStatus?.account.hasAccount && userStatus?.hasSubscription) {
    return PageId.DOWNLOAD
  }

  if (userStatus?.hasSubscription && !upsellPages.length) {
    return PageId.FINISHING_TOUCHES
  }

  if (userStatus?.hasSubscription && upsellPages.length) {
    return upsellPages[0]
  }

  if (userStatus?.email.hasEmail && !userStatus?.hasSubscription) {
    return currentSubscriptionPageId || steps[0].id
  }

  return '' as PageId
}
