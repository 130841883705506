import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { usePageInfo } from 'contexts/PageInfoProvider'

import { Logo } from 'components/Logo'
import { SvgImage } from 'components/SvgImage'

import {
  selectCurrentVariantOnboardingProgressbarBreakpoints,
  selectCurrentVariantSteps,
  selectHeaderSkipButton,
} from 'root-redux/selects/common'

import { IStep } from 'models/variant.model'

import arrowRight from 'assets/images/arrow-right-icon.png'
import arrowSvg from 'assets/images/sprite/back-arrow.svg'

import { goBack } from 'browser-history'
import { PageId } from 'page-constants'
import { Color } from 'root-constants'

import { StyledHeader as S } from './Header.styles'

const onboardingStart = 0

export const Header: React.FC = () => {
  const { t } = useTranslation()
  const { hasProgressbar, currentPageId } = usePageInfo()
  const steps = useSelector(selectCurrentVariantSteps)
  const onboardingProgressbarBreakpoints = useSelector(
    selectCurrentVariantOnboardingProgressbarBreakpoints,
  )
  const { hasSkipButton, onSkip } = useSelector(selectHeaderSkipButton)

  const categoriesNames = useMemo(
    () => Object.keys(onboardingProgressbarBreakpoints),
    [onboardingProgressbarBreakpoints],
  )

  const breakpoints = useMemo(() => {
    const breakpointsItems = new Set(
      Object.values(onboardingProgressbarBreakpoints).flat(),
    )

    return Array.from(breakpointsItems) as PageId[]
  }, [onboardingProgressbarBreakpoints])

  const segmentedSteps: IStep[][] = useMemo(
    () =>
      steps.reduce((stepsWithSegments: IStep[][], currentStep: IStep) => {
        const breakPointIndex = breakpoints?.findIndex(
          (item: PageId) => item === currentStep.id,
        )

        if (breakPointIndex >= 0) {
          stepsWithSegments[breakPointIndex] = [currentStep]
        } else {
          stepsWithSegments[stepsWithSegments.length - 1]?.push(currentStep)
        }

        return stepsWithSegments
      }, []),
    [breakpoints, steps],
  )

  const [currentSectionIndex, currentStepIndex] = useMemo(() => {
    const sectionIndex = segmentedSteps.findIndex(
      (block) => !!block?.find((step) => step.id === currentPageId),
    )
    const stepIndex = segmentedSteps[sectionIndex]?.findIndex(
      (step) => step.id === currentPageId,
    )

    return [sectionIndex, stepIndex]
  }, [currentPageId, segmentedSteps])

  const segmentedOnboardingSteps = useMemo(
    () => segmentedSteps.slice(onboardingStart, categoriesNames?.length),
    [categoriesNames?.length, segmentedSteps],
  )

  return (
    <S.Wrapper>
      {hasProgressbar ? (
        <>
          <S.BackButton type="button" onClick={goBack}>
            <SvgImage
              fill={Color.BLACK_100}
              svg={arrowSvg}
              width={24}
              height={24}
            />
          </S.BackButton>
          <S.CategoryBlock>
            <S.Title>{t(categoriesNames?.[currentSectionIndex])}</S.Title>
            <S.ProgressContainer>
              {segmentedOnboardingSteps.map((stepsBlock, index) => {
                const currentBlockProgressValue =
                  currentSectionIndex === index ? currentStepIndex + 1 : 0

                const progressValue =
                  currentSectionIndex > index
                    ? stepsBlock.length
                    : currentBlockProgressValue

                return (
                  <S.Progress
                    key={stepsBlock[0].id}
                    max={stepsBlock.length}
                    value={progressValue}
                  />
                )
              })}
            </S.ProgressContainer>
          </S.CategoryBlock>
        </>
      ) : (
        <>
          <Logo marginBottom={0} />
          {hasSkipButton && (
            <S.SkipButton type="button" onClick={onSkip}>
              {t('actions.skip')}
              <img src={arrowRight} alt="back" />
            </S.SkipButton>
          )}
        </>
      )}
    </S.Wrapper>
  )
}
