import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { LanguageSelector } from 'components/LanguageSelector'

import { setIsInContextShownAction } from 'root-redux/actions/common'
import { selectIsInContextShown } from 'root-redux/selects/common'

import { Language, SUPPORTED_LOCALES } from 'root-constants'

import { StyledLanguageSelector as S } from './InContextControlPanel.styles'

export const InContextControlPanel: React.FC<{ defaultValue: string }> = ({
  defaultValue,
}) => {
  const dispatch = useDispatch()
  const isInContextShown = useSelector(selectIsInContextShown)

  const handleChangeInContextVisibility = () => {
    dispatch(setIsInContextShownAction(true))
  }

  return (
    <S.Wrapper>
      {isInContextShown ? (
        <LanguageSelector
          defaultValue={defaultValue}
          options={[...SUPPORTED_LOCALES, Language.IN_CONTEXT]}
        />
      ) : (
        <S.Button type="button" onClick={handleChangeInContextVisibility}>
          Turn on In-Context
        </S.Button>
      )}
    </S.Wrapper>
  )
}
