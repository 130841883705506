import { useSelector } from 'react-redux'

import {
  selectIsOnboardingFinished,
  selectIsOnboardingSkipped,
  selectTestEnvironmentQueryParam,
  selectUUID,
  selectUserAccountLoginMethod,
  selectUserCountryCode,
  selectUserLoginEmail,
  selectUserOnboardingEmail,
  selectUserStatus,
  selectUserSubscriptionDuration,
  selectUtmTags,
} from 'root-redux/selects/user'

export const useUserStore = () => {
  const uuid = useSelector(selectUUID)
  const userStatus = useSelector(selectUserStatus)
  const email = useSelector(selectUserOnboardingEmail)
  const isOnboardingSkipped = useSelector(selectIsOnboardingSkipped)
  const isOnboardingFinished = useSelector(selectIsOnboardingFinished)
  const loginMethod = useSelector(selectUserAccountLoginMethod)
  const userEmail = useSelector(selectUserLoginEmail)
  const testEnvironmentQueryParam = useSelector(selectTestEnvironmentQueryParam)
  const userCountryCode = useSelector(selectUserCountryCode)
  const utmTags = useSelector(selectUtmTags)
  const subscriptionDuration = useSelector(selectUserSubscriptionDuration)

  return {
    uuid,
    userStatus,
    email,
    isOnboardingSkipped,
    isOnboardingFinished,
    loginMethod,
    userEmail,
    testEnvironmentQueryParam,
    userCountryCode,
    utmTags,
    subscriptionDuration,
  }
}
