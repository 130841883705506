import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Logo } from 'components/Logo'
import { ProgressAnalyzer } from 'components/ProgressAnalyzer'

import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Color } from 'root-constants'

import { StyledGoodHandsV2 as S } from './GoodHandsV2.styles'
import { QUESTION } from './constants'

export const GoodHandsV2: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const { counter } = useCounter(true, {
    duration: 7500,
    callback: handleContinue,
  })

  return (
    <S.Wrapper>
      <Logo />
      <S.Container>
        <S.Title>{t('onboarding.goodHands.title')}</S.Title>
        <S.Count>1,100,000+</S.Count>
        <S.Subtitle>
          <Trans i18nKey="onboarding.goodHands.subTitleV1" />
        </S.Subtitle>
        <ProgressAnalyzer value={counter} color={Color.PRIMARY}>
          {t('onboarding.goodHands.progressTitle')}
        </ProgressAnalyzer>
      </S.Container>
    </S.Wrapper>
  )
}
