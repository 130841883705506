import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { GrowthBook } from '@growthbook/growthbook-react'

import { selectGrowthBookExperiments } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { getCurrentEnvironment } from 'helpers/getCurrentEnvironment'

import { eventLogger } from 'services/eventLogger.service'

const PROD_GROWTHBOOK_CLIENT_KEY = process.env.GROWTHBOOK_CLIENT_KEY
const DEV_GROWTHBOOK_CLIENT_KEY = process.env.GROWTHBOOK_CLIENT_KEY

const clientKey = getCurrentEnvironment().isProdEnvironment
  ? PROD_GROWTHBOOK_CLIENT_KEY
  : DEV_GROWTHBOOK_CLIENT_KEY

const { isDevEnvironment, isStageEnvironment } = getCurrentEnvironment()

export const growthBook = new GrowthBook({
  enableDevMode: true,
  apiHost: 'https://cdn.growthbook.io',
  clientKey,
  subscribeToChanges: true,
  onFeatureUsage: (featureKey, result) => {
    if (isDevEnvironment || isStageEnvironment) {
      console.debug(featureKey, '->', result.value)
    }
  },
})

export const useGrowthBook = (): void => {
  const uuid = useSelector(selectUUID)
  const location = useLocation()
  const growthBookExperiments = useSelector(selectGrowthBookExperiments)

  const updateGrowthBookURL = () => {
    growthBook.setURL(window.location.href)
  }

  useEffect(() => {
    growthBook.loadFeatures({ autoRefresh: true })
  }, [])

  useEffect(() => {
    growthBook.setAttributes({
      id: uuid,
    })
  }, [uuid])

  useEffect(() => {
    updateGrowthBookURL()
  }, [location])

  useEffect(() => {
    growthBook.subscribe((experiment, result) => {
      if (!growthBookExperiments || !result.hashUsed) return

      const experimentKey = experiment.key || ''
      const variantId = String(result.variationId)

      eventLogger.logGrowthBookAbSegmentName({
        variantId,
        experimentKey,
        variantName: growthBookExperiments?.[experimentKey]?.[variantId],
      })
    })
  }, [growthBookExperiments])
}
