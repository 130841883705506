import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getDecoratedTimerValue } from 'modules/purchase/helpers/rootHelpers'
import { selectSubscriptionTimerValue } from 'modules/purchase/redux/selects'

import { StyledPersonalizedPlanTimer as S } from './PersonalizedPlanTimer.styles'

type TProps = {
  className?: string
}

export const PersonalizedPlanTimer: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  const timerValue = useSelector(selectSubscriptionTimerValue)

  const { minutes, seconds } = useMemo(
    () => getDecoratedTimerValue(timerValue, false),
    [timerValue],
  )

  return (
    <S.Container className={className}>
      <S.InfoBlock>
        <S.Info>{t('purchase.personalizedPlanTimer.info')}</S.Info>
      </S.InfoBlock>
      <S.TimerBlock>
        <S.TimerContent>
          <Trans
            i18nKey="purchase.personalizedPlanTimer.offerExpires"
            values={{ timerValue: `${minutes}:${seconds}` }}
          />
        </S.TimerContent>
      </S.TimerBlock>
    </S.Container>
  )
}
