export const CUSTOMER_REVIEWS = [
  {
    id: '1',
    title: 'purchase.customerReviews.firstReviewTitle',
    subtitle: '05.06.2023',
    author: 'Marta86',
    review: 'purchase.customerReviews.firstReviewText',
    shortReview: 'purchase.customerReviews.firstPreview',
  },
  {
    id: '2',
    title: 'purchase.customerReviews.secondReviewTitle',
    subtitle: '29.02.2022',
    author: 'Athletic',
    review: 'purchase.customerReviews.secondReviewText',
    shortReview: 'purchase.customerReviews.secondPreview',
  },
  {
    id: '3',
    title: 'purchase.customerReviews.thirdReviewTitle',
    subtitle: '20.09.2022',
    author: 'PBarcelo',
    review: 'purchase.customerReviews.thirdReviewText',
    shortReview: 'purchase.customerReviews.thirdPreview',
  },
]
