import React, { useCallback, useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Gender } from 'root-constants'

import { StyledImageWithText as S } from './ImageWithText.styles'

export const ImageWithTextV1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const gender = useSelector(selectCurrentUserGender)

  const {
    title = '',
    subtitle,
    buttonText,
    imageUrls,
    pageTexts,
  } = useDynamicOBConfig()

  const handleContinue = useNextStep({
    pageId,
    question: title,
    nextPagePath,
  })

  const { imageUrl, info } = useMemo(() => {
    const [femaleImage, maleImage] = imageUrls || []
    const [text] = pageTexts || []

    return {
      imageUrl:
        gender === Gender.FEMALE ? femaleImage : maleImage || femaleImage,
      info: text,
    }
  }, [imageUrls, gender, pageTexts])

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <KitPageContainer
      hasContinueButton
      continueButtonContent={buttonText}
      onContinueButtonClick={handleNextClick}
    >
      <S.ImageContainer>
        <img src={imageUrl} alt="banner" />
      </S.ImageContainer>
      <PageTitle textAlign="left" marginBottom={16}>
        <Trans>{title}</Trans>
      </PageTitle>
      {subtitle && (
        <S.Subtitle>
          <Trans>{subtitle}</Trans>
        </S.Subtitle>
      )}
      {info && (
        <S.Info>
          <Trans>{info}</Trans>
        </S.Info>
      )}
    </KitPageContainer>
  )
}
