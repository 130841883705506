import React, { Suspense } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { shallowEqual, useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'

import { GrowthBookProvider } from '@growthbook/growthbook-react'
import { PageInfoProvider } from 'contexts/PageInfoProvider'
import { PrivacyPolicy } from 'pages/privacy-policy'
import { TermsOfUse } from 'pages/terms-of-use'
import { polyfill as smoothScrollPolyfill } from 'smoothscroll-polyfill'
import 'swiper/css'
import 'swiper/css/bundle'

import { LayoutProvider } from 'components/LayoutProvider'
import { Spinner } from 'components/Spinner'

import { selectConfig, selectCurrentVariant } from 'root-redux/selects/common'
import { selectUserStatus } from 'root-redux/selects/user'

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics'
import { growthBook } from 'hooks/useGrowthBook'
import { useHotJarInitialization } from 'hooks/useHotJarInitialization'
import { useInContextInitialization } from 'hooks/useInContextInitialization'
import { useInitAppConfig } from 'hooks/useInitAppConfig'
import { useInitPixels } from 'hooks/useInitPixels'
import { useLanguage } from 'hooks/useLanguage'
import { useOutbrainPixelInitialization } from 'hooks/useOutbrainPixelInitialization'
import { useScrollFix } from 'hooks/useScrollFix'
import { useSetUserStatus } from 'hooks/useSetUserStatus'
import { useSetUuid } from 'hooks/useSetUuid'
import { useSetVariant } from 'hooks/useSetVariant'
import { useTaboolaPixelInitialization } from 'hooks/useTaboolaPixelInitialization'

import { setViewportFullHeight } from 'helpers/setViewportFullHeight'

import { browserHistory } from 'browser-history'
import { PageId } from 'page-constants'
import { TOU_AND_PP_LINKS } from 'root-constants'

import { CustomRouter } from './CustomRouter'

setViewportFullHeight()
smoothScrollPolyfill()

export const App: React.FC = () => {
  const userStatus = useSelector(selectUserStatus, shallowEqual)
  const variant = useSelector(selectCurrentVariant, shallowEqual)
  const config = useSelector(selectConfig)

  useInitPixels()
  useInitAppConfig()
  useGoogleAnalytics()
  useSetVariant()
  useSetUuid()
  useSetUserStatus()
  useScrollFix()
  useTaboolaPixelInitialization()
  useOutbrainPixelInitialization()
  useLanguage()
  useInContextInitialization()
  useHotJarInitialization()

  return (
    <CustomRouter history={browserHistory}>
      <Suspense fallback={<Spinner />}>
        {TOU_AND_PP_LINKS.includes(window.location.pathname) ? (
          <HelmetProvider>
            <Routes>
              <Route path={PageId.TERMS_OF_USE} element={<TermsOfUse />} />
              <Route path={PageId.PRIVACY_POLICY} element={<PrivacyPolicy />} />
            </Routes>
          </HelmetProvider>
        ) : (
          <GrowthBookProvider growthbook={growthBook}>
            {userStatus && variant && config ? (
              <PageInfoProvider>
                <LayoutProvider />
              </PageInfoProvider>
            ) : (
              <Spinner />
            )}
          </GrowthBookProvider>
        )}
      </Suspense>
    </CustomRouter>
  )
}
