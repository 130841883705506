import styled from 'styled-components'

import { Footer } from 'components/Footer'
import { Option } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { Color } from 'root-constants'

export const StyledImageOptions = {
  Title: styled(PageTitle)`
    color: ${Color.GREEN_300};
    margin-bottom: 16px;
    text-transform: uppercase;
  `,
  Subtitle: styled.h3`
    color: ${Color.GREY_100};
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 24px;
    text-align: center;
  `,
  OptionsContainer: styled.div`
    display: grid;
    grid-gap: 20px 21px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    margin-bottom: 24px;
  `,
  Option: styled(Option)`
    aspect-ratio: 161 / 168;
    background-color: ${Color.GREY_900};
    border: 2px solid ${Color.GREY_900};
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0;
    padding: 2px;
    position: relative;
    width: 100%;

    :hover {
      border-color: ${Color.GREEN_400};
    }
  `,
  ImageContainer: styled.div<{ aspectRatio?: string }>`
    aspect-ratio: ${({ aspectRatio }) => aspectRatio || '153 / 124'};
    bottom: 50px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: calc(100% - 4px);
    z-index: 1;
  `,
  AnswerText: styled.span`
    align-items: center;
    background-color: ${Color.GREEN_400};
    border-radius: 16px;
    color: ${Color.WHITE};
    display: flex;
    font-size: 15px;
    font-weight: 600;
    justify-content: space-between;
    line-height: 20px;
    padding: 12px;
    width: 100%;
  `,
  ArrowIcon: styled.span`
    background-color: ${Color.WHITE};
    border-radius: 50%;
    height: 24px;
    position: relative;
    width: 24px;

    :after {
      border-left: 2px solid ${Color.GREEN_400};
      border-radius: 1px;
      border-top: 2px solid ${Color.GREEN_400};
      content: '';
      display: block;
      height: 8px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-75%, -50%) rotate(135deg);
      width: 8px;
    }
  `,
  Footer: styled(Footer)`
    font-size: 11px;
    line-height: 14px;
  `,
}
