import React from 'react'
import { useTranslation } from 'react-i18next'

import { useDynamicPaywallConfig } from 'modules/purchase/hooks/useDynamicPaywallConfig'

import { StyledPlanHighlights as S } from './PlanHighlights.styles'
import { LIST_ITEMS } from './constants'

type TProps = {
  titleElementRef?: React.RefObject<HTMLHeadingElement>
  className?: string
}

export const PlanHighlights: React.FC<TProps> = ({
  titleElementRef,
  className,
  ...props
}) => {
  const { t } = useTranslation()
  const { benefitsBlockTitle, benefits } = useDynamicPaywallConfig()

  return (
    <S.Wrapper>
      <S.Title ref={titleElementRef}>
        {benefitsBlockTitle || t('purchase.listTitle')}
      </S.Title>
      <S.List className={className} {...props}>
        {(benefits?.length ? benefits : LIST_ITEMS).map(({ id, text }) => (
          <S.ListItem key={id}>{benefits?.length ? text : t(text)}</S.ListItem>
        ))}
      </S.List>
    </S.Wrapper>
  )
}
