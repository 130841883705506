import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  ACTIVITY_DURING_WORK_MAP,
  ACTIVITY_LEVEL_MAP,
  BMI_VALUE_TO_MARKUP_MAP,
  PRIMARY_GOAL_MAP,
} from 'map-constants'

import {
  selectActivityDuringWork,
  selectCurrentUserGoalWeight,
  selectCurrentUserPrimaryGoal,
  selectUserMeasurementSystem,
  selectWorkout,
} from 'root-redux/selects/common'

import { useUsersBmi } from 'hooks/useUsersBmi'

import { convertTextFromSnakeCase } from 'helpers/convertTextFromSnakeCase'

import { useDynamicPaywallConfig } from 'modules/purchase/hooks/useDynamicPaywallConfig'

import cupBullet from 'assets/images/bullet-cup.svg'
import personHandsUpBullet from 'assets/images/bullet-person-hands-up.svg'
import runningPersonBullet from 'assets/images/bullet-running-person.svg'
import weightBullet from 'assets/images/bullet-weight-2.svg'

import { MeasurementSystem } from 'root-constants'

import { StyledPersonalizedPlanV1 as S } from './PersonalizedPlanV1.styles'

type TProps = {
  className?: string
  withTitle?: boolean
}

export const PersonalizedPlanV1: React.FC<TProps> = ({
  className,
  withTitle = true,
}) => {
  const { t } = useTranslation()

  const { title } = useDynamicPaywallConfig()

  const primaryGoal = useSelector(selectCurrentUserPrimaryGoal)
  const activityDuringWork = useSelector(selectActivityDuringWork)
  const workoutValue = useSelector(selectWorkout)
  const goalWeight = useSelector(selectCurrentUserGoalWeight)
  const measurementSystem = useSelector(selectUserMeasurementSystem)

  const goal = useMemo(() => PRIMARY_GOAL_MAP[primaryGoal], [primaryGoal])

  const { currentBMI } = useUsersBmi()

  const activity = useMemo(
    () =>
      t(ACTIVITY_DURING_WORK_MAP[activityDuringWork]) ||
      t(ACTIVITY_LEVEL_MAP[workoutValue]),
    [activityDuringWork, workoutValue, t],
  )

  const weight = useMemo(
    () =>
      `${goalWeight} ${
        measurementSystem === MeasurementSystem.METRIC
          ? t('commonComponents.kg')
          : t('commonComponents.lbs')
      }`,
    [t, measurementSystem, goalWeight],
  )

  const bmiValue = useMemo(
    () => BMI_VALUE_TO_MARKUP_MAP[currentBMI.bmiValue.value],
    [currentBMI],
  )

  return (
    <div className={className}>
      {withTitle ? (
        <S.Title>{title || t('purchase.personalizedPlan.title')}</S.Title>
      ) : null}
      <S.PlanContainer>
        <S.PlanRow>
          <S.PlanItem bullet={cupBullet} hasPaddingBottom>
            <S.ItemTitle>
              {convertTextFromSnakeCase(t('purchase.personalizedPlan.goal'))}
            </S.ItemTitle>
            <S.ItemValue>{convertTextFromSnakeCase(t(goal))}</S.ItemValue>
          </S.PlanItem>
          <S.PlanItem bullet={weightBullet} hasPaddingBottom>
            <S.ItemTitle>
              {convertTextFromSnakeCase(
                t('purchase.personalizedPlan.targetWeight'),
              )}
            </S.ItemTitle>
            <S.ItemValue>{weight}</S.ItemValue>
          </S.PlanItem>
        </S.PlanRow>
        <S.PlanRow>
          <S.PlanItem bullet={personHandsUpBullet} hasPaddingTop>
            <S.ItemTitle>
              {convertTextFromSnakeCase(
                t('purchase.personalizedPlan.bmiCategory'),
              )}
            </S.ItemTitle>
            <S.ItemValue>{convertTextFromSnakeCase(t(bmiValue))}</S.ItemValue>
          </S.PlanItem>
          <S.PlanItem bullet={runningPersonBullet} hasPaddingTop>
            <S.ItemTitle>
              {convertTextFromSnakeCase(
                t('purchase.personalizedPlan.activityLevel'),
              )}
            </S.ItemTitle>
            <S.ItemValue>{convertTextFromSnakeCase(activity)}</S.ItemValue>
          </S.PlanItem>
        </S.PlanRow>
      </S.PlanContainer>
    </div>
  )
}
