import styled, { css } from 'styled-components'

import { Color, Gradient } from 'root-constants'

const commonCodeStyle = css`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${Color.BLACK_100};
`

const commonDiscountValueStyle = css`
  background-color: ${Color.BLACK_100};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  padding: 2px 4px;
  color: ${Color.WHITE};
`

const commonDiscountCodeStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 0 8px;
  color: ${Color.GREY_100};
`

export const StyledDefaultDiscountBlock = {
  ContentBlock: styled.div`
    display: flex;
    justify-content: space-between;
    height: 114px;
    padding: 16px;
    border-radius: 16px;
    background: radial-gradient(${Gradient.RADIAL_RED_YELLOW});
  `,
  ContentColumn: styled.div`
    display: flex;
    flex-direction: column;
  `,
  ColumnTitle: styled.h4`
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: ${Color.WHITE};
    margin: 0 0 8px 0;
  `,
  Offer: styled.div`
    width: 144px;
    background-color: ${Color.WHITE};
    border-radius: 8px;
    overflow: hidden;
  `,
  OldOffer: styled.div`
    ${commonDiscountValueStyle};

    span {
      text-decoration: line-through;
      text-decoration-color: #f03434;
    }
  `,
  NewOffer: styled.div`
    ${commonDiscountCodeStyle};
  `,
  OldPromoCode: styled.p`
    font-weight: 700;
    font-size: 8px;
    line-height: 10px;
    text-align: center;
    color: ${Color.DISABLED};
    text-decoration: line-through;
    text-decoration-color: ${Color.DISABLED};
  `,
  NewPromoCode: styled.p`
    ${commonCodeStyle};
  `,
  TimerBlock: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  TimerColumn: styled.div`
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px 8px 8px 8px;
    background-color: ${Color.WHITE};
    border-radius: 8px;

    &:first-of-type {
      margin: 0 8px 0 0;
    }
  `,
  TimerValue: styled.span`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.GREY_100};
    margin: 0 0 2px 0;
  `,
  TimerUnits: styled.span`
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    color: ${Color.GREY_100};
  `,
  DiscountValueBlock: styled.div`
    ${commonDiscountValueStyle};
  `,
  DiscountCodeBlock: styled.div`
    ${commonDiscountCodeStyle};
  `,
  Code: styled.p`
    ${commonCodeStyle};
    text-transform: uppercase;
  `,
}
