import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useVatInfo } from 'hooks/useHasVatInfo'

import {
  COHORTS_WITHOUT_TRIAL_FOR_WEEKLY_PLAN,
  CURRENCY_SYMBOLS,
  PurchaseProduct,
} from 'modules/purchase/constants'
import {
  selectCurrency,
  selectProductId,
  selectSubscriptionFullPrice,
  selectSubscriptionIntroDifAmount,
  selectSubscriptionIntroDifPercents,
  selectSubscriptionTrialPeriodPrice,
  selectTrialSubscriptionFullPriceBeforeDiscount,
} from 'modules/purchase/redux/selects'

import { StyledCheckoutSummary as S } from './CheckoutSummary.styles'

export const TrialCheckoutSummary: React.FC = () => {
  const { t } = useTranslation()

  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const currency = useSelector(selectCurrency)
  const product = useSelector(selectProductId)
  const discountAmount = useSelector(selectSubscriptionIntroDifAmount)
  const discountPercent = useSelector(selectSubscriptionIntroDifPercents)
  const fullPrice = useSelector(selectSubscriptionFullPrice)
  const trialCurrentPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const trialPriceBeforeDiscount = useSelector(
    selectTrialSubscriptionFullPriceBeforeDiscount,
  )

  const hasVatInfo = useVatInfo()

  const oldPrice = useMemo(() => {
    if (
      product === PurchaseProduct.SEVEN_DAY &&
      !COHORTS_WITHOUT_TRIAL_FOR_WEEKLY_PLAN.includes(cohortToUse)
    ) {
      return trialPriceBeforeDiscount.toFixed(2)
    }

    return fullPrice.toFixed(2)
  }, [fullPrice, cohortToUse, product, trialPriceBeforeDiscount])

  const currentPrice = useMemo(
    () => trialCurrentPrice.toFixed(2),
    [trialCurrentPrice],
  )

  const getOfferDiscountTitle = useMemo(
    () => t('purchase.checkoutV1.offerDiscount', { discountPercent }),
    [t, discountPercent],
  )

  return (
    <>
      <>
        <S.DescriptionContainer>
          <S.PersonalizedPlan>
            <Trans i18nKey="purchase.checkoutV1.personalizedPlan" />
          </S.PersonalizedPlan>
          <S.PlanPrice>
            {t('purchase.checkoutV1.fullPrice', {
              oldPrice,
              currency: CURRENCY_SYMBOLS[currency],
            })}
          </S.PlanPrice>
        </S.DescriptionContainer>
        <S.DescriptionContainer>
          <S.PersonalizedPlan>{getOfferDiscountTitle}</S.PersonalizedPlan>
          <S.Discount>
            -
            {t('purchase.checkoutV1.discountAmount', {
              discountAmount: discountAmount.toFixed(2),
              currency: CURRENCY_SYMBOLS[currency],
            })}
          </S.Discount>
        </S.DescriptionContainer>
      </>
      <S.TotalAmountContainer>
        <span>
          {t('purchase.checkoutV1.total', {
            includingVat: hasVatInfo ? t('purchase.includingVat') : '',
          })}
        </span>
        <span>
          {t('purchase.checkoutV1.price', {
            price: currentPrice,
            currency: CURRENCY_SYMBOLS[currency],
          })}
        </span>
      </S.TotalAmountContainer>
      <S.DiscountDescription>
        {t('purchase.checkoutV1.discountDescription', {
          discountAmount: discountAmount.toFixed(2),
          discountPercent,
          currency: CURRENCY_SYMBOLS[currency],
        })}
      </S.DiscountDescription>
    </>
  )
}
