import { useState } from 'react'

import { OptionType } from 'components/Option'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { TBuilderOptionProps, TPageProps } from 'models/common.model'

import { CHECKBOX_CUSTOM_VALUES } from 'root-constants'

type TProps = {
  isFirstPage?: boolean
} & TPageProps

export const useDynamicPage = ({
  pageId,
  nextPagePath,
  isFirstPage,
}: TProps) => {
  const [answers, setAnswers] = useState<string[]>([])
  const { title, customPageId, optionData, optionType, subtitle, buttonText } =
    useDynamicOBConfig()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: customPageId || pageId,
    question: title,
    nextPagePath,
    isFirstPage,
  })

  const handleSingleChoice = (value: string) => {
    handleChange(value, handleContinue)
    setIsAnswersDisabled(true)
  }

  const handleMultipleChoice = (value: string, isChecked: boolean) => {
    if (isChecked) {
      setAnswers([...answers, value])
      return
    }

    const newValues = answers.filter((item) => item !== value)
    setAnswers(newValues)
  }

  const handleCustomChoice = (value: string, isChecked: boolean) => {
    if (isChecked) {
      CHECKBOX_CUSTOM_VALUES.includes(value)
        ? setAnswers([value])
        : setAnswers((prevState) =>
            [...prevState, value].filter(
              (answer) => !CHECKBOX_CUSTOM_VALUES.includes(answer),
            ),
          )
      return
    }

    setAnswers((prevState) => [...prevState].filter((item) => item !== value))
  }

  const isCustomChoice = optionData
    ?.map((data) => data.value)
    .some((value) => CHECKBOX_CUSTOM_VALUES.includes(value))

  const optionHandler = (() => {
    if (optionType === OptionType.RADIO) return handleSingleChoice
    if (optionType === OptionType.CHECKBOX && !isCustomChoice) {
      return handleMultipleChoice
    }

    return handleCustomChoice
  })()

  const optionProps: TBuilderOptionProps = {
    name: pageId,
    type: optionType,
    onChange: optionHandler,
  }

  return {
    title,
    subtitle,
    buttonText,
    optionType,
    optionData,
    optionProps,
    answers,
    isAnswersDisabled,
    handleContinue,
  }
}
