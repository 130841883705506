import React, { lazy, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation, useRoutes } from 'react-router-dom'

import { usePageInfo } from 'contexts/PageInfoProvider'
import { Download } from 'pages/download'
import { FinishingTouches } from 'pages/finishingTouches'

import { AnimationProvider } from 'components/AnimationProvider'
import { OnboardingFlow } from 'components/OnboardingFlow'
import { ProtectedRoute } from 'components/ProtectedRoute'

import {
  selectCurrentVariantCohort,
  selectLanguage,
} from 'root-redux/selects/common'
import {
  selectTestEnvironmentQueryParam,
  selectUUID,
} from 'root-redux/selects/user'

import { useUserStatus } from 'hooks/useUserStatus'

import { getDynamicPageId } from 'helpers/getDynamicPageId'
import { getPathFromPageId } from 'helpers/getPathFromPageId'

import { Login } from 'modules/login'

import { IStep } from 'models/variant.model'

import { ID_TO_PAGE_MAP, PageId } from 'page-constants'
import { PUBLIC_PAGES } from 'root-constants'

const PaymentProvider = lazy(() => import('components/PaymentProvider'))

export const RouteList: React.FC = () => {
  const { search } = useLocation()

  const cohort = useSelector(selectCurrentVariantCohort)
  const uuid = useSelector(selectUUID)
  const testEnvironmentQueryParam = useSelector(selectTestEnvironmentQueryParam)
  const language = useSelector(selectLanguage)

  const availableRoute = useUserStatus()
  const { steps, currentSubscriptionPageId } = usePageInfo()

  const firstPagePath = useMemo(
    () =>
      getPathFromPageId({
        pageId: steps[0]?.id,
        cohort,
        uuid,
        testEnvironmentQueryParam,
        language,
        currentSearch: search,
      }),
    [steps, cohort, uuid, testEnvironmentQueryParam, language, search],
  )

  const { onboardingPages, subscriptionPages, upsellPages } = useMemo(
    () =>
      steps.reduce<{
        onboardingPages: IStep[]
        subscriptionPages: IStep[]
        upsellPages: IStep[]
      }>(
        (accum, { isSubscriptions, isPayment, isUpsell }, ind, arr) => {
          if (isSubscriptions || isPayment) {
            accum.subscriptionPages.push(arr[ind])
            return accum
          }

          if (isUpsell) {
            accum.upsellPages.push(arr[ind])
            return accum
          }

          accum.onboardingPages.push(arr[ind])
          return accum
        },
        {
          onboardingPages: [],
          subscriptionPages: [],
          upsellPages: [],
        },
      ),
    [steps],
  )

  return useRoutes([
    { index: true, element: <Navigate to={firstPagePath} /> },
    {
      element: <OnboardingFlow />,
      children: onboardingPages.map(({ id: pageId }, index, arr) => {
        const currentPageId = pageId.includes('dynamic')
          ? getDynamicPageId(pageId)
          : pageId
        const CurrentPage = ID_TO_PAGE_MAP[currentPageId]

        const nextPagePath = getPathFromPageId({
          pageId: arr[index + 1]?.id,
          cohort,
          uuid,
          testEnvironmentQueryParam,
          language,
          currentSearch: search,
        })

        const alternativePageId = arr.find(
          (dynamicPage, dynamicPageIndex) =>
            !dynamicPage.isSkippable && dynamicPageIndex > index,
        )?.id

        const alternativePagePath = getPathFromPageId({
          pageId: alternativePageId || arr[index + 1]?.id,
          cohort,
          uuid,
          testEnvironmentQueryParam,
          language,
          currentSearch: search,
        })

        const isFirstPage = index === 0

        if (PUBLIC_PAGES.includes(pageId)) {
          return {
            path: pageId,
            element: (
              <AnimationProvider>
                <CurrentPage
                  isFirstPage={isFirstPage}
                  pageId={pageId}
                  nextPagePath={nextPagePath}
                  alternativePagePath="/"
                />
              </AnimationProvider>
            ),
          }
        }

        return {
          path: pageId,
          element: (
            <ProtectedRoute isAvailable={!availableRoute}>
              <AnimationProvider>
                <CurrentPage
                  isFirstPage={isFirstPage}
                  pageId={pageId}
                  nextPagePath={nextPagePath}
                  alternativePagePath={alternativePagePath}
                />
              </AnimationProvider>
            </ProtectedRoute>
          ),
        }
      }),
    },
    {
      element: (
        <PaymentProvider>
          <Outlet />
        </PaymentProvider>
      ),
      children: [
        {
          element: <OnboardingFlow />,
          children: subscriptionPages.map(({ id: pageId }, index, arr) => {
            const PurchasePage = ID_TO_PAGE_MAP[pageId]
            const nextPagePath = getPathFromPageId({
              pageId: arr[index + 1]?.id,
              cohort,
              uuid,
              testEnvironmentQueryParam,
              language,
              currentSearch: search,
            })

            const alternativePageId = arr.find(
              (dynamicPage, dynamicPageIndex) =>
                !dynamicPage.isSkippable && dynamicPageIndex > index,
            )?.id

            const alternativePagePath = getPathFromPageId({
              pageId: alternativePageId || arr[index + 1]?.id,
              cohort,
              uuid,
              testEnvironmentQueryParam,
              language,
              currentSearch: search,
            })

            return {
              path: pageId,
              element: (
                <ProtectedRoute
                  isAvailable={availableRoute === currentSubscriptionPageId}
                >
                  <PaymentProvider>
                    <PurchasePage
                      pageId={pageId}
                      nextPagePath={nextPagePath}
                      alternativePagePath={alternativePagePath}
                    />
                  </PaymentProvider>
                </ProtectedRoute>
              ),
            }
          }),
        },
        {
          element: <OnboardingFlow />,
          children: upsellPages.map(({ id: pageId }, index, arr) => {
            const UpsellPage = ID_TO_PAGE_MAP[pageId]

            const nextPagePath = getPathFromPageId({
              pageId: arr[index + 1]?.id,
              cohort,
              uuid,
              testEnvironmentQueryParam,
              language,
              currentSearch: search,
            })

            const alternativePageId = arr.find(
              (dynamicPage, dynamicPageIndex) =>
                !dynamicPage.isSkippable && dynamicPageIndex > index,
            )?.id

            const alternativePagePath = getPathFromPageId({
              pageId: alternativePageId || arr[index + 1]?.id,
              cohort,
              uuid,
              testEnvironmentQueryParam,
              language,
              currentSearch: search,
            })

            return {
              path: pageId,
              element: (
                <ProtectedRoute isAvailable={availableRoute === pageId}>
                  <PaymentProvider>
                    <UpsellPage
                      pageId={pageId}
                      nextPagePath={nextPagePath}
                      alternativePagePath={alternativePagePath}
                    />
                  </PaymentProvider>
                </ProtectedRoute>
              ),
            }
          }),
        },
      ],
    },
    {
      path: PageId.FINISHING_TOUCHES,
      element: (
        <ProtectedRoute
          isAvailable={availableRoute === PageId.FINISHING_TOUCHES}
        >
          <AnimationProvider>
            <FinishingTouches />
          </AnimationProvider>
        </ProtectedRoute>
      ),
    },
    {
      path: PageId.LOGIN,
      element: (
        <ProtectedRoute
          isAvailable={availableRoute === PageId.FINISHING_TOUCHES}
        >
          <AnimationProvider>
            <Login />
          </AnimationProvider>
        </ProtectedRoute>
      ),
    },
    {
      path: PageId.DOWNLOAD,
      element: (
        <ProtectedRoute isAvailable={availableRoute === PageId.DOWNLOAD}>
          <AnimationProvider>
            <Download />
          </AnimationProvider>
        </ProtectedRoute>
      ),
    },
    { path: '*', element: <Navigate to={firstPagePath} /> },
  ])
}
