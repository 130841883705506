import { CDN_FOLDER_LINK } from 'root-constants'

const linkPrefix = `${CDN_FOLDER_LINK}/videos`
export const VIDEO_REVIEWS = [
  {
    id: 'video_1_britney',
    userName: 'Britney, 37',
    videoUrl: `${linkPrefix}/short_video_review_britney.mp4`,
    posterUrl: `${linkPrefix}/britney-video-poster.png`,
  },
  {
    id: 'video_2_brandon',
    userName: 'Brandon, 35',
    videoUrl: `${linkPrefix}/short_video_review_brandon.mp4`,
    posterUrl: `${linkPrefix}/brandon-video-poster.png`,
  },
  {
    id: 'video_3_leslie',
    userName: 'Leslie, 48',
    videoUrl: `${linkPrefix}/short_video_review_leslie.mp4`,
    posterUrl: `${linkPrefix}/leslie-video-poster.png`,
  },
] as const
