import { ISubscription } from 'models/subscriptions.model'

import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { OutbrainEventName, TaboolaEventName } from 'root-constants'

type TProps = {
  uuid: string
  subscriptionsList: ISubscription[]
}

export const logGeneralCheckoutStartedEvents = ({
  uuid,
  subscriptionsList,
}: TProps) => {
  window.fbq('track', 'InitiateCheckout', {}, { eventID: uuid })
  window.obApi?.('track', OutbrainEventName.CHECKOUT)
  window._tfa?.push({
    notify: 'event',
    name: TaboolaEventName.CHECKOUT,
  })

  googleAnalyticsLogger.logCheckoutStarted(subscriptionsList)
}
