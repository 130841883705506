import React, { useCallback, useLayoutEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { TimerProvider } from 'contexts/TimerProvider'

import { KitPageContainer } from 'components/PageContainer'
import { Spinner } from 'components/Spinner'

import {
  getSubscriptionListAction,
  setScreenNameAction,
} from 'root-redux/actions/common'
import { sendFacebookParamsAction } from 'root-redux/actions/user'
import {
  selectIsPersonalDataAllowed,
  selectUserOnboardingEmail,
} from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store'

import { useVatInfo } from 'hooks/useHasVatInfo'
import { usePayment } from 'hooks/usePayment'
import { useTargetHiddenOnScroll } from 'hooks/useTargetHiddenOnScroll'

import { CustomerReviews, PlanHighlights } from 'modules/purchase/components'
import { FIFTEEN_MINUTES_IN_SECONDS } from 'modules/purchase/constants'
import { useDynamicPaywallConfig } from 'modules/purchase/hooks/useDynamicPaywallConfig'

import { TPageProps } from 'models/common.model'

import banner from 'assets/images/kit-purchase-email-v1-banner.png'

import { goTo } from 'browser-history'
import {
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { StyledKitPurchaseEmailVariant1 as S } from './KitPurchaseEmailVariant1.styles'

export const KitPurchaseEmailVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const { title } = useDynamicPaywallConfig()
  const targetElementRef = useRef<HTMLHeadingElement>(null)

  const email = useSelector(selectUserOnboardingEmail)
  const isPersonalDataAllowed = useSelector(selectIsPersonalDataAllowed)

  const { hasPrices, handleShowPayment } = usePayment()
  const hasVatInfo = useVatInfo()

  const isTargetHidden = useTargetHiddenOnScroll(targetElementRef)

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.ONBOARDING))

    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        hasVatInfo ? SubscriptionTags.TAX : SubscriptionTags.NO_TAX,
      ),
    )
  }, [dispatch, hasVatInfo])

  const handleContinue = useCallback(() => {
    dispatch(sendFacebookParamsAction())
    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
    handleShowPayment()

    goTo(nextPagePath)
  }, [dispatch, email, handleShowPayment, isPersonalDataAllowed, nextPagePath])

  return hasPrices ? (
    <TimerProvider id={pageId} time={FIFTEEN_MINUTES_IN_SECONDS}>
      <S.PositionedTimer isVisible={isTargetHidden} />
      <KitPageContainer
        paddingTop={16}
        hasContinueButton
        continueButtonContent={t('actions.continue')}
        onContinueButtonClick={handleContinue}
      >
        <S.Banner>
          <img src={banner} alt="banner" />
        </S.Banner>
        <S.Title>
          {(title && <Trans>{title}</Trans>) || (
            <Trans i18nKey="kitPurchaseEmailV1.title" />
          )}
        </S.Title>
        <S.PersonalizedPlanTimer />
        <PlanHighlights titleElementRef={targetElementRef} />
        <S.SubscriptionsBlock titleMargin={16} />
        <S.Disclaimer />
        <CustomerReviews withTitle />
      </KitPageContainer>
    </TimerProvider>
  ) : (
    <Spinner />
  )
}
